const acid = {
    concept_name: "Acid",
    concept_type: "Damage Type",
    concept_text: "Elemental Damage may be blocked by weapons and shields.$$ Dissolution and disintegration destroy all things, given enough potency and time. "
}

const arcane = {
    concept_name: "Arcane",
    concept_type: "Damage Type",
    concept_text: "This damage type is %%unblockable%%.$$ Pure energy of arcana focused into destructive force. "
}

const fire = {
    concept_name: "Fire",
    concept_type: "Damage Type",
    concept_text: "Elemental Damage may be blocked by weapons and shields.$$ Searing magical flames or white-hot steel, both will burn right through flesh and metal. "
}

const cold = {
    concept_name: "Cold",
    concept_type: "Damage Type",
    concept_text: "Elemental Damage may be blocked by weapons and shields..$$ The chill of a blizzard, weaponized. Cold comes in many forms, but sapping the heat from a body is the same regardless of means. "
}

const damage = {
    concept_name: "Damage",
    concept_type: "Damage Type",
    concept_text: "The default method of violence.$$ Arrows, claws, fists, swords, all are used to damage your enemies. Every weapon can do damage if in the hand of a skilled user."
}

const drain = {
    concept_name: "Drain",
    concept_type: "Damage Type",
    concept_text: "This damage type is %%unblockable%%.$$ This vampiric force both harms the recipient and heals the caster in equal share, provided they can hit their mark. If the target takes any damage, the user is healed for the full amount."
}

const elemental = {
    concept_name: "Elemental",
    concept_type: "Damage Type",
    concept_text: "This group of damage types are associated with specific elements. There are many creatures which possess weaknesses to damage of a specific element, which experimentation can reveal. Elemental Damage may be blocked by weapons and shields."
}

const harm = {
    concept_name: "Harm",
    concept_type: "Damage Type",
    concept_text: "This damage type is %%unblockable%%.$$ This damage type is unaffected by armor, and directly impacts health. It damages the living and heals the unliving."
}

const heal = {
    concept_name: "Heal",
    concept_type: "Damage Type",
    concept_text: "This damage type is %%unblockable%%.$$ This damage type is unaffected by armor, and directly impacts health. It damages the unliving and heals the living."
}

const pierce = {
    concept_name: "Pierce",
    concept_type: "Damage Type",
    concept_text: "This damage type is %%unblockable%%.$$ This conventional damage bypasses even the most sturdy defenses, and may only be absorbed by health and armor."
}

const pure = {
    concept_name: "Pure",
    concept_type: "Damage Type",
    concept_text: "This damage type is %%unblockable%%.$$ Damage of an unknown nature used by @@The Endless@@. This annihilating force comes from beyond, too foreign for any other definition."
}

const shock = {
    concept_name: "Shock",
    concept_type: "Damage Type",
    concept_text: "Elemental Damage may be blocked by weapons and shields.$$ High current is destructive to all conductive mediums, and nearly everything is conductive if you use enough."
}

export const damageTypes = [acid, arcane, cold, damage, drain, elemental, fire, harm, heal, pierce, pure, shock]

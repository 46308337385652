import { Box, Button, Divider, Grid, Input, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { getBankByCharacterId, updateBankById } from "../../processing/RestClient";


function AdminBankTab() {

  const [responseVal, setResponseVal] = useState("");
  const [characterId, setCharacterId] = useState(-1);
  const [characterBank, setCharacterBank] = useState("");
  const [goldTotal, setGoldTotal] = useState(-1);
  const [bankInv, setBankInv] = useState([]);
  const [fontColor, setFontColor] = useState("primary");
  const [loaded, setLoaded] = useState(false);

  const [bronzeCount, setBronzeCount] = useState(-1);
  const [electrumCount, setElectrumCount] = useState(-1);
  const [glassCount, setGlassCount] = useState(-1);
  const [orichalcumCount, setOrichalcumCount] = useState(-1);
  const [mithrilCount, setMithrilCount] = useState(-1);
  const [galvornCount, setGalvornCount] = useState(-1);
  const [hexiteCount, setHexiteCount] = useState(-1);
  const [manaCount, setManaCount] = useState(-1);
  const [candleCount, setCandleCount] = useState(-1);
  const [toothCount, setToothCount] = useState(-1);
  const [featherCount, setFeatherCount] = useState(-1);

  const bankSub = {
    character_name: "",
    gold_total: goldTotal,
    banked_resources: []
  };

  const valueMatcher = (nameString) => {
    switch (nameString) {
      case "Bronze Ingot":
        return bronzeCount;
        break;
      case "Electrum Ingot":
        return electrumCount;
        break;
      case "Glass Ingot":
        return glassCount;
        break;
      case "Orichalcum Ingot":
        return orichalcumCount;
        break;
      case "Mithril Ingot":
        return mithrilCount;
        break;
      case "Galvorn Ingot":
        return galvornCount;
        break;
      case "Hexite":
        return hexiteCount;
        break;
      case "Crystalized Mana":
        return manaCount;
        break;
      case "Sacred Candle":
        return candleCount;
        break;
      case "Petrified Dragon Tooth":
        return toothCount;
        break;
      case "Fossilized Raven Feather":
        return featherCount;
        break;
      default:
        return null;
    }
  }

  const writeMatcher = (nameString, value) => {
    switch (nameString) {
      case "Bronze Ingot":
        return setBronzeCount(value);
        break;
      case "Electrum Ingot":
        return setElectrumCount(value);
        break;
      case "Glass Ingot":
        return setGlassCount(value);
        break;
      case "Orichalcum Ingot":
        return setOrichalcumCount(value);
        break;
      case "Mithril Ingot":
        return setMithrilCount(value);
        break;
      case "Galvorn Ingot":
        return setGalvornCount(value);
        break;
      case "Hexite":
        return setHexiteCount(value);
        break;
      case "Crystalized Mana":
        return setManaCount(value);
        break;
      case "Sacred Candle":
        return setCandleCount(value);
        break;
      case "Petrified Dragon Tooth":
        return setToothCount(value);
        break;
      case "Fossilized Raven Feather":
        return setFeatherCount(value);
        break;
      default:
        return null;
    }
  }

  const buildCompArray = () => {
    const newArr = [];
    console.log(loaded);
    if (loaded) {
      bankInv.forEach((resource) => {
        console.log(resource);
        const relevantCount = parseInt(valueMatcher(resource.resource_name));
        console.log(valueMatcher(resource.resource_name));
        console.log(resource.resource_count);
        if (relevantCount !== null && relevantCount !== resource.resource_count) {
          console.log("fired")
          newArr.push({
            resource_name: resource.resource_name,
            resource_count: relevantCount,
          })
        }
      })
    }
    console.log(newArr)
    if (newArr.length > 0) {
      console.log("fired 2");
      setBankInv(newArr);
      console.log(bankInv);
    }
    console.log(bankInv)
  }

  const submitBankUpdate = () => {
    if (!(characterId === -1 || characterId === 0 || characterId === null)) {
      if (bankInv.length > 0) {
        if (goldTotal > -1) {
          let currentBankSub;
          buildCompArray();
          console.log(bankInv);
          currentBankSub = { ...bankSub };
          console.log(currentBankSub);
          console.log(characterId);
          currentBankSub.character_name = characterBank.character.character_name
          currentBankSub.banked_resources = bankInv;
          console.log(currentBankSub);
          updateBankById(characterId, currentBankSub).then((response) => {
            if (response.status === 200) {
              setFontColor("success");
              setResponseVal("Updated Bank");
            }
            else {
              setFontColor("error");
              setResponseVal(response.status);
            };
          });
        }
        else {
          setFontColor("error");
          setResponseVal("Invalid Gold total");
        };
      }
      else {
        setFontColor("error");
        setResponseVal("No Changes to bank Set");
      }
    }
    else {
      setFontColor("error");
      setResponseVal("No User ID Set");
    }
  }

  const getBank = () => {
    console.log(characterId);
    if (characterId === -1 || characterId === 0 || characterId === null || characterId === "") {
      setResponseVal("No User ID Set");
    }
    else {
      let repoBank = {};
      getBankByCharacterId(characterId).then((response) => {
        if (response.status === 200) {
          console.log(response);
          repoBank = response.data;
          setGoldTotal(repoBank.gold_total);
          if (repoBank.banked_resources !== null && repoBank.banked_resources.length > 0) {
            const newArr = []
            repoBank.banked_resources.forEach((resourceCounter) => {
              const resSub = {
                resource_count: resourceCounter.resource_count,
                resource_name: resourceCounter.resource_item.resource_name
              }
              newArr.push(resSub);
              console.log(resSub)
              writeMatcher(resSub.resource_name, resSub.resource_count);
            })
            setBankInv(newArr);
            console.log(newArr);
          }
          else {
            setBankInv([]);
          }
          setCharacterBank(response.data)
          setLoaded(true);
        } else {
          setFontColor("error")
          setResponseVal(response.status);
        }
      });
    }
  }



  return (
    <Grid container direction="column" xs={12} maxWidth={"md"}>
      <Grid contianer item xs={12}>
        <Grid item>
          <TextField type="number" label="Character ID" id="character-id" required onChange={(e) => setCharacterId(e.target.value)} />
        </Grid>
        <Button variant="contained" onClick={() => getBank()}>
          Get Bank
        </Button>
      </Grid>
      <Box>
        <Divider />
      </Box>
      <Grid item>
        <TextField type="number" label="Gold" id="gold-count" value={goldTotal} required onChange={(e) => setGoldTotal(e.target.value)} />
      </Grid>
      <Grid item>
        <Typography>
          Components
        </Typography>
      </Grid>
      <Grid container direction="row" item xs={12} spacing={2}>
        <Grid container direction="column" item>
          <Grid item>
            <TextField type="number" label="Hexite" id="hexite-count" value={hexiteCount} required onChange={(e) => setHexiteCount(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField type="number" label="Mana" id="mana-count" value={manaCount} required onChange={(e) => setManaCount(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField type="number" label="Candle" id="candle-count" value={candleCount} required onChange={(e) => setCandleCount(e.target.value)} />
          </Grid>
        </Grid>
        <Grid container direction="column" item>
          <Grid item>
            <TextField type="number" label="Tooth" id="tooth-count" value={toothCount} required onChange={(e) => setToothCount(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField type="number" label="Feather" id="feather-count" value={featherCount} required onChange={(e) => setFeatherCount(e.target.value)} />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Divider />
      </Box>
      <Grid item>
        <Typography>
          Ingots
        </Typography>
      </Grid>
      <Grid container direction="row" item xs={12} spacing={2}>
        <Grid container direction="column" item>
          <Grid item>
            <TextField type="number" label="Bronze" id="bronze-count" value={bronzeCount} required onChange={(e) => setBronzeCount(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField type="number" label="Electrum" id="electrum-count" vale={electrumCount} required onChange={(e) => setElectrumCount(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField type="number" label="Glass" id="glass-count" value={glassCount} required onChange={(e) => setGlassCount(e.target.value)} />
          </Grid>
        </Grid>
        <Grid container direction={"column"} item>
          <Grid item>
            <TextField type="number" label="Orichalcum" id="orichalcum-count" value={orichalcumCount} required onChange={(e) => setOrichalcumCount(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField type="number" label="Mithril" id="mithril-count" value={mithrilCount} required onChange={(e) => setMithrilCount(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField type="number" label="Galvorn" id="galvorn-count" value={galvornCount} required onChange={(e) => setGalvornCount(e.target.value)} />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Divider />
      </Box>
      <Grid item container justifyContent={"center"} xs={12}>
        <Grid item>
          <Typography color={fontColor}>
            {responseVal}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => submitBankUpdate()}>
            Submit Updated
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

}

export default AdminBankTab;
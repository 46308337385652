import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { findConcept, formatTextBlob } from "../../processing/DataFormattingUtils";
import { AbilityTextMatcher } from "../PillarBuild/PillarBuildUtils";

function SummaryAbility({ pbAbility, encounterPbAbility }) {

  const [abilityDailyCount, setAbilityDailyCount] = useState(0);
  const [abilityEncounterCount, setAbilityEncounterCount] = useState(0);

  const abilityMatch = AbilityTextMatcher(pbAbility.ability);
  const abilArray = [];
  const encArray = [];

  for (let i = 0; i < pbAbility.ability_count; i++) {
    abilArray.push(1);
  }

  if (encounterPbAbility != null) {
    for (let i = 0; i < pbAbility.ability_count; i++) {
      encArray.push(1);
    }
  }

  const dailyChange = (checked) => {
    if (checked) {
      setAbilityDailyCount(abilityDailyCount - 1);
    }
    else {
      setAbilityDailyCount(abilityDailyCount + 1);
    }
  }

  const abilUses = pbAbility.ability_count * pbAbility.ability.ability_uses;

  console.log(abilUses);

  return (
    <Accordion>
      <AccordionSummary sx={{ border: '2px solid #2A2A2A', fontWeight: 'bold' }}
        expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row" spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <Typography>
              {pbAbility ? pbAbility.ability.ability_name : null}
            </Typography>
          </Grid>
          <Grid item>
            <Stack direction="row">
              <Typography variant="b1">
                {abilityDailyCount}
                <Typography variant="b1">
                  {/* TODO: There needs to be a multiplier here for that ability's uses. */}
                  / {pbAbility && pbAbility.ability.ability_uses != null ? abilUses : null}
                </Typography>
              </Typography>
              {!encounterPbAbility ? null :
                <Typography variant="b1">
                  {abilityEncounterCount}
                  <Typography variant="b1">
                    / {encounterPbAbility.ability_count}
                  </Typography>
                </Typography>
              }
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '15px 5px 16px;', border: '2px solid #2A2A2A' }}>
        <Grid container justifyContent="flex-start" alignItems={"flex-start"}>
          <Grid item container>
            <Grid container item>
              <Grid item>Daily</Grid>
              <Grid xs={12} item container>
                {[...Array(pbAbility.ability_count)].map((content, index) => {
                  return (
                    <Grid item>
                      <Checkbox 
                      // onChange={() => dailyChange(checked)} 
                      color="primary" />
                    </Grid>)
                })}
              </Grid>
            </Grid>
            {!encounterPbAbility ? null :
              <Grid container item>
                <Grid item>Encounter-</Grid>
                <Grid xs={12} item container>
                  {[...Array(encounterPbAbility.ability_count)].map((content, index) => {
                    return (
                      <Grid item>
                        <Checkbox defaultChecked color="primary" />
                      </Grid>)
                  })}
                </Grid>
              </Grid>
            }
          </Grid>
          <Divider sx={{ width: "100%" }} />
          <Grid xs={12} item>
            <Stack direction="row" spacing={1}>
              {pbAbility.ability.ability_tags.map((tag, index) => {
                const tagConcept = findConcept(tag);
                return (
                  <Box container>
                    <Tooltip title={tagConcept ? formatTextBlob(tagConcept.concept_text) : ""}>
                      <Typography color="primary" id="modal-modal-title" variant="subtitle1">
                        {tag.toUpperCase()} {pbAbility.ability.ability_tags.length - 1 === index ? null : bullet}
                      </Typography>
                    </Tooltip>
                  </Box>
                )
              })}
            </Stack>
          </Grid>
          <Divider sx={{ width: "100%" }} />
          <Grid xs={12} item>
            {formatTextBlob(abilityMatch.ability_text)}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const bullet = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default SummaryAbility;
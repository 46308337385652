const soldier = {
    pillar_name: "Soldier",
    pillar_text: "This pillar represents the grit and tenacity of a Character and their will to survive, facing impossible odds or wounds that would kill lesser beings. Clad in armor, the Soldier is capable of both defending their allies and carving their enemies apart in a show of brutal efficiency, the quintessential martial fighter is the Soldier. ",
    pillar_description: "This Pillar focuses on weapon damage, increased physical health and armor, and has many Skill abilities focused on defense."
}

const mage = {
    pillar_name: "Mage",
    pillar_text: "This Pillar represents a Character’s acumen in channeling the magic that permeates the elemental structures of the world. Masters of both the destructive and defensive capabilities of magic, the Mage is the epitome of its myriad facets.",
    pillar_description: "This Pillar focuses on magical offense, spell shields, and has many Spell abilities focused on temporary buffs."
}

const chemist = {
    pillar_name: "Chemist",
    pillar_text: "This Pillar represents a Character’s knowledge of the secret formulas, allowing them to to transmute and control the chemicals of the body and world to heal or fortify your allies. Such are not to be taken as churigeons and healers only, as such mastery allows one to cause a slow and painful death.",
    pillar_description: "This Pillar focuses on combat preparation and restoration, melee condition and condition prevention, and has many Skill abilities focused on powerful quick-count healing."
}

const cleric = {
    pillar_name: "Cleric",
    pillar_text: "This Pillar represents the Character’s prowess at leveraging their connection to the gods to perform miracles. Some of these manifest in the form of offensive spells, others in healing light. Regardless of how your god’s will is manifested, you channel it in a way unlike any other.",
    pillar_description: "This Pillar focuses on inflicting conditions, quick healing, and has many Spell abilities focused on removing conditions."
}

const sage = {
    pillar_name: "Sage",
    pillar_text: "This Pillar represents the Character’s collected knowledge and applying it in the right way at the right time. The Sage calmly understands their situation through their knowledge and can both act and instruct others in the correct course of action. The Sage is unique in that its discipline is just as beneficial to others as it is to itself.",
    pillar_description: "This Pillar focuses on team play, long-duration buffs, and has many Shout abilities focused on group cooperation."
}

const berserker = {
    pillar_name: "Berserker",
    pillar_text: "This specialization represents a Character’s ability to engage in thoughtless slaughter, dealing titanic blows while receiving wounds that would fell less hardy warriors. Their fury knows no bounds, and none shall deny them the Berserker’s right to bloody victory.",
    pillar_description: "This Specialization focuses on damage, self-healing, high health, and has many Skill abilities focused on Pierce damage."
}

const rogue = {
    pillar_name: "Rogue",
    pillar_text: "This specialization represents a Character’s ability to outplay and disrupt their foes. A rogue knows that a blow to the temple or chop to the throat are often more appropriate than a bloodcurdling scream, and they know when to apply them in tandem with their blades.",
    pillar_description: "This Specialization focuses on tactical exploitation, backstab damage, and has many Skill abilities focused on powerful short-term negative conditions."
}

const priest = {
    pillar_name: "Priest",
    pillar_text: "This specialization represents a Character’s connection to the gods of health and healing. Through this link, the Priest is able to bring wholeness of body and mind to the children of the gods, shining as a beacon of hope to those in need.",
    pillar_description: "This Specialization focuses on removing conditions, large amounts of touch healing, and has many Spell abilities focused on rapid pick up healing."
}

const necromancer = {
    pillar_name: "Necromancer",
    pillar_text: "This specialization represents a Character’s connection to the gods of death and their ability to control their charnel magics. The Necromancer is a master of death so potent that they are able to fuel their own bodies with the vital forces of other beings",
    pillar_description: "This Specialization focuses on raising the undead, consumptive self-healing, and has many Spell abilities focused on applying negative conditions."
}

const aegis = {
    pillar_name: "Aegis",
    pillar_text: "This specialization represents a Character’s mastery of the defensive arcane arts. Your wards are sturdier, your defenses more impregnable, and your understanding of magic so potent that you are able not only to protect yourself and others from harm, but often even turn that harm back upon those who would deal it.",
    pillar_description: "This Specialization focuses on magical defense, battlefield control, and has many Spell abilities focused on granting defenses."
}

const evoker = {
    pillar_name: "Evoker",
    pillar_text: "This specialization represents a Character’s arcane connection to the basic elements of creation. Manipulating and enhancing these forces allows the user to create surges of destructive energy in both themselves and their foes.",
    pillar_description: "This Specialization focuses on elemental control, improving weapons, and has many Spell abilities focused on elemental damage."
}

const poisoner = {
    pillar_name: "Poisoner",
    pillar_text: "This specialization represents a Character’s mastery over the toxic and alkaline substances of the world, used to cause myriad negative effects on their victims. Some of your poisons utilize rare and unusual compounds which allow you to create even more exotic effects.",
    pillar_description: "This Specialization focuses on potent melee conditions, acid damage, and has many Skill abilities focused on prepared long-duration negative conditions."
}

const pharmacist = {
    pillar_name: "Pharmacist",
    pillar_text: "This specialization represents a Character’s mastery over the soothing balms and revitalizing fluids of the world, and their extensive knowledge in how to prepare them. Your mastery of the alchemical sciences has even led you to be able to enhance your body in unique and powerful ways.",
    pillar_description: "This Specialization focuses on potent healing, condition prevention, and has many Skill abilities focused on buffs."
}

const bard = {
    pillar_name: "Bard",
    pillar_text: "This specialization represents a Character’s mastery over the lore of the world and the ability to properly deliver it. The Bard’s knowledge has given them access to secret methods of speech and song that allow them to impact their foes' psyche directly.",
    pillar_description: "This Pillar focuses on lores, unique damage abilities, and has many Shout abilities focused on disrupting enemies."
}

const tactician = {
    pillar_name: "Tactician",
    pillar_text: "This specialization represents a Character’s mastery over commanding forces of soldiers, and the optimization of their positioning and timing. This character can inspire their warriors to perform phenomenal feats, and strike fear into the hearts of their foes.",
    pillar_description: "This Pillar focuses on strategic play, granting abilities, and has many Shout abilities focused on cohort effects."
}

export const pillarsArray = [chemist, cleric, mage, sage, soldier, berserker, rogue, priest, necromancer,
    aegis, evoker, poisoner, pharmacist, bard, tactician]
import { Button, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function AbilityModalButton(props) {
    const ability = props.ability;
    const currentAbility = props.currentAbility;
    const originalAbility = props.originalAbility;
    const disabled = props.disabled;

    let colorValue = "primary"

    if (currentAbility.ability_name === ability.ability_name) {
        colorValue = "success"
    }
    else if (ability.ability_name === originalAbility.ability_name) {
        colorValue = "warning"
    }

    return (
        <Button startIcon={<AddCircleOutlineIcon />} variant="contained"
            color={colorValue}
            onClick={props.onClick}
            disabled={disabled}
        >
            <Stack>
                <Typography >
                    {ability.ability_name}
                </Typography>
                <Typography variant="caption">
                    {ability.prerequesite ? ability.prerequesite : null}
                </Typography>
                <Typography variant="caption">
                    {ability.ability_tier_limit ? "Tier Limit: " + ability.ability_tier_limit  : null}
                </Typography>
            </Stack>
        </Button>
    )
}
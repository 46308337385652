//Tier 1
export const cohort = 
{ability_name: "Cohort",
ability_text: "You have gathered about you the pinnacle of adventurers, and with your skilled hand they operate as a well oiled machine. You pick and wear a Cohort symbol that you have registered with game staff. This symbol bestows upon you an additional bonus point of Health and Armor for each time you purchase this Ability. Additionally, for each time you purchase this Skill, one other person gains its benefits so long as they wear your symbol. A Character may only be a part of one Cohort at a time, and no more than twelve creatures may be a part of a single cohort.",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Passive", "Cohort"],
ability_cost: 1,
ability_uses: 0,
ability_tier_limit: null,
}

export const diagnose = 
{ability_name: "Diagnose",
ability_text: "Your extensive knowledge of medicine allows you to rapidly determine the causes of illnesses. You may Touch a creature, and upon completion of a three Second “Diagnose” Count, they are required to tell you their current Health, total Health, and any Conditions affecting them. Creatures may Resist if they are capable of fending off killing blows.",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Skill", "Touch", "3 Count"],
ability_cost: 1,
ability_uses: 0,
ability_tier_limit: 1,
}

export const enhanceWeapon = 
{ability_name: "Enhance Weapon",
ability_text: "Your knowledge of weaponry allows you to maintain it much better than one might normally expect. You must Touch a weapon, and upon the completion of a thirty Second Count of “Enhancing Weapon”, that weapon calls one additional point of one-handed damage until the start of the next Encounter.",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Skill", "Touch", "30 Count"],
ability_cost: 1,
ability_uses: 1,
ability_tier_limit: null,
}

export const identifyCreature = 
{ability_name: "Identify Creature",
ability_text: "Your extensive knowledge of beasts and monsters allows you to understand them rapidly. You may Touch any slain creature and upon completion of a 5 Count say “Identify Creature” to them, causing them to report to you the name and type of the creature they are. For each time this Skill is purchased, the user picks one creature type from the list of creatures in the core rules, and regards that creature as a “Favored Foe.”",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Skill", "Touch", "5 Count"],
ability_cost: 1,
ability_uses: 0,
ability_tier_limit: null,
}

// Tier 2

export const enhanceArmor = 
{ability_name: "Enhance Armor",
ability_text: "Your knowledge of Armor allows you to reinforce it to a great degree. You must Touch a chest piece or helmet, and upon the completion of a thirty Second Count of “Enhancing Armor,” that Armor grants five additional Armor points until the end of the next Encounter.",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Skill", "Touch", "30 Count"],
ability_cost: 2,
ability_uses: 1,
ability_tier_limit: null,
}

export const firstAid = 
{ability_name: "First Aid",
ability_text: "You are capable of making split second decisions that can save a life in the heat of battle. You may Touch a Dying creature, and upon completion of a thirty Second “First Aid” Count, their Health is set to one. For the duration of this Count, their “Dying” Count is suspended.",
pillar_name: "Sage",
prerequesite: "Diagnose",
ability_tags:["Skill", "Touch", "30 Count"],
ability_cost: 2,
ability_uses: 0,
ability_tier_limit: 1,
}

export const lorePrevention = 
{ability_name: "Lore: Prevention",
ability_text: "Your knowledge of creatures allows you to teach others tricks to avoid their nastiest attacks. You must choose up to five creatures within Touch of you, and upon the completion of a ten Second Count of “Prevention,” you may grant those creatures the power to call %%Shout Counter {Favored Foe}%% against one Attack or Ability before the end of the next Encounter so long as that creature is within Reach. You must select the Favored Foe from your list of such. Only one Lore may be active on a Target at a time.",
pillar_name: "Sage",
prerequesite: "Identify Creature",
ability_tags:["Skill", "Touch", "10 Count", "Counter", "Lore"],
ability_cost: 2,
ability_uses: 1,
ability_tier_limit: null,
}

export const warcry = 
{ability_name: "Warcry / Embolden",
ability_text: "Your knowledge of motivation allows you great capacity in leading your Cohort, from the front or from afar. The user notifies their Cohort and may call %%Shout Counter Fear%% or %%Shout Counter Immobilize%%, with all members of the Cohort taking the Condition. Alternatively, the user may gather their Cohort within Reach, and upon the completion of a ten Second “Embolden” Count, grant them five temporary Armor.",
pillar_name: "Sage",
prerequesite: "Cohort",
ability_tags:["Shout", "Cohort", "Counter", "Fear/Immobalize", "Reach", "10 Count"],
ability_cost: 2,
ability_uses: 1,
ability_tier_limit: null,
}


//Tier 3 

export const enhanceBody = 
{ability_name: "Enhance Body",
ability_text: "Your knowledge of the body allows you to teach others how to control their breathing and reflexes to make them hardier. You must Touch another Character, and upon the completion of a thirty Second Count of “Enhancing Body,” that Character gains five additional Health until the end of the next Encounter.",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Skill", "Touch", "30 Count"],
ability_cost: 3,
ability_uses: 1,
ability_tier_limit: null,
}

export const rally = 
{ability_name: "Rally / Inspire",
ability_text: "You know how to lead your Cohort to help them push through the muck to victory. The user notifies their Cohort, and may call %%Shout 10 Heal%% or %%Shout 10 Harm%%, all members of the Cohort taking the effect. Note that this will damage unliving or living members of your cohort respectively. Alternatively, the user may gather his Cohort within Reach, and upon the completion of a ten Count, grant them the power to call %%Shout 5 Heal%%, or %%Shout 5 Harm%% once per Encounter, taking the effect individually.",
pillar_name: "Sage",
prerequesite: "Cohort",
ability_tags:["Shout", "Cohort", "Heal/Harm", "Reach", "10 Count"],
ability_cost: 3,
ability_uses: 1,
ability_tier_limit: null,
}

export const triage = 
{ability_name: "Triage",
ability_text: "You are so skilled in the ways of medicine that you are capable of performing field surgery to revive the dead. You may Touch a Dead creature, and upon completion of a 120 Second %%“Triage”%% Count, they are changed from Dead Status to Dying Status. For the duration of this Count, their “Dead” Count is suspended. ",
pillar_name: "Sage",
prerequesite: "First Aid",
ability_tags:["Skill", "Touch", "120 Count"],
ability_cost: 3,
ability_uses: 0,
ability_tier_limit: 1,
}

export const loreDestruction = 
{ability_name: "Lore: Destruction",
ability_text: "Your knowledge of creatures allows you to teach others how to more effectively kill them. You must choose up to five creatures within Touch of you, and upon the completion of a ten Second Count of “Destruction,” you may cause those creatures to call an additional point of one handed damage against one of your Favored Foes until the end of the next Encounter. You must select the Favored Foe from your list of such. Only one Lore may be active on a Target at a time.",
pillar_name: "Sage",
prerequesite: "Identify Creature",
ability_tags:["Shout", "Touch", "10 Count", "Lore"],
ability_cost: 3,
ability_uses: 1,
ability_tier_limit: null,
}

//Tier 4

export const assault = 
{ability_name: "Assault / Tactics",
ability_text: "Your tactical acumen inspires your Cohort such that they know just when to pounce. The user notifies their Cohort, and may call %%Shout Assault%%, granting their Cohort the power to call %%Shout {damage} Pierce%% on their individual next 5 weapon Attacks. Alternatively, the user may gather his Cohort within Reach, and upon the completion of a ten Count, grant them the power to call %%Shout {damage} Pierce%% twice until the end of their next Encounter.",
pillar_name: "Sage",
prerequesite: "Cohort",
ability_tags:["Shout", "Cohort", "Reach", "Pierce", "10 Count"],
ability_cost: 4,
ability_uses: 1,
ability_tier_limit: null,
}

export const loreEndurance = 
{ability_name: "Lore: Endurance",
ability_text: "Your knowledge of creatures allows you to help your allies recover from their attacks. You must choose up to five creatures within Touch of you, and upon the completion of a ten Second Count of %%“Endurance,”%% you may call %%Heal%% or %%Harm 10%% on all of those creatures, so long as one of your Target creatures have slain one of your Favored Foes since their last Encounter.",
pillar_name: "Sage",
prerequesite: "Identify Creature",
ability_tags:["Shout", "Touch", "10 Count", "Lore"],
ability_cost: 4,
ability_uses: 1,
ability_tier_limit: null,
}

export const repairArmor = 
{ability_name: "Repair Armor",
ability_text: "Field repair is a tricky business, but you have the necessary grit to pull it off.  You must Touch a Character wearing Armor, and upon the completion of a thirty Second Count of %%“Repairing Armor,”%% that Character's Armor total is set to its full value.",
pillar_name: "Sage",
prerequesite: "Enhance Armor",
ability_tags:["Skill", "Touch", "30 Count"],
ability_cost: 4,
ability_uses: 1,
ability_tier_limit: null,
}

export const snakeOil = 
{ability_name: "Snake Oil",
ability_text: "Your medical knowledge allows you to craft mundane elixirs that stop the flow of lifeblood in a pinch. While Touching another creature, you may complete a ten Second “Snake Oil” Count to give them the capacity to perform a five Second %%“Pouring” Count%% while Touching a creature. That creature's %%“Dying” Count%% is frozen for 120 Seconds.",
pillar_name: "Sage",
prerequesite: "Diagnose",
ability_tags:["Skill", "Reach", "10 Count"],
ability_cost: 4,
ability_uses: 1,
ability_tier_limit: null,
}

//Tier 5

export const battlePlan = 
{ability_name: "Battle Plan",
ability_text: "You have meticulously planned out each event that will occur in the battle, allowing your Cohort to be in the right place at the right time. The user must gather their Cohort within Reach, and upon the completion of a ten Count, grant them the power to call one additional point of one handed damage until the end of their next Encounter.",
pillar_name: "Sage",
prerequesite: "Cohort",
ability_tags:["Cohort", "Reach", "10 Count"],
ability_cost: 5,
ability_uses: 1,
ability_tier_limit: null,
}

export const enhanceResistance =
{ability_name: "Enhance Resistance",
ability_text: "You enchant the body and mind of your fellows against even the most disastrous effects. You must Touch another Character, and upon the completion of a thirty Second Count of %%“Enhancing Resistance,”%% that Character gains a single Resist All.",
pillar_name: "Sage",
prerequesite: "Enhance Body",
ability_tags:["Skill", "Touch", "30 Count"],
ability_cost: 5,
ability_uses: 1,
ability_tier_limit: null,
}

export const lorePreparation =
{ability_name: "Lore: Preparation",
ability_text: "Your knowledge of creatures allows you to teach others tricks to avoid their nastiest attacks. You must choose up to five creatures within Touch of you, and upon the completion of a ten Second Count of %%“Preparation,”%% you may grant those creatures the power to call %%Resist {Favored Foe}%% against one Condition delivered by your Favored Foe. You must select the Favored Foe from your list of such. Only one Lore may be active on a Target at a time.",
pillar_name: "Sage",
prerequesite: "Identify Creature",
ability_tags:["Shout", "Touch", "10 Count", "Counter", "Lore"],
ability_cost: 5,
ability_uses: 1,
ability_tier_limit: null,
}

export const medicalMiracle =
{ability_name: "Medical Miracle",
ability_text: "You are capable of curing almost all ailments, given time. You may gather up to five creatures within Touch, and upon completion of a 300 Second %%“Medical Miracle”%% Count, they are restored to full Health, and all Conditions are removed from them.",
pillar_name: "Sage",
prerequesite: "First Aid",
ability_tags:["Skill", "Touch", "300 Count", "Counter"],
ability_cost: 5,
ability_uses: 1,
ability_tier_limit: null,
}

//Tierless

export const sageExpertiseAbility = 
{ability_name: "Guidance",
ability_text: "Your knowledge crosses into many disciplines, and as a result you are able to help others use their powers at peak efficiency. You must Touch another creature, and on completion of a fifteen Second %%“Guidance”%% Count, you restore to them the use of one expended per Day or per Encounter Ability of a tier no higher than the tier of this Expertise.",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Shout", "Touch", "15 Count"],
ability_cost: null,
ability_uses: 1,
ability_tier_limit: 1,
}

export const sageCapstoneAbility = 
{ability_name: "Sage Champion",
ability_text: "Once per Day, when you use an active Ability that affects at least one creature, you may double the numerical effect of that Ability. ",
pillar_name: "Sage",
prerequesite: null,
ability_tags:["Passive", "Skill"],
ability_cost: 6,
ability_uses: 1,
ability_tier_limit: 1,
}
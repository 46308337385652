import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { adminDelete, adminGet, adminPost, adminPut } from "../../processing/RestClient";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AdminHTTPTab() {

  const [urlValue, setUrlValue] = useState("");
  const [bodyValue, setBodyValue] = useState("");
  const [responseValue, setResponseValue] = useState("");
  const [responseData, setResponseData] = useState("");
  const [httpMethod, setHttpMethod] = useState("get");

  const adminHTTPSubmit = () => {
    console.log(bodyValue);
    if (httpMethod === "get") {
      adminGet(urlValue).then((response) => {
        console.log(">>>>> Admin Get HTTP RESPONSE:");
        console.log(response);
        const data = JSON.stringify(response.data);
        setResponseData(data);
        setResponseValue(response.status);
      }).catch((error) => {
        console.log(">>>>> Admin Get HTTP Error:");
        console.log(error)
      });
    }
    if (httpMethod === "post") {
      adminPost(urlValue, bodyValue).then((response) => {
        console.log(">>>>> Admin Post HTTP RESPONSE:");
        console.log(response);
        const data = JSON.stringify(response.data);
        setResponseData(data);
        setResponseValue(response.status);
      }).catch((error) => {
        console.log(">>>>> Admin Post HTTP Error:");
        console.log(error)
      })
    }
    if (httpMethod === "put") {
      adminPut(urlValue, bodyValue).then((response) => {
        console.log(">>>>> Admin Put HTTP RESPONSE:");
        console.log(response);
        const data = JSON.stringify(response.data);
        setResponseData(data);
        setResponseValue(response.status);
      }).catch((error) => {
        console.log(">>>>> Admin Put HTTP Error:");
        console.log(error)
      })
    }
    if (httpMethod === "delete") {
      adminDelete(urlValue, bodyValue).then((response) => {
        console.log(">>>>> Admin Delete HTTP RESPONSE:");
        console.log(response);
        const data = JSON.stringify(response.data);
        setResponseData(data);
        setResponseValue(response.status);
      }).catch((error) => {
        console.log(">>>>> Admin Delete HTTP Error:");
        console.log(error)
      })
    }
  }

  return (
    <Paper sx={{m:"10px", p:"10px"}}>
      <Grid container maxWidth="md" direction="column" spacing={1}>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography>
              URL
            </Typography>
          </Grid>
          <Grid item>
            <TextField id="post-url" required onChange={e => setUrlValue(e.target.value)} />
          </Grid>
          <Grid item>
            <Select
              labelId="httpMethod"
              id="http-method-selector"
              value={httpMethod}
              label="httpMethod"
              required
              onChange={(event) => setHttpMethod(event.target.value)}
            >
              <MenuItem key={'post-dropdown-option'} value={"get"}> Get </MenuItem>
              <MenuItem key={'post-dropdown-option'} value={"post"}> Post </MenuItem>
              <MenuItem key={'post-dropdown-option'} value={"put"}> Put </MenuItem>
              <MenuItem key={'post-dropdown-option'} value={"delete"}> Delete </MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography>
              BODY
            </Typography>
          </Grid>
          <Grid item>
            <TextField multiline id="post-body" variant="outlined" color="primary" onChange={e => setBodyValue(e.target.value)} />
          </Grid>
        </Grid>
        {responseValue !== "" ?
          <Grid item container direction="column">
            <Accordion key={"heritage-lore-accordion"}>
              <AccordionSummary
                sx={{ backgroundColor: 'primary.dark' }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography align="center" variant="h6">
                  Response: {responseValue}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph variant="body2" sx={{maxWidth:"sm"}}>
                  <>{responseData}</>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          : null}
        <Grid item>
          <Button variant="outlined"
            onClick={() => adminHTTPSubmit()}>
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )

}

export default AdminHTTPTab;
import { chemistCapstone, clericCapstone, mageCapstone, pharmacistCapstone, aegisCapstone, berserkerCapstone, necromancerCapstone, poisonerCapstone, bardCapstone, priestCapstone, tacticianCapstone, evokerCapstone, rogueCapstone, sageCapstone, soldierCapstone } from "../../data/Capstone";
import ExpertiseButton from "../Expertise/ExpertiseButton/ExpertiseButton";
import CapstoneButton from "../Capstone/CapstoneButton/CapstoneButton";
import AbilityButton from "../Ability/AbilityButton/AbilityButton";
import { aegisAbilities, bardAbilities, berserkerAbilities, chemistAbilities, clericAbilities, evokerAbilities, mageAbilities, necromancerAbilities, pharmacistAbilities, poisonerAbilities, priestAbilities, rogueAbilities, sageAbilities, soldierAbilities, tacticianAbilities } from "../../data/AbilitiesByPillar";

export function createRenderablePillar(pillarBuild, pillarIndex) {
    const workingObj = {
        daily1: pillarBuild.daily_abilities.find(ability => ability.ability_cost === 1) ? indexTier([...pillarBuild.daily_abilities]).filter(ability => ability.ability_cost === 1) : [generateEmptyAbility(1, pillarBuild.pillar_name, pillarIndex)],
        daily2: pillarBuild.daily_abilities.find(ability => ability.ability_cost === 2) ? indexTier([...pillarBuild.daily_abilities]).filter(ability => ability.ability_cost === 2) : [generateEmptyAbility(2, pillarBuild.pillar_name, pillarIndex)],
        daily3: pillarBuild.daily_abilities.find(ability => ability.ability_cost === 3) ? indexTier([...pillarBuild.daily_abilities]).filter(ability => ability.ability_cost === 3) : [generateEmptyAbility(3, pillarBuild.pillar_name, pillarIndex)],
        daily4: pillarBuild.daily_abilities.find(ability => ability.ability_cost === 4) ? indexTier([...pillarBuild.daily_abilities]).filter(ability => ability.ability_cost === 4) : [generateEmptyAbility(4, pillarBuild.pillar_name, pillarIndex)],
        daily5: pillarBuild.daily_abilities.find(ability => ability.ability_cost === 5) ? indexTier([...pillarBuild.daily_abilities]).filter(ability => ability.ability_cost === 5) : [generateEmptyAbility(5, pillarBuild.pillar_name, pillarIndex)],
        dailyExp: pillarBuild.daily_expertise ? [...pillarBuild.daily_expertise] : [],
        enc1: indexTier([...pillarBuild.encounter_abilities]).filter(ability => ability.ability_cost === 1),
        enc2: indexTier([...pillarBuild.encounter_abilities]).filter(ability => ability.ability_cost === 2),
        enc3: indexTier([...pillarBuild.encounter_abilities]).filter(ability => ability.ability_cost === 3),
        encExp: pillarBuild.encounter_expertise ? [...pillarBuild.encounter_expertise] : [],
        capstone: pillarBuild.capstone,
    }
    const tier1 = createRenderableTier(pillarBuild.pillar_name, workingObj.daily1, workingObj.enc1, 1, pillarIndex);
    const tier2 = createRenderableTier(pillarBuild.pillar_name, workingObj.daily2, workingObj.enc2, 2, pillarIndex);
    const tier3 = createRenderableTier(pillarBuild.pillar_name, workingObj.daily3, workingObj.enc3, 3, pillarIndex);
    const tier4 = createRenderableTier(pillarBuild.pillar_name, workingObj.daily4, [], 4, pillarIndex);
    const tier5 = createRenderableTier(pillarBuild.pillar_name, workingObj.daily5, [], 5, pillarIndex);
    const capstone = findCapstone(pillarBuild.pillar_name);
    capstone.pillar_number = pillarIndex;

    return {
        tier1: tier1,
        tier2: tier2,
        tier3: tier3,
        tier4: tier4,
        tier5: tier5,
        expertise: createRenderableExpertise(workingObj.dailyExp, workingObj.encExp, pillarBuild.pillar_name, pillarIndex),
        capstone: { ...capstone },
        pillar_number: pillarIndex,
    }
}

function createRenderableTier(pillarName, dailyAbilities, encounterAbilities, tier, index) {
    const workingDailyAbil = []
    const workingEncounterAbil = []
    dailyAbilities.forEach((ability) => {
        const item = {
            pillar_number: index,
            pillar_build_index: ability.pillar_build_index,
            ability_name: ability.ability_name,
            ability_text: ability.ability_text,
            pillar_name: ability.pillar_name,
            prerequesite: ability.prerequesite,
            ability_tags: [...ability.ability_tags],
            ability_cost: ability.ability_cost,
            ability_uses: ability.ability_uses,
            ability_verbal: ability.ability_verbal,
            ability_tier_limit: ability.ability_tier_limit,
            usage: "daily",
        }
        workingDailyAbil.push(item);
    })
    encounterAbilities.forEach((ability) => {
        const item = {
            pillar_number: index,
            pillar_build_index: ability.pillar_build_index,
            ability_name: ability.ability_name,
            ability_text: ability.ability_text,
            pillar_name: ability.pillar_name,
            prerequesite: ability.prerequesite,
            ability_tags: [...ability.ability_tags],
            ability_cost: ability.ability_cost,
            ability_uses: ability.ability_uses,
            ability_tier_limit: ability.ability_tier_limit,
            usage: "encounter",
        }
        workingEncounterAbil.push(item);
    })

    if (dailyAbilities.length === encounterAbilities.length) {
        return [...workingEncounterAbil];
    }
    else if (dailyAbilities.length > encounterAbilities.length && encounterAbilities.length !== 0) {
        let renderableTier = [...workingDailyAbil];
        workingEncounterAbil.forEach((encounterAbility) => {
            const replacee = renderableTier.find((dailyAbility) => ((encounterAbility.ability_name === dailyAbility.ability_name) && dailyAbility.usage === "daily"));
            if (replacee !== undefined) {
                const replaceeIndex = renderableTier.findIndex((item) => item === replacee);
                renderableTier[replaceeIndex].usage = "encounter";
            }
        })
        return renderableTier;
    }
    else if (dailyAbilities.length === 4 && encounterAbilities.length === 0) {
        return [...workingDailyAbil];
    }
    else if (dailyAbilities.length > 0 && encounterAbilities.length === 0) {
        const renderableTier = [...workingDailyAbil];
        pushEmptyItemToArray(renderableTier, generateEmptyAbility(tier, pillarName, index), 4);
        return renderableTier;
    }
    else {
        const renderableTier = []
        pushEmptyItemToArray(renderableTier, generateEmptyAbility(tier, pillarName, index), 4);
        return renderableTier;
    }
}

export function indexTier(tier) {
    tier.forEach((ability, index) => {
        ability.pillar_build_index = index;
    })
    return tier;
}

function createRenderableExpertise(dailyExp, encExp, pillarName, pillarNumber) {
    const demark = dailyExp.length + encExp.length;
    const workingDailyExp = []
    const workingEncExp = []
    dailyExp.forEach((expertise) => {
        const item = {
            expertise_ability: expertise.expertise_ability,
            expertise_name: expertise.expertise_name,
            expertise_text: expertise.expertise_text,
            pillar_number: pillarNumber,
            pillar_name: pillarName,
            usage: "daily",
        }
        workingDailyExp.push(item);
    })
    encExp.forEach((exp) => {
        const item = {
            expertise_ability: exp.expertise_ability,
            expertise_name: exp.expertise_name,
            expertise_text: exp.expertise_text,
            pillar_number: pillarNumber,
            pillar_name: pillarName,
            usage: "encounter",
        }
        workingEncExp.push(item);
    })
    if (demark > 5 && encExp.length > 0) {
        return [...workingEncExp].concat([...workingDailyExp].slice(workingEncExp.length));
    }
    else if (demark === 5 && encExp.length === 0) {
        return [...workingDailyExp]
    }
    else if (0 < demark < 5) {
        let renderableExpertise = [...workingDailyExp]
        pushEmptyItemToArray(renderableExpertise, generateEmptyExpertise(pillarName, pillarNumber), 5);
        return renderableExpertise;
    }
    else {
        let renderableExpertise = []
        pushEmptyItemToArray(renderableExpertise, generateEmptyExpertise(pillarName, pillarNumber), 5);
        return renderableExpertise;
    }
}

function pushEmptyItemToArray(itemArray, emptyItem, maxValue) {
    if (itemArray.length < maxValue) {
        itemArray.push(emptyItem);
        pushEmptyItemToArray(itemArray, emptyItem, maxValue);
    }
    else {
        return itemArray;
    }
}

export function renderAbilityTiers(pillarBuild, unspentXp, buildMode, renderableTierArray, originalAbility) {
    return (
        renderableTierArray.map((tier, index) => {
            let disabled = false;
            const encounterPresent = tier.filter((item) => { return (item.usage === "encounter" ? true : false) });
            const nonEmptyTier = tier.filter((item) => { return (item.ability_name === " " ? false : true) });
            let previousTier = []
            if (index > 0) { previousTier = renderableTierArray[index - 1] }
            const nonEmptyPreviousTier = previousTier.filter((item) => { return (item.ability_name === " " ? false : true) });

            return (tier.map((ability, abilIndex) => {
                let abilityCost = pillarNumberXpCost(pillarBuild.pillar_number, ability.ability_cost)
                //Multiplies cost for encounter abilities.
                if (pillarBuild.capstone) {
                    abilityCost = abilityCost * 2;
                }
                //Disables empty if not in buildmode
                if (!buildMode && ability.ability_name === " ") {
                    disabled = true;
                }
                if (buildMode) {
                    if (ability.ability_name) {
                        if (ability.ability_name === " ") {                            //Disables blanks that cost too much
                            if (abilityCost > unspentXp) {
                                disabled = true;
                            }
                            else {
                                //No abilities 
                                if (abilIndex > nonEmptyTier.length) {
                                    disabled = true;
                                }
                                else {
                                    //Tier 2-5
                                    if (index > 0) {
                                        //Disables if the previous tier isn't at least 2
                                        if (nonEmptyPreviousTier.length < 2) {
                                            disabled = true;
                                        }
                                        //When previous tier is full, always enable
                                        else if (nonEmptyPreviousTier.length == 4) {
                                            disabled = false;
                                        }
                                        //Previous tier must have one more ability than current tier.
                                        //Any ability button at or above the previous tier's abilities is disabled
                                        else if (abilIndex >= nonEmptyPreviousTier.length -1) {
                                            disabled = true;
                                        }
                                    }
                                    //Tier 1
                                    else {
                                    }
                                }
                            }
                        }
                        else if (ability.ability_name !== " ") {
                            if (pillarBuild.capstone) {
                                if (abilityCost > unspentXp) {
                                    disabled = true;
                                }
                            }
                        }
                    }
                }
                return (
                    <AbilityButton buildMode={buildMode} pillarBuild={pillarBuild} originalAbility={originalAbility} ability={ability} disabled={disabled} />
                )
            }))
        })
    )
}

export function renderExpertise(pillarBuild, unspentXp, buildMode, renderableExpertise) {
    return (
        renderableExpertise.map((expertise, index) => {
            let buttonState = buildMode ? "buy" : "disabled";
            let disabled = false;
            const numberOfDailyExp = pillarBuild.daily_expertise.length;
            const numberOfEncounterExp = pillarBuild.encounter_expertise.length;
            const relevantDailyTier = pillarBuild.daily_abilities.filter((ability) => ability.ability_cost === numberOfDailyExp + 1)
            const relevantEncounterTier = pillarBuild.encounter_abilities.filter((ability) => ability.ability_cost === numberOfEncounterExp + 1)
            const dailyExpXpCost = pillarNumberXpCost(pillarBuild.pillar_number, numberOfDailyExp + 1)
            const EncExpXpCost = pillarNumberXpCost(pillarBuild.pillar_number, numberOfEncounterExp + 1) * 2

            if (buildMode) {
                if (expertise.expertise_name === " " && numberOfEncounterExp === 0 && unspentXp < dailyExpXpCost) {
                    disabled = true;
                }
                else if (expertise.expertise_name !== " " && 5 > numberOfDailyExp > 0) {
                    buttonState = "replace";
                }
                else if (expertise.expertise_name !== " " && numberOfDailyExp === 5 && numberOfEncounterExp === 0 && unspentXp < EncExpXpCost) {
                    disabled = true;
                }
                else if (expertise.usage !== "encounter" && expertise.expertise_name !== " " && 3 > numberOfEncounterExp > 0 && unspentXp < EncExpXpCost) {
                    disabled = true;
                }
                if (numberOfEncounterExp > 0 && index > 2) {
                    buttonState = "disabled";
                }
                if (expertise.expertise_name === " " && numberOfEncounterExp === 0) {
                    if (relevantDailyTier.length < 4) {
                        disabled = true;
                        buttonState = "disabled";
                    }
                    else if (index + 1 !== numberOfDailyExp + 1) {
                        disabled = true;
                        buttonState = "disabled";
                    }
                }
                else if (numberOfEncounterExp > 0) {
                    if (expertise.usage !== "encounter" && index + 1 !== numberOfEncounterExp + 1) {
                        disabled = true;
                        buttonState = "disabled";
                    }
                    if (index > 2) {
                        disabled = true;
                        buttonState = "disabled";
                    }
                }
            }
            else {
                if (expertise.expertise_name === " ") {
                    disabled = true;
                }
            }
            return (
                <ExpertiseButton buildMode={buildMode} buttonState={buttonState} usage={expertise.usage} pillarBuild={pillarBuild} disabled={disabled} expertise={expertise} />
            )
        })
    )
}

export function renderCapstone(pillarBuild, renderablePillar, buildMode) {
    const purchased = pillarBuild.capstone ? true : false;
    const valid = pillarBuild.daily_abilities.length === 20 && !purchased;
    return (
        <CapstoneButton capstone={renderablePillar.capstone} valid={valid} purchased={purchased} />
    )
}

export function findCapstone(pillarName) {
    switch (pillarName) {
        //core pillars
        case "Soldier":
            return (soldierCapstone);
        case "Sage":
            return (sageCapstone);
        case "Mage":
            return (mageCapstone);
        case "Cleric":
            return (clericCapstone);
        case "Chemist":
            return (chemistCapstone);
        //specialized pillars
        case "Berserker":
            return (berserkerCapstone);
        case "Evoker":
            return (evokerCapstone);
        case "Priest":
            return (priestCapstone);
        case "Pharmacist":
            return (pharmacistCapstone);
        case "Tactician":
            return (tacticianCapstone);
        case "Aegis":
            return (aegisCapstone);
        case "Bard":
            return (bardCapstone);
        case "Poisoner":
            return (poisonerCapstone);
        case "Necromancer":
            return (necromancerCapstone);
        case "Rogue":
            return (rogueCapstone);
    }
}

export function pillarNumberXpCost(pillarBuildNumber, abilityCost) {
    switch (pillarBuildNumber) {
        case 1:
            return 1 * abilityCost;
        case 2:
            switch (abilityCost) {
                case 1:
                    return 1;
                case 2:
                    return 3;
                case 3:
                    return 4;
                case 4:
                    return 6;
                case 5:
                    return 8;
            }
            break;
        case 3:
            return 2 * abilityCost;
        case 4:
            return 3 * abilityCost;
        case 5:
            return 4 * abilityCost;
    }
}

export function AbilityTextMatcher(ability) {

    let tempPillar;

    switch (ability.pillar_name) {
        case "Soldier":
            tempPillar = soldierAbilities;
            break;
        case "Mage":
            tempPillar = mageAbilities;
            break;
        case "Cleric":
            tempPillar = clericAbilities;
            break;
        case "Chemist":
            tempPillar = chemistAbilities;
            break;
        case "Sage":
            tempPillar = sageAbilities;
            break;
        case "Berserker":
            tempPillar = berserkerAbilities;
            break;
        case "Evoker":
            tempPillar = evokerAbilities;
            break;
        case "Priest":
            tempPillar = priestAbilities;
            break;
        case "Pharcmacist":
            tempPillar = pharmacistAbilities;
            break;
        case "Tactician":
            tempPillar = tacticianAbilities;
            break;
        case "Rogue":
            tempPillar = rogueAbilities;
            break;
        case "Aegis":
            tempPillar = aegisAbilities;
            break;
        case "Necromancer":
            tempPillar = necromancerAbilities;
            break;
        case "Poisoner":
            tempPillar = poisonerAbilities;
            break;
        case "Bard":
            tempPillar = bardAbilities;
            break;
        default:
            return ability;
    }
    switch (ability.ability_cost) {
        case 1:
            return (tempPillar.tier1.find(item => item.ability_name === ability.ability_name));
        case 2:
            return (tempPillar.tier2.find(item => item.ability_name === ability.ability_name));
        case 3:
            return (tempPillar.tier3.find(item => item.ability_name === ability.ability_name));
        case 4:
            return (tempPillar.tier4.find(item => item.ability_name === ability.ability_name));
        case 5:
            return (tempPillar.tier5.find(item => item.ability_name === ability.ability_name));
        default:
            return (ability);
    }
}

export function generateEmptyAbility(tier, pillarName, pillarIndex) {
    return {

        pillar_number: pillarIndex,
        ability_name: " ",
        ability_text: " ",
        pillar_name: pillarName,
        prerequesite: " ",
        ability_tags: [],
        ability_cost: tier,
        ability_uses: 0,
        ability_tier_limit: 0,
        usage: "none",
    }
}

export function generateEmptyExpertise(pillarName, pillarIndex) {
    return {
        expertise_name: " ",
        expertise_text: " ",
        usage: "none",
        pillar_number: pillarIndex,
        expertise_ability: generateEmptyAbility(0, pillarName, pillarIndex),
    }
}

export function pillarCost(pillarBuild) {
    let pillarCost = 0;
    pillarBuild.daily_abilities.forEach((dailyAbility) => {
        const added = pillarNumberXpCost(pillarBuild.pillar_number, dailyAbility.ability_cost);
        pillarCost = pillarCost + added;
    })
    pillarBuild.encounter_abilities.forEach((encounterAbility) => {
        const added = pillarNumberXpCost(pillarBuild.pillar_number, encounterAbility.ability_cost);
        pillarCost = pillarCost + (added * 2);
    })
    pillarBuild.daily_expertise.forEach((expertise, index) => {
        const added = pillarNumberXpCost(pillarBuild.pillar_number, index + 1);
        pillarCost = pillarCost + added;
    })
    pillarBuild.encounter_expertise.forEach((encounterExpertise, index) => {
        const added = pillarNumberXpCost(pillarBuild.pillar_number, index + 1);
        pillarCost = pillarCost + (added * 2);
    })
    if (pillarBuild.capstone && pillarBuild.capstone.capstone_name !== undefined) {
        if (pillarBuild.pillar_number === 2) {
            pillarCost = pillarCost + 12
        }
        else {
            pillarCost = pillarCost + 6
        }
    }
    return pillarCost;
}

export function respendFalsifier(pillarBuild) {
    if (pillarBuild.capstone != null) {
        return false;
    }
    else if (pillarBuild.pillar_number > 2) {
        if (pillarBuild.daily_abilities.length != null && pillarBuild.daily_abilities.length > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        return true;
    }
}

export const stretchBoxes = {
    color: '#fefefe',
    minWidth: '50px',
    height: "100%",
    border: '1px solid #bb86fc',
    width: {
        xs: '100%',
        sm: '100%',
        med: '100%',
        lg: '100%',
        xl: '100%'
    },
    fontSize: {
        xs: '.4rem',
        sm: '.8rem',
        md: '1rem',
        lg: '1rem',
        xl: '1rem',
    },
    fontWeight: 'bold',
    margin: '0 10px 0 0'
}

export const boxgrid = {
    margin: {
        xs: '0px 0px 0 0',
        sm: '0px 0px 0 0',
        md: '0px 0px 0 0',
        lg: '0px 0px 0 0',
        xl: '0px 0px 0 0'
    },
    height: {
        xs: '50px',
        sm: '70px',
        md: '100px',
        lg: '100px',
        xl: '100px',
    },
    width: '100%'
}
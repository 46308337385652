//Tier 1

export const redirect = {
    ability_name: "Redirect",
    ability_text: "You twist the winds of magic to direct an assault against one of your charges to yourself. When an ally within your melee Reach has been hit by an Attack or Ability delivered by a weapon or packet, you may call %%Spell Intercept%% within three Seconds, becoming the Target of the triggering effect.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "Reach", "Intercept"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const halt = {
    ability_name: "Halt",
    ability_text: "You create a moving hurdle of arcane energy to impede your foe. You must incant the Verbal and may then throw a %%Spell Immobilize 5%% packet.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Immobilize"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: "Halt! Spell Immobilize 5...",
    ability_tier_limit: null,
}

//Tier 2

export const forcePush = {
    ability_name: "Force Push",
    ability_text: "You fling back your enemies with a roiling wave of arcane force, pinning them away from your charges. You must incant the Verbal and may then throw three %%Spell Push 5%% packets. You must throw all packets within five seconds of completing your verbal.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Push"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: "Force push! Spell Push 5...",
    ability_tier_limit: null,
}

export const jaunt = {
    ability_name: "Jaunt",
    ability_text: "You can temporarily remove a creature’s form from the battlefield, protecting them from harm, or banishing them from reality. You must incant the Verbal, and may throw a %%Spell Insubstantial 5 packet%%.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Insubstantial"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: "Jaunt! Spell Insubstantial 5...",
    ability_tier_limit: null,
}

//Tier 3

export const arcaneInversion = {
    ability_name: "Arcane Inversion",
    ability_text: "With a thought, the winds of magic warp and carry a spell back to its source. You may call %%Spell Return%% on one Spell delivered to you.",
    pillar_name: "Aegis",
    prerequesite: "Redirect",
    ability_tags: ["Spell", "Self", "Return"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const barrier = {
    ability_name: "Barrier",
    ability_text: "You create a potent cube of arcane force preventing your enemy moving against your charges. You must incant the Verbal and may then throw a %%Spell Push 30%% packet.",
    pillar_name: "Aegis",
    prerequesite: "Halt",
    ability_tags: ["Spell", "Verbal", "Packet", "Push"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Barrier! Spell Push 30...",
    ability_tier_limit: null,
}

//Tier 4

export const eldritchArmor = {
    ability_name: "Eldritch Armor",
    ability_text: "You forge a brilliant set of arcane armor about yourself. You must complete a three second “Eldritch Armor” count, and may then grant yourself thirty Temporary Armor.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "3 Count", "Self"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const mute = {
    ability_name: "Mute",
    ability_text: "You create a blockage of force around your Target's mouth, enforcing strict silence. You must incant the Verbal and may then throw a %%Spell Silence 60%% packet.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Silence"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: "Mute! Spell Silence 60...",
    ability_tier_limit: null,
}

//Tier 5

export const invulnerability = {
    ability_name: "Invulnerability",
    ability_text: "You create a nigh impenetrable barrier of force about you. Upon completion of a three second \“Invulnerability\” count you become Insubstantial for sixty Seconds. You may end this condition on yourself on completion of a three second \“Fading\” count.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "3 Count", "Self", "Insubstantial"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const wallOfForce = {
    ability_name: "Wall of Force",
    ability_text: "Positioning yourself between your charges and their foes, they have no hope of passage. You must incant the Verbal and may throw a %%Spell Push 10%% packet. You may additionally choose to be Immobilized for up to sixty Seconds. While Immobilized, you may repeat the verbal and throw a %%Spell Push 10%% packet once for each repetition.",
    pillar_name: "Aegis",
    prerequesite: "Barrier",
    ability_tags: ["Spell", "Stance", "Verbal", "Packet", "Push"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: "Wall of Force! Spell Push 10...",
    ability_tier_limit: null,
}

//Tierless

export const aegisExpertiseAbility = {
    ability_name: "Arcane Armiger",
    ability_text: "Your mastery of defensive arcana allows you to craft intricate and effective wards around your charges. You must Touch one creature, and complete a three second “Armiger” count. Upon completion, you may grant the Target ten temporary Armor.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Spell", "3 Count", "Touch"],
    ability_cost: null,
    ability_uses: 2,
    ability_tier_limit: 1,
}

export const aegisCapstoneAbility = {
    ability_name: "Aegis Champion",
    ability_text: "You gain an additional ten Armor Points. This increase ignores your armor cap and is automatically repaired at the start of each encounter. Once per day, upon completion of a three second “Armiger” count, you gain fifty Temporary Armor.",
    pillar_name: "Aegis",
    prerequesite: null,
    ability_tags: ["Passive", "3 Count"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: 1,
}
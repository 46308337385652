import { aegisAbilities, bardAbilities, berserkerAbilities, chemistAbilities, clericAbilities, evokerAbilities, mageAbilities, necromancerAbilities, pharmacistAbilities, poisonerAbilities, priestAbilities, rogueAbilities, sageAbilities, soldierAbilities, tacticianAbilities } from "../../../data/AbilitiesByPillar";
import { pillarNumberXpCost } from "../../PillarBuild/PillarBuildUtils";

export function calculatePillarBuildLevel(pillarBuild) {
    const encounterAbilities = pillarBuild.encounter_abilities;
    const dailyAbilities = pillarBuild.daily_abilities;
    if (encounterAbilities.length > 0) {
        let counter = 0;
        let counterArray = [];
        encounterAbilities.forEach((ability) => !isNaN(ability.ability_cost) ? counterArray.push(ability.ability_cost) : null);
        counter = Math.max(...counterArray);
        return (counter + 6)
    }
    else {
        let counter = 0;
        let counterArray = [];
        dailyAbilities.forEach((ability) => !isNaN(ability.ability_cost) ? counterArray.push(ability.ability_cost) : null);
        counter = Math.max(...counterArray);
        return counter;
    }
}

export function calculateTotalLevel(pillarInventory) {
    let counter = 0;
    pillarInventory.forEach((pillarBuild) => counter = counter + pillarBuild.pillar_level);
    return counter;
}

export function generateAbilityCost(pillarNumber, buildCost, encounterStatus) {
    const adjustedAbilityCost = pillarNumberXpCost(pillarNumber + 1, buildCost);
    if (encounterStatus) {
        return (adjustedAbilityCost * 2)
    }
    else {
        return adjustedAbilityCost
    }
}

export function abilityPillarMatcher(ability_cost, pillarBuildIndex, pillar_name) {
    switch (pillar_name) {
        //Core Pillars
        case "Soldier":
            return (indexAdder(abilityTierMatcher(ability_cost, soldierAbilities), pillarBuildIndex))
        case "Sage":
            return (indexAdder(abilityTierMatcher(ability_cost, sageAbilities), pillarBuildIndex))
        case "Mage":
            return (indexAdder(abilityTierMatcher(ability_cost, mageAbilities), pillarBuildIndex))
        case "Cleric":
            return (indexAdder(abilityTierMatcher(ability_cost, clericAbilities), pillarBuildIndex))
        case "Chemist":
            return (indexAdder(abilityTierMatcher(ability_cost, chemistAbilities), pillarBuildIndex))
        //Specialized Pillars
        case "Berserker":
            return (indexAdder(abilityTierMatcher(ability_cost, berserkerAbilities), pillarBuildIndex))
        case "Tactician":
            return (indexAdder(abilityTierMatcher(ability_cost, tacticianAbilities), pillarBuildIndex))
        case "Evoker":
            return (indexAdder(abilityTierMatcher(ability_cost, evokerAbilities), pillarBuildIndex))
        case "Priest":
            return (indexAdder(abilityTierMatcher(ability_cost, priestAbilities), pillarBuildIndex))
        case "Pharmacist":
            return (indexAdder(abilityTierMatcher(ability_cost, pharmacistAbilities), pillarBuildIndex))
        case "Rogue":
            return (indexAdder(abilityTierMatcher(ability_cost, rogueAbilities), pillarBuildIndex))
        case "Bard":
            return (indexAdder(abilityTierMatcher(ability_cost, bardAbilities), pillarBuildIndex))
        case "Aegis":
            return (indexAdder(abilityTierMatcher(ability_cost, aegisAbilities), pillarBuildIndex))
        case "Poisoner":
            return (indexAdder(abilityTierMatcher(ability_cost, poisonerAbilities), pillarBuildIndex))
        case "Necromancer":
            return (indexAdder(abilityTierMatcher(ability_cost, necromancerAbilities), pillarBuildIndex))
        default:
            return (indexAdder(abilityTierMatcher(ability_cost, soldierAbilities), pillarBuildIndex))
    }
}

function indexAdder(array, pillarBuildIndex) {
    array.forEach((ability) => {
        ability.pillar_number = pillarBuildIndex;
        if (!ability.usage) {
            ability.usage = "none"
        }
    })
    return array;
}

export function buyTextGenerator(currentAbility, originalAbility, capstone, cost, unspentXp, buildMode) {

    const currentAbilityName = currentAbility.ability_name;
    const originalAbilityName = originalAbility.ability_name;

    if (!buildMode) {
        return ("Close");
    }
    else {
        if (currentAbilityName === " ") {
            return ("Close");
        }
        else if (currentAbilityName === originalAbilityName && !capstone) {
            return ("Close");
        }
        else if (originalAbilityName !== " " && currentAbilityName !== originalAbilityName) {
            return ("Replace");
        }
        else if (currentAbility.usage === "none") {
            return ("Buy");
        }
        else if (currentAbility.usage === "daily" && currentAbility.ability_cost > 3 && capstone) {
            return ("Close");
        }
        else if (currentAbility.usage === "daily" && capstone && cost > unspentXp) {
            return ("Close");
        }
        else if (currentAbility.usage === "daily" && capstone && cost <= unspentXp) {
            return ("Upgrade");
        }
    }
}

function abilityTierMatcher(ability_cost, pillarData) {
    switch (ability_cost) {
        case 1:
            return (pillarData.tier1)
        case 2:
            return (pillarData.tier2)
        case 3:
            return (pillarData.tier3)
        case 4:
            return (pillarData.tier4)
        case 5:
            return (pillarData.tier5)
        default:
            return (pillarData.tier1);
    }
}

export function canRemoveAbilityGivenTiers(dailyAbilities, abilityToRemove) {
    let countSameTierAbilities = 0, countOneHigherTierAbilities = 0;
    dailyAbilities.forEach((ability) => {
        if (ability.ability_cost === abilityToRemove.ability_cost) {
            countSameTierAbilities++;
        } else if (ability.ability_cost === abilityToRemove.ability_cost + 1) {
            countOneHigherTierAbilities++;
        }
    });
    //Can always remove last ability.
    //
    return countSameTierAbilities == 1 || (countSameTierAbilities > countOneHigherTierAbilities + 1);
}

export const disabledDeviser = (ability, relevantPillarBuild) => {
    let tierLimit = ability.ability_tier_limit

    let falsifier = false;

    if (ability.prerequesite) {
        const prerequesiteFind = relevantPillarBuild.daily_abilities.find((item) => item.ability_name === ability.prerequesite);
        if (tierLimit && tierLimit !== 0) {
            const intermediaryTier = relevantPillarBuild.daily_abilities.filter((item) => item.ability_cost === ability.ability_cost);
            const relevantTier = intermediaryTier.filter((item) => item.ability_name === ability.ability_name);
            const prereq = !prerequesiteFind;
            const tierLimitBool = tierLimit <= relevantTier.length;
            if (ability.ability_name === "Fortitude") {
            }
            falsifier = !(prereq === tierLimitBool);

        }
        else {
            falsifier = !prerequesiteFind;
        }
    }
    else if (tierLimit && tierLimit === 0) {
        falsifier = false;
    }
    else if (tierLimit && tierLimit !== 0) {
        //Returns true if you have greater than or equal to your tier limit for encounters
        if (ability.usage === "encounter") {
            const intermediaryTier = relevantPillarBuild.encounter_abilities.filter((item) => item.ability_cost === ability.ability_cost);
            const relevantTier = intermediaryTier.filter((item) => item.ability_name === ability.ability_name);
            falsifier = (tierLimit <= relevantTier.length);
        }
        //Returns true if you have greater than or equal to your tier limit for dialies
        else {
            const intermediaryTier = relevantPillarBuild.daily_abilities.filter((item) => item.ability_cost === ability.ability_cost);
            const relevantTier = intermediaryTier.filter((item) => item.ability_name === ability.ability_name);
            falsifier = (tierLimit <= relevantTier.length);
        }
    }
    return falsifier;
}
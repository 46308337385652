import { Grid, Typography, Tooltip, Divider } from "@mui/material";
import { conceptArray } from "../data/glossary/Concepts";
import { heritageList } from "../data/Heritage"
import { Box } from "@mui/system";
import { expertiseList } from "../data/expertise/Expertise";
import { abilitiesByPillarArray, invalidAbility } from "../data/AbilitiesByPillar";

export function findPillarsByCharacter(character, pillarInventory) {
    let matchingNamePillarInventories = [];
    matchingNamePillarInventories = pillarInventory.filter(pillarInventorySub => pillarInventorySub.character_id === character.id);
    if (matchingNamePillarInventories) {
        return (matchingNamePillarInventories);
    }
    else {
        return { pillar_builds: [] }
    }
}

function splitHilightText(incommingText) {
    return incommingText.split("%%")
};

function splitNewlines(incommingText) {
    return incommingText.split("$$")
};

function writeTextObj(text, type) {
    return ({
        body: text,
        text_type: type,
    })
}

function splitEndlessText(incommingText) {
    return incommingText.split("@@")
};

export function characterNameSizer(name) {
    if (name !== undefined && name !== null) {
        const nameArray = name.split(' ')
        const firstName = nameArray[0]
        if (firstName.length <= 7) {
            return '1.75rem'
        }
        else if (firstName.length <= 10) {
            return '1.5rem'
        }
        else if (firstName.length <= 13) {
            return '1.25rem'
        }
        else if (firstName.length <= 17) {
            return '1rem'
        }
    }
    else {
        return "2rem"
    }
}


function renderHilights(textArray) {
    return (textArray.map((text, index) => {
        if (index % 2 === 0) {
            return (
                <span>
                    {text}
                </span>
            )
        }
        else {
            return (
                <Typography variant="button" color="primary" sx={{ mx: "6px" }}>
                    {text}
                </Typography>
            )
        }
    }))
}

export function heritageMatcher(heritageName) {
    return heritageList.filter((heritage) => heritage.heritage_name === heritageName)
}

export function heritageResistanceShortener(heritageName) {
    switch (heritageName) {
        case 'Human':
            return 'Variable'
            break;
        case 'Elf':
            return 'Sleep'
            break;
        case 'Lizadfolk':
            return 'Push';
            break;
        case 'Nephilim: infernal':
            return 'Immobilize';
            break;
        case 'Nephilim: celestial':
            return 'Immobilize';
            break;
        case 'Littlefolk':
            return 'Slow';
            break;
        case 'Orc':
            return 'Fear';
            break;
        case 'Revenant':
            return 'Waste';
            break;
        case 'Beastfolk':
            return 'Silence';
            break;
        case 'Elemental':
            return 'Curse';
            break;
        case 'Dwarf: sun chaser':
            return 'Waste';
            break;
        case 'Dwarf: hate forged':
            return 'Waste';
            break;
        default:
            return 'None';
            break;
    }
}

export function countServiceReward(user) {
    let counter = 0;
    const attendance = user.user_attendance;
    if (attendance != null && attendance.length > 0) {
        attendance.map((each) => {
            if (each.service_reward != null && each.service_reward.length > 0) {
                each.service_reward.map((service) => {
                    counter = counter + service.unassigned;
                })
            }
        })
    }
    return counter;
}

export function formatTextBlob(incommingText) {
    const textArray = formatTextBlobArray(incommingText);
    return (
        <Typography variant='inherit'>
            {textArray.map((object) => {
                if (object.text_type === 'highlighted') {
                    const wordArray = object.body.split(' ')
                    return (
                        <Box sx={{ color: 'primary.main', fontWeight: '400' }} component='span'>
                            {wordArray.map((entry, index) => {
                                const tagConcept = findConcept(entry);
                                if (tagConcept) {
                                    return (
                                        <Tooltip enterTouchDelay={100} leaveTouchDelay={5000} title={tagConcept ? formatTextBlob(tagConcept.concept_text) : ""}>
                                            <Box
                                                id={'process-text-' + entry + index} component='span'>
                                                {entry + ' '}
                                            </Box>
                                        </Tooltip>
                                    )
                                }
                                else {
                                    return (
                                        <Box
                                            sx={{ color: 'primary.main' }} id={'process-text-' + entry + index} component='span'>
                                            {entry + ' '}
                                        </Box>
                                    )
                                }
                            })}
                        </Box>
                    )
                }
                else if (object.text_type === 'endless') {
                    return (
                        <Box sx={{ fontWeight: '100' }} component='span'>
                            {object.body}
                        </Box>
                    )
                }
                else if (object.text_type === 'newline') {
                    return (
                        <>
                            <br />
                            <Box component='span'>
                                {object.body}
                            </Box>
                        </>
                    )
                }
                else if (object.text_type === 'unprocessed') {
                    return (
                        <Box component='span'>
                            {object.body}
                        </Box>
                    )
                }
                else {
                    return (null);
                }
            })}
        </Typography>
    )

}

export function formatHeritageTextBlob(incommingText) {
    const textArray = formatTextBlobArray(incommingText);
    return (
        <Typography variant='inherit'>
            {textArray.map((object) => {
                if (object.text_type === 'highlighted') {
                    const wordArray = object.body.split(' ')
                    return (
                        <Box sx={{ color: 'primary.main', fontWeight: '400' }} component='span'>
                            {wordArray.map((entry, index) => {
                                const tagConcept = findConcept(entry);
                                if (tagConcept) {
                                    return (
                                        <Tooltip enterTouchDelay={100} leaveTouchDelay={5000} title={tagConcept ? formatTextBlob(tagConcept.concept_text) : ""}>
                                            <Box
                                                id={'process-text-' + entry + index} component='span'>
                                                {entry + ' '}
                                            </Box>
                                        </Tooltip>
                                    )
                                }
                                else {
                                    return (
                                        <Box
                                            sx={{ color: 'primary.main' }} id={'process-text-' + entry + index} component='span'>
                                            {entry + ' '}
                                        </Box>
                                    )
                                }
                            })}
                        </Box>
                    )
                }
                else if (object.text_type === 'endless') {
                    return (
                        <Box sx={{ fontWeight: '750' }} component='span'>
                            {object.body}
                        </Box>
                    )
                }
                else if (object.text_type === 'newline') {
                    return (
                        <>
                            <Box sx={{ my: "5px" }} />
                            <Typography variant="p" sx={{ my: "10px" }}>
                                {object.body}
                            </Typography>
                            <Box sx={{ my: "5px" }} />
                        </>
                    )
                }
                else if (object.text_type === 'unprocessed') {
                    return (
                        <>
                            <Typography variant="p" sx={{ my: "10px" }}>
                                {object.body}
                            </Typography>
                        </>
                    )
                }
                else {
                    return (null);
                }
            })}
        </Typography>
    )

}

function formatTextBlobArray(incommingText) {
    const initialBreak = incommingText.split("%%")
    const highlightedText = []
    initialBreak.forEach((block, index) => {
        if (index % 2 === 0) {
            highlightedText.push(writeTextObj(block, 'unprocessed'))
        }
        else {
            highlightedText.push(writeTextObj(block, 'highlighted'))
        }
    })
    const highlightedEndlessText = []
    highlightedText.forEach((object) => {
        if (object.text_type === 'unprocessed') {
            const blocksplit = object.body.split('@@');
            if (blocksplit.length > 1) {
                blocksplit.forEach((split, index) => {
                    if (index % 2 === 0) {
                        highlightedEndlessText.push(writeTextObj(split, 'unprocessed'))
                    }
                    else {
                        highlightedEndlessText.push(writeTextObj(split, 'endless'))
                    }
                })
            }
            else {
                highlightedEndlessText.push(object);
            }
        }
        else {
            highlightedEndlessText.push(object);
        }
    })
    // Always must be last.
    const newLinesArray = []
    highlightedEndlessText.forEach((object) => {
        if (object.text_type === 'unprocessed') {
            const blocksplit = object.body.split('$$');
            if (blocksplit.length > 1) {
                blocksplit.forEach((split, index) => {
                    if (index % 2 === 0) {
                        newLinesArray.push(writeTextObj(split, 'unprocessed'))
                    }
                    else {
                        newLinesArray.push(writeTextObj(split, 'newline'))
                    }
                })
            }
            else {
                newLinesArray.push(object);
            }
        }
        else {
            newLinesArray.push(object);
        }
    })
    return newLinesArray;
}

export function findConcept(keyword) {
    const conceptEntry = conceptArray.find((item) => item.concept_name.toLowerCase() === keyword.toLowerCase())
    if (conceptEntry === undefined) {
        return (null)
    }
    else {
        return (conceptEntry);
    }
}

function findPillarAbilityDataByAbility(ability) {
    const selectedPillar = abilitiesByPillarArray.find((pillar) => pillar.name.toLowerCase() === ability.pillar_name.toLowerCase());
    const relevantTierAbilities = findAbilityArrByCost(ability.ability_cost, selectedPillar);
    const returnableAbility = relevantTierAbilities.find((abil) => abil.ability_name === ability.ability_name);
    return (returnableAbility);
}

function findAbilityArrByCost(abilityCost, pillarData) {
    switch (abilityCost) {
        case 1:
            return pillarData.tier1;
            break;
        case 2:
            return pillarData.tier2;
            break;
        case 3:
            return pillarData.tier3;
            break;
        case 4:
            return pillarData.tier4;
            break;
        case 5:
            return pillarData.tier5;
            break;
    }
}

export function convertPBToSubmitable(pillarBuild) {
    const validBuild = JSON.parse(JSON.stringify(pillarBuild));
    const dailyArr = [];
    const encounterArr = [];
    abilityDestacker(false, dailyArr, validBuild.daily_abilities);
    abilityDestacker(false, encounterArr, validBuild.encounter_abilities);
    const dailyExpertise = expertiseDestacker(false, null, validBuild.daily_expertise);
    const encounterExpertise = expertiseDestacker(false, null, validBuild.encounter_expertise);
    validBuild.daily_abilities = dailyArr;
    validBuild.encounter_abilities = encounterArr;
    validBuild.daily_expertise = dailyExpertise;
    validBuild.encounter_expertise = encounterExpertise;
    validBuild.new = null;
    return validBuild;
}

export function convertSubmitableToPB(pillarBuild) {
    const validBuild = { ...pillarBuild };
    const dailyArr = [];
    const encounterArr = [];
    const dailyExpertiseArr = [];
    const encounterExpertiseArr = [];
    const pillarName = validBuild.pillar_name;
    abilityDestacker(true, dailyArr, pillarBuild.daily_abilities, pillarName);
    abilityDestacker(true, encounterArr, pillarBuild.encounter_abilities, pillarName);
    expertiseDestacker(true, dailyExpertiseArr, pillarBuild.daily_expertise, pillarName);
    expertiseDestacker(true, encounterExpertiseArr, pillarBuild.encounter_expertise, pillarName);
    validBuild.daily_abilities = dailyArr;
    validBuild.encounter_abilities = encounterArr;
    validBuild.daily_expertise = dailyExpertiseArr;
    validBuild.encounter_expertise = encounterExpertiseArr;
    return validBuild;
}

//This is where it's going wrong. Destacking is false.
function abilityDestacker(destacking, arrToPost, arrToMod, pillarName) {
    if (arrToMod != null) {
        //Takes PillarBuildAbilities and turns them into Ability Lists.
        if (destacking) {
            arrToMod.forEach((item) => {
                let matchedAbility;
                matchedAbility = findPillarAbilityDataByAbility(item.ability);
                if (!(matchedAbility && matchedAbility.pillar_name != null)) {
                    matchedAbility = invalidAbility;
                }
                matchedAbility.pillar_name = pillarName;
                if(item.ability && item.ability.ability_cost && item.ability.ability_cost !== null){
                    matchedAbility.ability_cost = item.ability.ability_cost
                }
                else{
                    matchedAbility.ability_cost = 1;
                }
                for (let i = 0; i < item.ability_count; i++) {
                    if (matchedAbility) {
                        arrToPost.push(matchedAbility);
                    }
                    else {
                        arrToPost.push(item.ability);
                    }
                }
            })
        }
        // Takes Ability Lists and turns them into PillarBuildAbilities.
        else {
            const returnArray = [];
            const baseAbilityArray = [...new Map(arrToMod.map((a) => [a.ability_name, a])).values()];
            const offCostArray = []
            baseAbilityArray.forEach((ability, index) => {
                let counter = 0;
                arrToMod.forEach((countAbility) => {
                    if (countAbility.ability_name === ability.ability_name && countAbility.ability_cost === ability.ability_cost) {
                        counter++
                    }
                    else if (countAbility.ability_name === ability.ability_name && countAbility.ability_cost !== ability.ability_cost) {
                        offCostArray.push(countAbility);
                    }
                })
                const item = {
                    ability: ability,
                    ability_count: counter,
                }
                returnArray.push(item);
            })
            const filteredOffCost = [...new Map(offCostArray.map((a) => [a.ability_cost, a])).values()]
            filteredOffCost.forEach((ability, index) => {
                let counter = 0;
                arrToMod.forEach((countAbility) => {
                    if (countAbility.ability_name === ability.ability_name && countAbility.ability_cost === ability.ability_cost) {
                        counter++
                    }
                })
                const item = {
                    ability: ability,
                    ability_count: counter,
                }
                returnArray.push(item);
            })
            if (returnArray.length > 0) {
                returnArray.forEach((ability) => {
                    arrToPost.push(ability)
                })
            }
            else {
                arrToPost = null;
            }
        }
    }
    else {
        arrToPost = null;
    }
}

function expertiseDestacker(destacking, arrToPost, itemToDestack, pillarName) {
    if (itemToDestack != null) {
        //Takes PillarBuildExpertise and turns them into Expertise Lists.
        if (destacking) {
            if (itemToDestack != null) {
                const matchedExpertise = expertiseList.find((expertise) => expertise.expertise_name === itemToDestack.expertise.expertise_name);
                for (let i = 0; i < itemToDestack.expertise_count; i++) {
                    if (matchedExpertise != null) {
                        arrToPost.push(matchedExpertise);
                    }
                    else {
                        arrToPost.push(itemToDestack.expertise);
                    }
                }
            }
        }
        // Takes Expertise Lists and turns them into PillarBuildExpertise.
        else {
            const expExists = itemToDestack.length > 0;
            if (expExists === false) {
                return null;
            }
            else {
                const expertise = {
                    expertise: itemToDestack[0],
                    expertise_count: itemToDestack.length,
                }
                return expertise;
            }

        }
    }
}
import { OneKPlusOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, Fade, Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { activeCharacterContext, charactersContext, relevantModalObjContext } from "../../../App";
import { formatTextBlob } from "../../../processing/DataFormattingUtils";
import { buildModeContext, modifiedAbilsContext, relevantPillarInventoryContext } from '../../CharacterScreen/CharacterScreen';
import { expertiseModalOpenContext } from "../../PillarBuild/PillarBuild";
import ExpertiseModalHeader from "./ExpertiseModalHeader";
import { currentXpCost, expButtonText, expertisePillarMatcher } from "./ExpertiseModalUtils";

function ExpertiseModal() {

    const { expertiseModalOpen, setExpertiseModalOpen } = useContext(expertiseModalOpenContext);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { buildMode, setBuildMode } = useContext(buildModeContext);
    const { relevantPillarInventory, setRelevantPillarInventory } = useContext(relevantPillarInventoryContext);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const { modifiedAbils, setModifiedAbils } = useContext(modifiedAbilsContext);

    const handleClose = () => {
        setExpertiseModalOpen(false);
        setRelevantModalObj("");
    }

    //write a falsifier here for the button to use to prevent it rendering if there's not enough xp.

    if (buildMode & relevantModalObj.pillar_number !== undefined) {

        const currentPillarNumber = relevantModalObj.pillar_number;

        if (relevantModalObj.expertise_name === " ") {
            setRelevantModalObj(expertisePillarMatcher(relevantModalObj));
        };

        const currentPillarbuild = relevantPillarInventory[currentPillarNumber];
        const relevantDailyExpertiseArray = currentPillarbuild.daily_expertise;
        const relevantEncounterExpertiseArray = currentPillarbuild.encounter_expertise;
        const relevantExpertise = relevantModalObj;
        const buttonText = expButtonText(relevantModalObj, currentPillarbuild, activeCharacter.xp_unspent);

        let modifiedPillarBuild = { ...currentPillarbuild };
        let modifiedPillarInventory = [ ...relevantPillarInventory ]
        let modifiedActiveCharacter = { ...activeCharacter }
        let modifiedCharactersArray = [...characters]

        const characterIndex = modifiedCharactersArray.findIndex((character) => character.character_name === activeCharacter.character_name);

        const setNewExpertise = (usage, abilityCost) => {
            const modifiedPBArray = usage === "encounter" ? modifiedPillarBuild.encounter_expertise : modifiedPillarBuild.daily_expertise;
            modifiedPBArray.push(relevantExpertise);
            modifiedActiveCharacter.xp_unspent = activeCharacter.xp_unspent - abilityCost;
            modifiedCharactersArray[characterIndex] = modifiedActiveCharacter;
            setRelevantPillarInventory(modifiedPillarInventory);
            setActiveCharacter(modifiedActiveCharacter);
            setCharacters(modifiedCharactersArray);
            setModifiedAbils(true);
            setExpertiseModalOpen(false);
        };

        const refundExpertise = (usage) => {
            const modifiedPBArray = usage === "encounter" ? modifiedPillarBuild.encounter_expertise : modifiedPillarBuild.daily_expertise;
            const cost = currentXpCost(currentPillarNumber, relevantDailyExpertiseArray.length)
            modifiedPBArray.pop();
            modifiedActiveCharacter.xp_unspent = activeCharacter.xp_unspent + cost;
            modifiedCharactersArray[characterIndex] = modifiedActiveCharacter;
            setRelevantPillarInventory(modifiedPillarInventory);
            setActiveCharacter(modifiedActiveCharacter);
            setCharacters(modifiedCharactersArray);
            setModifiedAbils(true);
            setExpertiseModalOpen(false);

        };

        const buyExpertise = (event) => {
            if (relevantDailyExpertiseArray.length === 0 && relevantEncounterExpertiseArray.length === 0) {
                const cost = currentXpCost(currentPillarNumber, 1);
                setNewExpertise("daily", cost);
            }
            else if (0 < relevantDailyExpertiseArray.length && relevantDailyExpertiseArray.length < 5 && relevantEncounterExpertiseArray.length === 0) {
                const cost = currentXpCost(currentPillarNumber, relevantDailyExpertiseArray.length + 1);
                setNewExpertise("daily", cost);
            }
            else if (relevantEncounterExpertiseArray.length === 0 && relevantDailyExpertiseArray.length === 5) {
                const cost = currentXpCost(currentPillarNumber, 1) * 2
                setNewExpertise("encounter", cost)
            }
            else if (3 > relevantEncounterExpertiseArray.length && relevantEncounterExpertiseArray.length > 0) {
                const cost = currentXpCost(currentPillarNumber, relevantEncounterExpertiseArray.length + 1) * 2
                setNewExpertise("encounter", cost)
            }
            else {
                setExpertiseModalOpen(false);
                setRelevantModalObj("");
            }
        };

        const costBase = currentXpCost(currentPillarNumber, relevantEncounterExpertiseArray.length + 1)
        let totalCost = costBase;
        if (relevantExpertise.usage === "encounter"){totalCost = totalCost*2};
        const buyable = totalCost <= activeCharacter.xp_unspent

        return (
            <Modal
                open={expertiseModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
            >
                <Fade in={expertiseModalOpen} timeout={200}>
                <Grid container justifyContent='center' alignItems='center' sx={{width: '100%'}}>
                        <Grid item sx={{ maxWidth: 'sm'}}>
                            <Box>
                                <Paper sx={{ margin: '40px auto 0', padding: '40px', borderRadius: '10px', maxWidth: 'sm' }}>
                                    <ExpertiseModalHeader expertise={relevantModalObj.expertise_ability} />
                                    {relevantModalObj.ability_name === " " ? null : <Divider />}
                                    {formatTextBlob(relevantModalObj.expertise_text)}
                                    <Divider />
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            {buttonText === "Close" ?
                                                <Button startIcon={<CloseIcon />} variant="contained" xs={4} sx={{ py: "10px", px: "30px", m: "10px" }} color="success" onClick={ (event) => handleClose()}>
                                                    {buttonText}
                                                </Button>
                                                :
                                                <Button startIcon={<AddCircleOutlineIcon />} variant="contained" xs={4} sx={{ py: "10px", px: "30px", m: "10px" }} color="success" onClick={buyExpertise}>
                                                    {buttonText}
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item>
                                            {relevantExpertise.usage === "daily" && buttonText === "upgrade" || relevantDailyExpertiseArray.length === 0 || (currentPillarbuild.capstone !== undefined && relevantEncounterExpertiseArray.length > 0) ? null :
                                                <Button startIcon={<CloseIcon />} variant="contained" xs={4} sx={{ py: "10px", px: "30px", m: "10px" }} color="error" onClick={refundExpertise}>
                                                    Remove
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        )
    }
    else {
        return (
            <Modal
                open={expertiseModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Paper sx={{ margin: '40px auto 0', padding: '40px', borderRadius: '10px' }}>
                        <ExpertiseModalHeader expertise={relevantModalObj.expertise_ability} />
                        {relevantModalObj.ability_name === " " ? null : <Divider />}
                        <Grid container maxWidth="m" id="modal-modal-description" sx={{ mt: 2 }}>
                            {relevantModalObj.expertise_text !== undefined ? formatTextBlob(relevantModalObj.expertise_text) : null}
                        </Grid>
                    </Paper>
                </Box>
            </Modal>
        )
    }
}

export default ExpertiseModal;
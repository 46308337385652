import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HelpIcon from '@mui/icons-material/Help';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Fade, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { activeCharacterContext, relevantModalObjContext } from "../../App";
import { classIconMapper } from '../../data/Icons';
import { pillarsArray } from "../../data/Pillars";
import { formatTextBlob } from "../../processing/DataFormattingUtils";
import { newPillarModalContext, relevantPillarInventoryContext } from '../CharacterScreen/CharacterScreen';

export function NewPillarModal() {

    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
    const { relevantPillarInventory, setRelevantPillarInventory } = useContext(relevantPillarInventoryContext);
    const { newPillarModal, setNewPillarModal } = useContext(newPillarModalContext);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const [pillarSelection, setPillarSelection] = useState("");

    const [value, setValue] = useState("");

    //Allows you to buy focus pillars if you don't have two of them
    const totalPillars = (event) => {
        if (relevantPillarInventory.length < 2) {
            return true;
        }
        else {
            const focus1 = relevantPillarInventory.find((pb) => pb.pillar_number === 1)
            const focus2 = relevantPillarInventory.find((pb) => pb.pillar_number === 2)

            if (!focus1 || !focus2) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    let disabled = true;
    const foundPillar1 = relevantPillarInventory.find((item) => item.pillar_number === 1);
    const foundPillar2 = relevantPillarInventory.find((item) => item.pillar_number === 2);
    if (foundPillar1 === undefined || foundPillar2 === undefined) {
        disabled = false;
    }

    const corePillars = ["Soldier", "Mage", "Cleric", "Sage", "Chemist"];
    const specializedPillars1 = ["Berserker", "Evoker", "Priest", "Tactician", "Poisoner"];
    const specializedPillars2 = ["Rogue", "Aegis", "Necromancer", "Bard", "Pharmacist"];

    const handleButton = (buttonValue) => {
        const pillarInfo = pillarsArray.find((pillar) => pillar.pillar_name === buttonValue);
        if (pillarInfo !== undefined) {
            setPillarSelection(pillarInfo);
        }
        setValue(buttonValue);
    }

    const handleSubmit = (event) => {
        if (value !== "") {
            const numberOfExistingBuilds = relevantPillarInventory.length;
            let respendAvail = false;
            respendAvail = numberOfExistingBuilds <= 2;
            const workingPillarIventory = [...relevantPillarInventory]
            let pillarNumber = 1;
            workingPillarIventory.forEach((build) => {
                if (pillarNumber > build.pillar_number && pillarNumber < numberOfExistingBuilds) {
                    pillarNumber = pillarNumber;
                }
                else if (build.pillar_number < pillarNumber || build.pillar_number === pillarNumber) {
                    pillarNumber = pillarNumber + 1;
                }
                else if (pillarNumber > build.pillar_number) {
                    pillarNumber = pillarNumber;
                }
            })
            const newPillarBuild = {
                active: true,
                pillar_name: value,
                character_id: activeCharacter.id,
                respend: respendAvail,
                pillar_number: pillarNumber,
                pillar_level: 0,
                capstone: null,
                daily_abilities: [],
                daily_expertise: [],
                encounter_abilities: [],
                encounter_expertise: [],
                new: true,
            }
            workingPillarIventory.push(newPillarBuild);
            workingPillarIventory.sort((a, b) => a.pillar_number - b.pillar_number)
            setValue('');
            setPillarSelection("");
            setRelevantPillarInventory(workingPillarIventory);
            setNewPillarModal(false);
        }
    }

    const closeButton = () => {
        setValue('');
        setPillarSelection("");
        setNewPillarModal(false)
    }

    const buttonStyling = { px: '5px', mx: '5px', my: '5px', minWidth: '100px', fontSize: '0.75rem' }
    const buttonStyling2 = { px: '0px', mx: '5px', my: '5px', fontSize: '.8rem' }
    const selectionStyling = { fontSize: '0.75rem' }



    return (
        <Modal
            open={newPillarModal}
            onClose={() => { setRelevantModalObj("") }}
            sx={{ overflow: "scroll" }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
        >
            <Fade in={newPillarModal} timeout={200}>
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item>
                        <Paper sx={{ m: '10px', p: '10px', maxWidth: 'sm' }}>
                            <Grid container direction="column">
                                <Grid container item justifyContent="flex-end" direction="row">
                                    <Grid item>
                                        <IconButton onClick={() => closeButton()}>
                                            <CloseOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
                                    <Grid item>
                                        <Typography>

                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<HelpIcon />}
                                            >
                                                <Grid container justifyContent="center" alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="button" sx={{ height: '50px' }}>
                                                            Pillars
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="caption" sx={{ fontSize: "0.6rem" }}>
                                                    {formatTextBlob("During the age of peace between the Children of the New Gods and Humankind, special powers and gifts manifested among the population.$$" +
                                                        " These powers were grouped into five distinct varieties, called Pillars, which manifested among similar individuals.$$" +
                                                        " These Pillars continue to exist today, but manifest differently amongst the people in accordance with their gifts$$" +
                                                        " These pillars represent your Character's abilities in the game world that set them apart from the common man, an innate greatness they can use to become the hero or villain of their own story.")}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Divider sx={{ my: '5px', color: 'white' }} />
                                    <Grid container item columns={5} direction="row" justifyContent="center" alignContent="center">
                                        {totalPillars() ?
                                            <Grid item>
                                                {corePillars.map((items) => {
                                                    const active = (items === value);
                                                    return (
                                                        <Grid item xs={1}>
                                                            <Button variant="contained" color={active ? "success" : "primary"} onClick={() => handleButton(items)} sx={buttonStyling}> {items} </Button>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            :
                                            corePillars.map((items) => {
                                                const active = (items === value);
                                                return (
                                                    <Grid item xs={1}>
                                                        <Button variant="contained" color={active ? "success" : "primary"} onClick={() => handleButton(items)} sx={buttonStyling2}> {items} </Button>
                                                    </Grid>
                                                )
                                            })
                                        }
                                        {totalPillars() ?
                                            <Grid item>
                                                {specializedPillars1.map((items) => {
                                                    const active = (items === value);
                                                    return (
                                                        <Grid item xs={1}>
                                                            <Button variant="contained" color={active ? "success" : "primary"} onClick={() => handleButton(items)} sx={buttonStyling}> {items} </Button>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            : null}
                                        {totalPillars() ?
                                            <Grid item>
                                                {
                                                    specializedPillars2.map((items) => {
                                                        const active = (items === value);
                                                        return (
                                                            <Grid item xs={1}>
                                                                <Button variant="contained" color={active ? "success" : "primary"} onClick={() => handleButton(items)} sx={buttonStyling}> {items} </Button>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            : null}
                                    </Grid>
                                    <Grid container item justifyContent="center" alignItems="center" sx={{ minWidth: "100%", my:"0.5rem"}}>
                                        <Grid item justifyContent="center" alignContent="center">
                                            {pillarSelection === "" ? null :
                                                <img object-fit="cover" height="150" src={classIconMapper(pillarSelection.pillar_name.toLowerCase())} alt="pillar icon" />
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container item justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <Divider sx={{ m: '5px', color: 'white' }} />
                                        </Grid>
                                        <Grid item sx={{mb: "0.5rem"}}>
                                            <Typography color="primary.main" variant="Button" sx={{ fontSize: '1.5rem' }}>
                                                {pillarSelection === "" ? null : pillarSelection.pillar_name.toUpperCase()}
                                            </Typography>
                                        </Grid>
                                        <Divider sx={{ m: '10px', color: 'white' }} />
                                        <Grid item>
                                            <Typography variant="caption" sx={selectionStyling}>
                                                {pillarSelection === "" ? null : formatTextBlob(pillarSelection.pillar_text)}
                                            </Typography>
                                        </Grid>
                                        <Divider sx={{ m: '10px' }} />
                                        <Grid item >
                                            <Typography variant="caption" sx={selectionStyling}>
                                                {pillarSelection === "" ? null : formatTextBlob(pillarSelection.pillar_description)}
                                            </Typography>
                                        </Grid>
                                        <Divider sx={{ m: '10px' }} />
                                    </Grid>
                                    <Grid item>
                                        {value !== "" && pillarSelection !== "" ?
                                            <Button sx={{ m: '10px', p: '10px' }} onClick={handleSubmit} variant="contained" color="success">
                                                Purchase {pillarSelection.pillar_name}
                                            </Button>
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Fade>
        </Modal >
    )
}

export default NewPillarModal;
export const mockUser1 = {
    username: "",
    first_name: 0,
    last_name: 0,
    email: 0,
    password: 0,
    dob:1938,
    create_date: 0,
    account_active:true,
    service_total: "N/A",
    account_role: 1
}
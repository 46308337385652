import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { menuOpenContext } from '../App';

function TopMenuDrawer() {

    const { topMenuOpen, setTopMenuOpen } = useContext(menuOpenContext);
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setTopMenuOpen(open);
    };

    const list = (anchor) => (
        <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                    <ListItem button key={"CharacterSelection"} onClick ={ ()=> {navigate("/character")}}>
                        <ListItemIcon>
                            <EmojiEmotionsIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Character Selection"} />
                    </ListItem>
                    <ListItem button key={"GlossarySelection"} onClick ={ ()=> {navigate("/glossary")}}>
                        <ListItemIcon>
                            <TextSnippetIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Glossary"} />
                    </ListItem>
            </List>
            <Divider />
            <List>
                    <ListItem button variant={"contained"} color={"error"} key={"Logout"} onClick ={()=> {navigate("/login")}}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <Drawer
                anchor={"left"}
                open={topMenuOpen}
                onClose={toggleDrawer(false)}
            >
                {list("left")}
            </Drawer>
        </div>
    );
}

export default TopMenuDrawer;
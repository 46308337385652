import { arcaneArmiger, arcaneFont, armorOfFaith, chemicalInjection, command, constitution, dirtyDeeds, guidance, healingTincture, healingWords, mending, powerSurge, toughness, vampiricPower, venomousStrike } from "../../../data/expertise/Expertise";

export function splitAbilityText(incommingText) {
    return incommingText.split("%%")
};

export function expertisePillarMatcher(relevantModalObj) {
    let matchedExpertise = {}
    switch (relevantModalObj.expertise_ability.pillar_name) {
        case "Soldier":
            matchedExpertise = toughness;
            break;
        case "Sage":
            matchedExpertise = guidance;
            break;
        case "Mage":
            matchedExpertise = arcaneFont;
            break;
        case "Cleric":
            matchedExpertise = armorOfFaith;
            break;
        case "Chemist":
            matchedExpertise = chemicalInjection;
            break;
        case "Berserker":
            matchedExpertise = constitution;
            break;
        case "Rogue":
            matchedExpertise = dirtyDeeds;
            break;
        case "Poisoner":
            matchedExpertise = venomousStrike;
            break;
        case "Pharmacist":
            matchedExpertise = healingTincture;
            break;
        case "Evoker":
            matchedExpertise = powerSurge;
            break;
        case "Aegis":
            matchedExpertise = arcaneArmiger;
            break;
        case "Necromancer":
            matchedExpertise = vampiricPower;
            break;
        case "Priest":
            matchedExpertise = healingWords;
            break;
        case "Bard":
            matchedExpertise = mending;
            break;
        case "Tactician":
            matchedExpertise = command;
            break;
    }
    matchedExpertise.pillar_number = relevantModalObj.pillar_number
    return matchedExpertise;
};

export function currentXpCost(pillarNumber, expertiseCost) {
    switch (pillarNumber + 1) {
        case 1:
            return 1 * expertiseCost;
        case 2:
            switch (expertiseCost) {
                case 1:
                    return 1;
                case 2:
                    return 3;
                case 3:
                    return 4;
                case 4:
                    return 6;
                case 5:
                    return 8;
            }
            break;
        case 3:
            return 2 * expertiseCost;
        case 4:
            return 3 * expertiseCost;
        case 5:
            return 4 * expertiseCost;
    }
};

export function expButtonText(relevantModalObj, pillarBuild, unspentXp) {
    const currentPillarNumber = relevantModalObj.pillar_number;
    const usage = relevantModalObj.usage;
    const capstone = pillarBuild.capstone;
    const relevantDailyExpertiseArray = pillarBuild.daily_expertise;
    const relevantEncounterExpertiseArray = pillarBuild.encounter_expertise;
    let relevantExpertiseArray;
    let relevantAbilityArray;
    if (relevantDailyExpertiseArray.length >= 5 || usage === "encounter") {
        relevantExpertiseArray = relevantEncounterExpertiseArray;
        relevantAbilityArray = pillarBuild.encounter_abilities
    }
    else {
        relevantExpertiseArray = relevantDailyExpertiseArray;
        relevantAbilityArray = pillarBuild.daily_abilities
    }
    const prerequesiteTierNumber = relevantExpertiseArray.length + 1
    const filteredAbilityArray = relevantAbilityArray.filter((ability => ability.ability_cost === prerequesiteTierNumber));
    const costBase = currentXpCost(currentPillarNumber, relevantExpertiseArray.length + 1);
    let cost = costBase;
    if (usage === "encounter") { cost = costBase * 2 }
    const buyable = cost <= unspentXp && filteredAbilityArray.length === 4;
    if (buyable) {
        if (usage === "daily" && pillarBuild.daily_expertise.length >= 5 && capstone) {
            return ("Upgrade");
        }
        else if (usage === "daily" || usage === "none" || usage === undefined) {
            return ("Buy");
        }
    }
    else if (usage === undefined) {
        return "Buy"
    }
    else if (usage === "encounter") {
        return ("Close");
    }
    else {
        return ("Close")
    }
};
//Tier 1

export const terrifyPoison = {
    ability_name: "Terrifying Poison",
    ability_text: "One simple jab and pure chemical fear courses into your foes' brains. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Fear 60%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill Fear 20%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "fear", "poison"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const numbPoison = {
    ability_name: "Numbing Poison",
    ability_text: "Neurotoxins always affect the digital extremities first. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Immobilize 30%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill Immobilize 10%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "wither", "poison"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 2

export const torporPoison = {
    ability_name: "Torpor Poison",
    ability_text: "There are several toxins that lead to instant cataonia and you have mixed them all. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Sleep 300%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill Sleep 60%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "sleep", "poison"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const babblePoison = {
    ability_name: "Babbling Poison",
    ability_text: "The tongue contains many complex muscles and inducing numbness is trivial. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Silence 300%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill Silence 60%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "silence", "poison"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 3

export const hemorrPoison = {
    ability_name: "Hemorrhagic Poison",
    ability_text: "Excessive bleeding is a gruesome but effective way of killing an enemy. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Hemorrhage 300%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill Hemorrhage 60%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "hemorrhage", "poison"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const hemlock = {
    ability_name: "Hemlock",
    ability_text: "The simplest poisons are often the most deadly. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill 150 Acid%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill 75 Acid%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "acid", "poison"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 4

export const blightedPoison = {
    ability_name: "Blighted Poison",
    ability_text: "Some poisons are improved with a little divine sanctimony. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Blight 300%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill Blight 60%% until it either delivers its effect or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "blight", "poison"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const drainPoison = {
    ability_name: "Draining Poison",
    ability_text: "Some poisons can be enchanted with a binary compound to bring healing as they bring harm. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill 60 Drain%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls $$Skill 20 Drain$$ until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "drain", "poison"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 5

export const daturaNarc = {
    ability_name: "Datura Narcisse",
    ability_text: "Some poisons can be enchanted with a binary compound to bring healing as they bring harm. You must Touch a Dead creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Undeath 120%% and %%Skill 20 Harm%%. A creature may only be raised in this way once per death. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "undeath", "poison"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const psuedocomae = {
    ability_name: "Psuedocomae",
    ability_text: "Powerful paralytics allow you to sever someone’s consciousness from their body for a time. You must Touch another creature, and on completion of a five Second “Pouring” Count, you may call %%Skill Paralyze 300%%. You may instead Touch one weapon or piece of ammunition, and on a five count of “applying,” that weapon calls %%Skill Paralyze 60%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. Only one Poison may be applied to a weapon at a time.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "paralyze", "poison"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tierless

export const poisonerCapstoneAbility = {
    ability_name: "Poisoner Champion",
    ability_text: "When using one of your poison abilities on a target, if that target responds with “No Effect,” “Immune” or similar, that use of your poison is refreshed. You must still reapply the poison in the conventional way to reactivate it. Poisons that are resisted are still expended.",
    pillar_name: "Poisoner",
    prerequesite: null,
    ability_tags: ["passive"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const poisonerExpertiseAbility = {
    ability_name: "Venomous Strike",
    ability_text: "A quick sleight of hand allows you to dose your weapon in the same movement as you strike, though only with moderate efficacy. You may call one of %%Skill Hemorrhage 3%%, %%Skill Immobilize 3%%, %%Skill Slow 3%%.",
    pillar_name: "Poisoner",
    prerequisite: null,
    ability_tags: ["skill", "strike", "hemorrhage", "immobilize", "slow"],
    ability_cost: null,
    ability_uses: 1,
    ability_tier_limit: 1,
}
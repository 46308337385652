//Tier 1
export const block =
{ability_name: "Block",
ability_text: "You are able to protect those around you with your blade, bulwark, or body. When an ally within your melee Reach has been hit by an Attack or Ability delivered by a weapon or packet, you may call %%Skill Intercept%% within three Seconds, becoming the Target of the triggering effect. ",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "reach", "intercept"],
ability_cost: 1,
ability_uses: 1,
ability_tier_limit: null,
}

export const disarm =
{ability_name: "Disarm",
ability_text: "Weapons are your instrument, and others simply cannot match your precision. You may call %%Skill Wither 3%% on a successful strike with a weapon.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "strike", "wither"],
ability_cost: 1,
ability_uses: 1,
ability_tier_limit: null,
}

export const hew = 
{ability_name: "Hew",
ability_text: "You can focus your might into a powerful blow that punches through your foe’s guard with ease. You may call %%Skill {Damage} Pierce%% on one weapon Attack instead of your normal damage type.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "strike", "pierce"],
ability_cost: 1,
ability_uses: 1,
ability_tier_limit: null,
}

export const prof1 = 
{ability_name: "Weapons Proficiency",
ability_text: "You are the consummate warrior, your prowess reflected in your handling of all weapons. You gain a bonus of one to the damage you deal with one-handed weapons. This damage is cumulative with all further purchases of this Ability, and this is doubled when used with two-handed weapons.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["passive"],
ability_cost: 1,
ability_uses: null,
ability_tier_limit: 1,
}

//Tier 2

export const bash =
{ability_name: "Bash",
ability_text: "A firm blow sends your foe reeling away. You may call %%Skill Push 1%% on a successful strike with a weapon.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "strike", "push"],
ability_cost: 2,
ability_uses: 1,
ability_tier_limit: null,
}

export const hamstring =
{ability_name: "Hamstring",
ability_text: "Slicing quickly, you can disrupt your opponent's footwork. You may call %%Skill Immobilize 5%% on a successful strike with a weapon.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "strike", "immobilize"],
ability_cost: 2,
ability_uses: 1,
ability_tier_limit: null,
}

export const indom = 
{ability_name: "Indomitable",
ability_text: "There are many things that dissuade mortals from their goals, but you are not so easily shaken. You may call %%Resist%% to any single Condition applied to you so long as that Condition has a duration.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "self", "resist"],
ability_cost: 2,
ability_uses: 1,
ability_tier_limit: 1,
}

export const parry = 
{ability_name: "Parry",
ability_text: "Turning the blade is far easier than turning the other cheek. You may call %%Skill Counter%% on one Attack or Skill delivered to you or an ally within Reach.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "reach", "counter"],
ability_cost: 2,
ability_uses: 1,
ability_tier_limit: null,
}

// Tier 3

export const bruteStrength = 
{ability_name: "Brute Strength",
ability_text: "With dedicated application of strength, you carve your foes into ribbons. For the next five Swings, you increase your one-handed damage by five and this is doubled when used with two-handed weapons.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "self"],
ability_cost: 3,
ability_uses: 1,
ability_tier_limit: null,
}

export const prof3 = 
{ability_name: "Weapons Proficiency",
ability_text: "You are the consummate warrior, your prowess reflected in your handling of all weapons. You gain a bonus of one to the damage you deal with one-handed weapons. This damage is cumulative with all further purchases of this Ability, and this is doubled when used with two-handed weapons.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["passive"],
ability_cost: 3,
ability_uses: null,
ability_tier_limit: 1,
}

export const refit = 
{ability_name: "Combat Repairs",
ability_text: "You’ve become so familiar with your Armor that you can provide minor repairs in the field. Upon completion of a ten Second Count, you may set your Armor to half its maximum value.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "self", "10 count"],
ability_cost: 3,
ability_uses: 1,
ability_tier_limit: null,
}

export const slay = 
{ability_name: "Slay",
ability_text: "Whether it is by evisceration or severing limbs, you deal death. You may call %%Skill 50 Pierce%% on a successful strike with a weapon.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "strike", "pierce"],
ability_cost: 3,
ability_uses: 1,
ability_tier_limit: null,
}

//Tier 4

export const fortitude = 
{ability_name: "Fortitude",
ability_text: "You ignore pain so thoroughly your body has been forced to adapt to extreme punishment. You gain a permanent +5 bonus to your Health.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["passive"],
ability_cost: 4,
ability_uses: null,
ability_tier_limit: 1,
}

export const mutilate = 
{ability_name: "Mutilate",
ability_text: "You open veins in a ceasless tide. You may call %%Skill Hemorrhage 10%% on a successful strike with a weapon.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "strike", "Hemorrhage"],
ability_cost: 4,
ability_uses: 1,
ability_tier_limit: null,
}

export const sunderArmor = 
{ability_name: "Sunder Armor",
ability_text: "Armor cannot withstand your strikes, at least not for long. You may call %%Skill Sunder%% on a successful strike with a weapon.",
pillar_name: "Soldier",
prerequesite: "Disarm",
ability_tags:["skill", "strike", "sunder"],
ability_cost: 4,
ability_uses: 1,
ability_tier_limit: null,
}

export const terrifyingWrath = 
{ability_name: "Terrifying Wrath",
ability_text: "Your might easily inspires fear in those who oppose you. You may call %%Skill Fear 10%% on a successful strike with a weapon.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "strike", "fear"],
ability_cost: 4,
ability_uses: 1,
ability_tier_limit: null,
}

//tier 5

export const barrage = 
{ability_name: "Barrage",
ability_text: "Bracing your feet, you slash your way through all those foolish enough to approach. You may choose to be Immobilized for up to ten Seconds. While Immobilized, you may call %%Skill {Damage} Pierce%% on your weapon Attacks instead of your normal damage type. If any strike delivered by this Ability is Countered, the Ability immediately ends.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "self", "stance"],
ability_cost: 5,
ability_uses: 1,
ability_tier_limit: null,
}

export const prof5 = 
{ability_name: "Weapons Proficiency",
ability_text: "You are the consummate warrior, your prowess reflected in your handling of all weapons. You gain a bonus of one to the damage you deal with one-handed weapons. This damage is cumulative with all further purchases of this Ability, and this is doubled when used with two-handed weapons.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["passive"],
ability_cost: 5,
ability_uses: null,
ability_tier_limit: 1,
}

export const riposte = 
{ability_name: "Riposte",
ability_text: "A twist of the wrist turns the enemy’s deathblow back upon themselves. You may call %%Skill Return%% on one Skill or Attack delivered to you.",
pillar_name: "Soldier",
prerequesite: "Parry",
ability_tags:["skill", "self", "return"],
ability_cost: 5,
ability_uses: 1,
ability_tier_limit: null,
}

export const spellCarve = 
{ability_name: "Spell Carve",
ability_text: "A swift strike and the threads of magic are split in twain. You may call %%Skill Counter%% on one Spell delivered to you or an ally within Reach.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "reach", "counter"],
ability_cost: 5,
ability_uses: 1,
ability_tier_limit: null,
}

//Tierless
export const soldierCapstoneAbility = 
{ability_name: "Soldier Capstone",
ability_text: "Once per Day, you may subject yourself to %%Heal 10%% or %%Harm 10%% on a ten Count. You may do this when Dying, but not while Dead.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["skill", "healing", "10 count"],
ability_cost: 6,
ability_uses: 2,
ability_tier_limit: null,
usage: "encounter"
}

export const soldierExpertiseAbility = 
{ability_name: "Toughness",
ability_text: "You have trained your body such that wounds others find mortal barely slow you down. You gain +2 to your Health total, and your Armor cap increases by 5.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:["passive"],
ability_cost: null,
ability_uses: null,
ability_tier_limit: 1,
}
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { Box, Button, Card, CardActions, CardContent, Container, Divider, Grid, List, ListItem, ListItemButton, Stack, Tab, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createContext, Fragment, useContext, useState } from "react";
import { activeCharacterContext, charactersContext, offlineModeContext, pillarInventoryContext } from "../../App";
import { characterNameSizer, convertPBToSubmitable, findPillarsByCharacter, formatTextBlob, heritageResistanceShortener } from '../../processing/DataFormattingUtils';
import { theme } from "../../styles/AppTheme";
import Equipment from '../Equipment/Equipment';
import NewPillarModal from '../NewPillar/NewPillarModal';
import PillarBuild from '../PillarBuild/PillarBuild';
import CharacterTraits from './CharacterTraits';
import ItemSelectModal from '../Equipment/ItemSelectModal';
import { postPillarBuild, updateProfessions } from '../../processing/RestClient';
import Crafting from '../Crafting/Crafting';
import NewCraftingModal from '../Crafting/NewCraftingModal';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ConstructionIcon from '@mui/icons-material/Construction';
import SaveIcon from '@mui/icons-material/Save';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import HardwareIcon from '@mui/icons-material/Hardware';
import Bank from '../Bank/Bank';

export const buildModeContext = createContext(false);
export const modifiedBuildContext = createContext(false);
export const newPillarModalContext = createContext(false);
export const newCraftingModalContext = createContext(false);
export const itemSelectModalContext = createContext(false);
export const relevantPillarInventoryContext = createContext({ pillar_builds: [] });
export const modifiedCraftingContext = createContext([]);
export const modifiedAbilsContext = createContext(false);
export const modifiedCraftingFlagContext = createContext([]);


function CharacterScreen() {
    const [buildMode, setBuildMode] = useState(false);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext)
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const [modifiedBuild, setModifiedBuild] = useState(activeCharacter);
    const [relevantPillarInventory, setRelevantPillarInventory] = useState(findPillarsByCharacter(activeCharacter, pillarInventory));
    const [newPillarModal, setNewPillarModal] = useState(false);
    const [newCraftingModal, setNewCraftingModal] = useState(false);
    const [tabValue, setTabValue] = useState("1");
    const [itemSelectModal, setItemSelectModal] = useState(false);
    const [modifiedCrafting, setModifiedCrafting] = useState([]);
    const [modifiedAbils, setModifiedAbils] = useState(false);
    const [modifiedCraftingFlag, setModifiedCraftingFlag] = useState(false);

    const { offlineMode, setOfflineMode } = useContext(offlineModeContext)

    let buildModeSetter = (event) => {
        setModifiedBuild(relevantPillarInventory);
        buildMode ? setBuildMode(false) : setBuildMode(true);
    }

    const saveCharacter = () => {
        const submitablePBArray = [];
        relevantPillarInventory.forEach((pb) => {
            submitablePBArray.push(convertPBToSubmitable(pb));
        })

        if (modifiedCraftingFlag) {
            updateProfessions(modifiedCrafting);
        }
        if (modifiedAbils) {

            postPillarBuild(submitablePBArray)
                .then((response) => {
                })
        }
        setModifiedCraftingFlag(false);
        setModifiedAbils(false);
        setBuildMode(false);
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const newButtonStyling = { width: '300px', borderRadius: '15px', border: '2px', borderStyle: 'solid', borderColor: 'success.main' };

    return (
        <ThemeProvider theme={theme}>
            <buildModeContext.Provider value={{ buildMode, setBuildMode }}>
                <modifiedBuildContext.Provider value={{ buildMode, setBuildMode }}>
                    <modifiedAbilsContext.Provider value={{ modifiedAbils, setModifiedAbils }}>
                        <relevantPillarInventoryContext.Provider value={{ relevantPillarInventory, setRelevantPillarInventory }}>
                            <itemSelectModalContext.Provider value={{ itemSelectModal, setItemSelectModal }}>
                                <newPillarModalContext.Provider value={{ newPillarModal, setNewPillarModal }}>
                                    <newCraftingModalContext.Provider value={{ newCraftingModal, setNewCraftingModal }}>
                                        <modifiedCraftingFlagContext.Provider value={{ modifiedCraftingFlag, setModifiedCraftingFlag }}>
                                            <modifiedCraftingContext.Provider value={{ modifiedCrafting, setModifiedCrafting }}>
                                                <Box>
                                                    {buildMode ? <NewPillarModal /> : null}
                                                    {buildMode ? <NewCraftingModal /> : null}
                                                    <ItemSelectModal />
                                                    <Box sx={{ mt: '10px' }} >
                                                        <ListItem disablePadding sx={{ marginTop: '30px' }}>
                                                            <ListItemButton sx={{ margin: '0 !important', padding: '0 !important' }}>
                                                                <Stack spacing={2} direction="row" sx={{ color: '#ffffff', bgcolor: '#121212', borderRadius: '10px', padding: '10px 0 20px', width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' } }}>
                                                                    <Card variant="outlined" elevation={0} sx={{ border: 'none', bgcolor: 'none', mr: '10px', margin: '0 !important', padding: '0 !important' }}>
                                                                        <CardContent>
                                                                            <Typography variant="h6" gutterBottom sx={{ color: '#fefefe', fontWeight: '900', fontSize: characterNameSizer(activeCharacter.character_name), inlineSize: '150px', overflowWrap: 'break-word' }}>
                                                                                {activeCharacter.character_name}
                                                                            </Typography>
                                                                            <Stack spacing={3} direction="row" sx={{ marginTop: '-10px' }}>
                                                                                <Typography sx={{ fontSize: '1rem' }} component="div">
                                                                                    {activeCharacter.heritage.heritage_name}
                                                                                </Typography>
                                                                            </Stack>
                                                                            <Stack spacing={1} direction="row" >
                                                                                {relevantPillarInventory.map(pillarBuild => {
                                                                                    return (
                                                                                        <Fragment key={pillarBuild.pillar_name + activeCharacter.id + pillarBuild.pillar_nnumber}>
                                                                                            <Typography sx={{ fontSize: '.5rem', style: "italic" }} component="div">
                                                                                                {pillarBuild.pillar_name}
                                                                                            </Typography>
                                                                                        </Fragment>
                                                                                    )
                                                                                })}
                                                                            </Stack>
                                                                            <Typography variant='caption' component="div">
                                                                                {formatTextBlob('%% Resist ' + heritageResistanceShortener(activeCharacter.heritage.heritage_name) + '%%')}
                                                                            </Typography>
                                                                            <Stack spacing={1} direction="row">
                                                                                <Typography sx={{ fontSize: '1rem' }} component="div">
                                                                                    Essence:
                                                                                </Typography>
                                                                                <Typography sx={{ fontSize: '1rem' }} color="primary" component="div">
                                                                                    {activeCharacter.life_total}
                                                                                </Typography>
                                                                            </Stack>
                                                                            <Grid container justifyContent="flex-start" alignItems="center" spacing={1} direction="row" >
                                                                                <Grid item >
                                                                                    <Typography variant="caption" component="div">
                                                                                        Unspent XP:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item >
                                                                                    <Typography variant="h6" component="div" color="primary">
                                                                                        {activeCharacter.xp_unspent}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>
                                                                    <Card variant="outlined" elevation={0} sx={{ border: 'none', bgcolor: 'none', pr: '10px', padding: '0 !important', margin: '0 !important' }}>
                                                                        <CardContent sx={{ margin: '0', padding: '0' }}>
                                                                            <Typography variant="h6" textAlign="center" component="div" sx={{ fontWeight: 'bold', color: '#A1A1A1', marginTop: '25px' }}>
                                                                                LEVEL
                                                                            </Typography>
                                                                            <Typography sx={{ fontWeight: '900', fontSize: '3.5rem' }} variant="h3" textAlign="center" component="div" >
                                                                                {activeCharacter.level >= 0 ? activeCharacter.level : 0}
                                                                            </Typography>
                                                                            <Typography variant="h5" textAlign="center" component="div" sx={{ fontSize: '1rem' }}>
                                                                                XP: {activeCharacter.xp_total}
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <CardActions>
                                                                            <Stack direction='column' spacing={0.25}>
                                                                                <Button variant={buildMode ? 'contained' : 'outlined'} sx={buildMode ? { color: 'black', px: '10px', mx: '10px' } : { color: 'white', px: '10px', mx: '10px' }} endIcon={<ConstructionIcon />} onClick={buildModeSetter} color="success">
                                                                                    Build Character
                                                                                </Button>
                                                                                {!buildMode ? null :
                                                                                    // eslint-disable-next-line no-restricted-globals
                                                                                    <Button variant='contained' sx={{ color: 'black', px: '10px', mx: '10px' }} endIcon={<SaveIcon />} onClick={saveCharacter} color="save">
                                                                                        Save Character
                                                                                    </Button>
                                                                                }
                                                                            </Stack>
                                                                        </CardActions>
                                                                    </Card>
                                                                </Stack>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </Box >
                                                    <Divider />
                                                    <Container sx={{ flexGrow: 1, }}>
                                                        <CharacterTraits />
                                                    </Container>
                                                    <Divider />
                                                    <Grid container justifyContent="center" alignContent="center" sx={{ mb: '10px' }}>
                                                        <Grid item>
                                                            <TabContext value={tabValue}>
                                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                                    <TabList value={tabValue} onChange={handleTabChange} variant='scrollable' scrollButtons allowScrollButtonsMobile>
                                                                        <Tab icon={<AppRegistrationIcon />} iconPosition="top" label="Abilities" value="1" />
                                                                        <Tab icon={<HardwareIcon />} iconPosition="top" label="Crafting" value="2" />
                                                                        {/* TODO: Get item screen working */}
                                                                        {/* <Tab icon={<HomeRepairServiceIcon />} iconPosition="top" label="Equipment" value="3" /> */}
                                                                        <Tab icon={<AccountBalanceIcon />} iconPosition="top" label="Bank" value="4" />
                                                                        {/* <Tab icon={<AddToPhotosIcon />} iconPosition="top" label="Scan" value="5" /> */}
                                                                    </TabList>
                                                                </Box>
                                                                <TabPanel value="1">
                                                                    <Stack direction={"column"}>
                                                                        <PillarBuild />
                                                                        {relevantPillarInventory.length < 5 && buildMode ?
                                                                            <Grid item>
                                                                                <List>
                                                                                    <ListItem button key={"new-character-list"} sx={{ margin: "0 auto" }} disablePadding onClick={() => setNewPillarModal(true)}>
                                                                                        <ListItemButton key={"new-character-button"} sx={{ margin: "0 auto", bgcolor: '#121212', border: '2px', borderStyle: 'solid', borderColor: "success.main", borderRadius: '10px', py: '10px', width: '300px' }} divider={true}>
                                                                                            <Grid container justifyContent="center" alignContent="center">
                                                                                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                                                                                    <Typography variant='button' color='success.main'>Add Pillar</Typography>
                                                                                                    <AddCircleIcon color="success" sx={{ minWidth: '30px', minHeight: '30px' }} />
                                                                                                </Stack>
                                                                                            </Grid>
                                                                                        </ListItemButton>
                                                                                    </ListItem>
                                                                                </List>
                                                                            </Grid>
                                                                            : null}
                                                                    </Stack>
                                                                </TabPanel>
                                                                <TabPanel value="2">
                                                                    <Stack direction={"column"}>
                                                                        <Crafting />
                                                                        {activeCharacter.professions.length < 5 && buildMode ?
                                                                            <Grid item>
                                                                                <List>
                                                                                    <ListItem button key={"new-character-list"} sx={{ margin: "0 auto" }} disablePadding onClick={() => setNewCraftingModal(true)}>
                                                                                        <ListItemButton key={"new-character-button"} sx={{ margin: "0 auto", bgcolor: '#121212', border: '2px', borderStyle: 'solid', borderColor: "success.main", borderRadius: '10px', py: '10px', width: '300px' }} divider={true}>
                                                                                            <Grid container justifyContent="center" alignContent="center">
                                                                                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                                                                                    <Typography variant='button' color='success.main'>Add Profession</Typography>
                                                                                                    <AddCircleIcon color="success" sx={{ minWidth: '30px', minHeight: '30px' }} />
                                                                                                </Stack>
                                                                                            </Grid>
                                                                                        </ListItemButton>
                                                                                    </ListItem>
                                                                                </List>
                                                                            </Grid>
                                                                            : null}
                                                                    </Stack>
                                                                </TabPanel>
                                                                <TabPanel value="4">
                                                                    <Bank/>
                                                                </TabPanel>
                                                            </TabContext>
                                                        </Grid>
                                                    </Grid>
                                                    {/* Item Summary Display */}
                                                </Box>
                                            </modifiedCraftingContext.Provider>
                                        </modifiedCraftingFlagContext.Provider>
                                    </newCraftingModalContext.Provider>
                                </newPillarModalContext.Provider>
                            </itemSelectModalContext.Provider>
                        </relevantPillarInventoryContext.Provider>
                    </modifiedAbilsContext.Provider>
                </modifiedBuildContext.Provider>
            </buildModeContext.Provider>
        </ThemeProvider >
    )
}

export default CharacterScreen;
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { capstoneConfirmationModalOpenContext } from "./CapstoneModal";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function CapstoneConfirmationModal(props) {

    const onConfirm = props.onConfirm;
    const exit = props.exit;

    const { capstoneConfirmationModalOpen, setCapstoneConfirmationModalOpen } = useContext(capstoneConfirmationModalOpenContext);

    return (
        <Modal
            open={capstoneConfirmationModalOpen}
            onClose={exit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
        >
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item>
                    <Box>
                        <Paper sx={{ margin: '40px auto 0', padding: '40px', borderRadius: '10px', maxWidth: 'sm' }}>
                            <Typography variant="h6">
                                Please Confirm your selection
                            </Typography>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    <Button startIcon={<CancelIcon />} variant="contained" color="success" onClick={exit}>
                                        Close
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button endIcon={<AddCircleOutlineIcon />} variant="contained" color="error" onClick={onConfirm}>
                                        Confirm
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    )

}

export default CapstoneConfirmationModal;


import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { findConcept, formatTextBlob } from "../../../processing/DataFormattingUtils";

function CapstoneModalHeader(props) {

    const capstone = props.capstone;
    const ability = capstone.capstone_ability;

    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {ability.ability_name !== " " ? ability.ability_name : null}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {usesRender(ability)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify='center'>
                {ability.ability_tags.map((tag, index) => {
                    const tagConcept = findConcept(tag);
                    return (
                        <div>
                            <Box container>
                                <Tooltip title={tagConcept ? formatTextBlob(tagConcept.concept_text) : ""}>
                                    <Typography color="primary" id="modal-modal-title" variant="subtitle1" component="h2">
                                        {tag} {ability.ability_tags.length - 1 === index ? null : bullet}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </div>
                    )
                })}
            </Grid>
            <Typography color="white" variant="h6" id="spell-verbals">
                {ability.ability_verbal ? ability.ability_verbal : null}
            </Typography>
        </Box>
    );
}

function usesRender(ability) {
    if (ability.ability_name === " ") {
        return (
            null
        )
    }
    else if (ability.ability_uses === 0 || !ability.ability_uses) {
        return (
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Passive
            </Typography>
        )
    }
    else {
        return (
            <Typography id="modal-modal-title" variant="h6" component="h2">
                x {ability.ability_uses} per {ability.usage === "encounter" ? "Encounter" : "Day"}
            </Typography>
        )
    }
}

const bullet = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default CapstoneModalHeader;
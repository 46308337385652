
//Tier 1
export const fortifySkin =
{
    ability_name: "Fortify Skin",
    ability_text: "This Tonic causes the skin of its imbiber to thicken and toughen, making them that much harder to slay. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may grant them five temporary Armor. This Skill is a Tonic, and only one Tonic may be applied to a creature at a time.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Tonic"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const potionOfHealingHarming =
{
    ability_name: "Potion of Healing/Harming",
    ability_text: "You can create a basic elixir of healing, capable of curing most wounds in seconds. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill 10 Heal%% or %%Skill 10 Harm%%.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Heal", "Harm"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const remedy =
{
    ability_name: "Remedy",
    ability_text: "You have learned the first of the universal solvents, and its uses are myriad. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call one of the following: %%Skill Counter Slow%%, %%Skill Counter Immobilize%%, or %%Skill Counter Wither%%.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Counter", "Slow", "Immobilize", "Wither"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const slowPoison =
{
    ability_name: "Slow Poison",
    ability_text: "You have mastered the creation of an insidious poison that deadens one's reflexes and thinking. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill Slow 60%%. You may instead Touch one weapon or piece of ammunition, and on a five count of %%applying,%% that weapon calls %%Skill Slow 20%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. ",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Slow", "Poison"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

//Tier 2
export const fortifyBlood =
{
    ability_name: "Fortify Blood",
    ability_text: "This Tonic causes the blood of its imbiber to run far hotter than normal, neutralizing many toxins. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may grant them the ability to call %%Resist%% against your choice of Charm, Fear, Hemorrhage, Paralyze, Waste, or Wither one time before the start of the next encounter. This Skill is a Tonic, and only one Tonic may be applied to a creature at a time.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Resist", "Tonic"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const glueBomb =
{
    ability_name: "Glue Bomb",
    ability_text: "You have unlocked the capacity to create a fast-acting sticky glue that binds your enemies to the ground. You may throw a %%Skill Immobilize 5%% packet.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Packet", "Immobilize"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const rectify =
{
    ability_name: "Rectify",
    ability_text: "You have learned to mix a potent universal solvent, capable of curing almost all poisons. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call one of the following: %%Skill Counter Charm%%, %%Skill Counter Fear%%, %%Skill Counter Hemorrhage%%, or %%Skill Counter Paralyze%%.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Counter", "Charm", "Fear", "Hemorrhage", "Paralyze"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const witheringPoison =
{
    ability_name: "Withering Poison",
    ability_text: "This poison rapidly numbs the limbs of your foes. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill Wither 60.%% You may instead Touch one weapon or piece of ammunition, and on a five count of %%applying,%% that weapon calls %%Skill Wither 20%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. ",
    pillar_name: "Chemist",
    prerequesite: "Slow Poison",
    ability_tags: ["Skill", "Touch", "5 Count", "Wither", "Poison"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

//Tier 3
export const defibrilate =
{
    ability_name: "Defibrilate",
    ability_text: "A careful mixture of galvanism and tinctures can even revive the dead. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call either %%Skill Death%% or %%Skill Life%%.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Life", "Death"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const greaterHealHarmPotion =
{
    ability_name: "Greater Heal/Harm Potion",
    ability_text: "You can create a powerful restorative elixir that can heal almost all wounds. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill 30 Heal%% or %%Skill 30 Harm%%.",
    pillar_name: "Chemist",
    prerequesite: "Potion of Healing/Harming",
    ability_tags: ["Skill", "Touch", "5 Count", "Heal", "Harm"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const inoculation =
{
    ability_name: "Inoculation",
    ability_text: "You have been exposed to so many different chemicals and concoctions that normal dosages no longer affect you. You may call %%Resist%% once to one of Charm, Fear, Hemorrhage, Paralyze, Waste, or Wither.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Self", "Resist", "Charm", "Fear", "Hemorrhage", "Paralyze", "Waste", "Wither"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const paralyticPoison =
{
    ability_name: "Paralytic Poison",
    ability_text: "You have mastered the creation of a poison that causes sudden and immediate seizing of the joints. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill Paralyze 30%%. You may instead Touch one weapon or piece of ammunition, and on a five count of %%applying,%% that weapon calls %%Skill Paralyze 10%% until it either delivers its effect or until the start of the next Encounter. ",
    pillar_name: "Chemist",
    prerequesite: "Withering Poison",
    ability_tags: ["Skill", "Touch", "5 Count", "Paralyze", "Poison"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

//Tier 4
export const charmPoison =
{
    ability_name: "Charm Poison",
    ability_text: "Your poisons can cloud the minds of others, causing them to see you as an ally. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill Charm 300%%. You may instead Touch one weapon or piece of ammunition, and on a five count of %%applying,%% that weapon calls %%Skill Charm 120%% until it either delivers its effect, it is resisted, or until the start of the next Encounter. ",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "5 Count", "Charm", "Poison"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const cureAll =
{
    ability_name: "Cure-all",
    ability_text: "You have mastered the most powerful solvent, capable of purifying even the most corrupt flesh. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill Counter All.%%",
    pillar_name: "Chemist",
    prerequesite: "Rectify",
    ability_tags: ["Skill", "Touch", "5 Count", "Counter"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const fortifyMuscles =
{
    ability_name: "Fortify Muscles",
    ability_text: "This Tonic causes the muscles of the imbiber to swell and bulge, strengthening their attacks. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, the Target's next five Swings have their one handed damage increased by five. This Skill is a Tonic, and only one Tonic may be applied to a creature at a time.",
    pillar_name: "Chemist",
    prerequesite: "Fortify Skin",
    ability_tags: ["Skill", "Touch", "5 Count", "Tonic"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const wastingPoison =
{
    ability_name: "Wasting Poison",
    ability_text: "You have mastered the creation of a poison that causes sudden and immediate seizing of the joints. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill Waste 120%%. You may instead Touch one weapon or piece of ammunition, and on a five count of %%applying,%% that weapon calls %%Skill Waste 30%% until it either delivers its effect, or until the start of the next Encounter. ",
    pillar_name: "Chemist",
    prerequesite: "Paralytic Poison",
    ability_tags: ["Skill", "Touch", "5 Count", "Waste", "Poison"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

//Tier 5
export const curare =
{
    ability_name: "Curare",
    ability_text: "Curare is the king of poisons, capable of rendering someone into a gelatinous mess. You must Touch another creature, and on completion of a five Second %%Pouring%% Count you may %%Skill 200 Acid%%. You may instead Touch one weapon or piece of ammunition, and on a five count of %%applying,%% that weapon calls %%Skill 100 Acid%% until it either delivers its effect, it is resisted or until the start of the next Encounter.",
    pillar_name: "Chemist",
    prerequesite: "Wasting Poison",
    ability_tags: ["Skill", "Touch", "5 Count", "Acid", "Poison"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const fortifyEssence =
{
    ability_name: "Fortify Essence",
    ability_text: "This Tonic pervades every inch of your body, fortifying every system within it. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you allow them to call %%Resist%% to shrug off any Condition until the start of the next Encounter. This Skill is a Tonic, and only one Tonic may be applied to a creature at a time.",
    pillar_name: "Chemist",
    prerequesite: "Fortify Blood",
    ability_tags: ["Skill", "Touch", "5 Count", "Resist", "Tonic"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const panacea =
{
    ability_name: "Panacea",
    ability_text: "You have created the most powerful restorative draught known. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, the Target is set to Living Status, all of their conditions are %%Countered%%, and their Health is returned to its maximum value.",
    pillar_name: "Chemist",
    prerequesite: "Defibrilate",
    ability_tags: ["Skill", "Touch", "5 Count", "Counter", "Life", "Death"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

export const tarPatch =
{
    ability_name: "Tar Patch",
    ability_text: "You create a bomb that spreads thick black tar all over your Target. You may throw a %%Skill Immobilize 60%% packet.",
    pillar_name: "Chemist",
    prerequesite: "Glue Bomb",
    ability_tags: ["Skill", "Packet", "Immobilize"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: null,
    ability_tier_limit: null,
}

//Tierless
export const chemistCapstoneAbility =
{
    ability_name: "Chemist Capstone",
    ability_text: "Your mastery of alchemy allows you to mix a Hyde Serum. Once per Day, you can ingest this Tonic on a five second %%pouring%% Count, granting you greater strength and durability. You gain ten Health, ten Armor (that ignores your Armor cap), and deal an additional five damage with one-handed weapons for one Encounter. When the effect ends, you are subjected to %%Waste 300%%. At any time before the start of the next Encounter, you may end the effect on a five Second %%Returning%% Count.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Self", "5 count", "Tonic"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const chemistExpertiseAbility =
{
    ability_name: "Chemical Injection",
    ability_text: "You have mastered the art of applying stabilizers to your concoctions, allowing them to remain exposed to oxygen for a limited time. For each purchase of this Expertise, you must select one Alchemy Ability you possess from the same tier as this Expertise that requires a five Second Count. You must Touch a melee weapon or piece of ammunition you possess and complete a five Second %%Applying%% Count, and you may deliver that Ability on your next successful Strike rather than a Count. If used with a melee weapon if the strike fails to connect  it may continue to be called until successfully delivered or resisted. Usage of this Skill does not consume your use of that Ability.",
    pillar_name: "Chemist",
    prerequesite: null,
    ability_tags: ["Skill", "Strike", "Touch", "5 Count"],
    ability_cost: null,
    ability_uses: 1,
    ability_tier_limit: 1,
}
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from "@mui/material";
import { Box } from '@mui/system';
import { formatTextBlob } from '../../processing/DataFormattingUtils';

function GlossaryEntry(props) {
    const id = props.id;
    const conceptName = props.concept.concept_name;
    const conceptType = props.concept.concept_type;
    const conceptText = props.concept.concept_text;
    const expanded = props.expanded;
    const handleChange = props.handleChange;

    return (
        <Accordion sx={{ my: "5px", minWidth: '100%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id={id}
            >
                <Grid container direction="row" columnSpacing={{ xs: 8 }} sx={{ minWidth: '100%' }} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={3}>
                        <Typography>
                            { conceptName === '@@The Endless@@' ? formatTextBlob(conceptName) :conceptName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption" color="secondary">
                            {conceptType}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Divider />
                <Typography>
                    <Grid container sx={{ mt: "10px", width: '100%' }}>
                        {renderConceptText(conceptText)}
                    </Grid>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );

    function splitHilightText(incommingText) {
        return incommingText.split("%%")
    };

    function splitNewlines(incommingText) {
        return incommingText.split("$$")
    };

    function renderHilights(textArray) {
        return (textArray.map((text, index) => {
            if (index % 2 === 0) {
                return (
                    <span>
                        {text}
                    </span>
                )
            }
            else {
                return (
                    <Typography variant="button" color="primary">
                        {text}
                    </Typography>
                )
            }
        }))
    }

    function renderConceptText(incommingText) {
        const paragraphBlocks = splitNewlines(incommingText);
        return (
            paragraphBlocks.map((paragraph) => {
                const hilightArray = splitHilightText(paragraph);
                return (
                    <Grid item direction="row" sx={{ mb: "5px" }}>
                        {renderHilights(hilightArray)}
                    </Grid>
                )
            })
        )
    }

};
export default GlossaryEntry;
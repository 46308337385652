import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab } from "@mui/material";
import HttpIcon from '@mui/icons-material/Http';
import SavingsIcon from '@mui/icons-material/Savings';
import { useState } from "react";
import AdminHTTPTab from "./AdminHTTPTab";
import AdminBankTab from "./AdminBankTab";

function AdminTab() {

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  return (
    <>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList value={tabValue} onChange={handleTabChange} variant='scrollable' scrollButtons allowScrollButtonsMobile>
            <Tab icon={<SavingsIcon />} iconPosition="top" label="Banking" value="1" />
            <Tab icon={<HttpIcon />} iconPosition="top" label="HTTP" value="10" />
            {/* TODO: Get item screen working */}
            {/* <Tab icon={<HomeRepairServiceIcon />} iconPosition="top" label="Equipment" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <AdminBankTab />
        </TabPanel>
        <TabPanel value="10">
          <AdminHTTPTab />
        </TabPanel>
      </TabContext>
    </>
  )
}

export default AdminTab;
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { Fragment, useContext } from "react";
import { activeCharacterContext } from '../../App';
import { TraitList } from '../../data/Traits';
import { createRenderableSummaryTrait } from './SummaryUtils';

function SummaryTraits() {
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext)

    return (
        <Box>
            <Grid container gutter={24} justify='center'>
                {TraitList.map(trait => {
                    if (activeCharacter != null && activeCharacter.pillar_builds != null && activeCharacter.pillar_builds.length > 0) {
                        return (
                            characterTrait(trait, activeCharacter.pillar_builds)
                        )
                    }
                })}
            </Grid>
        </Box>
    )
}

function characterTrait(trait, pillarBuilds) {
    const traitValue = createRenderableSummaryTrait(trait, pillarBuilds)
    if (traitValue > 0) {
        return (
            <Fragment>
                <Card sx={traitCard} key={trait.trait_name}>
                    <CardContent>
                        <Typography variant="caption" textAlign="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                            {trait.trait_name}
                        </Typography>
                        <Typography variant="h4" textAlign="center" component="div" color="primary" sx={{ fontWeight: '900' }}>
                            {traitValue}
                        </Typography>
                    </CardContent>
                </Card>
            </Fragment>
        )
    }
    else {
        return (null)
    }
}

const traitCard = {
    bgcolor: '#121212',
    color: '#fefefe',
    border: '2px solid #666666',
    margin: '10px 10px 10px 0px',
    fontWeight: 'bold'
}

export default SummaryTraits;
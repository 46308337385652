import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { charactersContext, offlineModeContext, pillarInventoryContext, relevantModalObjContext } from '../../App';
import { saveToSource } from '../../processing/control/Controller';
import { deleteCharacterOpenContext } from './CharacterSelect';
import { manageCharacterModalContext } from './CharacterSelectSummary';
import { deleteCharacter } from '../../processing/RestClient';

function DeleteCharacterModal() {

    const { deleteCharacterOpen, setDeleteCharacterOpen } = useContext(deleteCharacterOpenContext);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { offlineMode, setOfflineMode } = useContext(offlineModeContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);
    const {manageCharacterModal, setManageCharacterModal} = useContext(manageCharacterModalContext);

    const removeCharacter = () => {
        const character = relevantModalObj;
        const sanitizedCharacters = characters.filter((toon) => toon.character_name !== character.character_name);
        const sanitizedPillarInventory = pillarInventory.filter((pb) => pb.character_name !== character.character_name);
        saveToSource(offlineMode, 'pillarInventory', sanitizedPillarInventory);
        saveToSource(offlineMode, 'characters', sanitizedCharacters);
        setPillarInventory(sanitizedPillarInventory);
        setCharacters(sanitizedCharacters);
        deleteCharacter(relevantModalObj.id);
        setDeleteCharacterOpen(false);
        setManageCharacterModal(false);
        setRelevantModalObj("");
    }

    const onCancel = () => {

        setDeleteCharacterOpen(false);
        setRelevantModalObj("");
    }

    const characterText = () => {
        if (relevantModalObj && relevantModalObj.character_name !== null) {
            return relevantModalObj.character_name;
        }
        else {
            return null;
        }
    }

    return (
        <Modal
            open={deleteCharacterOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
        >
            <Box>
                <Paper sx={{ margin: '40px auto 0', padding: '40px', borderRadius: '10px', maxWidth: 'sm' }}>
                    <Grid container justifyContent="center" align="center" direction="column">
                        <Grid item>
                            <Typography variant="h6">
                                Are you sure you want to delete this character?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color='error' variant="h6">
                                {characterText()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Service Rewards Spent will <Typography variant="b1" color="error.main" sx={{ fontWeight: "bold", textDecoration: "underline" }}>NOT</Typography> be refunded
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Button startIcon={<CancelIcon />} variant="contained" color="error" onClick={() => onCancel()}>
                                Close
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button endIcon={<AddCircleOutlineIcon />} variant="contained" color="success" onClick={() => removeCharacter()}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}

export default DeleteCharacterModal;
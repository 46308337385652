import { Close } from "@mui/icons-material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SaveIcon from '@mui/icons-material/Save';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Box, Button, Divider, Fade, Grid, IconButton, Modal, Paper, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { charactersContext, relevantModalObjContext, userContext, userRewardNumberContext } from "../../../App";
import { manageCharacterModalContext } from "../CharacterSelectSummary";
import { deleteCharacterOpenContext } from "../CharacterSelect";
import { countServiceReward } from "../../../processing/DataFormattingUtils";
import { submitAudit, submitServiceAssignment } from "../../../processing/RestClient";

function ManageCharacterModal() {

  const { user, setUser } = useContext(userContext);
  const { characters, setCharacters } = useContext(charactersContext);
  const { manageCharacterModal, setManageCharacterModal } = useContext(manageCharacterModalContext);
  const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
  const { deleteCharacterOpen, setDeleteCharacterOpen } = useContext(deleteCharacterOpenContext)
  const { userRewardNumber, setUserRewardNumber } = useContext(userRewardNumberContext);

  const [serviceValue, setServiceValue] = useState(0);
  const [auditReport, setAuditReport] = useState("")

  let fontColor = "primary";

  const arrowUp = () => {
    let serviceCurrent = serviceValue + 1;
    setServiceValue(serviceCurrent)
  }

  const arrowDown = () => {
    let serviceCurrent = 0;
    if (serviceValue > 0) {
      serviceCurrent = serviceValue - 1;
    }
    setServiceValue(serviceCurrent)
  }

  const onClose = () => {
    setManageCharacterModal(false);
    setRelevantModalObj("");
  }

  const openManageCharacterModal = (character) => {
    setDeleteCharacterOpen(true);
  }

  const auditCharacter = () => {
    const id = relevantModalObj.id;
    submitAudit(id).then((response) => {
      if (response.status === 200) {
        fontColor = "success"
        setAuditReport("Character Audited: XP updated, please refresh the app");
      }
      else if (response.status === 304) {
        fontColor = "primary"
        setAuditReport(response.data);
      }
      else if (response.status === 302) {
        fontColor = "primary"
        setAuditReport(response.data);
      }
      else {
        fontColor = "error"
        setAuditReport("Error")
      }
    });
  }

  const saveServiceAssignment = () => {
    const submission = {
      character_id: relevantModalObj.id,
      number_spent: serviceValue
    }
    const apRemoved = serviceValue * 5;
    const moddedUser = { ...user };
    const moddedChracter = { ...relevantModalObj };
    const charIndex = characters.findIndex(char => char = relevantModalObj);
    const newCharArray = [...characters];
    submitServiceAssignment(submission)
    	.then(function (response) {
		    moddedUser.user.service_total = response.service_total;
    		moddedChracter.xp_total = response.xp_total;
    		moddedChracter.xp_unspent = response.xp_unspent;
    		newCharArray[charIndex] = moddedChracter;
    		setUserRewardNumber(userRewardNumber - serviceValue);
    		setUser(moddedUser);
    		setCharacters(newCharArray);
		})
		.catch(function (error) {
			//Error Handling for SubmitServiceAssignment
		});
    setServiceValue(0);
    setManageCharacterModal(false);
    setRelevantModalObj("");
  }

  const unspentServiceVal = countServiceReward(user);
  const apTotal = (relevantModalObj.user && relevantModalObj.user.service_total) ? relevantModalObj.user.service_total : 0;
  const statsFont = { fontSize: "14pt", fontWeight: "bold" }

  return (
    <Modal
      open={manageCharacterModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={manageCharacterModal} timeout={200}>
        <Box maxWidth={"xs"}>
          <Paper sx={{ m: '10px', borderRadius: '10px', p: '25px' }}>
            <Grid container justifyContent={"flex-end"}>
              <Grid item>
              </Grid>
            </Grid>
            <Grid container direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ width: "100%" }}>
              <Grid container justifyContent={"space-between"} xs={12} item>
                <Grid item>
                  <Stack direction="column">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      MANAGE:
                    </Typography>
                    <Typography variant="h5" color="primary">
                      {relevantModalObj.character_name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack direction="column">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      ID:
                    </Typography>
                    <Typography variant="h5" color="primary">
                      {relevantModalObj.id ? relevantModalObj.id : "N/A"}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <IconButton sx={{ fontWeight: "bold" }} onClick={() => onClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid xs={12} container item>
                <Grid xs={12} item>
                  <Box><Divider sx={{ mt: "0.25rem" }} color="white" fullwidth /></Box>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item xs={7} justifyContent={"center"} alignItems={"center"}>
                      <Stack direction="row" spacing={1}>
                        <Typography sx={statsFont} variant="b1">
                          Rewards:
                        </Typography>
                        <Typography sx={statsFont} variant="b1" color="success.main">
                          {unspentServiceVal} {serviceValue > 0 ? <Typography variant="b1" color="error"> - {serviceValue} </Typography> : null}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography sx={statsFont} variant="b1">
                          AP total:
                        </Typography>
                        <Typography sx={statsFont} variant="b1" color={serviceValue * 5 > apTotal ? "error" : "success"}>
                          {apTotal} {serviceValue > 0 ? <Typography variant="b1" color="error"> - {serviceValue * 5} </Typography> : null}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="b1" sx={statsFont}>
                          Unspent XP:
                        </Typography>
                        <Typography sx={statsFont} variant="b1" color={"success.main"}>
                          {relevantModalObj.xp_unspent} {serviceValue > 0 ? <Typography variant="b1" color="primary"> + {serviceValue} </Typography> : null}
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={statsFont} spacing={1}>
                        <Typography variant="b1">
                          Total XP:
                        </Typography>
                        <Typography sx={statsFont} variant="b1" color="success.main">
                          {relevantModalObj.xp_total} {serviceValue > 0 ? <Typography variant="b1" color="primary"> + {serviceValue} </Typography> : null}
                        </Typography>
                      </Stack>
                      <Button onClick={() => saveServiceAssignment()} sx={{ mt: "1rem", mb: "0.25rem" }} disabled={serviceValue <= 0 || serviceValue * 5 > apTotal} variant="contained" endIcon={<SaveIcon />}>
                        <Typography sx={{ fontSize: "10px" }} variant="button">
                          Assign Rewards
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Box></Box>
                    </Grid>
                    <Grid container item xs={4} justifyContent={"center"} alignItems={"center"}>
                      <Box sx={{ my: "-1px", px: "0.5rem", py: "0.25rem", border: '1px solid white', borderRight: "0px" }}>
                        <Grid item>
                          <Typography variant="caption" sx={{ mb: "-15px" }}>
                            Assign Rewards
                          </Typography>
                        </Grid>
                        <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"}>
                          <Grid item>
                            <Typography sx={{ fontWeight: "bold" }} variant="h4" color="primary">
                              {serviceValue}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Stack direction="column">
                              <IconButton disabled={serviceValue >= unspentServiceVal} onClick={() => arrowUp()} aria-label="serviceUp">
                                <ArrowDropUpIcon fontSize="large" />
                              </IconButton>
                              <IconButton disabled={serviceValue === 0} onClick={() => arrowDown()} aria-label="serviceDown" >
                                <ArrowDropDownIcon fontSize="large" />
                              </IconButton>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" sx={{ mt: "-15px" }}>
                            Cost: <Typography color={user.service_total > (serviceValue * 5) ? "success" : "error"} variant="b1"> {serviceValue * 5} </Typography> AP
                          </Typography>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box><Divider sx={{ mb: "0.25rem" }} color="white" fullwidth /></Box>
                </Grid>
              </Grid>
              <Grid spacing={2} xs={12} direction={"column"} container item justifyContent={"flex-start"}>
                <Grid item>
                  <Typography>
                    "After Auditing, reload your app to show any changes."
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => auditCharacter()} sx={{ mt: "1rem", mb: "0.25rem" }} variant="contained" endIcon={< QueryStatsIcon />}>
                    Audit Character
                  </Button>
                </Grid>
                <Grid item>
                  <Typography color={fontColor}>
                    {auditReport}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} item>
                I WILL PROVIDE BIOGRAPHICAL OPTIONS
              </Grid>
              <Grid xs={12} item>
                I WILL BE AN ICON PICKER
              </Grid>
              <Grid xs={12} item>
                I WILL ALLOW PLAYERS TO SEE THEIR ATTENDANCE
              </Grid>
            </Grid>
            <Divider color={"white"} />
            <Grid container justifyContent={"center"} alignContent={"center"}>
              <Button sx={{ m: '5px', mx: '20%' }} variant='contained' color='error' onClick={() => openManageCharacterModal(relevantModalObj)}>
                DELETE {relevantModalObj.character_name} <RemoveCircleIcon sx={{ ml: "0.5rem" }} />
              </Button>
            </Grid>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ManageCharacterModal;
import { Button } from "@mui/material";
import { useContext } from "react";
import { capstoneModalOpenContext } from "../../PillarBuild/PillarBuild";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box } from "@mui/system";
import { relevantModalObjContext } from "../../../App";

function CapstoneButton(props) {

    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { capstoneModalOpen, setCapstoneModalOpen } = useContext(capstoneModalOpenContext);

    const capstone = props.capstone;
    const valid = props.valid;
    const purchased = props.purchased ? "contained" : "outlined";
    const buildMode = props.buildMode;

    const onclick = (event) => {
        setRelevantModalObj(capstone);
        setCapstoneModalOpen(true);
    }

    return (
            <Button variant={purchased} color={"warning"} fullWidth sx={stretchBoxesCap} onClick={onclick}>
                {capstone.capstone_name}{valid && buildMode ? <AddCircleIcon/> : null}
            </Button>
    )
}

const stretchBoxesCap = {
    color: '#fefefe',
    marginTop: '15px',
    border: '1px solid #3dc1c9'
}

export default CapstoneButton;
import { aegisExpertiseAbility } from "../abilities/AegisAbilities";
import { bardExpertiseAbility } from "../abilities/BardAbilities";
import { berserkerExpertiseAbility } from "../abilities/BerserkerAbilities";
import { chemistExpertiseAbility } from "../abilities/ChemistAbilities";
import { evokerExpertiseAbility } from "../abilities/EvokerAbilities";
import { mageExpertiseAbility } from "../abilities/MageAbilities";
import { necromancerExpertiseAbility } from "../abilities/NecromancerAbilities";
import { pharmacistExpertiseAbility } from "../abilities/PharmacistAbilities";
import { poisonerExpertiseAbility } from "../abilities/PoisonerAbilities";
import { rogueExpertiseAbility } from "../abilities/RogueAbilities";
import { sageExpertiseAbility } from "../abilities/SageAbilities";
import { soldierExpertiseAbility } from "../abilities/SoldierAbilities";
import { tacticianExpertiseAbility } from "../abilities/TacticianAbilities";
import { priestExpertiseAbility } from "../abilities/PriestAbilities";
import { clericExpertiseAbility } from "../abilities/ClericAbilities";

export const toughness = {
    expertise_name: soldierExpertiseAbility.ability_name,
    expertise_text: soldierExpertiseAbility.ability_text,
    expertise_ability: soldierExpertiseAbility,
}

export const guidance = {
    expertise_name: sageExpertiseAbility.ability_name,
    expertise_text: sageExpertiseAbility.ability_text,
    expertise_ability: sageExpertiseAbility,
}

export const chemicalInjection = {
    expertise_name: chemistExpertiseAbility.ability_name,
    expertise_text: chemistExpertiseAbility.ability_text,
    expertise_ability: chemistExpertiseAbility,
}

export const arcaneFont = {
    expertise_name: mageExpertiseAbility.ability_name,
    expertise_text: mageExpertiseAbility.ability_text,
    expertise_ability: mageExpertiseAbility,
}

export const armorOfFaith = {
    expertise_name: clericExpertiseAbility.ability_name,
    expertise_text: clericExpertiseAbility.ability_text,
    expertise_ability: clericExpertiseAbility,
}

//Specialization Expertise

export const dirtyDeeds = {
    expertise_name: rogueExpertiseAbility.ability_name,
    expertise_text: rogueExpertiseAbility.ability_text,
    expertise_ability: rogueExpertiseAbility,
}

export const constitution = {
    expertise_name: berserkerExpertiseAbility.ability_name,
    expertise_text: berserkerExpertiseAbility.ability_text,
    expertise_ability: berserkerExpertiseAbility,
}

export const venomousStrike = {
    expertise_name: poisonerExpertiseAbility.ability_name,
    expertise_text: poisonerExpertiseAbility.ability_text,
    expertise_ability: poisonerExpertiseAbility,
}

export const healingTincture = {
    expertise_name: pharmacistExpertiseAbility.ability_name,
    expertise_text: pharmacistExpertiseAbility.ability_text,
    expertise_ability: pharmacistExpertiseAbility,
}

export const powerSurge = {
    expertise_name: evokerExpertiseAbility.ability_name,
    expertise_text: evokerExpertiseAbility.ability_text,
    expertise_ability: evokerExpertiseAbility,
}

export const arcaneArmiger = {
    expertise_name: aegisExpertiseAbility.ability_name,
    expertise_text: aegisExpertiseAbility.ability_text,
    expertise_ability: aegisExpertiseAbility,
}

export const vampiricPower = {
    expertise_name: necromancerExpertiseAbility.ability_name,
    expertise_text: necromancerExpertiseAbility.ability_text,
    expertise_ability: necromancerExpertiseAbility,
}

export const healingWords = {
    expertise_name: priestExpertiseAbility.ability_name,
    expertise_text: priestExpertiseAbility.ability_text,
    expertise_ability: priestExpertiseAbility,
}

export const mending = {
    expertise_name: bardExpertiseAbility.ability_name,
    expertise_text: bardExpertiseAbility.ability_text,
    expertise_ability: bardExpertiseAbility,
}

export const command = {
    expertise_name: tacticianExpertiseAbility.ability_name,
    expertise_text: tacticianExpertiseAbility.ability_text,
    expertise_ability: tacticianExpertiseAbility,
}

export const expertiseList = [toughness, guidance, chemicalInjection, arcaneFont, armorOfFaith, dirtyDeeds, constitution, venomousStrike, healingTincture, powerSurge, arcaneArmiger, vampiricPower, healingWords, mending, command]
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { charactersContext, menuOpenContext, pillarInventoryContext, userContext } from '../App';
import { mockUser1 } from '../mocks/MockUser';
import { googleUserLogout } from '../processing/RestClient';

function TopBar() {

    const linkMode = process.env.REACT_APP_LOCALHOST;

    const { topMenuOpen, setTopMenuOpen } = useContext(menuOpenContext);
    const { user, setUser } = useContext(userContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);


    const navigate = useNavigate();

    const handleMenuClick = (event) => {
        event.preventDefault();
        setTopMenuOpen(!topMenuOpen);
    }

    const handleLogoutClick = (event) => {
        event.preventDefault();
        console.log(">>>>>>>> FIRED")
        //Add logic to clear login data
        setUser(mockUser1);
        setCharacters([]);
        setPillarInventory([]);
        googleUserLogout();
        navigate('/login')
    }

    const linkAddressSet = () => {
        if (linkMode) {
            return ("http://localhost/logout")
        }
        else {
            return ("http://5pillarslarp.com/logout")
        }
    }

    const linkAddress = "http://5pillarslarp.com/login";

    return (
        <Box justify="center" position="fixed" elevation={3} sx={{ width: '100%', flexGrow: 1, mb: '40px', pt: '10px', zIndex: '2', borderBottom: '1px solid #2a2a2a' }}>
            <AppBar position="static" elevation={0}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={event => handleMenuClick(event)}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: "inline-block", paddingLeft: '10px', marginTop: '5px' }}><img src='https://mir-s3-cdn-cf.behance.net/project_modules/disp/fb3b78135411131.61e765358c109.png' width='20px' /></Box>
                    <Grid container justifyContent='space-between' align='center'>
                        <Grid item >
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <Box sx={{ display: "inline-block", marginLeft: '10px', mt: '5px' }}>{user != null && user.user != null && user.user.first_name != null ?
                                    "Hello " + user.user.first_name : "Hello User"}</Box>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <Button color="gray" onClick={(event) => handleLogoutClick} sx={{ mt: '5px' }}>Change User</Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default TopBar;

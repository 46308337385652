import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useContext, useState } from "react";
import { useNavigate } from 'react-router';
import { userContext } from "../../App";
import { mockUser1 } from "../../mocks/MockUser";
import { newUserModalContext } from "../LoginPage/LoginPage";

function NewUserModal() {

    const { user, setUser } = useContext(userContext);
    const { newUserModal, setNewUserModal } = useContext(newUserModalContext);
    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newFirstName, setNewFirstName] = useState("");
    const [newLastName, setNewLastName] = useState("");
    const [newDOB, setNewDOB] = useState(moment().format('Do MM YYYY'));
    const [newEmail, setNewEmail] = useState("");

    const navigate = useNavigate();

    let usernameError = false;
    let usernameErrorMessage = "";
    let firstnameError = false;
    let firstnameErrorMessage = "";
    let lastnameError = false;
    let lastnameErrorMessage = "";
    let DOBError = false;
    let DOBErrorMessage = "";
    let emailError = false;
    let emailErrorMessage = "";
    let passwordError = false;
    let passwordErrorMessage = "";
    let createErrorText = "";

    const handleDateChange = (newValue) => {
        setNewDOB(newValue);
    };

    const handleSubmit = () => {
        let creatable = true;
        const createDate = moment.format('Do MM YYYY');
        if (newUsername === "") {
            usernameError = true;
            usernameErrorMessage = "Username is required"
            creatable = false;
        }
        else if (newUsername.length < 8) {
            usernameError = true;
            usernameErrorMessage = "Username must be a minimum of 8 characters"
            creatable = false;
        }
        if (newPassword === "") {
            passwordError = true;
            passwordErrorMessage = "Password is required"
            creatable = false;
        }
        else if (newPassword.length < 8) {
            passwordError = true;
            passwordErrorMessage = "Password must have a minimum of 8 characters"
            creatable = false;
        }
        if (newFirstName === "") {
            firstnameError = true;
            firstnameErrorMessage = "First name is required"
            creatable = false;
        }
        if (newLastName === "") {
            lastnameError = true;
            lastnameErrorMessage = "Last name is required"
            creatable = false;
        }
        if (newDOB === "") {
            DOBError = true;
            DOBErrorMessage = "Date of Birth is required"
            creatable = false;
        }

        if (creatable) {
            const newUserObj = {
                username: newUsername,
                first_name: newFirstName,
                last_name: newLastName,
                email: newEmail,
                password: newPassword,
                dob: newDOB,
                create_date: createDate,
                account_active: true,
                service_total: 0,
                account_role: "user"
            }
            //submit and do an await validation before setting the user.
            setUser(newUserObj);
            navigate('/character')
        }
        else {
            createErrorText = "Creation Failed"
        }
    }

    const ageCheckboxText =  "I am 18 years of age as of " + moment().format("MM/DD/YYYY");

    return (
        <Modal
            open={newUserModal}
            aria-labelledby="new-character-modal"
            aria-describedby="modal-makes-new-characters"
            sx={{ overflow: "scroll" }}
        >
            <Box>

                <Paper sx={{margin: '40px auto 0', padding: '40px', borderRadius: '10px', maxWidth: 'sm' }}>
                    <Grid container justifyContent={"center"}>
                        <Typography variant="h6">
                            Create New User
                        </Typography>
                        <Grid container rowSpacing={1} columnSpacing={1} direction={"row"} justifyContent={"center"}>
                            <Grid item>
                                <Stack direction={"column"}>
                                    <TextField xs={6} error={usernameError}
                                        helperText={usernameErrorMessage} required id="newuser-username" label="Username" variant="outlined" color="primary" sx={{ bgcolor: '#202020', borderRadius: '4px', m: '10px' }} onChange={e => setNewUsername(e.target.value)} />
                                    <TextField xs={6} error={passwordError} helperText={passwordErrorMessage} required type="password" id="newuser-password" label="A secure password" variant="outlined" color="primary" sx={{ bgcolor: '#202020', borderRadius: '4px', m: '10px' }} onChange={e => setNewEmail(e.target.value)} />
                                    <TextField xs={6} error={emailError} helperText={emailErrorMessage} required id="newuser-email" label="Your Email" variant="outlined" color="primary" sx={{ bgcolor: '#202020', borderRadius: '4px', m: '10px' }} onChange={e => setNewEmail(e.target.value)} />
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Stack direction={"column"}>
                                    <TextField error={firstnameError} helperText={firstnameErrorMessage} required id="newuser-first-name" label="Your real first name" variant="outlined" color="primary" sx={{ bgcolor: '#202020', borderRadius: '4px', m: '10px' }} onChange={e => setNewFirstName(e.target.value)} />
                                    <TextField error={lastnameError} helperText={lastnameErrorMessage} required id="newuser-last-name" label="Your real last name" variant="outlined" color="primary" sx={{ bgcolor: '#202020', borderRadius: '4px', m: '10px' }} onChange={e => setNewLastName(e.target.value)} />
                                </Stack>
                            </Grid>
                            <Grid container item sx={{ width: '50%', borderRadius: '4px', m: '10px' }} direction="column" justifyContent={"center"} >
                                <FormGroup>
                                    <FormControlLabel required control={<Checkbox/>} label="I agree to the Code of Conduct" />
                                    <FormControlLabel required control={<Checkbox/>} label={ageCheckboxText} />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Typography id='creatre-error-box' color='error' variant='button'>
                            {createErrorText}
                        </Typography>
                        <Grid container justifyContent="space-evenly" alignContent={"center"} direction="row" sx={{ m: "10px", p: '10px', width: '100%' }}>
                            <Grid item>
                                <Button variant="contained" color="success" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="error" onClick={(event) => {
                                    setUser(mockUser1)
                                    setNewUserModal(false);
                                }}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}

export default NewUserModal;

import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { activeCharacterContext } from "../../App";
import { heritageResistanceShortener } from "../../processing/DataFormattingUtils";
import SummaryAbility from "./SummaryAbility";
import SummaryTraits from "./SummaryTraits";
import { determineSummaryArmorCap, determineSummaryDamage, determineSummaryHealth } from "./SummaryUtils";

function Summary() {

  const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);

  const heritageName= activeCharacter.heritage.heritage_name;

  return (
    <Paper sx={{ mt: "2rem", mb: "3rem" }}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column" sx={{ maxWidth: "sm", minwidth: "xs" }}>
        <Grid container direction="column" alignItems={"center"} justifyContent="flex-start" item sx={{ width: "100%", border: '2px', borderStyle: "solid", borderRadius: '5px', borderColor: "primary.main" }}>
          <Grid item>
            <Typography variant="h6">
              {activeCharacter.character_name.toUpperCase()}
            </Typography>
          </Grid>
          <Grid sx={{mb:"0.25rem"}} item>
            <Stack spacing= {2} direction="row" justifyContent={"space-around"}>
              <Typography variant="caption" color="primary.main">
                {heritageName.toUpperCase()}
              </Typography>
              <Typography variant="caption" color="primary.main">
                Resist: {heritageResistanceShortener(activeCharacter.heritage.heritage_name)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={{ my: "0.5rem" }} justifyContent={"space-between"} direction="row" item>
          <Grid xs={4} item>
            <Typography variant="h6">
              Health: <Typography color="primary.main" variant="b2">{determineSummaryHealth(activeCharacter.pillar_builds, activeCharacter.professions)}</Typography>
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography variant="h6">
              Armor Cap: <Typography color="primary.main" variant="b2">{determineSummaryArmorCap(activeCharacter.pillar_builds)}</Typography>
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography variant="h6">
              Base Damage: <Typography color="primary.main" variant="b2">{determineSummaryDamage(activeCharacter.pillar_builds)}</Typography>
            </Typography>
          </Grid>
          <SummaryTraits />
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          {(activeCharacter != null && activeCharacter.pillar_builds !=null && activeCharacter.pillar_builds.length > 0) ? activeCharacter.pillar_builds.map((pillarBuild) => {
            return (
              <Grid container sx={{ width: "100%" }} spacing={0.5}>
                <Box sx={{ width: "100%" }}><Divider fullwidth /></Box>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold" }} variant="caption">
                    {pillarBuild.pillar_name}
                  </Typography>
                </Grid>
                {pillarBuild.daily_abilities.map((pbAbility) => {
                  const foundEnc = pillarBuild.encounter_abilities.find((item => item.ability.ability_name === pbAbility.ability.ability_name))
                  if (pbAbility.ability.ability_uses >= 1) {
                    return (
                      <Grid item xs={12}>
                        <SummaryAbility pbAbility={pbAbility} encounterPbAbility={foundEnc} />
                      </Grid>
                    )
                  }
                  else {
                    return null
                  }
                })}
              </Grid>
            )
          }) : null}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Summary;
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { Box, Button, Grid } from "@mui/material";
import { Fragment, useContext } from "react";
import { relevantModalObjContext } from '../../../App';
import { abilityModalOpenContext, originalAbilityContext } from "../../PillarBuild/PillarBuild";
import { boxgrid } from '../../PillarBuild/PillarBuildUtils';

function AbilityButton(props) {

    const ability = props.ability;
    const pillarBuild = props.pillarBuild;
    const capstone = props.pillarBuild.capstone;
    const disabled = props.disabled;
    const buildMode = props.buildMode;

    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { originalAbility, setOriginalAbility } = useContext(originalAbilityContext);
    const { abilityModalOpen, setAbilityModalOpen } = useContext(abilityModalOpenContext);

    if (ability.usage === "encounter") {
        return (
            <Grid item xs={3} sx={boxgrid}>
                <Button variant="contained" fullWidth sx={boxStyle} onClick={
                    () => {
                        if (ability.pillar_name !== " ") {
                            setRelevantModalObj(ability)
                            setOriginalAbility(ability)
                            setAbilityModalOpen(true)
                        }
                        else {
                            setRelevantModalObj({ relevant_pillar: pillarBuild.pillar_name })
                            setAbilityModalOpen(true)
                        }
                    }}>{ability.ability_name}</Button>
            </Grid>
        );
    }
    else {
        return (
            <Grid item xs={3} sx={boxgrid}>
                <Button variant="outlined" fullWidth sx={boxStyle} disabled={disabled} onClick={
                    () => {
                        if (ability.pillar_name !== " ") {
                            setRelevantModalObj(ability)
                            setOriginalAbility(ability)
                            setAbilityModalOpen(true)
                        }
                        else {
                            setRelevantModalObj({ relevant_pillar: pillarBuild.pillar_name })
                            setAbilityModalOpen(true)
                        }
                    }}>
                    {abilityTextDisplay(ability, buildMode, capstone, disabled)}
                </Button>
            </Grid>
        )
    }


}

function abilityTextDisplay(ability, buildMode, capstone, disabled) {
    //Requires tier eval
    if (ability.ability_name === " " && buildMode) {
        return (
            <Fragment>
                {disabled ? null : <AddCircleIcon sx={addSkillIcon} />}
            </Fragment>
        );
    }
    else if (ability.ability_name !== " " && capstone !== null && ability.ability_cost < 4 && buildMode) {
        return (
            <Fragment>
                <Box sx={abilityNameStyle}>{ability.ability_name}</Box> {disabled ? null : <AddCircleIcon sx={addSkillIcon} />}
            </Fragment>
        )
    }
    else if (ability.ability_name !== " " && capstone === null && buildMode) {
        return (
            <Fragment>
                <Box sx={abilityNameStyle}>{ability.ability_name}</Box> {disabled ? null : <ChangeCircleOutlinedIcon sx={addSkillIcon} />}
            </Fragment>
        )
    }
    else {
        return (ability.ability_name);
    }
}


const addSkillIcon = {
    height: {
        xs: '40px',
        sm: '65px',
        md: '90px',
        lg: '90px',
        xl: '90px',
    },
    color: 'rgba(255,255,255,.15)',
    fontSize: '60px',
    zIndex: '1',
    textAlign: 'center'
}

const abilityNameStyle = {
    zIndex: '2',
    position: 'absolute'
}

export const boxStyle = {
    color: '#fefefe',
    minWidth: '50px',
    height: "100%",
    border: '1px solid #bb86fc',
    width: '100%',
    fontSize: {
        xs: '.4rem',
        sm: '.8rem',
        md: '1rem',
        lg: '1rem',
        xl: '.8rem',
    },
    margin: '0 0 0 0',
}



export default AbilityButton;
import { Button, Card, CardActions, CardContent, Fade, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { activeCharacterContext, charactersContext, offlineModeContext, pillarInventoryContext, userContext, userPermissionsContext } from "../../../App";
import { newCharacterModalContext } from "../../CharacterSelect/CharacterSelectSummary";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { generateEmptyAbility, generateEmptyExpertise } from "../../PillarBuild/PillarBuildUtils";
import NewCharacterHeritageInfo from "./NewCharacterHeritageInfo";
import CloseIcon from '@mui/icons-material/Close';
import { Controller, readFromSource, saveToSource } from "../../../processing/control/Controller";
import { findCharacterByName, createCharacter } from "../../../processing/RestClient";
import { heritageList } from "../../../data/Heritage";

function NewCharacterModal() {

    const { characters, setCharacters } = useContext(charactersContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
    const { newCharacterModal, setNewCharacterModal } = useContext(newCharacterModalContext);
    const { user, setUser } = useContext(userContext);
    const { offlineMode, setOfflineMode } = useContext(offlineModeContext);
    const {userPermissions, setUserPermissions} = useContext(userPermissionsContext);

    const [characterName, setCharacterName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const [heritage, setHeritage] = useState(heritageList.find(item => item.heritage_name === "Human"));
    const [xpTotal, setXpTotal] = useState(50);
    const [characterErrorText, setCharacterErrorText] = useState("");

    const freeBuild = process.env.REACT_APP_FREE_BUILD;
    let newCharacterArray = [...characters];
    let newPillarInventoryArray = [];

    const newCharacter = {
        id: (characters.length + 1) * -1,
        background: "",
        character_name: characterName,
        description: description,
        image: "",
        level: 0,
        life_total: 5,
        heritage: heritage,
        user: user,
        username: user.user.username,
        xp_total: xpTotal,
        xp_unspent: xpTotal,
        professions: [],
        pillar_builds: [],
        bank: {
            gold_total: 0,
            banked_resources: []
        }
    }
    const newPillarInventory = {};
    let nameTaken = false;
    let nameErrorMessage = "";
    let heritageError = false;

    const saveNewCharacter = () => {
        findCharacterByName(newCharacter).then(response => {
            if (response.status === 202) {
                if (newCharacter.character_name !== "") {
                    newCharacter.id = null;
                    console.log(newCharacter);
                    newCharacter.heritage = newCharacter.heritage.heritage_name;
                    newCharacter.user = null;
                    createCharacter(newCharacter).then(response2 => {
                        if(response2.character_name !== null ){
                            const newCharacter2 = response2.data;
                            if (newCharacter2.professions == null){ newCharacter2.professions = []} ;
                            newCharacterArray.push(newCharacter2);
                            newPillarInventoryArray = pillarInventory.filter((pi) => pi.character_name !== newCharacter2.character_name);
                            newPillarInventoryArray.push(newCharacter2);
                            setCharacters(newCharacterArray);
                            // saveToSource(offlineMode, 'characters', newCharacterArray);
                            // saveToSource(offlineMode, 'pillarInventory', newPillarInventoryArray)
                            setPillarInventory(newPillarInventoryArray);
                            setActiveCharacter(newCharacter2);
                            setNewCharacterModal(false);
                        }
                        else{
                        }
                    })
                }
            }
            else if (response.character_name !== null) {
                nameErrorMessage = "Character already exists";
                setError("You must pick a unique character name")
            }
            else {
                nameErrorMessage = "Unknown Error";
                setError("Unknown Error")
            }
        });
        newCharacterArray = characters.filter(() => true);
    }

    const closeModal = () => {
        setNewCharacterModal(false);
    }

    return (
        <Modal
            open={newCharacterModal}
            aria-labelledby="new-character-modal"
            aria-describedby="modal-makes-new-characters"
            sx={{ overflow: "scroll" }}

        >
            <Fade in={newCharacterModal} timeout={200}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Box maxWidth='s'>
                            <Card sx={{ m: '10px', my: '20px', p: '10px', maxWidth: 'sm' }}>
                                <CardContent>
                                    <Grid container direction="row" align="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="h6" sx={{ my: '10px' }}>
                                                Create New Character
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <CloseIcon onClick={closeModal} size="large" />
                                        </Grid>
                                    </Grid>
                                    <Typography color="error" variant="caption">
                                        {error}
                                    </Typography>
                                    <Grid container direction="row">
                                        <Grid container item direction="column" sx={{ mr: '10px' }}>
                                            <TextField error={nameTaken} helperText={nameErrorMessage} required id="newcharacter-character-name" label="Character name" variant="outlined" color="primary" sx={{ bgcolor: '#202020', borderRadius: '4px', width: '100%', mb: '10px' }} onChange={e => setCharacterName(e.target.value)} />
                                            <TextField multiline id="newcharacter-description" label="A short description" variant="outlined" color="primary" sx={{ bgcolor: '#202020', borderRadius: '4px', width: '100%', mb: '10px' }} onChange={e => setDescription(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl sx={{ minWidth: 100, mb: '10px', mr: '10px' }}>
                                                <InputLabel>Heritage</InputLabel>
                                                <Select
                                                    labelId="Heritage"
                                                    id="heritage-selector"
                                                    value={heritage}
                                                    label="Heritage"
                                                    required
                                                    error={heritageError}
                                                    onChange={(event) => setHeritage(event.target.value)}
                                                >
                                                    {
                                                        heritageList.map(heritage => {
                                                            if (heritage.heritage_unliving) {
                                                                return (
                                                                    <MenuItem sx={{ color: 'warning.main' }} key={heritage.heritage_name + '-dropdown-option'} value={heritage}>{heritage.heritage_name}</MenuItem>
                                                                )
                                                            }
                                                            return (
                                                                <MenuItem key={heritage.heritage_name + '-dropdown-option'} value={heritage}>{heritage.heritage_name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            {(userPermissions === "Super" || userPermissions === "Admin") ?
                                                <FormControl sx={{ minWidth: 150, mb: '10px' }}>
                                                    <InputLabel>Starting XP</InputLabel>
                                                    <Select
                                                        labelId="Starting XP"
                                                        id="test-mode-level-selector"
                                                        label="Starting XP"
                                                        onChange={(event) => setXpTotal(event.target.value)}
                                                    >
                                                        <MenuItem deault key="50-xp" value={50}>50</MenuItem>
                                                        <MenuItem key="100-xp" value={100}>100</MenuItem>
                                                        <MenuItem key="150-xp" value={150}>150</MenuItem>
                                                        <MenuItem key="200-xp" value={200}>200</MenuItem>
                                                        <MenuItem key="250-xp" value={250}>250</MenuItem>
                                                        <MenuItem key="500-xp" value={500}>500</MenuItem>
                                                        <MenuItem key="1000-xp" value={1000}>1000</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </Grid>
                                    <NewCharacterHeritageInfo key={"info-" + heritage.heritage_name} heritage={heritage} />
                                </CardContent >
                                <CardActions>
                                    <Grid container justifyContent="space-between" sx={{ mb: '10px' }}>
                                        <Grid item key={"new-character-create-button"}>
                                            <Button variant="contained" color="success" startIcon={<AddCircleOutlineIcon />} onClick={saveNewCharacter}>
                                                Create Character
                                            </Button>
                                        </Grid>
                                        <Grid item key={"new-character-cancel-button"}>
                                            <Button variant="contained" color="error" endIcon={<CancelIcon />} onClick={(event) => setNewCharacterModal(false)}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card >
                        </Box >
                    </Grid>
                </Grid>
            </Fade>
        </Modal >
    )
}

export default NewCharacterModal;
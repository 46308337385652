const cohortTrait = {
    trait_name: "Cohort Count",
    trait_ability: ["Cohort"],
    trait_encounter_multiplier: 1,
    trait_multiplier: 1
};

const loreTrait = {
    trait_name: "Favored Enemies",
    trait_ability: ["Identify Creature"],
    trait_encounter_multiplier: 1,
    trait_multiplier: 1
};

const backstabs = {
    trait_name: "Backstab Damage",
    trait_ability: ["Dirty Deeds"],
    trait_encounter_multiplier: 1,
    trait_multiplier: 1
}

const surge = {
    trait_name: "Surge Damage",
    trait_ability: ["Power Surge"],
    trait_encounter_multiplier: 1,
    trait_multiplier: 1
}

const layOnHandsTrait = {
    trait_name: "Healing Pool",
    trait_ability: ["Lay on Hands", 'Word of Mending'],
    magnified_ability: ["Greater Lay on Hands"],
    trait_encounter_multiplier: 10,
    trait_multiplier: 5
};

const magicMissileTrait = {
    trait_name: "Magic Missile Pool",
    trait_ability: ["Magic Missiles"],
    magnified_ability: ["Greater Magic Missiles"],
    trait_encounter_multiplier: 20,
    trait_multiplier: 10
};

const elementalBlastTrait = {
    trait_name: "Elemental Pool",
    trait_ability: ["Elemental Blast"],
    magnified_ability: ["Greater Elemental Blast"],
    trait_encounter_multiplier: 40,
    trait_multiplier: 20
};

const vampiricPowerTrait = {
    trait_name: "Vampiric Health",
    trait_ability: ["Vampiric Power"],
    trait_encounter_multiplier: 6,
    trait_multiplier: 3
};

const aegisArmor = {
    trait_name: "Aegis Armor",
    trait_ability: ["Aegis Champion"],
    trait_encounter_multiplier: 10,
    trait_multiplier: 10
}

const armorOfFaith = {
    trait_name: "Armor of Faith",
    trait_ability: ["Armor of Faith"],
    trait_encounter_multiplier: 10,
    trait_multiplier: 5
}

export const TraitList = [
    cohortTrait, loreTrait, backstabs, layOnHandsTrait, elementalBlastTrait, magicMissileTrait, 
    vampiricPowerTrait, surge, aegisArmor, armorOfFaith
]
import { useContext, useState } from "react";
import { modifiedCraftingContext, modifiedCraftingFlagContext, newCraftingModalContext } from "../CharacterScreen/CharacterScreen";
import { Box, Button, Divider, Fade, Grid, IconButton, Modal, Paper, Stack, Typography } from "@mui/material";
import { activeCharacterContext, relevantModalObjContext } from "../../App";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ProfessionArray } from "../../data/Professions";
import { updateProfessions } from "../../processing/RestClient";
import { professionIconMapper, professionMaterialIconMapper } from "../../data/Icons";

function NewCraftingModal() {

  const [value, setValue] = useState("");
  const [craftingSelection, setCraftingSelection] = useState("");

  const { newCraftingModal, setNewCraftingModal } = useContext(newCraftingModalContext);
  const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
  const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
  const { modifiedCrafting, setModifiedCrafting } = useContext(modifiedCraftingContext);
  const { modifiedCraftingFlag, setModifiedCraftingFlag } = useContext(modifiedCraftingFlagContext);

  const closeButton = () => {
    setValue('');
    setCraftingSelection("");
    setRelevantModalObj("")
    setNewCraftingModal(false)
  }

  const buyProf = () => {
    const newActiveCharacter = { ...activeCharacter }
    const newProfs = [...activeCharacter.professions];
    const newProfNumber = newProfs.length + 1;
    const newProfession = {
      character_name: activeCharacter.character_name,
      profession_level: 1,
      profession_number: newProfNumber,
      profession: {
        name: relevantModalObj.name
      },
      profession_name: relevantModalObj.name
    }
    newProfs.push(newProfession);
    newActiveCharacter.professions = newProfs;
    const newXp = newActiveCharacter.xp_unspent - xpCost();
    newActiveCharacter.level = activeCharacter.level + 1;
    newActiveCharacter.xp_unspent = newXp;
    setModifiedCrafting(newProfs);
    setActiveCharacter(newActiveCharacter);
    setRelevantModalObj("");
    setModifiedCraftingFlag(true);
    setNewCraftingModal(false)
  }

  const professionBought = (profession) => {
    let bought = false;
    activeCharacter.professions.forEach((prof) => {
      if (prof.profession_name != null && prof.profession_name === profession.name) {
        bought = true;
      }
    })
    return bought;
  };

  const xpCost = () => {
    let count = 5;
    switch (activeCharacter.professions.length + 1) {
      case 1:
        count = 5;
        break;
      case 2:
        count = 10;
        break;
      case 3:
        count = 15;
        break;
      case 4:
        count = 25;
        break;
      case 5:
        count = 40;
        break;
      default:
        count = 5;
    }
    return count;
  }
  const validBuy = activeCharacter.xp_unspent > xpCost();

  return (
    <Modal
      open={newCraftingModal}
      onClose={() => { setRelevantModalObj("") }}
      sx={{ overflow: "scroll" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={newCraftingModal} timeout={200}>
        <Grid container justifyContent='center' alignItems='center' sx={{ my: "0.5rem" }}>
          <Grid item>
            <Paper sx={{ m: '0.5rem', p: '1rem', maxWidth: 'sm' }}>
              <Grid container direction="column" justifyContent={"center"} alignItems={"center"}>
                <Grid container item justifyContent="flex-end" direction="row">
                  <Grid item>
                    <IconButton onClick={() => closeButton()}>
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container item sx={{ width: "100%" }} direction="column" justifyContent={"center"} alignItems={"center"}>
                  <Grid item>
                    <Typography>
                      Crafting Professions
                    </Typography>
                  </Grid>
                  <Divider sx={{ my: '5px', color: 'white' }} />
                  <Grid container justifyContent={"center"} spacing={1}>
                    {ProfessionArray.map((profession) => {
                      return (
                        <Grid item xs={6} sm={2}>
                          <Button
                            disabled={professionBought(profession)}
                            onClick={() => setRelevantModalObj(profession)}
                            variant="contained"
                            color={profession.name === relevantModalObj.name ? "success" : "primary"}
                            sx={{ p: "0.5rem", m: "0.3rem", width: "100%" }}>
                            {profession.name}
                          </Button>
                        </Grid>
                      )
                    })}
                  </Grid>
                  <Grid container justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                      {!relevantModalObj.description ? null :
                        <Grid sx={{ mt: "0.75rem" }} container item direction="column" alignItems="center" justifyContent={"center"} spacing={2}>
                          <Grid item >
                            <img object-fit="cover" height="150" src={relevantModalObj.name ? professionIconMapper(relevantModalObj.name.toLowerCase()) : ""} alt="profession icon" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h5" color="" sx={{fontWeight:"bold"}}>
                              {relevantModalObj.name ? relevantModalObj.name.toUpperCase() : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                    <Grid item sx={{ my: "0.75rem" }}>
                    <Divider sx={{mt:".25rem", mb:".5rem"}} color="white"/>
                      <Typography variant="subtitle1">
                        {relevantModalObj.description ? relevantModalObj.description : "Select a Profession"}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ my: "0.75rem" }}>
                      <Stack direction="column" justifyContent={"center"} alignItems={"center"}>
                        {(relevantModalObj !== "" && relevantModalObj.component !== null) ?
                          <img object-fit="cover" height="75" src={relevantModalObj.component ? professionMaterialIconMapper(relevantModalObj.component.toLowerCase()) : ""} alt="resource icon" />
                          : null}
                        <Stack direction="row" spacing={1}>
                          <Typography variant="subtitle1">
                            {relevantModalObj.component ? "Common Component: " : ""}
                          </Typography>
                          <Typography variant="subtitle1" color="primary">
                            {relevantModalObj.component ? relevantModalObj.component : ""}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid container direction="column">
                      <Divider sx={{ my: '0.5rem', color: 'white' }} />
                      <Grid item>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                          <Typography variant="caption">
                            {relevantModalObj.component ? "Profession Ability:" : ""}
                          </Typography>
                          <Typography variant="caption" color="primary">
                            {relevantModalObj.ability_name ? relevantModalObj.ability_name.toUpperCase() : ""}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ my: "0.5rem" }}>
                      <Typography variant={"body2"}>
                        {relevantModalObj.ability_description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => buyProf()} sx={{ mt: "1rem" }} variant="contained" disabled={!validBuy} color="success">
                      <Grid container direction="column">
                        <Grid item>
                          <Typography>
                            Purchase
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color={validBuy ? "" : "error"} variant="caption">
                            {xpCost()} XP
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fade >
    </Modal >

  );

}

export default NewCraftingModal;
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Stack, Typography } from "@mui/material";
import { formatHeritageTextBlob, formatTextBlob } from "../../../processing/DataFormattingUtils";
import { heritageIconMapper } from '../../../data/Icons';


function NewCharacterHeritageInfo(props) {
    const heritage = props.heritage;
    return (
        <Grid key={"new-character-wrapper"} container direction="column" alignContent={"center"} alignItems={"center"}>
            <Grid key={"heritage-image-header"} item>
                {!heritage.heritage_name ? null :
                    <img object-fit="cover" height="200" src={heritageIconMapper(heritage.heritage_name.toLowerCase())} alt="resource icon" />
                }
            </Grid>
            <Grid key={"heritage-name-header"} item>
                <Typography align="center" variant="h6"> {heritage.heritage_name} </Typography>
            </Grid>
            <Accordion key={"heritage-lore-accordion"}>
                <AccordionSummary
                    sx={{ backgroundColor: 'primary.dark' }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography align="center" variant="h6"> Lore </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph variant="body2">
                        {formatHeritageTextBlob(heritage.heritage_lore)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion key={"heritage-culture-accordion"}>
                <AccordionSummary
                    sx={{ backgroundColor: 'primary.dark' }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography align="center" variant="h6"> Culture </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph variant="body2">
                        {formatTextBlob(heritage.heritage_culture)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion key={"heritage-appearance-accordion"}>
                <AccordionSummary
                    sx={{ backgroundColor: 'primary.dark' }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography align="center" variant="h6"> Appearance </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph variant="body2">
                        {formatTextBlob(heritage.heritage_appearance)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion key={"heritage-mechanics-accordion"}>
                <AccordionSummary
                    sx={{ backgroundColor: 'primary.dark' }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography align="center" variant="h6"> Mechanics & Costuming </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column">
                        {heritage.heritage_unliving === '' ? null :
                            <Typography color="error" variant="body1">
                                {formatTextBlob(heritage.heritage_unliving)}
                            </Typography>
                        }
                        <Typography variant="body1">
                            {formatTextBlob(heritage.heritage_resistance)}
                        </Typography>
                        <Divider sx={{ mt: '10px' }} />
                        <Typography variant="body2">
                            {formatTextBlob(heritage.heritage_costuming)}
                        </Typography>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ mb: '10px' }} />
        </Grid>
    )
}

export default NewCharacterHeritageInfo;
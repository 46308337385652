import { Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { findConcept, formatTextBlob } from "../../../processing/DataFormattingUtils";
import HelpIcon from '@mui/icons-material/Help';

function AbilityModalHeader(props) {
    const ability = props.ability;
    const cost = props.adjustedCost;

    return (
        <Box>
            <Grid container justifyContent="center" spacing={1}>
                <Grid item>
                    <Typography>
                        Cost:
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography color="primary" id="modal-modal-title" variant="body1" component="h2">
                        {cost} XP
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {ability.ability_name !== " " ? ability.ability_name : null}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {usesRender(ability)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify='center'>
                {ability.ability_tags.map((tag, index) => {
                    const tagConcept = findConcept(tag);
                    return (
                        <div>
                            <Box container>
                                <Tooltip title={tagConcept ? formatTextBlob(tagConcept.concept_text) : ""}>
                                    <Typography color="primary" id="modal-modal-title" variant="subtitle1" component="h2">
                                        {tag} {ability.ability_tags.length - 1 === index ? null : bullet}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </div>
                    )
                })}
                {ability.ability_tags.length > 0 ?
                    <Tooltip title='Mouse over the key words to the left for effect descriptions'>
                        <HelpIcon sx={{ ml: '10px' }} color='primary' />
                    </Tooltip>
                    : null}
            </Grid>
            <Typography sx={{ fontStyle: 'italic' }} color="warning.main" variant="body1" id="spell-verbals">
                {ability.ability_verbal ? ability.ability_verbal : null}
            </Typography>
        </Box>
    );
}

function usesRender(ability) {
    if (ability.ability_name === " ") {
        return (
            null
        )
    }
    else if (ability.ability_uses === 0 || !ability.ability_uses) {
        return (
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Passive
            </Typography>
        )
    }
    else {
        return (
            <Typography id="modal-modal-title" variant="h6" component="h2">
                x {ability.ability_uses} per {ability.usage === "encounter" ? "Encounter" : "Day"}
            </Typography>
        )
    }
}

const bullet = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default AbilityModalHeader;
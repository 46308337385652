import { Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { findConcept, formatTextBlob } from "../../../processing/DataFormattingUtils";
import HelpIcon from '@mui/icons-material/Help';

function ExpertiseModalHeader(props) {
    const expertise = props.expertise;
    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {expertise.ability_name != " " ? expertise.ability_name : null}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {usesRender(expertise)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify='center'>
                {expertise.ability_tags.map((tag, index) => {
                    const tagConcept = findConcept(tag);
                    return (
                        <div>
                            <Box container>
                                <Tooltip title={tagConcept ? formatTextBlob(tagConcept.concept_text) : ""}>
                                    <Typography color="primary" id="modal-modal-title" variant="subtitle1" component="h2">
                                        {tag} {expertise.ability_tags.length - 1 === index ? null : bullet}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </div>
                    )
                })}
                {expertise.ability_tags.length > 0 ?
                    <Tooltip title='Mouse over the key words to the left for effect descriptions'>
                        <HelpIcon sx={{ ml: '10px' }} color='primary' />
                    </Tooltip>
                    : null}
            </Grid>
            <Typography color="white" variant="h6" id="spell-verbals">
                {expertise.ability_verbal ? expertise.ability_verbal : null}
            </Typography>
        </Box>
    );
}

function usesRender(expertise) {
    if (expertise.ability_name === " ") {
        return (
            null
        )
    }
    else if (expertise.ability_uses === 0 || !expertise.ability_uses) {
        return (
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Passive
            </Typography>
        )
    }
    else {
        return (
            <Typography id="modal-modal-title" variant="h6" component="h2">
                x {expertise.ability_uses} per {expertise.usage === "encounter" ? "Encounter" : "Day"}
            </Typography>
        )
    }
}

const bullet = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default ExpertiseModalHeader;
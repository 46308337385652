import { aegisCapstoneAbility, aegisExpertiseAbility, arcaneInversion, barrier, eldritchArmor, forcePush, halt, invulnerability, jaunt, mute, redirect, wallOfForce } from "./abilities/AegisAbilities";
import { bardCapstoneAbility, bardExpertiseAbility, dirge, disruptingForce, enthrall, interjection, misdirection, retort, succor, unrelentingForce, warringWords, wordsOfWonder } from "./abilities/BardAbilities";
import { battery, berserkerCapstoneAbility, berserkerExpertiseAbility, bloodbath, bloodrush, cleave, demolisher, mightyBlows, mortalStrike, powerStrike, prof2, seethe } from "./abilities/BerserkerAbilities";
import { charmPoison, chemistCapstoneAbility, chemistExpertiseAbility, curare, cureAll, defibrilate, fortifyBlood, fortifyEssence, fortifyMuscles, fortifySkin, glueBomb, greaterHealHarmPotion, inoculation, panacea, paralyticPoison, potionOfHealingHarming, rectify, remedy, slowPoison, tarPatch, wastingPoison, witheringPoison } from "./abilities/ChemistAbilities";
import { clericCapstoneAbility, clericExpertiseAbility, condemn, decrepify, expelCorruption, fear, freedomOfMovement, healHarmGreaterWounds, healHarmMinorWounds, healHarmWounds, holdPerson, holyLight, layOnHands1, layOnHands3, layOnHands5, lifeDeath, martyrdom, purity, repel, revivify, slow, unbind } from "./abilities/ClericAbilities";
import { desolation, elementalBlast1, elementalBlast2, elementalBlast3, elementalBlast4, elementalBlast5, evokerCapstoneAbility, evokerExpertiseAbility, imbueWeapon, rendMetal, searingTorrent, thunderstrike } from "./abilities/EvokerAbilities";
import { blink, coma, counterspell, destroyMagic, doom, elementalAdaptation, gag, mageArmor, mageCapstoneAbility, mageExpertiseAbility, magicMirror, magicMissiles1, magicMissiles2, magicMissiles3, magicMissiles4, magicMissiles5, shield, silence, spellShield, telekinesis, trance, web } from "./abilities/MageAbilities";
import { animateDead, boilBlood, consume, corruption, createUndead, defile, drain, necromancerCapstoneAbility, necromancerExpertiseAbility, seizure, siphon, terror } from "./abilities/NecromancerAbilities";
import { balanceHumors, excoriator, fortifyBones, fortifySoul, greaterRestorePotion, invigorateHumors, pharmacistCapstoneAbility, pharmacistExpertiseAbility, preventativeMeasures, restorativePotion, stabilizedHealDraught, stabilizedHealTincture } from "./abilities/PharmacistAbilities";
import { babblePoison, blightedPoison, daturaNarc, drainPoison, hemlock, hemorrPoison, numbPoison, poisonerCapstoneAbility, poisonerExpertiseAbility, psuedocomae, terrifyPoison, torporPoison } from "./abilities/PoisonerAbilities";
import { assassinate, bellRinger, blackjack, bladeFlurry, dodge, kidneyShot, lowBlow, rogueCapstoneAbility, rogueExpertiseAbility, shadowstep, shank, throatChop, turnabout } from "./abilities/RogueAbilities";
import { assault, battlePlan, cohort, diagnose, enhanceArmor, enhanceBody, enhanceResistance, enhanceWeapon, firstAid, identifyCreature, loreDestruction, loreEndurance, lorePreparation, lorePrevention, medicalMiracle, rally, repairArmor, sageCapstoneAbility, sageExpertiseAbility, snakeOil, triage, warcry } from "./abilities/SageAbilities";
import { barrage, bash, block, bruteStrength, disarm, fortitude, hamstring, hew, indom, mutilate, parry, prof1, prof3, prof5, refit, riposte, slay, soldierCapstoneAbility, soldierExpertiseAbility, spellCarve, sunderArmor, terrifyingWrath } from "./abilities/SoldierAbilities";
import { bellow, defensiveTraining, demolitionTraining, gird, howl, onslaughtTraining, optimizeArmor, precisionTraining, reversalTraining, rouse, tacticianCapstoneAbility, tacticianExpertiseAbility } from "./abilities/TacticianAbilities";
import { balm, consecrate, layOnHands2, layOnHands4, pacifism, priestCapstoneAbility, priestExpertiseAbility, purge, reconstruction, rest, soothe, vim } from "./abilities/PriestAbilities";

function specPillarRename(abilityArray, pillarName) {
    const newArray = JSON.parse(JSON.stringify(abilityArray));

    newArray.forEach((item) => item.pillar_name = pillarName);

    return newArray;
}

export const chemistAbilities = {
    name: "Chemist",
    tier1: [fortifySkin, potionOfHealingHarming, remedy, slowPoison],
    tier2: [fortifyBlood, glueBomb, rectify, witheringPoison],
    tier3: [defibrilate, greaterHealHarmPotion, inoculation, paralyticPoison],
    tier4: [charmPoison, cureAll, fortifyMuscles, wastingPoison],
    tier5: [curare, fortifyEssence, panacea, tarPatch],
    expertise: [chemistExpertiseAbility],
    capstone: [chemistCapstoneAbility],
}

export const clericAbilities = {
    name: "Cleric",
    tier1: [fear, freedomOfMovement, healHarmMinorWounds, layOnHands1],
    tier2: [holyLight, repel, slow, unbind],
    tier3: [expelCorruption, healHarmWounds, holdPerson, layOnHands3],
    tier4: [condemn, decrepify, healHarmGreaterWounds, purity],
    tier5: [lifeDeath, layOnHands5, martyrdom, revivify],
    expertise: [clericExpertiseAbility],
    capstone: [clericCapstoneAbility],
}

export const mageAbilities = {
    name: "Mage",
    tier1: [gag, magicMissiles1, shield, telekinesis],
    tier2: [elementalAdaptation, mageArmor, magicMissiles2, trance],
    tier3: [counterspell, destroyMagic, magicMissiles3, silence],
    tier4: [blink, magicMissiles4, spellShield, web],
    tier5: [coma, doom, magicMirror, magicMissiles5],
    expertise: [mageExpertiseAbility],
    capstone: [mageCapstoneAbility],
}

export const sageAbilities = {
    name: "Sage",
    tier1: [identifyCreature, cohort, diagnose, enhanceWeapon],
    tier2: [enhanceArmor, firstAid, lorePrevention, warcry],
    tier3: [enhanceBody, loreDestruction, triage, rally],
    tier4: [assault, loreEndurance, repairArmor, snakeOil],
    tier5: [battlePlan, enhanceResistance, lorePreparation, medicalMiracle],
    expertise: [sageExpertiseAbility],
    capstone: [sageCapstoneAbility],
}

export const soldierAbilities = {
    name: "Soldier",
    tier1: [prof1, hew, disarm, block],
    tier2: [bash, hamstring, indom, parry],
    tier3: [prof3, slay, refit, bruteStrength],
    tier4: [sunderArmor, fortitude, mutilate, terrifyingWrath],
    tier5: [prof5, barrage, spellCarve, riposte],
    expertise: [soldierExpertiseAbility],
    capstone: [soldierCapstoneAbility],
}

//Specialization Abilities

export const rogueAbilities = {
    name: "Rogue",
    tier1: specPillarRename([lowBlow, throatChop, { ...prof1 }, { ...disarm }], "Rogue"),
    tier2: specPillarRename([kidneyShot, shank, { ...parry }, { ...hamstring }], "Rogue"),
    tier3: specPillarRename([assassinate, bellRinger, { ...prof3 }, { ...slay }], "Rogue"),
    tier4: specPillarRename([blackjack, bladeFlurry, turnabout, { ...mutilate }, { ...terrifyingWrath }], "Rogue"),
    tier5: specPillarRename([dodge, shadowstep, { ...prof5 }, { ...riposte }], "Rogue"),
    expertise: [rogueExpertiseAbility],
    capstone: [rogueCapstoneAbility],
}

export const berserkerAbilities = {
    name: "Berserker",
    tier1: specPillarRename([cleave, powerStrike, { ...prof1 }, { ...disarm }], "Berserker"),
    tier2: specPillarRename([battery, prof2, { ...indom }, { ...hamstring }], "Berserker"),
    tier3: specPillarRename([bloodrush, mightyBlows, { ...prof3 }, { ...slay }], "Berserker"),
    tier4: specPillarRename([demolisher, seethe, { ...mutilate }, { ...fortitude }], "Berserker"),
    tier5: specPillarRename([bloodbath, mortalStrike, { ...prof5 }, { ...barrage }], "Berserker"),
    expertise: [berserkerExpertiseAbility],
    capstone: [berserkerCapstoneAbility],
}

export const poisonerAbilities = {
    name: "Poisoner",
    tier1: specPillarRename([terrifyPoison, numbPoison, { ...remedy }, { ...slowPoison }], "Poisoner"),
    tier2: specPillarRename([torporPoison, babblePoison, { ...glueBomb }, { ...witheringPoison }], "Poisoner"),
    tier3: specPillarRename([hemorrPoison, hemlock, { ...inoculation }, { ...paralyticPoison }], "Poisoner"),
    tier4: specPillarRename([blightedPoison, drainPoison, { ...charmPoison }, { ...wastingPoison }], "Poisoner"),
    tier5: specPillarRename([daturaNarc, psuedocomae, { ...curare }, { ...tarPatch }], "Poisoner"),
    expertise: [poisonerExpertiseAbility],
    capstone: [poisonerCapstoneAbility],
}

export const pharmacistAbilities = {
    name: "Pharmacist",
    tier1: specPillarRename([restorativePotion, stabilizedHealDraught, { ...fortifySkin }, { ...remedy }], "Pharmacist"),
    tier2: specPillarRename([balanceHumors, excoriator, { ...fortifyBlood }, { ...rectify }], "Pharmacist"),
    tier3: specPillarRename([greaterRestorePotion, stabilizedHealTincture, { ...defibrilate }, { ...inoculation }], "Pharmacist"),
    tier4: specPillarRename([invigorateHumors, fortifySoul, { ...cureAll }, { ...fortifyMuscles }], "Pharmacist"),
    tier5: specPillarRename([preventativeMeasures, fortifyBones, { ...fortifyEssence }, { ...panacea }], "Pharmacist"),
    expertise: [pharmacistExpertiseAbility],
    capstone: [pharmacistCapstoneAbility],
}

export const evokerAbilities = {
    name: "Evoker",
    tier1: specPillarRename([elementalBlast1, imbueWeapon, { ...shield }, { ...telekinesis }], "Evoker"),
    tier2: specPillarRename([elementalBlast2, thunderstrike, { ...mageArmor }, { ...trance }], "Evoker"),
    tier3: specPillarRename([elementalBlast3, searingTorrent, { ...counterspell }, { ...destroyMagic }], "Evoker"),
    tier4: specPillarRename([elementalBlast4, rendMetal, { ...blink }, { ...spellShield }], "Evoker"),
    tier5: specPillarRename([elementalBlast5, desolation, { ...coma }, { ...magicMirror }], "Evoker"),
    expertise: [evokerExpertiseAbility],
    capstone: [evokerCapstoneAbility],
}

export const aegisAbilities = {
    name: "Aegis",
    tier1: specPillarRename([redirect, halt, { ...gag }, { ...telekinesis }], "Aegis"),
    tier2: specPillarRename([forcePush, jaunt, { ...mageArmor }, { ...trance }], "Aegis"),
    tier3: specPillarRename([arcaneInversion, barrier, { ...counterspell }, { ...destroyMagic }], "Aegis"),
    tier4: specPillarRename([eldritchArmor, mute, { ...spellShield }, { ...web }], "Aegis"),
    tier5: specPillarRename([invulnerability, wallOfForce, { ...coma }, { ...magicMirror }], "Aegis"),
    expertise: [aegisExpertiseAbility],
    capstone: [aegisCapstoneAbility],
}

export const necromancerAbilities = {
    name: "Necromancer",
    tier1: specPillarRename([siphon, seizure, { ...fear }, { ...freedomOfMovement }], "Necromancer"),
    tier2: specPillarRename([animateDead, boilBlood, { ...slow }, { ...unbind }], "Necromancer"),
    tier3: specPillarRename([drain, terror, { ...expelCorruption }, { ...holdPerson }], "Necromancer"),
    tier4: specPillarRename([consume, createUndead, { ...condemn }, { ...decrepify }], "Necromancer"),
    tier5: specPillarRename([defile, corruption, { ...lifeDeath }, { ...revivify }], "Necromancer"),
    expertise: [necromancerExpertiseAbility],
    capstone: [necromancerCapstoneAbility],
}

export const priestAbilities = {
    name: "Priest",
    tier1: specPillarRename([rest, soothe, { ...freedomOfMovement }, { ...layOnHands1 }], "Priest"),
    tier2: specPillarRename([layOnHands2, vim, { ...repel }, { ...unbind }], "Priest"),
    tier3: specPillarRename([balm, pacifism, { ...expelCorruption }, { ...layOnHands3 }], "Priest"),
    tier4: specPillarRename([reconstruction, layOnHands4, { ...condemn }, { ...decrepify }], "Priest"),
    tier5: specPillarRename([consecrate, purge, { ...lifeDeath }, { ...layOnHands5 }], "Priest"),
    expertise: [priestExpertiseAbility],
    capstone: [priestCapstoneAbility],
}

export const bardAbilities = {
    name: "Bard",
    tier1: specPillarRename([disruptingForce, misdirection, { ...identifyCreature }, { ...diagnose }], "Bard"),
    tier2: specPillarRename([retort, succor, { ...firstAid }, { ...lorePrevention }], "Bard"),
    tier3: specPillarRename([interjection, wordsOfWonder, { ...loreDestruction }, { ...triage }], "Bard"),
    tier4: specPillarRename([dirge, unrelentingForce, { ...loreEndurance }, { ...snakeOil }], "Bard"),
    tier5: specPillarRename([enthrall, warringWords, { ...lorePreparation }, { ...medicalMiracle }], "Bard"),
    expertise: [bardExpertiseAbility],
    capstone: [bardCapstoneAbility],
}

export const tacticianAbilities = {
    name: "Tactician",
    tier1: specPillarRename([rouse, onslaughtTraining, { ...cohort }, { ...enhanceWeapon }], "Tactician"),
    tier2: specPillarRename([bellow, defensiveTraining, { ...enhanceArmor }, { ...warcry }], "Tactician"),
    tier3: specPillarRename([gird, precisionTraining, { ...rally }, { ...enhanceBody }], "Tactician"),
    tier4: specPillarRename([demolitionTraining, optimizeArmor, { ...assault }, { ...repairArmor }], "Tactician"),
    tier5: specPillarRename([howl, reversalTraining, { ...battlePlan }, { ...enhanceResistance }], "Tactician"),
    expertise: [tacticianExpertiseAbility],
    capstone: [tacticianCapstoneAbility],
}

export const invalidAbility = 
{ability_name: "Invalid",
ability_text: "This Ability is invalid or has been removed. Pick a new one.",
pillar_name: "Soldier",
prerequesite: null,
ability_tags:[],
ability_cost: 1,
ability_uses: 1,
ability_tier_limit: null,
}

export const abilitiesByPillarArray = [  
    soldierAbilities, berserkerAbilities, rogueAbilities, 
    mageAbilities, evokerAbilities, aegisAbilities, 
    clericAbilities, priestAbilities, necromancerAbilities, 
    chemistAbilities, poisonerAbilities, pharmacistAbilities, 
    sageAbilities, bardAbilities, tacticianAbilities
];
//Tier 1
export const throatChop = {
    ability_name: "Throat Chop",
    ability_text: "A quick blow to the esophagus stops casters in their tracks. You may call %%Skill Silence 5%% on a successful strike with a weapon.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "strike", "silence"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}


export const lowBlow = {
    ability_name: "Low Blow",
    ability_text: "Joints are funny things, and a swift kick to one sends your opponent sprawling. You may call %%Skill Push 3%% on a successful strike with a weapon.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "strike", "push"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 2

export const kidneyShot = {
    ability_name: "Kidney Shot",
    ability_text: "A quick jab to the kidney leaves your opponent reeling. You may call %%Skill Slow 5%% on a successful strike with a weapon.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "strike", "slow"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const shank = {
    ability_name: "Shank",
    ability_text: "A flashing blade in the dark is all it takes to end a life. So long as you are not using a two-handed or versatile weapon in two hands, you may call %%Skill {Damage + Dirty Deeds} Pierce%% on a successful strike with a weapon. This attack deals double damage if executed from fully behind your target.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "strike", "pierce"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 3

export const assassinate = {
    ability_name: "Assassinate",
    ability_text: "A knife across the throat, a life spilled on the ground. You may call %%Skill 100 Pierce%% on a successful strike of a weapon, so long as you are fully behind your victim.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "strike", "pierce"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const bellRinger = {
    ability_name: "Bell Ringer",
    ability_text: "The Temples are a universal weak point for all humanoids, making it simple to disable them. You may call %%Skill Paralyze 3%% on a successful strike with a weapon.",
    pillar_name: "Rogue",
    prerequesite: "Low Blow",
    ability_tags: ["skill", "strike", "paralyze"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 4

export const blackjack = {
    ability_name: "Blackjack",
    ability_text: "The back brainstem is uniquely vulnerable, and the proper force can induce instant unconsciousness. You may call %%Skill Sleep 10%% on a successful strike with a weapon.",
    pillar_name: "Rogue",
    prerequesite: "Kidney Shot",
    ability_tags: ["skill", "strike", "sleep"],
    ability_cost: 4,
    ability_uses: 2,
    ability_tier_limit: null,
}

export const turnabout = {
    ability_name: "Turnabout",
    ability_text: "Joint manipulation makes it simple to turn an enemy’s careless strike back on them. You may call %%Skill Return%% on one standard Attack delivered to you.",
    pillar_name: "Rogue",
    prerequesite: "Parry",
    ability_tags: ["skill", "return"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const bladeFlurry = {
    ability_name: "Blade Flurry",
    ability_text: "You have learned that almost all attacks follow similar patterns, and how to turn those patterns against those who use them. You may choose to be %%Immobilized%% for up to ten Seconds. While Immobilized, you may call %%Skill Counter%% on all damaging strikes or packets targeting you without an origin prefix, such as “Ten Damage”, “Twenty Fire”, or “Five Pierce.”",
    pillar_name: "Rogue",
    prerequesite: "Parry",
    ability_tags: ["skill", "self", "stance", "return"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 5

export const dodge = {
    ability_name: "Dodge",
    ability_text: "Why absorb a hit when you can simply not get hit? You may call %%Resist%% to any single Attack, Packet or Strike.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "self", "resist"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: 2,
}

export const shadowstep = {
    ability_name: "Shadowstep",
    ability_text: "Whether through magic or cunning, you are capable of disappearing into the shadows. You become Insubstantial for three Seconds.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "self", "insubstantial"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tierless

export const rogueCapstoneAbility = {
    ability_name: "Rogue Champion",
    ability_text: "Once per Day, you may call %%Skill 150 Pierce%% on a successful Strike with a weapon.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["skill", "strike", "pierce"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const rogueExpertiseAbility = {
    ability_name: "Dirty Deeds",
    ability_text: "You know precisely when and where to stab for the greatest effect, your enemy’s back is a canvas awaiting your bloody brush. Your Armor cap increases by 2. Additionally, you gain a bonus of one to the damage you deal with one-handed weapons when striking while fully behind your target. This damage is cumulative with all further purchases of this Expertise.",
    pillar_name: "Rogue",
    prerequesite: null,
    ability_tags: ["passive"],
    ability_cost: null,
    ability_uses: null,
    ability_tier_limit: 1,
}
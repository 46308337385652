import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { Fragment, useContext } from "react";
import { activeCharacterContext, pillarInventoryContext } from '../../App';
import { TraitList } from '../../data/Traits';
import { relevantPillarInventoryContext } from './CharacterScreen';
import { rogueCapstone } from '../../data/Capstone';

function CharacterTraits() {
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext)
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext)
    const { relevantPillarInventory, setRelevantPillarInventory } = useContext(relevantPillarInventoryContext);

    return (
        <Box>
            <Grid container gutter={24} justify='center'>
                <Card elevation={0} sx={traitCard}>
                    <CardContent >
                        <Typography variant="caption" textAlign="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Base Health
                        </Typography>
                        <Typography variant="h6" textAlign="center" component="div" color="primary" sx={{ fontWeight: '900' }}>
                            {determineHealth(relevantPillarInventory, activeCharacter.professions)}
                        </Typography>
                    </CardContent>
                </Card>
                <Card elevation={0} sx={traitCard}>
                    <CardContent>
                        <Typography variant="caption" textAlign="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Armor Cap
                        </Typography>
                        <Typography variant="h6" textAlign="center" component="div" color="primary" sx={{ fontWeight: '900' }}>
                            {determineArmorCap(relevantPillarInventory)}
                        </Typography>
                    </CardContent>
                </Card>
                <Card elevation={0} sx={traitCard}>
                    <CardContent>
                        <Typography variant="caption" textAlign="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Base Damage
                        </Typography>
                        <Typography variant="h6" textAlign="center" component="div" color="primary" sx={{ fontWeight: '900' }}>
                            {determineDamage(relevantPillarInventory)}
                        </Typography>
                    </CardContent>
                </Card>

            </Grid>
            <Grid container gutter={24} justify='center'>
                {TraitList.map(trait => {
                    return (
                        characterTrait(trait, relevantPillarInventory)
                    )
                })}
            </Grid>
        </Box>
    )
}

function characterTrait(trait, pillarBuilds) {
    const traitValue = createRenderableTrait(trait, pillarBuilds)
    if (traitValue > 0) {
        return (
            <Fragment>
                <Card sx={traitCard} key={trait.trait_name}>
                    <CardContent>
                        <Typography variant="caption" textAlign="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                            {trait.trait_name}
                        </Typography>
                        <Typography variant="h4" textAlign="center" component="div" color="primary" sx={{ fontWeight: '900' }}>
                            {traitValue}
                        </Typography>
                    </CardContent>
                </Card>
            </Fragment>
        )
    }
    else {
        return (null)
    }
}

function createRenderableTrait(trait, pillarBuilds) {
    let dailyCounter = 0;
    let encounterCounter = 0;
    let capstoneCounter = 0;
    pillarBuilds.forEach((pillarBuild) => {
        dailyCounter = dailyCounter +
            tallyCounter(pillarBuild.daily_abilities, trait) +
            tallyCounter(pillarBuild.daily_expertise, trait);
    })

    pillarBuilds.forEach((pillarBuild) => {
        encounterCounter = encounterCounter +
            tallyCounter(pillarBuild.encounter_abilities, trait) +
            tallyCounter(pillarBuild.encounter_expertise, trait);
    })

    pillarBuilds.forEach((pillarBuild) => {
        if (pillarBuild.capstone !== null) {
            capstoneCounter = capstoneCounter +
                tallyCounter([pillarBuild.capstone.capstone_ability], trait);
        }
    })

    return ((dailyCounter * trait.trait_multiplier) + (encounterCounter * trait.trait_encounter_multiplier) + capstoneCounter * trait.trait_multiplier);
}

function tallyCounter(inputAbilities, trait) {
    let countArray = [];
    inputAbilities.forEach((ability) => {
        trait.trait_ability.forEach((traitAbility) => {
            if (ability.ability_name === traitAbility || ability.expertise_name === traitAbility) { countArray.push(ability) }
        })
        if (trait.magnified_ability) {
            trait.magnified_ability.forEach((magnifierName) => {
                if (ability.ability_name === magnifierName) {
                    const countUp = 3;
                    for (let i = 0; i < countUp; i++) {
                        countArray.push(ability)
                    }
                }
            })
        }
    })
    return countArray.length;
}

function determineHealth(pillarBuilds, professions) {
    let totalHp = 0;
    if (professions && professions.length > 0) {
        professions.forEach((profession) => { totalHp = totalHp + profession.profession_level })
    }
    pillarBuilds.forEach((pillarBuild) => {
        totalHp = totalHp + pillarBuild.pillar_level
        if (pillarBuild.daily_expertise != null && pillarBuild.daily_expertise.length > 0) {
            pillarBuild.daily_expertise.forEach((ability) => {
                if (ability.expertise_name === "Toughness") { totalHp = totalHp + 2 }
                if (ability.expertise_name === "Constitution") { totalHp = totalHp + 4 }
            })
        }
        if (pillarBuild.encounter_expertise != null && pillarBuild.encounter_expertise.length > 0) {
            pillarBuild.encounter_expertise.forEach((ability) => {
                if (ability.expertise_name === "Toughness") { totalHp = totalHp + 2 }
                if (ability.expertise_name === "Constitution") { totalHp = totalHp + 4 }
            })
        }
        pillarBuild.daily_abilities.forEach((ability) => {
            if (ability.ability_name === "Fortitude") { totalHp = totalHp + 5 }
        })
        if (pillarBuild.capstone !== null && pillarBuild.capstone.capstone_name === "Pharmacist Champion") {
            totalHp = totalHp + 10
        }
    })
    if (totalHp > -1) {
        return totalHp;
    }
    else {
        return 0;
    }
}

function determineArmorCap(pillarBuilds) {
    let armorCap = 10;
    pillarBuilds.forEach((pillarBuild) => {
        if (pillarBuild.daily_expertise != null && pillarBuild.daily_expertise.length > 0) {
            pillarBuild.daily_expertise.forEach((ability) => {
                if (ability.expertise_name === "Toughness") { armorCap = armorCap + 5 }
                if (ability.expertise_name === "Dirty Deeds") { armorCap = armorCap + 2 }
            })
        }
        if (pillarBuild.encounter_expertise != null && pillarBuild.encounter_expertise.length > 0) {
            pillarBuild.encounter_expertise.forEach((ability) => {
                if (ability.expertise_name === "Toughness") { armorCap = armorCap + 5 }
                if (ability.expertise_name === "Dirty Deeds") { armorCap = armorCap + 2 }
            })
        }
        if (pillarBuild.capstone !== null && pillarBuild.capstone.capstone_name === "Soldier Champion") { armorCap = armorCap + 10 }
    })
    return armorCap;
}

function determineDamage(pillarBuilds) {
    let damage = 1;
    pillarBuilds.forEach((pillarBuild) => {
        pillarBuild.daily_abilities.forEach((ability) => {
            if (ability.ability_name === "Weapons Proficiency") { damage = damage + 1 }
        })
        pillarBuild.encounter_abilities.forEach((ability) => {
            if (ability.ability_name === "Weapons Proficiency") { damage = damage + 1 }
        })
    })
    return damage;
}

const traitCard = {
    bgcolor: '#121212',
    color: '#fefefe',
    border: '2px solid #666666',
    margin: '10px 10px 10px 0px',
    fontWeight: 'bold'
}

export default CharacterTraits;
import { CircularProgress, Grid, ThemeProvider, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { charactersContext, pillarInventoryContext, userContext, userRewardNumberContext } from '../../App';
import { convertSubmitableToPB, countServiceReward } from '../../processing/DataFormattingUtils';
import { googleUserCreate, googleUserLogin } from "../../processing/RestClient";
import { theme } from "../../styles/AppTheme";
import NewUserModal from '../NewUserModal/NewUserModal';

export const newUserModalContext = createContext(false);

function LoginPage() {

    const mode = process.env.REACT_APP_TEST_MODE;
    const linkMode = process.env.REACT_APP_LOCALHOST;
    const navigate = useNavigate();

    const [loginStatus, setLoginStatus] = useState("");
    const [viewablePassword, setViewablePassword] = useState("password");
    const [newUserModal, setNewUserModal] = useState(false);
    const [buttonValue, setButtonValue] = useState("");

    const { user, setUser } = useContext(userContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);
    const { userRewardNumber, setUserRewardNumber } = useContext(userRewardNumberContext);

    useEffect(() => {

        userLoginLogic();
    }
        , [])
    const userLoginLogic = () => {
        googleUserLogin().then((response) => {
            if (response.status === 200) {
                const inboundPillarInventories = []
                setUser(response.data);
                const newCharacterArray = []
                response.data.user_characters.forEach(character => {
                    const pbs = character.pillar_builds;
                    pbs.forEach((pb) => {
                        pb = convertSubmitableToPB(pb);
                        inboundPillarInventories.push(pb);
                    })
                    character.pillar_builds = pbs;
                    character.professions.forEach((profession) => {
                        profession.profession_name = profession.profession.name;
                    })
                    character.professions.sort((a, b) => a.profession_number - b.profession_number)
                    newCharacterArray.push(character);
                    character.bank = response.data.user_character_banks.filter(charBank => charBank.character_name === character.character_name)[0]
                    character.bank.character = null;
                });
                inboundPillarInventories.sort((a, b) => a.pillar_number - b.pillar_number)
                let rewardValue = countServiceReward(response.data);
                setUserRewardNumber(rewardValue);
                setCharacters(newCharacterArray);
                setPillarInventory(inboundPillarInventories);
                navigate("/character");
            }
            else if (response.status === 304 || response.status === 302) {
                setButtonValue("login")
            }
            else if (response.status === 404) {
                setButtonValue("create")
            }
        }).catch((error) => {
            console.log(error)
            switch (error.message) {
                case "Request failed with status code 404":
                    setButtonValue("create")
                    break;
                case "Request failed with status code 401":
                    setButtonValue("login")
                    break;
                default:
                    setButtonValue("login")
                    break;
            }
        })
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        //Do auth here in if/else
        userLoginLogic();
    }

    const handleNewCharacterSubmit = (event) => {
        event.preventDefault();
        googleUserCreate(event).then((response) => {
            navigate("/character")
        }
        ).catch((error) => {
            switch (error.message) {
                case "Request failed with status code 404":
                    setLoginStatus("Invalid Login");
                    break;
                case "Request failed with status code 401":
                    setLoginStatus("Incorrect Password");
                    break;
                default:
                    setLoginStatus("Unknown Error");
                    break;
            }
        })
    }

    const handleKeySubmit = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    const linkAddressSet = () => {
        if (linkMode === "localhost") {
            return ("http://localhost/login")
        }
        else {
            return ("https://5pillarslarp.com/login")
        }
    }

    let linkAddress = "https://5pillarslarp.com/login";
    console.log(linkAddress);
    linkAddress = linkAddressSet();
    console.log(linkAddress);

    return (
        <div>
            <ThemeProvider theme={theme} >
                <Container component="main" maxWidth="s">
                    <newUserModalContext.Provider value={{ newUserModal, setNewUserModal }}>
                        <NewUserModal />
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            sx={{ maxWidth: '320px', margin: '0 auto', padding: '30px 0', '& > :not(style)': { m: 1, width: '25ch' } }}
                        >
                            <Grid container>
                                <Grid item sx={{ margin: '0 auto', padding: '10px 0' }}>
                                    <Box sx={{ margin: '0 auto', width: '200px' }}>
                                        <img src='https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/9bcd19135411131.61e765359649d.png' width='100%' />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{ margin: '0 auto', width: '80px' }}>
                            </Box>
                            {buttonValue !== "" ? null :
                                <Grid item>
                                    <CircularProgress sx={{ height: "10rem" }} />
                                </Grid>
                            }
                            <Box container justifyContent="center" alignItems="center">
                                <Typography color="error">
                                    {loginStatus}
                                </Typography>
                            </Box>
                            <></>
                            {buttonValue === "login" ?
                                <a href={linkAddress}>
                                    <Button id="login-submit" variant="contained">
                                        Login</Button>
                                </a>
                                : null}
                            {buttonValue === "create" ?
                                <Button sx={{ m: '10px' }}
                                    id="new-user-button"
                                    variant="outlined"
                                    onClick={(event) => handleNewCharacterSubmit(event)}>
                                    Create New User
                                </Button>
                                : null}
                        </Box>
                    </newUserModalContext.Provider>
                </Container>
            </ThemeProvider>
        </div >
    );
}

export default LoginPage;

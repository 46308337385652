import { Box, Button, Divider, Fade, Grid, IconButton, Modal, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment, useContext, useState } from "react";
import { activeCharacterContext, charactersContext, pillarInventoryContext, relevantModalObjContext } from '../../../App';
import { abilityModalOpenContext, originalAbilityContext } from '../../PillarBuild/PillarBuild';
import { AbilityTextMatcher, pillarNumberXpCost } from '../../PillarBuild/PillarBuildUtils';
import AbilityModalButton from './AbilityModalButton';
import AbilityModalHeader from './AbilityModalHeader';
import { abilityPillarMatcher, buyTextGenerator, calculatePillarBuildLevel, calculateTotalLevel, canRemoveAbilityGivenTiers, disabledDeviser, generateAbilityCost } from './AbilityModalUtils';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { formatTextBlob } from "../../../processing/DataFormattingUtils";
import Close from '@mui/icons-material/Close';
import { buildModeContext, modifiedAbilsContext, relevantPillarInventoryContext } from '../../CharacterScreen/CharacterScreen';

function AbilityModal() {

    const { abilityModalOpen, setAbilityModalOpen } = useContext(abilityModalOpenContext);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { originalAbility, setOriginalAbility } = useContext(originalAbilityContext);
    const { buildMode, setBuildMode } = useContext(buildModeContext);
    const { relevantPillarInventory, setRelevantPillarInventory } = useContext(relevantPillarInventoryContext);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);
    const { modifiedAbils, setModifiedAbils } = useContext(modifiedAbilsContext);

    const [ removeAbilityErrorTooltipOpen, setRemoveAbilityErrorTooltipOpen ] = useState(false);
    const [ removeAbilityErrorTooltipTitle, setRemoveAbilityErrorTooltipTitle] = useState('');

    const foundAbility = AbilityTextMatcher(relevantModalObj);

    if (relevantModalObj && relevantModalObj.usage && relevantModalObj.pillar_number !== undefined) {
        console.log(relevantModalObj);
        const relevantPillarBuild = relevantPillarInventory[relevantModalObj.pillar_number];
        const relevantArray = abilityPillarMatcher(relevantModalObj.ability_cost, relevantModalObj.pillar_number, relevantPillarBuild.pillar_name);
        const adjustedCost = generateAbilityCost(relevantModalObj.pillar_number, relevantModalObj.ability_cost, relevantPillarBuild.capstone ? true : false)
        const purchaseButtonText = buyTextGenerator(relevantModalObj, originalAbility, relevantPillarBuild.capstone, adjustedCost, activeCharacter.xp_unspent, buildMode);

        const refundAbility = (relevantAbility) => {

            const modifiedActiveCharacter = { ...activeCharacter };
            const modifiedCharactersArray = [...characters];
            const characterIndex = modifiedCharactersArray.findIndex((character) => character.character_name === activeCharacter.character_name);
            const modifiedPillarInventory = [...relevantPillarInventory];
            let modifiedPillarBuild = {};
            if (validateRemoveAbility(relevantPillarBuild.daily_abilities, relevantAbility, relevantPillarBuild.daily_expertise)) {
                return;
            }
            
            if (originalAbility.usage === "daily" || originalAbility.usage === "encounter") {

                const refundAbilityCost = pillarNumberXpCost(relevantPillarBuild.pillar_number, originalAbility.ability_cost);
                modifiedPillarBuild = modifiedPillarInventory[relevantAbility.pillar_number];
                const relevantPBAbilityArray = originalAbility.usage === "encounter" ? modifiedPillarBuild.encounter_abilities : modifiedPillarBuild.daily_abilities;
                //const spliceIndex = relevantPBAbilityArray.findIndex((item) => item === originalAbility);

                relevantPBAbilityArray.splice(originalAbility.pillar_build_index, 1);
                modifiedPillarBuild.level = calculatePillarBuildLevel(modifiedPillarBuild);
                modifiedActiveCharacter.xp_unspent = activeCharacter.xp_unspent + refundAbilityCost;
                modifiedActiveCharacter.level = calculateTotalLevel(modifiedPillarInventory);
                modifiedCharactersArray[characterIndex] = modifiedActiveCharacter;
                setRelevantPillarInventory(modifiedPillarInventory);
                setActiveCharacter(modifiedActiveCharacter);
                setCharacters(modifiedCharactersArray);
                setModifiedAbils(true);
                closeAbilityModal();
            }
            else {
                closeAbilityModal();
            }
        }
        
        /**
         * Given a list of daily abilities in the pillar, which ability is being removed, and how many Expertise abilities are chosen in the pillar,
         * determine if attempting to remove the ability is valid.
         * 
         * @param dailyAbilities Array of all Daily Abilities in the current pillar.
         * @param abilityToRemove Ability that is being removed.
         * @param dailyExpertiseLength How many Daily Expertises are in the current pillar.
         * 
         * @returns True if there was a problem removing the ability. False if there was no problem.
         */
        const validateRemoveAbility = (dailyAbilities, abilityToRemove, expertiseAbilities) => {
            if (!canRemoveAbilityGivenTiers(dailyAbilities, abilityToRemove)) {
                //Can't remove this ability; Would result in a higher tier ability illegally purchased.
                //Display tooltip
                setRemoveAbilityErrorTooltipTitle('Cannot remove ability: Would result in higher tier ability without sufficient abilities below it');
                setRemoveAbilityErrorTooltipOpen(true);

                return true;
            }
            if (expertiseAbilities !== null && abilityToRemove.ability_cost <= expertiseAbilities.length) {
                //Can't remove this ability; would result in an Expertise on non-full tier.
                setRemoveAbilityErrorTooltipTitle('Must remove the Expertise from Tier '+ abilityToRemove.ability_cost + ' before you can remove this Ability');
                setRemoveAbilityErrorTooltipOpen(true);

                return true;
            }
            
            return false;
        }

        const closeAbilityModal = () => {
            setRemoveAbilityErrorTooltipOpen(false);
            setRelevantModalObj("");
            setAbilityModalOpen(false);
        }

        const changeAbility = (relevantAbility) => {

            const modifiedActiveCharacter = { ...activeCharacter };
            const modifiedCharactersArray = [...characters];
            const characterIndex = modifiedCharactersArray.findIndex((character) => character.character_name === activeCharacter.character_name);
            const modifiedPillarInventory = [...relevantPillarInventory];
            const adjustedAbilityCost = pillarNumberXpCost(relevantPillarBuild.pillar_number, relevantAbility.ability_cost);
            let modifiedPillarBuild = {};

            const setNewAbility = (usage, abilityCost) => {
                modifiedPillarBuild = modifiedPillarInventory[relevantAbility.pillar_number];
                const modifiedPBArray = usage === "encounter" ? modifiedPillarBuild.encounter_abilities : modifiedPillarBuild.daily_abilities;
                modifiedPBArray.push(relevantAbility);
                modifiedPillarBuild.pillar_level = calculatePillarBuildLevel(modifiedPillarBuild);
                modifiedActiveCharacter.xp_unspent = activeCharacter.xp_unspent - abilityCost;
                modifiedActiveCharacter.level = calculateTotalLevel(modifiedPillarInventory);
                modifiedCharactersArray[characterIndex] = modifiedActiveCharacter;
                modifiedActiveCharacter.pillar_builds = modifiedPillarInventory;
                setRelevantPillarInventory(modifiedPillarInventory);
                setActiveCharacter(modifiedActiveCharacter);
                setCharacters(modifiedCharactersArray);
                setModifiedAbils(true);
                closeAbilityModal();

            }

            let unclicked = true; 

            if (buildMode & unclicked) {
                if (relevantAbility.ability_cost && originalAbility.usage === "none") {
                    if (relevantModalObj.ability_name === " ") {
                        closeAbilityModal();
                    }
                    else {
                        setNewAbility("daily", adjustedAbilityCost);
                    }
                }
                else if (adjustedAbilityCost && originalAbility.usage === "daily") {
                    modifiedPillarBuild = modifiedPillarInventory[relevantAbility.pillar_number];

                    //If capstone exists, push to encounter abilities
                    if (modifiedPillarBuild.capstone) {
                        unclicked= false;
                        setNewAbility("encounter", adjustedAbilityCost * 2);
                        unclicked  =true;
                    }
                    //If the ability is empty, add to daily abilities
                    else if (originalAbility.ability_name === " ") {
                        unclicked= false;
                        setNewAbility("daily", adjustedAbilityCost);
                        unclicked  =true;
                    }
                    //In all other cases, add to daily abilities at the index.
                    else {
                        unclicked= false;
                        const upgrade = { ...relevantAbility };
                        upgrade.usage = "encounter";
                        modifiedPillarBuild.daily_abilities[originalAbility.pillar_build_index] = upgrade;
                        modifiedCharactersArray[characterIndex] = modifiedActiveCharacter;
                        setRelevantPillarInventory(modifiedPillarInventory);
                        setActiveCharacter(modifiedActiveCharacter);
                        setCharacters(modifiedCharactersArray);
                        setModifiedAbils(true);
                        closeAbilityModal();
                        unclicked  =true;
                    }
                }
            }
            else {
                closeAbilityModal();
            }
        }


        const renderUpgrade = () => {
            if (relevantPillarBuild.capstone) {
                if (activeCharacter.xp_unspent > adjustedCost) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        }
        return (
            <Modal
                open={abilityModalOpen}
                onClose={() => closeAbilityModal()}
                sx={{ overflow: "scroll", width: '100%' }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
            >
                <Fade in={abilityModalOpen} timeout={200}>
                    <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%' }}>
                        <Grid item sx={{ maxWidth: 'sm' }}>
                            <Paper sx={{ m: '10px', borderRadius: '10px', p: '25px' }}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <IconButton onClick={() => closeAbilityModal()}>
                                            <Close />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <AbilityModalHeader ability={relevantModalObj} adjustedCost={adjustedCost} />
                                {relevantModalObj.ability_name === " " ? null : <Divider />}
                                <Grid container maxWidth="m" id="modal-modal-description" sx={{ my: "1rem" }}>
                                    {relevantModalObj.ability_text ? formatTextBlob(relevantModalObj.ability_text): formatTextBlob(foundAbility.ability_text)}
                                </Grid>
                                {relevantModalObj.usage === "encounter" || originalAbility.ability_name && relevantPillarBuild.capstone ? null :
                                    <Box>
                                        <Divider />
                                        <Typography id="modal-modal-title" variant="h6">
                                            {renderUpgrade() ? "Upgrade" : "Select an Ability"}
                                        </Typography>
                                    </Box>
                                }
                                <Stack spacing={1} direction="column" justify="center">
                                    {relevantArray.map(ability => {
                                        console.log(ability);
                                        if (!relevantPillarBuild.capstone) {
                                            const disabled = disabledDeviser(ability, relevantPillarBuild);
                                            return (
                                                <Box>
                                                    <AbilityModalButton key={ability.ability_name} disabled={disabled} ability={ability} originalAbility={originalAbility} currentAbility={relevantModalObj} onClick={() => { setRelevantModalObj(ability) }} />
                                                </Box>
                                            )
                                        }
                                        else {
                                            return (
                                                null
                                            )
                                        }
                                    })}
                                </Stack>
                                {relevantModalObj.usage === "encounter" ? null :
                                    <Box>
                                        <Divider sx={{ my: '10px' }} />
                                        <Grid container justifyContent="space-between" direction="row" >
                                            <Grid item >
                                                <Button startIcon={purchaseButtonText === "Close" ? <CloseIcon /> : <AddCircleOutlineIcon />} variant="contained" color={purchaseButtonText === "Close" ? "error" : "success"} onClick={() => { purchaseButtonText === "close" ? closeAbilityModal() : changeAbility(relevantModalObj) }}>
                                                    {purchaseButtonText}
                                                </Button>
                                            </Grid>
                                            <Grid item >
                                                {!buildMode || originalAbility.ability_name === " " || originalAbility.ability_name && relevantPillarBuild.capstone ?
                                                    null
                                                    :
                                                    <Tooltip open={removeAbilityErrorTooltipOpen} title={removeAbilityErrorTooltipTitle} placement='bottom'arrow >
                                                        <Button id="removeButton" startIcon={<CloseIcon />} variant="contained" color={"error"} onClick={() => { refundAbility(originalAbility) }}>
                                                            Remove
                                                        </Button>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal >
        );
    }
}

export default AbilityModal;
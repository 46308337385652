//Tier 1

export const disruptingForce = {
    ability_name: "Disrupting Force",
    ability_text: "Using a lesser power-word, you send your foe reeling back with sudden force. You may throw a %%Shout Push 1%% packet or deliver the same effect with a successful strike.",
    pillar_name: "Bard",
    prerequesite: null,
    ability_tags: ["Shout", "Packet", "Strike", "Charm"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const misdirection = {
    ability_name: "Misdirection",
    ability_text: "Using your secret words, you quickly enthrall a target with whispered promises. You may throw a %%Shout Charm 10%% packet.",
    pillar_name: "Bard",
    prerequesite: null,
    ability_tags: ["Shout", "Packet", "Charm"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 2

export const retort = {
    ability_name: "Retort",
    ability_text: "Using a minor power-word, you introduce doubt and mental static into your foe. You may throw a %%Shout Slow 5%% packet or deliver the same effect with a successful strike.",
    pillar_name: "Bard",
    prerequesite: null,
    ability_tags: ["Shout", "Packet", "Strike", "Slow"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const succor = {
    ability_name: "Succor",
    ability_text: "You can use unmaking phrases and restorative words to free your allies. You may Touch a creature, and upon completion of a thirty Second “Succoring” Count, you may call %%Shout Counter%% to one Condition of your choice.",
    pillar_name: "Bard",
    prerequesite: null,
    ability_tags: ["Skill", "Touch", "30 Count"],
    ability_cost: 2,
    ability_uses: 0,
    ability_tier_limit: null,
}

//Tier 3

export const interjection = {
    ability_name: "Interjection",
    ability_text: "Power Words are fickle things, and carefully timed intrusions can negate the effects of magic. You may call %%Shout Counter%% on one Shout delivered to you or an ally within Reach.",
    pillar_name: "Bard",
    prerequesite: "Misdirection",
    ability_tags: ["Shout", "Reach", "Counter"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const wordsOfWonder = {
    ability_name: "Words of Wonder",
    ability_text: "Using a verse constructed of healing Power Words, you can heal your allies or harm your foes. You must incant the Verbal and select a damage type from Healing or Harm. You may then throw five %%Shout 5 {type}%% packets of the type selected. You must throw all packets within five seconds of the first thrown.",
    pillar_name: "Bard",
    prerequesite: null,
    ability_tags: ["Shout", "Packet", "Healing/Harm"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 4

export const dirge = {
    ability_name: "Dirge",
    ability_text: "Using a deeply unsettling Power Word, your foe’s limbs cease to respond to their will. You may throw a %%Shout Wither 10%% packet or deliver the same effect with a successful strike.",
    pillar_name: "Bard",
    prerequesite: "Retort",
    ability_tags: ["Shout", "Packet", "Strike", "Wither"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const unrelentingForce = {
    ability_name: "Unrelenting Force",
    ability_text: "Using a stern Power Word, you blast your foe with sonic force. You may throw a %%Shout 30 Pierce%% packet or deliver the same effect with a successful strike",
    pillar_name: "Bard",
    prerequesite: "Disrupting Force",
    ability_tags: ["Shout", "Packet", "Strike", "Pierce"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 5

export const enthrall = {
    ability_name: "Enthrall",
    ability_text: "Using a greater Power Word, you cause a foe to forget their allegiance and instead side with you. You may throw a %%Shout Charm 300%% packet.",
    pillar_name: "Bard",
    prerequesite: "Misdirection",
    ability_tags: ["Shout", "Packet", "Charm"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const warringWords = {
    ability_name: "Warring Words",
    ability_text: "You incant a verse of Power Words about your allies, snapping to action against hostile intent. Upon completion of a five Second “Warring Words” count while Touching your Target, the Target gains one %%Shout Resist%% against any Shout Ability that hits them. Only one Lore may be active on a Target at a time.",
    pillar_name: "Bard",
    prerequesite: "Interjection",
    ability_tags: ["Spell", "5 Count", "Touch", "Lore"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tierless

export const bardExpertiseAbility = {
    ability_name: "Mending",
    ability_text: "Careful incantations and song incite the knitting of bones and soothing of hurts. You must nominate another creature, and on completion of a thirty Second “Mending” Count, you may call %%Shout {Purchase} Heal%%, or %%Shout {Purchase} Harm%%, where purchase is the number of times you’ve purchased this Ability. This ability has no effect if the target cannot hear you shout.",
    pillar_name: "Bard",
    prerequesite: null,
    ability_tags: ["Passive", "Shout", "30 Count", "Heal/Harm"],
    ability_cost: null,
    ability_uses: 0,
    ability_tier_limit: 1,
}

export const bardCapstoneAbility = {
    ability_name: "Bardic Champion",
    ability_text: "Once per day, you may throw a %%Shout Death%% or %%Shout Life%% packet.",
    pillar_name: "Bard",
    prerequesite: null,
    ability_tags: ["Shout", "Packet", "Charm"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: 1,
}
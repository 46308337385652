import { Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { pbRemoveModalContext } from './PillarBuild';
import { buildModeContext, relevantPillarInventoryContext } from '../CharacterScreen/CharacterScreen';
import { pillarCost } from './PillarBuildUtils';
import { activeCharacterContext, charactersContext, pillarInventoryContext, relevantModalObjContext } from '../../App';
import { calculateTotalLevel } from '../Ability/AbilityModal/AbilityModalUtils';
import { deletePillarBuild } from '../../processing/RestClient';

function PillarBuildRemoveModal() {

    const { pbRemoveModal, setPbRemoveModal } = useContext(pbRemoveModalContext);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { buildMode, setBuildMode } = useContext(buildModeContext);
    const { relevantPillarInventory, setRelevantPillarInventory } = useContext(relevantPillarInventoryContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
    const { characters, setCharacters } = useContext(charactersContext);

    const removePillar = () => {
        //Setup
        const modifiedActiveCharacter = { ...activeCharacter };
        const removedPb = JSON.parse(JSON.stringify(relevantModalObj));
        const modifiedPillarInventory = [...relevantPillarInventory].filter((build) => build.pillar_number === removedPb.pillar_number);
        //Character Ops
        const pillarRefund = pillarCost(removedPb);
        const modifiedCharactersArray = [...characters];
        const characterIndex = modifiedCharactersArray.findIndex((character) => character.character_name === activeCharacter.character_name);
        modifiedActiveCharacter.xp_unspent = activeCharacter.xp_unspent + pillarRefund;
        modifiedActiveCharacter.level = calculateTotalLevel(modifiedPillarInventory);
        modifiedCharactersArray[characterIndex] = modifiedActiveCharacter;
        //Inventory Ops
        const modifiedPillarInventoryArr = [...pillarInventory]
        const newRelevantPbInventory = [];
        relevantPillarInventory.forEach((build) => {
            if (build.pillar_number !== removedPb.pillar_number) {
                newRelevantPbInventory.push(build);
            }
        })
        //PB Ops
        modifiedPillarInventoryArr[0] = newRelevantPbInventory;
        //Write to context
        setActiveCharacter(modifiedActiveCharacter);
        setCharacters(modifiedCharactersArray);
        setRelevantPillarInventory(newRelevantPbInventory);
        setPillarInventory(modifiedPillarInventoryArr)
        setPbRemoveModal(false);
        //Write to DB
        if (removedPb.id != null) {
            deletePillarBuild(removedPb.id);
        }
    }

    return (
        <Modal
            open={pbRemoveModal}
            onClose={() => { setRelevantModalObj("") }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
        >
            <Box>
                <Paper sx={{ margin: '40px auto 0', padding: '40px', borderRadius: '10px', maxWidth: 'sm' }}>
                    <Grid container justifyContent="center" align="center" direction="column">
                        <Grid item>
                            <Typography variant="h6">
                                Removing {relevantModalObj.pillar_name} Pillar
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                Please Confirm your selection
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Button startIcon={<CancelIcon />} variant="contained" color="error" onClick={() => setPbRemoveModal(false)}>
                                Close
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button endIcon={<AddCircleOutlineIcon />} variant="contained" color="success" onClick={() => removePillar()}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}

export default PillarBuildRemoveModal;
//Tier 1
//no fear heal/harm minor

export const rest = {
    ability_name: "Rest",
    ability_text: "Your foe drops into a short sleep, unaware of the troubles of life. You must incant the Verbal and may then throw a %%Spell Sleep 5%% packet.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Sleep"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: "Rest! Spell Sleep 5...",
    ability_tier_limit: null,
}

export const soothe = {
    ability_name: "Soothe",
    ability_text: "You bolster the vital essence of a being. You must incant the Verbal and may then throw a %%Spell 5 Heal%% packet.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Heal"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: "Soothe! Spell 5 Heal...",
    ability_tier_limit: null,
}

//Tier 2
//no holy light or slow

export const layOnHands2 = {
    ability_name: "Lay on Hands",
    ability_text: "Your touch can bring wholeness to those in need, be they Living or Dead. You gain a pool of 5 damage which you may choose to expend as Healing or Harm. After completing a three Second “Lay on Hands” Count while Touching a creature, you may expend as many points of this damage as you like. Each further purchase of this Ability adds 5 to this pool. This pool refills at the start of each day.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "Touch", "3 Count"],
    ability_cost: 2,
    ability_uses: 0,
    ability_tier_limit: null,
}

export const vim = {
    ability_name: "Vim",
    ability_text: "You call upon your god to protect you from harm. Upon completion of a three second \"Vim\" count, you grant yourself fifteen temporary Health.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "3 count", "Self"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 3
//no heal/harm or hold person

export const balm = {
    ability_name: "Balm",
    ability_text: "Life and love flow through your charge, mending their hurts. You must incant the Verbal and may then throw a %%Spell 15 Heal%% packet.",
    pillar_name: "Priest",
    prerequesite: "Soothe",
    ability_tags: ["Spell", "Verbal", "Packet", "Heal"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Balm! Spell 15 Heal...",
    ability_tier_limit: null,
}

export const pacifism = {
    ability_name: "Pacifism",
    ability_text: "You bring the crushing weight of your target’s crimes to bear in their mind. You must incant the Verbal and may then throw a %%Spell Slow 30%% packet.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Slow"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Pacifism! Spell Slow 30...",
    ability_tier_limit: null,
}

//Tier 4
//no heal harm or purity

export const reconstruction = {
    ability_name: "Reconstruction",
    ability_text: "Life and love flow through your charge, mending their hurts. You must incant the Verbal and may then throw a %%Spell 30 Heal%% packet.",
    pillar_name: "Priest",
    prerequesite: "Balm",
    ability_tags: ["Spell", "Verbal", "Packet", "Heal"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: "Reconstruction! Spell 30 Heal!",
    ability_tier_limit: null,
}

export const layOnHands4 = {
    ability_name: "Greater Lay on Hands",
    ability_text: "Your touch can bring wholeness to those in need, be they Living or Dead. You gain a pool of 15 damage which you may choose to expend as Healing or Harm. After completing a three Second “Lay on Hands” Count while Touching a creature, you may expend as many points of this damage as you like. Each further purchase of this Ability adds 5 to this pool. This pool refills at the start of each day.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "Touch", "3 Count"],
    ability_cost: 4,
    ability_uses: 0,
    ability_tier_limit: null,
}

//Tier 5
//no martyrdom or revivify

export const consecrate = {
    ability_name: "Consecrate",
    ability_text: "Consecration of the mortal vessel can repair any ill. You must Touch an ally, and on completion of a five Second “Martyrdom” Count, that ally’s Health is set to its maximum and all negative Conditions on them are Countered.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "Touch", "5 Count", "Counter"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const purge = {
    ability_name: "Purge",
    ability_text: "The gods are merciful and cleanse the corrupted broken bodies of the faithful. You must incant the Verbal and may then throw a %%Spell Counter All%% packet.",
    pillar_name: "Priest",
    prerequesite: "Expel Corruption",
    ability_tags: ["Spell", "Verbal", "Packet", "Counter"],
    ability_cost: 5,
    ability_uses: 2,
    ability_verbal: "Purge! Spell Counter All...",
    ability_tier_limit: null,
}

//Tierless

export const priestExpertiseAbility = {
    ability_name: "Word of Mending",
    ability_text: "You can channel the power of healing into your very words. The first time you purchase this Expertise, you may now incant the Verbal and throw a %%Spell 5 Heal%% packet, subtracting five from your Lay on Hands pool. For every purchase of this Expertise, the size of your Lay on Hands pool increases by five.",
    pillar_name: "Priest",
    prerequesite: "Lay on Hands",
    ability_tags: ["Passive", "Spell", "Verbal", "Packet", "Heal/Harm"],
    ability_cost: null,
    ability_uses: 0,
    ability_verbal: "Word of Mending! Spell 5 Heal...",
    ability_tier_limit: 1,
}

export const priestCapstoneAbility = {
    ability_name: "Priest Champion",
    ability_text: "If you have taken the Lay on Hands Ability, you gain the ability to Touch an ally, and after completing a three Second “Lay on Hands” Count, you may call %%Spell Counter%% to any single Condition affecting that creature. Each use of this ability consumes ten points from your Lay on Hands pool and may not be used if there are less than ten points of Healing or Harming in that pool.",
    pillar_name: "Priest",
    prerequesite: null,
    ability_tags: ["Spell", "Touch", "3 Count", "Counter"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: 1,
}
//Tier 1

export const siphon = {
    ability_name: "Siphon",
    ability_text: "You siphon away the vital essence of your foe. You must incant the Verbal and may then throw either a %%Spell 2 Drain%% packet or a %%Spell 2 Harm%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Drain", "Harm"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: "Siphon! Spell 2 {type} ...",
    ability_tier_limit: null,
}

export const seizure = {
    ability_name: "Seizure",
    ability_text: "You cause all your foe’s joints to seize for a moment. You must incant the Verbal and may then throw a %%Spell Paralyze 3%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Paralyze"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: "Seizure! Spell Paralyze 3...",
    ability_tier_limit: null,
}

//Tier 2

export const animateDead = {
    ability_name: "Animate Dead",
    ability_text: "Your Touch returns the dead to serve. Upon completion of a five second “Animating” count, you subject a dead creature to %%Spell Undeath 60%% and %%Spell 10 Harm%%. A creature may only be raised in this way once per death.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Touch", "5 Count", "Undeath"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const boilBlood = {
    ability_name: "Boil Blood",
    ability_text: "Your foe’s wounds seep and spill their lifeblood. You must incant the Verbal and may then throw a %%Spell Hemorrhage 30%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Hemorrhage"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: "Boil Blood! Spell Hemorrhage 30...",
    ability_tier_limit: null,
}

//Tier 3

export const drain = {
    ability_name: "Drain",
    ability_text: "You drain the very life force of your target. You must incant the Verbal and may then throw either a %%Spell 10 Drain%% packet, or a %%Spell 10 Harm%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Drain", "Harm"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Drain! Spell 10 {Type}...",
    ability_tier_limit: null,
}

export const terror = {
    ability_name: "Terror",
    ability_text: "You show your foe the grizzly deaths of entire worlds. You must incant the Verbal and may then throw a %%Spell Fear 30%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Fear"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Terror! Spell Fear 30...",
    ability_tier_limit: null,
}

//Tier 4

export const consume = {
    ability_name: "Consume",
    ability_text: "A soul is a thing so easily used, others must share theirs with you. You must incant the Verbal and may then throw either a %%Spell 15 Drain%% packet, or a %%Spell 15 Harm%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Drain", "Harm"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: "Consume! Spell 15 {type}...",
    ability_tier_limit: null,
}

export const createUndead = {
    ability_name: "Create Undead",
    ability_text: "Your Touch spurs the dead to action. Upon completion of a five second “Creating” count, you subject a dead creature to %%Spell Undeath 300%% and %%Spell 30 Harm%%. A creature may only be raised in this way once per death.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Touch", "5 Count", "Undeath"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 5

export const defile = {
    ability_name: "Defile",
    ability_text: "Your foe’s demise will serve a greater purpose. You must incant the Verbal and may then throw either a %%Spell 30 Drain%% packet, or a %%Spell 30 Harm%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Drain", "Harm"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: "Defile! Spell 30 {type}...",
    ability_tier_limit: null,
}

export const corruption = {
    ability_name: "Corruption",
    ability_text: "Death will not brook any defiance, nor any evasion. You must incant the Verbal and may then throw a %%Spell Waste 120%% packet.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Waste"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: "Corruption! Spell Waste 120...",
    ability_tier_limit: null,
}

//Tierless

export const necromancerExpertiseAbility = {
    ability_name: "Vampiric Power",
    ability_text: "Death and the dying empower you, draining their essence as they pass beyond the veil. Your maximum Health total increases by 3 for each purchase of this Expertise; however, you do not immediately gain health up to this number, instead only gaining health as per normal from your other features. Additionally, this bonus depletes at the start of each Encounter. Additionally, on completion of a Fifteen Second “Draining” count while touching a dead creature, you may call %%Spell Heal 5%%, or %%Spell Harm 5%% on yourself. Each creature may only be drained once in such a way for each time it reaches the Dead status.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Passive", "15 Count", "Heal", "Harm"],
    ability_cost: null,
    ability_uses: null,
    ability_tier_limit: 1,
}

export const necromancerCapstoneAbility = {
    ability_name: "Necromancer Champion",
    ability_text: "Once per Day, when your Dead count reaches its maximum and you would otherwise resurrect, you instead are subjected to the Life or Death effect.",
    pillar_name: "Necromancer",
    prerequesite: null,
    ability_tags: ["Spell", "Life", "Death"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: 1,
}
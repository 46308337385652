//Tier 1
export const gag = {
    ability_name: "Gag",
    ability_text: "You use your arcana to force the air from another's lungs. You must incant the Verbal and may then throw a %%Spell Silence 5%% packet.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Silence"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: "Gag! Spell Silence 5...",
    ability_tier_limit: null,
}

export const magicMissiles1 = {
    ability_name: "Magic Missiles",
    ability_text: "You can easily channel the Arcana to inflict destruction on those about you. You gain a pool of ten Arcane damage, which may be thrown in sums as great as five. You must incant the Verbal and follow with %%Spell {damage} Arcane%%. When purchased subsequent times, you add ten to your pool of Arcane damage. This pool refills at the start of each day.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Arcane"],
    ability_cost: 1,
    ability_uses: 0,
    ability_verbal: "Magic Missiles! Spell 5 Arcane...",
    ability_tier_limit: null,
}

export const shield = {
    ability_name: "Shield",
    ability_text: "You build a simple arcane shield around someone, protecting them from harm. You must Touch one creature, and on completion of a three second \"shielding\" count, may grant the Target five Temporary Armor.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "3 Count", "Touch"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const telekinesis = {
    ability_name: "Telekinesis",
    ability_text: "Through intense mental focus, you can push objects with your arcana. You must incant the Verbal and may then throw a %%Spell Push 1%% packet.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Push"],
    ability_cost: 1,
    ability_uses: 1,
    ability_verbal: "Telekinesis! Spell Push 1...",
    ability_tier_limit: null,
}

//Tier 2
export const elementalAdaptation = {
    ability_name: "Elemental Adaptation",
    ability_text: "With nary a word, you can shift your arcane energy to more mundane elemental types. You may change the damage type of your Magic Missiles Ability to one of Acid, Cold, Fire, or Shock for up to fifty damage.",
    pillar_name: "Mage",
    prerequesite: "Magic Missiles",
    ability_tags: ["Spell", "Self"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const mageArmor = {
    ability_name: "Mage Armor",
    ability_text: "You use your arcana to create a zone of disturbed air about you, hardening on impact. You must complete a three second Mage Armor” count, and may then grant yourself fifteen Temporary Armor.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "3 Count", "Self"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const magicMissiles2 = {
    ability_name: "Magic Missiles",
    ability_text: "You can easily channel the Arcana to inflict destruction on those about you. You gain a pool of ten Arcane damage, which may be thrown in sums as great as five. You must incant the Verbal and follow with %%Spell {damage} Arcane%%. When purchased subsequent times, you add ten to your pool of Arcane damage. This pool refills at the start of each day.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Arcane"],
    ability_cost: 2,
    ability_uses: 0,
    ability_verbal: "Magic Missiles! Spell 5 Arcane...",
    ability_tier_limit: null,
}

export const trance = {
    ability_name: "Trance",
    ability_text: "You generate a hypnotizing pattern of arcane energy to lul your foe to sleep. You must incant the Verbal and may then throw a %%Spell Sleep 30%% packet.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Sleep"],
    ability_cost: 2,
    ability_uses: 1,
    ability_verbal: "Trance! Spell Sleep 30...",
    ability_tier_limit: null,
}

//tier 3
export const counterspell = {
    ability_name: "Counterspell",
    ability_text: "With a sudden twist of the winds of magic and you unmake a spell. You may call %%Spell Counter%% on one Spell delivered to you or an ally within Reach.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Reach", "Counter"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const magicMissiles3 = {
    ability_name: "Magic Missiles",
    ability_text: "You can easily channel the Arcana to inflict destruction on those about you. You gain a pool of ten Arcane damage, which may be thrown in sums as great as five. You must incant the Verbal and follow with %%Spell {damage} Arcane%%. When purchased subsequent times, you add ten to your pool of Arcane damage. This pool refills at the start of each day.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Arcane"],
    ability_cost: 3,
    ability_uses: 0,
    ability_verbal: "Magic Missiles! Spell 5 Arcane...",
    ability_tier_limit: null,
}

export const silence = {
    ability_name: "Silence",
    ability_text: "You use your arcana to block the voicebox of your Target. You must incant the Verbal and may then throw a %%Spell Silence 30%% packet.",
    pillar_name: "Mage",
    prerequesite: "Gag",
    ability_tags: ["Spell", "Verbal", "Packet", "Silence"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Silence! Spell Silence 30...",
    ability_tier_limit: null,
}

export const destroyMagic = {
    ability_name: "Destroy Magic",
    ability_text: "You undo the threads of arcana sustaining a magical effect. You must incant the Verbal and may then throw a %%Spell Counter All%% packet.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Counter"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Desroy Magic! Spell Counter All...",
    ability_tier_limit: null,
}

//Tier 4

export const blink = {
    ability_name: "Blink",
    ability_text: "You are able to instantly phase your body through the arcane web. You become Insubstantial for ten Seconds.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Self", "Insubstantial"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const magicMissiles4 = {
    ability_name: "Greater Magic Missiles",
    ability_text: "You can easily channel the Arcana to inflict destruction on those about you. You gain a pool of thirty Arcane damage, which may be thrown in sums as great as five. You must incant the Verbal and follow with %%Spell {damage} Arcane%%. When purchased subsequent times, you add ten to your pool of Arcane damage. This pool refills at the start of each day.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Arcane"],
    ability_cost: 4,
    ability_uses: 0,
    ability_verbal: "Magic Missiles! Spell 5 Arcane...",
    ability_tier_limit: null,
}

export const spellShield = {
    ability_name: "Spell Shield",
    ability_text: "You build a complex arcane Ward around your Target, shielding them from magic. You must touch your target, and complete a three second \"shielding\" count. The Target gains one %%Spell Resist%% against any Spell Ability that hits them. Only one Ward may be active on a Target at a time.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "3 Count", "Touch", "Ward"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const web = {
    ability_name: "Mystic Web",
    ability_text: "You loop coils of the arcane about your foe, locking them in place. You must incant the Verbal and may then throw a %%Spell Immobilize 30%% packet.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Immobilize"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: "Mystic Web! Spell Immobilize 30...",
    ability_tier_limit: null,
}

//Tier 5
export const coma = {
    ability_name: "Thaumatic Coma",
    ability_text: "You use your arcana to force your foe’s brain into catatonia. You must incant the Verbal and may then throw a %%Spell Sleep 300%% packet.",
    pillar_name: "Mage",
    prerequesite: "Trance",
    ability_tags: ["Spell", "Verbal", "Packet", "Sleep"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: "Thaumatic Coma! Spell Sleep 300...",
    ability_tier_limit: null,
}

export const doom = {
    ability_name: "Arcane Doom",
    ability_text: "You rend the fabric of reality to drench a foe in your arcane power. You must incant the Verbal, and may then throw a %%Spell 50 Arcane%% packet.",
    pillar_name: "Mage",
    prerequesite: "Magic Missiles",
    ability_tags: ["Spell", "Verbal", "Packet", "Arcane"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: "Arcane Doom! Spell 50 Arcane...",
    ability_tier_limit: null,
}

export const magicMirror = {
    ability_name: "Magic Mirror",
    ability_text: "You weave a mirrored Ward around your Target, the defense of the archmagi. You must touch your target, and complete a three second \"Mirroring\" count. The Target may call %%Spell Return%% against any one Spell Ability that hits them. Only one Ward may be active on a Target at a time.",
    pillar_name: "Mage",
    prerequesite: "Spell Shield",
    ability_tags: ["Spell", "3 Count", "Touch", "Return", "Ward"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const magicMissiles5 = {
    ability_name: "Greater Magic Missiles",
    ability_text: "You can easily channel the Arcana to inflict destruction on those about you. You gain a pool of thirty Arcane damage, which may be thrown in sums as great as five. You must incant the Verbal and follow with %%Spell {damage} Arcane%%. When purchased subsequent times, you add ten to your pool of Arcane damage. This pool refills at the start of each day.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Arcane"],
    ability_cost: 5,
    ability_uses: 0,
    ability_verbal: "Magic Missiles! Spell 5 Arcane...",
    ability_tier_limit: null,
}

//Tierless

export const mageExpertiseAbility = {
    ability_name: "Arcane Font",
    ability_text: "While others have trained their bodies and minds to lesser pursuits, you have maximized your capacity to channel the arcane. Once per day, you may cast a spell you have already cast again, so long as it is no higher tier than the tier of this Expertise.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Passive"],
    ability_cost: null,
    ability_uses: 1,
    ability_tier_limit: 1,
}

export const mageCapstoneAbility = {
    ability_name: "Mage Champion",
    ability_text: "If you have taken Magic Missiles, the maximum sum of Arcane damage you may throw in an individual packet doubles (from five to ten, or from ten to twenty.) Once per Day on a thirty Second %%Quickening%% Count, you may Reset all of your Mage Abilities, allowing you to use them again.",
    pillar_name: "Mage",
    prerequesite: null,
    ability_tags: ["Passive", "30 Count", "Self"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: 1,
}
//Tier 1

export const rouse = {
    ability_name: "Rouse",
    ability_text: "You instruct one of your soldiers to toughen up. Upon completion of a three Second “Rousing” Count, you grant your Target five temporary Armor and Health.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Shout", "Touch", "3 Count", "Healing/Harm"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const onslaughtTraining = {
    ability_name: "Onslaught Training",
    ability_text: "You instruct your soldiers how to bypass their enemies' defenses in a single strike. You must Touch a creature and upon the completion of a five Second “Training” Count, once before the next Encounter, that creature may call Pierce instead of their normal damage type. Only one Lore may be active on a Target at a time.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Shout", "Touch", "5 Count", "Lore"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 2

export const bellow = {
    ability_name: "Bellow",
    ability_text: "You howl in primal rage, triggering your opposition’s fight or flight reflex. You may throw a %%Shout Fear 5%% packet or deliver the same effect with a successful strike.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Shout", "Packet", "Strike", "Fear"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const defensiveTraining = {
    ability_name: "Defensive Training",
    ability_text: "Your knowledge of tactics extends to impregnable defense. You must Touch one creature, and upon the completion of a five Second Count of “Training,” you may grant those creatures the power to call %%“Shout Counter”%% against one Attack or Skill before the start of the next Encounter. Only one Lore may be active on a Target at a time.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Shout", "Touch", "5 Count", "Counter", "Lore"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 3

export const gird = {
    ability_name: "Gird",
    ability_text: "You bolster the spirits of your soldiers and prepare them for the combat to come. You may Touch up to five creatures and must complete a five Second “Girding” Count. Those creatures each gain five temporary Health and Armor.",
    pillar_name: "Tactician",
    prerequesite: "Rouse",
    ability_tags: ["Shout", "Touch", "5 Count"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const precisionTraining = {
    ability_name: "Precision Training",
    ability_text: "You instruct your soldiers how to properly guide their weapons to their greatest effect. You must Touch a creature and must complete a five Second “Training” Count. Until the start of the next encounter, that creature may choose to add five to their one-handed damage for five sequential Swings. Only one Lore may be active on a Target at a time.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Shout", "Touch", "5 Count", "Lore"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 4

export const demolitionTraining = {
    ability_name: "Demolition Training",
    ability_text: "You instruct your soldiers how to slice armor from the hides of their foes. You must Touch a creature and must complete a five Second “Training” Count. Until the start of the next encounter, that creature may choose to call %%Shout Sunder%% once. Only one Lore may be active on a Target at a time.",
    pillar_name: "Tactician",
    prerequesite: "Onslaught Training",
    ability_tags: ["Shout", "Touch", "5 Count", "Lore"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const optimizeArmor = {
    ability_name: "Optimize Armor",
    ability_text: "You properly align and configure your soldier’s armor such that it will operate to its greatest effect. You may Touch up to five creatures and must complete a five Second “Optimizing” Count. Those creatures each gain ten bonus Armor until the start of the next encounter.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Shout", "Touch", "5 Count"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 5

export const howl = {
    ability_name: "Howl",
    ability_text: "Your scream of fury locks your opponent’s nerves. You may throw a %%Shout Paralyze 10%% packet or deliver the same effect with a successful strike.",
    pillar_name: "Tactician",
    prerequesite: "Bellow",
    ability_tags: ["Shout", "Packet", "Strike", "Fear"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const reversalTraining = {
    ability_name: "Reversal Training",
    ability_text: "Your knowledge of tactics allows your men to turn the enemy’s strengths into weaknesses. You must Touch one creature, and upon the completion of a five Second Count of “Training,” you may grant that creature the power to call %%“Shout Return”%% against one Attack or Skill before the start of the next Encounter. Only one Lore may be active on a Target at a time.",
    pillar_name: "Tactician",
    prerequesite: "Defensive Training",
    ability_tags: ["Spell", "5 Count", "Touch", "Lore"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tierless

export const tacticianExpertiseAbility = {
    ability_name: "Command",
    ability_text: "As a commander, your word is law, even against your foes. You may throw a %%Shout 5 Heal%% or %%Shout 5 Harm%% packet or deliver the same effect with a successful strike. You may instead increase the damage of a single strike you deliver with a weapon by five.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Shout", "Packet", "Strike", "Heal/Harm"],
    ability_cost: null,
    ability_uses: 1,
    ability_tier_limit: 1,
}

export const tacticianCapstoneAbility = {
    ability_name: "Tactician Champion",
    ability_text: "You must Touch up to five creatures, and upon the completion of a five Second Count of “Training,” you may grant those creatures the power to call %%“Shout 50 Pierce”%% before the start of the next Encounter.",
    pillar_name: "Tactician",
    prerequesite: null,
    ability_tags: ["Spell", "5 Count", "Touch"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: 1,
}
import { SvgIcon } from "@mui/material";
import React from "react";

// Component SVG Icons
import { ReactComponent as candleIconSvg } from "../img/icons/svg/Five-Pillars_Icons-comp-candle.svg";
import { ReactComponent as toothIconSvg } from "../img/icons/svg/Five-Pillars_Icons-comp-dragons-tooth.svg";
import { ReactComponent as featherIconSvg } from "../img/icons/svg/Five-Pillars_Icons-comp-feather.svg";
import { ReactComponent as hexiteIconSvg } from "../img/icons/svg/Five-Pillars_Icons-comp-hexite.svg";
import { ReactComponent as manaIconSvg } from "../img/icons/svg/Five-Pillars_Icons-comp-mana.svg";
import { ReactComponent as bronzeIconSvg } from "../img/icons/svg/Five-Pillars_Icons-ingot-bronze.svg";
import { ReactComponent as electrumIconSvg } from "../img/icons/svg/Five-Pillars_Icons-ingot-electrum.svg";
import { ReactComponent as galvornIconSvg } from "../img/icons/svg/Five-Pillars_Icons-ingot-galvorn.svg";
import { ReactComponent as glassIconSvg } from "../img/icons/svg/Five-Pillars_Icons-ingot-glass.svg";
import { ReactComponent as mithrilIconSvg } from "../img/icons/svg/Five-Pillars_Icons-ingot-mithril.svg";
import { ReactComponent as orichalcumIconSvg } from "../img/icons/svg/Five-Pillars_Icons-ingot-orichalcum.svg";

// Heritage PNG Icons
import humanIcon from "../img/icons/5P_H_Human.png";
import elfIcon from "../img/icons/5P_H_Elf.png";
import littlefolkIcon from "../img/icons/5P_H_Littlefolk.png";
import orcIcon from "../img/icons/5P_H_Orc.png";
import beastfolkIcon from "../img/icons/5P_H_Beastfolk.png";
import elementalIcon from "../img/icons/5P_H_Elemental.png";
import revenantIcon from "../img/icons/5P_H_Revenant.png";
import lizardfolkIcon from "../img/icons/5P_H_Lizardfolk.png";
import dwarfIcon from "../img/icons/5P_H_Dwarf.png";
import nephilimIcon from "../img/icons/5P_H_Nephilim.png";

// Component PNG Icons
import hexitIcon from "../img/icons/5P_C_Hexite.png";
import manaIcon from "../img/icons/5P_C_Mana.png";
import candleIcon from "../img/icons/5P_C_Candle.png";
import toothIcon from "../img/icons/5P_C_Dragonstooth.png";
import featherIcon from "../img/icons/5P_C_Feather.png";
import bronzeIcon from "../img/icons/5P_I_Bronze.png";
import electrumIcon from "../img/icons/5P_I_Electrum.png";
import glassIcon from "../img/icons/5P_I_Glass.png";
import orichalcumIcon from "../img/icons/5P_I_Orichalcum.png";
import mithrilIcon from "../img/icons/5P_I_Mithril.png";
import galvornIcon from "../img/icons/5P_I_Galvorn.png";

// Pillar PNG Icons
import aegisIcon from "../img/icons/FivePillarsIcons-aegis.png";
import bardIcon from "../img/icons/FivePillarsIcons-bard.png";
import berserkerIcon from "../img/icons/FivePillarsIcons-berserker.png";
import chemistIcon from "../img/icons/FivePillarsIcons-chemist.png";
import clericIcon from "../img/icons/FivePillarsIcons-cleric.png";
import evokerIcon from "../img/icons/FivePillarsIcons-evoker.png";
import mageIcon from "../img/icons/FivePillarsIcons-mage.png";
import necromancerIcon from "../img/icons/FivePillarsIcons-nercomancer.png";
import pharmacistIcon from "../img/icons/FivePillarsIcons-pharmacist.png";
import poisonerIcon from "../img/icons/FivePillarsIcons-poisoner.png";
import priestIcon from "../img/icons/FivePillarsIcons-priest.png";
import rogueIcon from "../img/icons/FivePillarsIcons-rogue.png";
import sageIcon from "../img/icons/FivePillarsIcons-sage.png";
import soldierIcon from "../img/icons/FivePillarsIcons-soldier.png";
import tacticianIcon from "../img/icons/FivePillarsIcons-tactician.png";

// Profession PNG Icons
import artificerIcon from "../img/icons/5P_ArtificerIcon.png";
import enchanterIcon from "../img/icons/FivePillarsIcons-enchanter.png";
import oracleIcon from "../img/icons/5P_OracleIcon.png";
import taskmasterIcon from "../img/icons/5P_TaskMasterIcon.png";
import mysticIcon from "../img/icons/5P_MysticIcon.png";

export function classIconMapper(className) {
  switch (className) {
    case "soldier":
      return (soldierIcon);
    case "mage":
      return (mageIcon);
    case "cleric":
      return (clericIcon);
    case "chemist":
      return (chemistIcon);
    case "sage":
      return (sageIcon);
    case "berserker":
      return (berserkerIcon);
    case "aegis":
      return (aegisIcon);
    case "priest":
      return (priestIcon);
    case "pharmacist":
      return (pharmacistIcon);
    case "tactician":
      return (tacticianIcon);
    case "rogue":
      return (rogueIcon);
    case "evoker":
      return (evokerIcon);
    case "necromancer":
      return (necromancerIcon);
    case "poisoner":
      return (poisonerIcon);
    case "bard":
      return (bardIcon);
    default:
      return ("");
  }
}

export function professionIconMapper(profName) {
  switch (profName) {
    case "artificer":
      return (artificerIcon);
    case "enchanter":
      return (enchanterIcon);
    case "oracle":
      return (oracleIcon);
    case "taskmaster":
      return (taskmasterIcon);
    case "mystic":
      return (mysticIcon);
    default:
      return ("");
  }
}

export function heritageIconMapper(heritageName) {
  switch (heritageName) {
    case "human":
      return (humanIcon);
    case "elf":
      return (elfIcon);
    case "littlefolk":
      return (littlefolkIcon);
    case "orc":
      return (orcIcon);
    case "beastfolk":
      return (beastfolkIcon);
    case "elemental":
      return (elementalIcon);
    case "revenant":
      return (revenantIcon);
    case "lizardfolk":
      return (lizardfolkIcon);
    case "dwarf: hate forged":
      return (dwarfIcon);
    case "dwarf: sun chaser":
      return (dwarfIcon);
    case "nephilim: angelic":
      return (nephilimIcon);
    case "nephilim: diabolic":
      return (nephilimIcon);
    default:
      return ("");
  }
}

export function professionMaterialIconMapper(profName) {
  switch (profName) {
    case "hexite":
      return (hexitIcon);
    case "crystalized mana":
      return (manaIcon);
    case "sacred candle":
      return (candleIcon);
    case "petrified dragon tooth":
      return (toothIcon);
    case "fossilized raven feather":
      return (featherIcon);
    default:
      return ("");
  }
}

export function ingotIconMapper(profName) {
  switch (profName) {
    case "bronze ingot":
      return (bronzeIcon);
    case "electrum ingot":
      return (electrumIcon);
    case "glass ingot":
      return (glassIcon);
    case "orichalcum ingot":
      return (orichalcumIcon);
    case "mithril ingot":
      return (mithrilIcon);
    case "galvorn ingot":
      return (galvornIcon);
    default:
      return ("");
  }
}

export function professionMaterialSVGIconMapper(profName) {
  switch (profName) {
    case "artificer":
      return (<HexiteIcon />);
    case "enchanter":
      return (<ManaIcon />);
    case "oracle":
      return (<CandleIcon />);
    case "taskmaster":
      return (<ToothIcon />);
    case "mystic":
      return (<FeatherIcon />);
    default:
      return ("");
  }
}

export function HexiteIcon(props) {
  return (
    <SvgIcon component={hexiteIconSvg} inheritViewBox />
  );
}

export function ManaIcon(props) {
  return (
    <SvgIcon component={manaIconSvg} inheritViewBox />
  );
}

export function CandleIcon(props) {
  return (
    <SvgIcon component={candleIconSvg} inheritViewBox />
  );
}

export function ToothIcon(props) {
  return (
    <SvgIcon component={toothIconSvg} inheritViewBox />
  );
}

export function FeatherIcon(props) {
  return (
    <SvgIcon component={featherIconSvg} inheritViewBox />
  );
}

export function BronzeIcon(props) {
  return (
    <SvgIcon component={bronzeIconSvg} inheritViewBox />
  );
}

export function ElectrumIcon(props) {
  return (
    <SvgIcon component={electrumIconSvg} inheritViewBox />
  );
}

export function GlassIcon(props) {
  return (
    <SvgIcon component={glassIconSvg} inheritViewBox />
  );
}

export function OrichalcumIcon(props) {
  return (
    <SvgIcon component={orichalcumIconSvg} inheritViewBox />
  );
}

export function MithrilIcon(props) {
  return (
    <SvgIcon component={mithrilIconSvg} inheritViewBox />
  );
}

export function GalvornIcon(props) {
  return (
    <SvgIcon component={galvornIconSvg} inheritViewBox />
  );
}
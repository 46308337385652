const allConcept = {
    concept_name: "All",
    concept_type: "System",
    concept_text: "Every status or condition of a specific type or source. “Spell Counter All” indicates that a spell Ability is countering all conditions on the target."
}

const attack = {
    concept_name: "Attack",
    concept_type: "System",
    concept_text: "Taking aggressive hostile action of any kind including those not explicitly defined by weaponry or packets."
}

const blueHeadband = {
    concept_name: "Blue Headband",
    concept_type: "System",
    concept_text: "An individual wearing a blue headband is an active rules marshal. They will provide active marshaling on the field or patrol and enact specific effects relevant to the event."
}

const character = {
    concept_name: "Character",
    concept_type: "System",
    concept_text: "An in-game persona of a specific player, including their statistics and past deeds."
}

const checkIn = {
    concept_name: "Check-In",
    concept_type: "System",
    concept_text: "At the start of the event, all players are required to check-in their in-play items."
}

const count = {
    concept_name: "Count",
    concept_type: "System",
    concept_text: "Must count up from 1 to the specified number using a minimum two-syllable word. “Dying 1, Dying 2” etc."
}

const coloredEyes = {
    concept_name: "Colored Eyes",
    concept_type: "System",
    concept_text: "Colored eyes may be represented either with appropriately colored contacts or eyeliner. The eyeliner must be present on both the upper and lower lid, and must be upkept as best as possible during an event. Eyeliner should be moderately exaggerated for clarity."
}

const damageBracketed = {
    concept_name: "{damage}",
    concept_type: "System",
    concept_text: "A placeholder for the value of damage the character can deal with a weapon, or chooses to deal with an Ability with variable damage. In some cases, the brackets will surround other combined damaging abilities such as {Dirty Deeds} to indicate that the damage dealt by that ability is being used by the relevant ability."
}

const day = {
    concept_name: "Day",
    concept_type: "System",
    concept_text: "A single day of play time, which is declared finished at reset."
}

const encounter = {
    concept_name: "Encounter",
    concept_type: "System",
    concept_text: "A single encounter, announced at its outset by a plot person with “Encounter Start”."
}

const enhancement = {
    concept_name: "Enhancement",
    concept_type: "System",
    concept_text: "Some abilities are enhancements. You may only benefit from one Enhancement of a given type at a time. For example, a Lore, a Tonic, and a Ward are all separate types of enhancements. One of each may be worn at the same time, but two of each may not."
}

const hold = {
    concept_name: "Hold",
    concept_type: "System",
    concept_text: "Hold is an out-of-play safety term that must be observed regardless of anything else. A 'Hold' can be called by any player to pause the game for safety reasons. The call is parroted by players in the vicinity until all understand they are in a hold. Once safe conditions are achieved and players consent to continue, play is resumed with a count to 3 followed by 'Play On.'"
}

const inPlay = {
    concept_name: "In-Play",
    concept_type: "System",
    concept_text: "An in-game object that is present in the game world. Abbreviated to IP."
}

const orangeHeadband = {
    concept_name: "Orange Headband",
    concept_type: "System",
    concept_text: "An individual wearing an orange headband may not engage or be engaged in combat. They may not throw packets for any purpose. If in a combat theater, an opponent may approach to within touching distance and Killing Blow the person as per normal."
}

const outOfPlay = {
    concept_name: "Out-of-Play",
    concept_type: "System",
    concept_text: "A real world object that is not present in the game world. Abbreviated to OOP."
}

const packet = {
    concept_name: "Packet",
    concept_type: "System",
    concept_text: "An ability or effect that must be delivered by way of an approved combat packet."
}

const passive = {
    concept_name: "Passive",
    concept_type: "System",
    concept_text: "An ability that has no active effect, or that grants an ability that can be used at-will."
}

const player = {
    concept_name: "Player",
    concept_type: "System",
    concept_text: "An out-of-play human being who came to site to play the game."
}

const proximity = {
    concept_name: "Proximity",
    concept_type: "System",
    concept_text: "Combat can be paused and adjusted if a participant feels unsafe due to location of combat, unsafe terrain, or proximity of combatants. Participant can say 'Unsafe' and hold both arms out in front of them. The opponent(s) must step away until the first person's arms are not touching their opponent's body when standing upright. If needed, combat location can be adjusted the minimum amount necessary. Play is resumed on a 3 count to 'Play On.'"
}

const reset = {
    concept_name: "Reset",
    concept_type: "System",
    concept_text: "An event that occurs once per day, or by plot use only. Completely heals, repairs, and restores all health, armor, and abilities of each character."
}

const secondTime = {
    concept_name: "Second (Time)",
    concept_type: "System",
    concept_text: "A standard second. To be counted as “1 Mississippi” in your head, even if the required count-word is of shorter duration."
}

const self = {
    concept_name: "Self",
    concept_type: "System",
    concept_text: "An ability that can only target the caster, namely you."
}

const speaking = {
    concept_name: "Speaking",
    concept_type: "System",
    concept_text: "In-play speech. Does not include out-of-play communication. All players may speak for the sake of their own safety and safety of others at any time."
}

const status = {
    concept_name: "Status",
    concept_type: "System",
    concept_text: "An ongoing effect or current state of a given character."
}

const step = {
    concept_name: "Step",
    concept_type: "System",
    concept_text: "A normal walking pace stride."
}

const swing = {
    concept_name: "Swing",
    concept_type: "System",
    concept_text: "An attempt to use a weapon to contact another character, including ranged attacks using ammunition."
}

const target = {
    concept_name: "Target",
    concept_type: "System",
    concept_text: "The entity struck by an effect, packet, or weapon, regardless of intent."
}

const tonic = {
    concept_name: "Tonic",
    concept_type: "System",
    concept_text: "Some abilities are tonics. You may only benefit from one Tonic at a time."
}

const touch = {
    concept_name: "Touch",
    concept_type: "System",
    concept_text: "Must be able to touch the intended target for the entire duration of the Ability, and may target yourself."
}

const verbal = {
    concept_name: "Verbal",
    concept_type: "System",
    concept_text: "Words that must be spoken as part of an ability for it to be executed.$$ These must be clearly and completely stated before speaking any combat instructions to other players for abilities that require them."
}

const ward = {
    concept_name: "Ward",
    concept_type: "System",
    concept_text: "Some abilities are wards. You may only benefit from one Ward at a time."
}

const whiteHeadband = {
    concept_name: "White Headband",
    concept_type: "System",
    concept_text: "Individuals wearing white headbands are out-of-play and may not be interacted with in-play. Another way of indicating one is out-of-play is placing your arm over or on your head, until you return to play on a 3 count."
}

const yellowHeadband = {
    concept_name: "Yellow Headband",
    concept_type: "System",
    concept_text: "Individuals wearing yellow headbands are not to be touched by another person's body for any reason and may only be touched by a packet held in a caster's hand."
}

const experiencePoints = {
    concept_name: "Experience Points",
    concept_type: "System",
    concept_text: "A measure of your character’s experience and growth. Abbreviated to XP."
}

export const systemConcepts = [allConcept, attack, blueHeadband, character, checkIn, coloredEyes, count, damageBracketed,
    day, encounter, enhancement, hold, inPlay, orangeHeadband, outOfPlay, packet, passive, player, proximity, reset, self, secondTime, speaking, status, step, swing, target, 
tonic, touch, verbal, ward, whiteHeadband, yellowHeadband, experiencePoints];

import { Box, Card, CardContent, Container, Grid, List, ListItem, ListItemButton, Paper, Stack, ThemeProvider, Typography } from '@mui/material';
import { createContext, Fragment, useContext, useEffect, useState } from "react";
import { charactersContext, userContext, userPermissionsContext, userRewardNumberContext } from '../../App';
import { theme } from "../../styles/AppTheme";
import CharacterSelectSummary from './CharacterSelectSummary';
import { isUserAdmin, isUserSuper } from '../../processing/RestClient';


export const deleteCharacterOpenContext = createContext(false);

function CharacterSelect() {
    const { user, setUser } = useContext(userContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const { userRewardNumber, setUserRewardNumber } = useContext(userRewardNumberContext);
    const {userPermissions, setUserPermissions} = useContext(userPermissionsContext);

    const [deleteCharacterOpen, setDeleteCharacterOpen] = useState(false);

    useEffect(() => {
        isUserSuper().then((response) => {
            if (response.status === 200) {
                setUserPermissions("Super");
                isUserAdmin().then((response) => {
                    if (response.status === 200) {
                        setUserPermissions("Admin");
                    }
                })}
            }).catch((error) => {
                console.log(error)

            })
    }, []);

    return (
        <div>
            <ThemeProvider theme={theme} >
                <Container component="main" maxWidth="xs" sx={{ padding: '0 !important' }}>
                    <deleteCharacterOpenContext.Provider value={{ deleteCharacterOpen, setDeleteCharacterOpen }}>
                        <Box sx={{ margin: '20px auto', }}>
                            <Grid container elevation={0} spacing={0} direction="row" sx={{ width: '100%' }}>
                                <Grid item sx={{ width: '100%' }}>
                                    <ListItem key={user.username + "-event-ticket-button"} divider={true}>
                                        <Card elevation={0} variant="outlined" sx={{ height: '120px', width: '100%' }}>
                                            {card1(user)}
                                        </Card>
                                    </ListItem>
                                </Grid>
                                <Grid item sx={{ width: '100%' }}>
                                    <ListItem key={user.username + "-service-button"} divider={true}>
                                        <Card elevation={0} variant="outlined" sx={{ height: '120px', width: '100%' }}>{card2(user, userRewardNumber)}</Card>
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Box>
                        <Paper elevation={0} sx={{ mx: '10px', border: '2px', borderStyle: "solid", borderRadius: '10px', borderColor: "primary.main" }}>
                            <Typography color="primary" variant="h4" component="div" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '10px 0' }}>
                                Character List
                            </Typography>
                        </Paper>
                        <List style={{ maxHeight: '100%', overflow: 'auto' }}>
                            <CharacterSelectSummary />
                        </List>
                    </deleteCharacterOpenContext.Provider>
                </Container>
            </ThemeProvider>
        </div >
    );
}

function card1(user) {
    return (
        <Fragment>
            <CardContent elevation={0} sx={{ minWidth: '100% !important' }}>
                <Grid container justifyContent="space-between" alignItems="center" direction='column'>
                    <Grid item>
                        <Typography sx={{ fontSize: 14, textAlign: 'center', mt: '10px' }} color="text.secondary" gutterBottom>
                            Next Event:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" component="div" sx={{ fontSize: '2rem', textAlign: 'center', fontWeight: 'bold' }}>
                            {user.next_event_date}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Fragment>
    )
};

function card2(user, userRewardNumber) {
    return (
        <Fragment>
            <CardContent elevation={0} sx={{ minWidth: '100% !important' }}>
                <Grid container direction="row" justifyContent="center" alignItems={"center"}>
                    <Stack xs={6} justifyContent="space-between" alignItems="center" direction='column'>
                        <Typography color="text.secondary" gutterBottom elevation={0}>
                            Adventure Points
                        </Typography>
                        <Typography color="success.dark" variant="h3" component="div" sx={{ fontWeight: 'bold' }} elevation={0}>
                            {user.user.service_total}
                        </Typography>
                    </Stack>
                    <Grid container item xs={6} justifyContent="space-between" alignItems="center" direction='column'>
                        <Grid item>
                            <Typography color="text.secondary" gutterBottom elevation={0}>
                                Unspent Rewards
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="success.dark" variant="h3" component="div" sx={{ fontWeight: 'bold' }} elevation={0}>
                                {userRewardNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Fragment>
    )
};

export default CharacterSelect;


import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

function ItemSelectModalHeader(props) {
    const item = props.item;

    return (
        <Box>
            <Stack direction='row' justifyContent='center'>
                <Typography variant='h4'>
                    {item.name}
                </Typography>
            </Stack>
            <Grid container direction='row' justifyContent='space-between'>
                <Grid item>
                    <Typography>
                        {item.type}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        {item.description}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        {item.identifier}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ItemSelectModalHeader;
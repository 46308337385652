import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Box, Typography } from "@mui/material";
import { lineHeight, padding } from '@mui/system';
import { Fragment, useContext } from "react";
import { expertiseModalOpenContext } from "../../PillarBuild/PillarBuild";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { relevantModalObjContext } from '../../../App';

function ExpertiseButton(props) {

    const disabled = props.disabled;
    const pillarBuild = props.pillarBuild;
    const usage = props.usage;
    const expertise = props.expertise;
    const buildMode = props.buildMode;
    let buttonState = props.ButtonState;
    buttonState = disabled ? "disabled" : props.buttonState;

    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { expertiseModalOpen, setExpertiseModalOpen } = useContext(expertiseModalOpenContext);

    if (usage === "encounter") {
        return (
            <Button sx={stretchBoxesExpert} disabled={disabled} color={"success"} variant="contained" fullwidth onClick={
                () => {
                    if (expertise.pillar_name !== " ") {
                        setRelevantModalObj(expertise)
                        setExpertiseModalOpen(true)
                    }
                    else {
                        setRelevantModalObj({ relevant_pillar: pillarBuild.pillar_name })
                        setExpertiseModalOpen(true)
                    }
                }}> {expertise.expertise_name}</Button>
        )
    }
    else {
        return (
            <Button sx={stretchBoxesExpert} disabled={disabled} color={"success"} variant="outlined"
                onClick={
                    () => {
                        if (expertise.expertise_ability.pillar_name !== " ") {
                            setRelevantModalObj(expertise)
                            setExpertiseModalOpen(true)
                        }
                        else {
                            setRelevantModalObj({ relevant_pillar: pillarBuild.pillar_name })
                            setExpertiseModalOpen(true)
                        }
                    }}
            > {buttonStateRender(disabled, buttonState, expertise)}
            </Button>
        )
    }
}

function buttonStateRender(disabled, buttonState, expertise) {
    switch (buttonState) {
        case "buy":
            return (
                <Fragment>
                    <Typography sx={abilityNameStyle}>{expertise.expertise_name}</Typography> 
                    {disabled ? null : <AddCircleIcon sx={changeSkillIcon} />}
                </Fragment>
            )
        case "disabled":
            return (
                <Fragment>
                    {expertise.expertise_name}
                </Fragment>
            )
        case "replace":
            return (
                <Fragment>
                    <Typography sx={abilityNameStyle} >{expertise.expertise_name}</Typography> 
                    {disabled ? null : <ChangeCircleOutlinedIcon sx={changeSkillIcon} />}
                </Fragment>
            )
    }


}

const stretchBoxesExpert = {
    color: '#fefefe',
    border: '1px solid #94c93d',
    minHeight: {
        xs: '50px',
        sm: '75px',
        md: '100px',
        lg: '100px',
        xl: '100px',
    },
    width: '100%',
    fontSize: {
        xs: '.4rem',
        sm: '.8rem',
        md: '1rem',
        lg: '1rem',
        xl: '1rem',
    },
    minWidth: {
        xs: '50px',
        sm: '100px',
        md: '100px',
        lg: '100px',
        xl: '200px',
    }
}

const changeSkillIcon = {
    height: {
        xs: '40px',
        sm: '65px',
        md: '90px',
        lg: '90px',
        xl: '90px',
    },
    color: 'rgba(255,255,255,.3)',
    fontSize: '60px',
    zIndex: '1',
    textAlign: 'center'
}

const abilityNameStyle = {
    zIndex: '2',
    position: 'absolute',
}

export default ExpertiseButton;



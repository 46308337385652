import { Card, CardContent, Fade, Grid, ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { userContext } from "../../App";
import { newCharacterModalContext } from "../CharacterSelect/CharacterSelectSummary";
import NewCharacterModal from "./NewCharacterModal/NewCharacterModal";
import AddCircleIcon from '@mui/icons-material/AddCircle';

function NewCharacterSelect() {

    const { newCharacterModal, setNewCharacterModal } = useContext(newCharacterModalContext);

    return (
        <Box sx={{ maxWidth: '1200px', margin: '0 auto', mx: '10px', my:"1.5rem"}}>
            <NewCharacterModal />
            <ListItem button key={"new-character-list"} sx={{ margin: "0 auto" }} disablePadding onClick={(event) => setNewCharacterModal(true)}>
                <ListItemButton key={"new-character-button"} sx={{ margin: "0 auto", bgcolor: '#121212', border: '2px', borderStyle: 'solid', borderColor: "success.main", borderRadius: '10px', padding: '10px 0 20px', width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' } }} divider={true}>
                    <Grid container direction="column" justifyContent="center" alignContent="center" columns={1}>
                        <Grid item>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                <Typography variant='button' color='success.main'>Create Character</Typography>
                                <AddCircleIcon color="success" sx={{ minWidth: '30px', minHeight: '30px' }} />
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItemButton>
            </ListItem>
        </Box >
    )
}

export default NewCharacterSelect;

import { Box, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { activeCharacterContext } from "../../App";
import { theme } from "../../styles/AppTheme";
import { buildModeContext, modifiedCraftingContext, modifiedCraftingFlagContext } from "../CharacterScreen/CharacterScreen";
import ProfessionAccordion from "./ProfessionAccordion";
import { updateProfessions } from "../../processing/RestClient";

function Crafting() {

  const { buildMode, setBuildMode } = useContext(buildModeContext);
  const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
  const { modifiedCrafting, setModifiedCrafting } = useContext(modifiedCraftingContext);
  const { modifiedCraftingFlag, setModifiedCraftingFlag } = useContext(modifiedCraftingFlagContext);

  const buyNextProfLevel = (input) => {

    const profLevelUp = input.profession_level + 1
    let levelUpCost;
    switch (input.profession_number) {
      case 1:
        levelUpCost = 5 * profLevelUp;
        break;
      case 2:
        levelUpCost = 10 * profLevelUp;
        break;
      case 3:
        levelUpCost = 15 * profLevelUp;
        break;
      case 4:
        levelUpCost = 25 * profLevelUp;
        break;
      case 5:
        levelUpCost = 40 * profLevelUp;
        break;
      default:
        levelUpCost = 0;
    }

    const newActiveCharacter = { ...activeCharacter }
    const newProfs = [...activeCharacter.professions];
    input.profession_level = input.profession_level + 1;
    newActiveCharacter.professions = newProfs;
    newActiveCharacter.level = activeCharacter.level + 1;
    const newXp = newActiveCharacter.xp_unspent - levelUpCost;
    newActiveCharacter.xp_unspent = newXp;
    setModifiedCrafting(newProfs);
    setModifiedCraftingFlag(true);
    setActiveCharacter(newActiveCharacter);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: 'sm' }}>
        {activeCharacter.professions.map(profession => {
          return (
            <ProfessionAccordion
              sx={{ maxWidth: "sm" }}
              key={"profession-Accordion-" + profession.profession_number}
              buyProf={() => buyNextProfLevel(profession)}
              profession={profession}
              unspentXp={activeCharacter.xp_unspent}
              buildMode={buildMode} />)
        })}
      </Box>
    </ThemeProvider>
  )
}

export default Crafting;
const charm = {
    concept_name: "Charm",
    concept_type: "Condition",
    concept_text: "Target considers the caster to be a close personal friend. Target does not remember the act of being charmed, but does remember the actions they took while charmed, which make sense in their mind."
}

const counter = {
    concept_name: "Counter",
    concept_type: "Condition",
    concept_text: "Target undoes an Ability of the type stated or the last Ability to hit a target if no type is stated."
}

const blight = {
    concept_name: "Blight",
    concept_type: "Condition",
    concept_text: "All damage dealt to target is doubled."
}

const death = {
    concept_name: "Death",
    concept_type: "Condition",
    concept_text: "A living target reduced to Dead status. An Unliving target returned to Living status at 1 Health."
}

const fear = {
    concept_name: "Fear",
    concept_type: "Condition",
    concept_text: "Target cannot attack and must move away from the caster. They may still defend themselves if pursued or cornered."
}

const hemorrhage = {
    concept_name: "Hemorrhage",
    concept_type: "Condition",
    concept_text: "Target may not be healed by any effect."
}

const immobilize = {
    concept_name: "Immobilize",
    concept_type: "Condition",
    concept_text: "Target may not move their feet."
}

const insubstantial = {
    concept_name: "Insubstantial",
    concept_type: "Condition",
    concept_text: "Target cannot take damage or hostile effects other than despell. This is represented by the target having both arms crossed over their chest. Target cannot make any hostile action in this state."
}

const intercept = {
    concept_name: "Intercept",
    concept_type: "Condition",
    concept_text: "Target becomes the victim of the triggering effect instead of the intended victim."
}

const life = {
    concept_name: "Life",
    concept_type: "Condition",
    concept_text: "A living target is returned to Living status at 1 Health. An unliving target is reduced to Dead status."
}

const paralyze = {
    concept_name: "Paralyze",
    concept_type: "Condition",
    concept_text: "Target cannot move or speak.$$ Paralyze prevents a character from taking any actions for the duration of the effect, other than to respond to %%Diagnose%% or other necessary out-of-play communication."
}

const push = {
    concept_name: "Push",
    concept_type: "Condition",
    concept_text: "Target must take 5 steps back and may not move any closer. If obstructed by an OOP barrier such as a wall, the target simply backs up as far as they can while remaining safe."
}

const returnCondition = {
    concept_name: "Return",
    concept_type: "Condition",
    concept_text: "Target suffers the effect of the last Ability they used on the intended target, as the intended target has 'returned' it to them."
}

const silence = {
    concept_name: "Silence",
    concept_type: "Condition",
    concept_text: "Target may not speak, use shouts, or use spells that require verbal components."
}

const slow = {
    concept_name: "Slow",
    concept_type: "Condition",
    concept_text: "Target may not use any strikes or shouts and may only move at a walking speed."
}

const sleep = {
    concept_name: "Sleep",
    concept_type: "Condition",
    concept_text: "Target must make snoring noises and take no actions. Condition may be ended by taking any damage or being shaken awake on a three second %%shaking%% count by another person within touch."
}

const sunder = {
    concept_name: "Sunder",
    concept_type: "Condition",
    concept_text: "Target's armor and temporary armor values are set to 0."
}

const undeath = {
    concept_name: "Undeath",
    concept_type: "Condition",
    concept_text: "A Dead target acts as though they were alive and unliving, but may not use any active Abilities. They must follow the orders of the caster and their Dead count is suspended. Upon expiration they return to Dead status as well as any other statuses affecting them."
}

const waste = {
    concept_name: "Waste",
    concept_type: "Condition",
    concept_text: "Target may not fight or use abilities, but may move at a crawl (if safe to do so). Target may fend off killing blows."
}

const wither = {
    concept_name: "Wither",
    concept_type: "Condition",
    concept_text: "Target may not use their hands."
}

export const conditions = [blight, charm, counter, death, fear, hemorrhage, immobilize, insubstantial,
    intercept, life, paralyze, push, returnCondition, silence, slow, sleep, sunder, undeath, waste, wither];
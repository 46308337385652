import { useContext } from "react";
import { activeCharacterContext } from "../../App";
import { Box, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { ingotIconMapper, professionMaterialIconMapper } from "../../data/Icons";

function Bank() {

  const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);

  const bank = activeCharacter.bank;
  const ingots = []
  const comps = []

  if (bank != null & bank.banked_resources != null & bank.banked_resources.length > 0) {
    bank.banked_resources.forEach((resource) => {
      if (resource.resource_item.resource_type === "Ingot") {
        ingots.push(resource);
      }
      else if (resource.resource_item.resource_type === "Component") {
        comps.push(resource);
      }
    });
  }

  return (
    <Grid container direction="column" sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", border: '2px solid #2A2A2A' }}>
        <Grid item container direction={"column"} justifyContent="center" alignItems={"center"}>
          <Paper sx={{ py: "0.25rem", width: "100%", borderBottom: '2px solid #2A2A2A' }}>
            <Grid item direction={"row"}>
              <Typography variant="h6">
                Total Gold:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }} variant="h5" color="success.main">
                {bank.gold_total}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid container direction="row" justifyContent={"center"} item xs={12} sx={{ mt: "0.5rem", pt: "0.25rem", pb: "0.5rem" }}>
          {comps.map((component) => {
            const item = component.resource_item;
            return (
              <Grid container direction="column" item justifyContent="center" alighnItems="center" sx={{ maxWidth: "120px", p: "0.25rem", border: "2px solid", borderRadius: "5px", borderColor: "white" }}>
                <Tooltip title={item.resource_description ? item.resource_description : ""}>
                  <Box>
                    <Grid item sx={{ width: "100px" }} spacing={1}>
                      <img object-fit="cover" height="40" src={professionMaterialIconMapper(component.resource_item.resource_name.toLowerCase())} alt="resource icon" />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {item.resource_name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5" color="primary.main" sx={{ fontWeight: "bold" }}>
                        {component.resource_count}
                      </Typography>
                    </Grid>
                  </Box>
                </Tooltip>
              </Grid>
            )
          })}
        </Grid>
        <Grid container direction="row" item xs={12} justifyContent={"center"} sx={{ pt: "0.25rem", pb: "0.5rem" }}>
          {ingots.map((component) => {
            const item = component.resource_item;
            return (
              <Grid container direction="column" item justifyContent="center" alighnItems="center" sx={{ maxWidth: "120px", p: "0.25rem", border: "2px solid", borderRadius: "5px", borderColor: "white" }}>
                <Tooltip title={item.resource_description ? item.resource_description : ""}>
                  <Box>
                    <Grid item sx={{ width: "100px" }} spacing={1}>
                      <img object-fit="cover" height="30" src={ingotIconMapper(component.resource_item.resource_name.toLowerCase())} alt="ingot icon" />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {item.resource_name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5" color="primary.main" sx={{ fontWeight: "bold" }}>
                        {component.resource_count}
                      </Typography>
                    </Grid>
                  </Box>
                </Tooltip>
              </Grid>
            )
          })}
        </Grid>
        <Grid container item>
          {/* <Grid item>
            IN THE FAR FUTURE, I WILL BE PEOPLE'S UNBOUND ITEMS
          </Grid> */}
        </Grid>
      </Paper>
    </Grid>
  )

}

export default Bank;
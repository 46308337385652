import { ThemeProvider } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import BottomNavigator from './component/BottomNavigator';
import CharacterScreen from './component/CharacterScreen/CharacterScreen';
import CharacterSelect from "./component/CharacterSelect/CharacterSelect";
import Summary from './component/CharacterSummary/Summary';
import Glossary from './component/Glossary/Glossary';
import LoginPage from "./component/LoginPage/LoginPage";
import TopBar from './component/TopBar';
import TopMenuDrawer from './component/TopMenuDrawer';
import { mockUser1 } from "./mocks/MockUser";
import { initialState, readFromSource } from './processing/control/Controller';
import { theme } from './styles/AppTheme';
import AdminTab from './component/AdminTab/AdminTab';

export const usernameContext = createContext("");
export const menuOpenContext = createContext(null);
export const locationContext = createContext("");
export const charactersContext = createContext("");
export const pillarInventoryContext = createContext("");
export const authenticationContext = createContext("");
export const userContext = createContext("");
export const activeCharacterContext = createContext("");
export const offlineModeContext = createContext(true);
export const relevantModalObjContext = createContext({});
export const userRewardNumberContext = createContext(0);
export const userPermissionsContext = createContext("");

const googleApiKey = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {

  const [offlineMode, setOfflineMode] = useState(false);
  const [username, setUsername] = useState('');
  const [user, setUser] = useState(mockUser1);
  const [characters, setCharacters] = useState(initialState('characters', readFromSource(offlineMode, 'characters')));
  const [pillarInventory, setPillarInventory] = useState(initialState('pillarInventory', readFromSource(offlineMode, 'pillarInventory')));
  const [authetication, setAuthentication] = useState("");
  const [location, setLocation] = useState("");
  const [topMenuOpen, setTopMenuOpen] = useState(false);
  const [activeCharacter, setActiveCharacter] = useState("");
  const [relevantModalObj, setRelevantModalObj] = useState('');
  const [userRewardNumber, setUserRewardNumber] = useState(0);
  const [userPermissions, setUserPermissions] = useState("User");

  useEffect(() => {
    document.title = "Five Pillars Character Builder";
  }, []);

  const loginPageRoute = <Route path="/login" element={<LoginPage />} />;

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={googleApiKey}>
        <usernameContext.Provider value={{ username, setUsername }}>
          <locationContext.Provider value={{ location, setLocation }}>
            <userContext.Provider value={{ user, setUser }}>
              <userPermissionsContext.Provider value={{ userPermissions, setUserPermissions }}>
                <offlineModeContext.Provider value={{ offlineMode, setOfflineMode }}>
                  <charactersContext.Provider value={{ characters, setCharacters }}>
                    <activeCharacterContext.Provider value={{ activeCharacter, setActiveCharacter }}>
                      <pillarInventoryContext.Provider value={{ pillarInventory, setPillarInventory }}>
                        <authenticationContext.Provider value={{ authetication, setAuthentication }}>
                          <menuOpenContext.Provider value={{ topMenuOpen, setTopMenuOpen }}>
                            <relevantModalObjContext.Provider value={{ relevantModalObj, setRelevantModalObj }}>
                              <userRewardNumberContext.Provider value={{ userRewardNumber, setUserRewardNumber }}>
                                <ThemeProvider theme={theme} >
                                  {(user.username !== '' && user !== null && user.username !== null) ?
                                    <Box>
                                      <TopMenuDrawer />
                                      <TopBar elevation={5} />
                                    </Box>
                                    : null}
                                  <Box component="main" justify="center" sx={{ backgroundImage: 'url(https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/1390e2135411131.61e76c99a1107.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', bgcolor: '#150216', color: '#fefefe', padding: '0' }}>
                                    <Grid container justifyContent="center" align="center" spacing={2}>
                                      <Grid item sx={{ my: '50px' }}>
                                        {/* <Router> */}
                                        <Routes>
                                          <Route path="/login" element={<LoginPage />} />
                                          {user.username !== '' ? <Route path="/character" element={<CharacterSelect />} /> : loginPageRoute}
                                          {user.username !== '' ? determineCharacterLoaded(activeCharacter) : loginPageRoute}
                                          {/* <Route path="/:id" component={UserPage} /> */}
                                          {user.username !== '' ? <Route path="/glossary" element={<Glossary />} /> : loginPageRoute}
                                          {user.username !== '' && activeCharacter !== "" ? <Route path="/summary" element={<Summary />} /> : loginPageRoute}
                                          <Route path="*" element={<Navigate from="*" to="/login" />} />
                                          {userPermissions === "Admin" ? <Route path="/admin" element={<AdminTab />} /> : loginPageRoute}
                                          <Route path="/logout" element={<LoginPage />} />
                                        </Routes>
                                        {/* </Router> */}
                                        {/* <LoginPage></LoginPage> */}
                                        <img src="/img/bg-image-1@1x.png" alt=""></img>
                                      </Grid>
                                      <Grid item>
                                        {(user.username !== '' && user !== null && user.username !== null) ?
                                          <BottomNavigator />
                                          :
                                          // <Copyright />
                                          null
                                        }
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </ThemeProvider>
                                {/* <Copyright /> */}
                              </userRewardNumberContext.Provider>
                            </relevantModalObjContext.Provider>
                          </menuOpenContext.Provider>
                        </authenticationContext.Provider>
                      </ pillarInventoryContext.Provider>
                    </activeCharacterContext.Provider>
                  </ charactersContext.Provider>
                </offlineModeContext.Provider>
              </userPermissionsContext.Provider>
            </ userContext.Provider>
          </locationContext.Provider>
        </usernameContext.Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

function determineCharacterLoaded(activeCharacter) {
  if (activeCharacter) {
    return (
      <Route path="/character/:id" element={<CharacterScreen />} />
    )
  }
  else {
    return (<Route path="/character/:id" element={<CharacterSelect />} />)
  }
}

export default App;

//Tier 1
export const freedomOfMovement = 
{ability_name: "Freedom of Movement",
ability_text: "You call upon divine power to free the shackled from their bondage in the name of your god. You must incant the Verbal and may then throw a %%Spell Counter Wither%% packet or a %%Spell Counter Slow%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Counter", "Wither", "Slow"],
ability_cost: 1,
ability_uses: 1,
ability_verbal: "Freedom of Movement! Spell Counter {type}...",
ability_tier_limit: null,
}

export const fear =
{ability_name: "Incite Fear",
ability_text: "You show your foe how truly insignificant they are in the face of the gods. You must incant the Verbal and may then throw a %%Spell Fear 5%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Fear"],
ability_cost: 1,
ability_uses: 1,
ability_verbal: "Incite Fear! Spell Fear 5...",
ability_tier_limit: null,
}

export const healHarmMinorWounds =
{ability_name: "Heal/Harm Minor Wounds",
ability_text: "You have been granted your powers to bring an end to the suffering of the righteous. You must incant the Verbal and may then throw either a %%Spell 2 Heal%% packet, or a %%Spell 2 Harm%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Heal", "Harm"],
ability_cost: 1,
ability_uses: 1,
ability_verbal: "Heal (or Harm) Minor Wounds! Spell {type} 2...",
ability_tier_limit: null,
}

export const layOnHands1 =
{ability_name: "Lay on Hands",
ability_text: "Your touch can bring wholeness to those in need, be they Living or Dead. You gain a pool of 5 damage, which you may choose to expend as Healing or Harm. After completing a three Second %%Lay on Hands%% Count while Touching a creature you may expend as many points of this damage as you like. Each further purchase of this Ability adds five to this pool. This pool refills at the start of each day.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Touch", "3 Count"],
ability_cost: 1,
ability_uses: 0,
ability_verbal: null,
ability_tier_limit: null,
}

//Tier 2
export const holyLight =
{ability_name: "Holy Light",
ability_text: "Your god wishes you to be whole, not bleeding all over Daear. On completion of a three Second %%Holy Light%% Count, you take %%10 Heal%% or %%10 Harm%%.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Self", "3 Count", "Heal", "Harm"],
ability_cost: 2,
ability_uses: 1,
ability_verbal: null,
ability_tier_limit: null,
}

export const repel =
{ability_name: "Repel Being",
ability_text: "You abjure the unfaithful with your divine sanction. You must incant the Verbal and may then throw a %%Spell Push 10%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Push"],
ability_cost: 2,
ability_uses: 1,
ability_verbal: "Repel Being! Spell Push 10...",
ability_tier_limit: null,
}

export const slow =
{ability_name: "Burden",
ability_text: "You mire the thoughts of your foe with doubt. You must incant the Verbal and may then throw a %%Spell Slow 10%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Slow"],
ability_cost: 2,
ability_uses: 1,
ability_verbal: "Burden! Spell Slow 10...",
ability_tier_limit: null,
}

export const unbind =
{ability_name: "Unbind",
ability_text: "You call divine power to free the shackled from their bondage in the name of your god. You must incant the Verbal and may then throw a %%Spell Counter Paralyze%% packet or a %%Spell Counter Immobilize%% packet",
pillar_name: "Cleric",
prerequesite: "Freedom of Movement",
ability_tags:["Spell", "Verbal", "Packet", "Counter", "Paralyze", "Immobilize"],
ability_cost: 2,
ability_uses: 1,
ability_verbal: "Unbind! Spell Counter {type}...",
ability_tier_limit: null,
}

//Tier 3
export const expelCorruption =
{ability_name: "Expel Corruption",
ability_text: "With divine power you boil corruption from the bones of your allies. You must incant the Verbal and may then throw a %%Spell Counter Blight%% packet or a %%Spell Counter Waste%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Counter", "Blight", "Waste"],
ability_cost: 3,
ability_uses: 1,
ability_verbal: "Expel Corruption! Spell Counter {type}...",
ability_tier_limit: null,
}

export const healHarmWounds =
{ability_name: "Heal/Harm Wounds",
ability_text: "Your powers can restore even the most grievously injured to fighting form. You must incant the Verbal and may then throw either a %%Spell 10 Heal%% packet, or a %%Spell 10 Harm%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Heal", "Harm"],
ability_cost: 3,
ability_uses: 1,
ability_verbal: "Heal (or Harm) Wounds! Spell {type} 10...",
ability_tier_limit: null,
}

export const holdPerson =
{ability_name: "Hold Person",
ability_text: "You stun a heretic with the might and power of the gods. You must incant the Verbal and may then throw a %%Spell Paralyze 10%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Paralyze"],
ability_cost: 3,
ability_uses: 1,
ability_verbal: "Hold Person! Spell Paralyze 10...",
ability_tier_limit: null,
}

export const layOnHands3 =
{ability_name: "Lay on Hands",
ability_text: "Your touch can bring wholeness to those in need, be they Living or Dead. You gain a pool of 5 damage, which you may choose to expend as Healing or Harm. After completing a three Second %%Lay on Hands%% Count while Touching a creature you may expend as many points of this damage as you like. Each further purchase of this Ability adds five to this pool. This pool refills at the start of each day.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Touch", "3 Count"],
ability_cost: 3,
ability_uses: 0,
ability_verbal: null,
ability_tier_limit: null,
}

//Tier 4
export const condemn =
{ability_name: "Condemn",
ability_text: "The gods have deemed this creature too repulsive to tolerate. You must incant the Verbal and may then throw a %%Spell Blight 60%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Blight"],
ability_cost: 4,
ability_uses: 1,
ability_verbal: "Condemn! Spell Blight 60...",
ability_tier_limit: null,
}

export const decrepify =
{ability_name: "Decrepify",
ability_text: "The gods smite the flesh of your target for their impertinence. You must incant the Verbal and may then throw a %%Spell Waste 30%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Waste"],
ability_cost: 4,
ability_uses: 1,
ability_verbal: "Decrepify! Spell Waste 30...",
ability_tier_limit: null,
}

export const healHarmGreaterWounds =
{ability_name: "Heal/Harm Wounds",
ability_text: "Mortal wounds are naught but trivialities for your god's power. You must incant the Verbal and may then throw either a %%Spell 15 Heal%% packet, or a %%Spell 15 Harm%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Heal", "Harm"],
ability_cost: 4,
ability_uses: 1,
ability_verbal: "Heal (or Harm) Greater Wounds! Spell {type} 15...",
ability_tier_limit: null,
}

export const purity =
{ability_name: "Purity",
ability_text: "You cannot serve your god with corruption coursing through you. On completion of a three Second %%Purity%% Count, you end all negative Conditions currently imposed on you.",
pillar_name: "Cleric",
prerequesite: "Holy Light",
ability_tags:["Spell", "Self", "3 Count", "Counter"],
ability_cost: 4,
ability_uses: 1,
ability_verbal: null,
ability_tier_limit: null,
}

//Tier 5
export const layOnHands5 =
{ability_name: "Greater Lay on Hands",
ability_text: "Your touch can bring wholeness to those in need, be they Living or Dead. You gain a pool of 15 damage, which you may choose to expend as Healing or Harm. After completing a three Second %%Lay on Hands%% Count while Touching a creature you may expend as many points of this damage as you like. Each further purchase of this Ability adds five to this pool. This pool refills at the start of each day.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Touch", "3 Count"],
ability_cost: 5,
ability_uses: 0,
ability_verbal: null,
ability_tier_limit: null,
}

export const lifeDeath =
{ability_name: "Divine Intervention",
ability_text: "Your gods have determined this being's life to be important to them and grant you judgement over it. You must incant the Verbal and may then throw a %%Spell Life%% Packet or a %%Spell Death%% Packet.",
pillar_name: "Cleric",
prerequesite: "Condemn",
ability_tags:["Spell", "Verbal", "Packet", "Life", "Death"],
ability_cost: 5,
ability_uses: 1,
ability_verbal: "Divine Intervention! Spell {type}...",
ability_tier_limit: null,
}

export const martyrdom =
{ability_name: "Martyrdom",
ability_text: "Your god will not tolerate the inaction of their servants, and it is your life in the balance. You must Touch an ally, and on completion of a five Second %%Martyrdom%% Count, you are subjected to Waste 30, and your Health is reduced to one. That ally's Health is set to its maximum, and all negative Conditions on them are Countered.",
pillar_name: "Cleric",
prerequesite: "Expel Corruption",
ability_tags:["Spell", "Self", "5 Count", "Counter", "Waste"],
ability_cost: 5,
ability_uses: 1,
ability_verbal: null,
ability_tier_limit: null,
}

export const revivify =
{ability_name: "Revivify",
ability_text: "The gods are merciful and cleanse the corrupted broken bodies of the faithful. You must incant the Verbal and may then throw a %%Spell Counter All%% packet.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Spell", "Verbal", "Packet", "Counter"],
ability_cost: 5,
ability_uses: 1,
ability_verbal: "Revivify! Spell Counter All...",
ability_tier_limit: null,
}

//Tierless
export const clericCapstoneAbility = 
{ability_name: "Cleric Capstone",
ability_text: "If you have taken Lay on Hands, twice per Day you may deliver a %%Spell {damage} Healing%% or %%Spell {damage} Harm%% strike that utilizes your pool from Lay on Hands, and like that effect, it has no limit as to how much or little damage you choose to use from your pool.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["skill", "healing", "10 count"],
ability_cost: 6,
ability_uses: 2,
ability_tier_limit: null,
}

export const clericExpertiseAbility = 
{ability_name: "Armor of Faith",
ability_text: "Your faith protects your mind and body to a supernatural degree. Your god takes good care of its tools, and would rather see you channeling their force in the world than see you lying dead in a heap. You gain five temporary Armor at the start of each Encounter. For each additional purchase of this Expertise, that number increases by five.",
pillar_name: "Cleric",
prerequesite: null,
ability_tags:["Passive"],
ability_cost: null,
ability_uses: null,
ability_tier_limit: 1,
}
//Tier 1

export const restorativePotion = {
    ability_name: "Restorative Potion",
    ability_text: "You have made a powerful restorative agent, imbuing its drinker with new vitality. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill 10 Heal%% or %%Skill 10 Harm%%. The target additionally gains five temporary Health.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "heal/harm"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const stabilizedHealDraught = {
    ability_name: "Stabilized Healing Draught",
    ability_text: "This potion is much more stable than your normal concoctions such that you trust another to use it properly. You must Touch another creature, and complete a five Second %%Granting%% Count to grant its usage. Once before the start of the next Encounter, that creature may complete a five second %%Pouring%% count and may then call %%Skill 5 Heal%% or %%Skill 5 Harm%%.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "heal/harm"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 2

export const balanceHumors = {
    ability_name: "Balance Humors",
    ability_text: "There are many ways to maximize your biological potential and all of them bear certain risks. Upon completion of a five second %%Balancing%% Count, you gain ten temporary Health.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "self", "5 count"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const excoriator = {
    ability_name: "Excoriator",
    ability_text: "You have invented a concoction that allows you to dissolve complex magical effects. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call one of the following: %%Skill Counter Blight%%, %%Skill Counter Slow%%, %%Skill Counter Waste%%, or %%Skill Counter Wither%%.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "counter"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 3

export const greaterRestorePotion = {
    ability_name: "Greater Restorative Potion",
    ability_text: "You have made a powerful restorative agent, imbuing its drinker with new vitality. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill 30 Heal%% or %%Skill 30 Harm%%. The target additionally gains fifteen temporary Health.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "heal/harm"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const stabilizedHealTincture = {
    ability_name: "Stabilized Healing Tincture",
    ability_text: "This potion is stable enough for even an idiot to use to great effect. You must Touch another creature and complete a five Second %%Granting%% Count to grant its usage. Once before the start of the next Encounter, that creature may complete a five second %%Pouring%% count and may then call %%Skill 10 Heal%% or %%Skill 10 Harm%%.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "heal/harm"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 4

export const invigorateHumors = {
    ability_name: "Invigorate Humors",
    ability_text: "There are many ways to maximize your biological potential and all of them bear certain risks. Upon completion of a five second %%Balancing%% Count, you gain Twenty Five temporary Health.",
    pillar_name: "Pharmacist",
    prerequisite: "Balance Humors",
    ability_tags: ["skill", "self", "5 count"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const fortifySoul = {
    ability_name: "Fortify Soul",
    ability_text: "This Tonic uses alchemical properties to reinforce the binding fabric between the soul and body. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may grant them one %%Resist%% that may be used against the Life or Death condition. This Skill is a Tonic, and only one Tonic may be applied to a creature at a time.",
    pillar_name: "Pharmacist",
    prerequisite: "Fortify Blood",
    ability_tags: ["skill", "touch", "5 count", "tonic"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 5

export const preventativeMeasures = {
    ability_name: "Preventative Measures",
    ability_text: "This Tonic creates a reservoir of emergency healing energy within your target, activating upon their untimely demise. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, before the next encounter the first time the Target’s dying count reaches 60, instead of being reduced to dead status, they instead are returned to 10 health. This Skill is a Tonic, and only one Tonic may be applied to a creature at a time.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "Tonic"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const fortifyBones = {
    ability_name: "Fortify Bones",
    ability_text: "This Tonic immediately increases the density of the drinker’s bones, allowing them to make strikes otherwise impossible. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, the Target’s next five Swings may call %%Skill {Damage} Pierce%% instead of their normal damage type. This Skill is a Tonic, and only one Tonic may be applied to a creature at a time.",
    pillar_name: "Pharmacist",
    prerequisite: "Fortify Skin",
    ability_tags: ["skill", "touch", "5 count", "Tonic"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tierless

export const pharmacistCapstoneAbility = {
    ability_name: "Pharmacist Champion",
    ability_text: "You gain ten additional health. On completion of a thirty second %%Mixing%% Count, you may grant yourself five temporary health at any time.",
    pillar_name: "Pharmacist",
    prerequesite: null,
    ability_tags: ["skill", "self", "30 count"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const pharmacistExpertiseAbility = {
    ability_name: "Healing Tincture",
    ability_text: "Your skills enable you to make a stable concoction that allows you to both heal and fortify. You must Touch another creature, and on completion of a five Second %%Pouring%% Count, you may call %%Skill 5 Heal%% or %%Skill 5 Harm%%. Additionally, the target gains two temporary Health.",
    pillar_name: "Pharmacist",
    prerequisite: null,
    ability_tags: ["skill", "touch", "5 count", "heal/harm",],
    ability_cost: null,
    ability_uses: 1,
    ability_tier_limit: 1,
}

import { Box, Button, Divider, Grid, ListItem, ListItemButton, Paper, Stack, Typography } from '@mui/material';
import { createContext, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { activeCharacterContext, charactersContext, offlineModeContext, pillarInventoryContext, relevantModalObjContext } from '../../App';
import { characterNameSizer, findPillarsByCharacter } from '../../processing/DataFormattingUtils';
import { saveToSource } from '../../processing/control/Controller';
import NewCharacterSelect from '../NewCharacter/NewCharacterSelect';
import { deleteCharacterOpenContext } from './CharacterSelect';
import DeleteCharacterModal from './DeleteCharacterModal';
import ManageCharacterModal from './ManageCharacter/ManageCharacterModal';

import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import ConstructionIcon from '@mui/icons-material/Construction';

export const newCharacterModalContext = createContext();
export const manageCharacterModalContext = createContext();
export const originalCharacterContext = createContext();

function CharacterSelectSummary() {
    const { characters, setCharacters } = useContext(charactersContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext)
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { offlineMode, setOfflineMode } = useContext(offlineModeContext);
    const { deleteCharacterOpen, setDeleteCharacterOpen } = useContext(deleteCharacterOpenContext);

    const [newCharacterModal, setNewCharacterModal] = useState(false);
    const [manageCharacterModal, setManageCharacterModal] = useState(false);
    const [originalCharacter, setOriginalCharacter] = useState({});
    const [deleteFunction, setDeleteFunction] = useState();

    const openManageCharacterModal = (character) => {
        setRelevantModalObj(character);
        setManageCharacterModal(true);
    }


    const navigate = useNavigate();

    return (
        <newCharacterModalContext.Provider value={{ newCharacterModal, setNewCharacterModal }}>
            <originalCharacterContext.Provider value={{ newCharacterModal, setNewCharacterModal }}>
                <manageCharacterModalContext.Provider value={{ manageCharacterModal, setManageCharacterModal }}>
                    <Box maxWidth="s" sx={{ maxWidth: '1200px', margin: '0 auto', mt: '5px', mb: '15px' }}>
                        <DeleteCharacterModal />
                        <ManageCharacterModal />
                        {characters.map(character => {

                            const removeCharacter = () => {
                                const sanitizedCharacters = characters.filter((toon) => toon.character_name !== character.character_name);
                                const sanitizedPillarInventory = pillarInventory.filter((pb) => pb.characterId !== character.id);
                                saveToSource(offlineMode, 'pillarInventory', sanitizedPillarInventory);
                                saveToSource(offlineMode, 'characters', sanitizedCharacters);
                                setPillarInventory(sanitizedPillarInventory);
                                setCharacters(sanitizedCharacters);
                            }

                            const openCharacter = (event) => {
                                event.preventDefault();
                                setOriginalCharacter(character);
                                setActiveCharacter(character);
                                navigate("/character/" + characters.indexOf(character));
                            }

                            const summarizeCharacter = (event) => {
                                event.preventDefault();
                                setActiveCharacter(character);
                                navigate("/summary/");
                            }

                            return (
                                <Box>
                                    <Stack direction="column">
                                        <ListItem key={character.character_name} disablePadding>
                                            <ListItem key={character.character_name + "button"} divider={true}>
                                                <Grid container direction="column">
                                                    <Grid key={character.character_name + "-summary-container"} container item columns={2} spacing={1} justifyContent='space-evenly' alignItems={"center"} direction="row" sx={{ color: '#ffffff', bgcolor: '#121212', borderRadius: '10px', margin: "0 auto", padding: '20px 0 20px', width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' } }}>
                                                        <Grid key={character.character_name + "1"} item xs={1}>
                                                            <Paper key={character.character_name + "summary1"} variant="outlined" elevation={0} sx={{ border: 'none', bgcolor: 'none' }}>
                                                                {card1(character, pillarInventory)}
                                                            </Paper>
                                                        </Grid>
                                                        <Grid key={character.character_name + "2"} item xs={1}>
                                                            <Paper key={character.character_name + "summary2"} variant="outlined" elevation={0} sx={{ border: 'none', bgcolor: 'none' }}>
                                                                {card2(character)}
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                    <Paper key={character.character_name + "3"} item xs={1} variant="outlined" elevation={0} sx={{ border: 'none', bgcolor: 'none' }}>
                                                        <Grid container item xs={12} spacing={1}>
                                                            <Grid container xs={12} spacing={1} item direction="column" justifyContent={"center"} alignItems={"center"}>
                                                                <Grid sx={{width:"100%"}} item>
                                                                    <Button xs={12} sx={{ width: "100%", py: "1rem" }} variant='contained' color='success' onClick={(event) => openCharacter(event)}>
                                                                        Build Character <ConstructionIcon sx={{ml:"0.5rem"}}/>
                                                                    </Button>
                                                                </Grid>
                                                                <Grid container item spacing={1} direction="row" justifyContent={"space-between"}>
                                                                    <Grid xs={6} item>
                                                                        <Button sx={{ width: "100%" }} variant='contained' color='primary' onClick={() => openManageCharacterModal(character)}>
                                                                            Manage <EditIcon sx={{ ml: "0.5rem" }} />
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid xs={6} item>
                                                                        <Button sx={{ width: "100%" }} disabled={( character.pillar_builds != null && character.pillar_builds.length < 1) ? true : false} variant='contained' color='primary' onClick={(event) => summarizeCharacter(event)}>
                                                                            Summary <ListIcon sx={{ ml: "0.5rem" }} />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                            </ListItem>
                                        </ListItem>
                                    </Stack>
                                    <Divider fullwidth />
                                </Box>
                            )
                        })}
                        <NewCharacterSelect />
                    </Box >
                </manageCharacterModalContext.Provider>
            </originalCharacterContext.Provider>
        </newCharacterModalContext.Provider>
    );
}

function card1(character, pillarInventory) {
    const filteredPillars = findPillarsByCharacter(character, pillarInventory);
    return (
        <Grid container sx={{ ml: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: '900', fontSize: characterNameSizer(character.character_name), inlineSize: '150px', overflowWrap: 'break-word' }}>
                {character.character_name}
            </Typography>
            <Grid container spacing={0.25} direction="column" sx={{ marginTop: '-10px' }}>
                <Grid item>
                    <Typography variant="body1">
                        {character.heritage.heritage_name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        Essence: {character.life_total}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={.5} justifyContent={filteredPillars.length > 1 ? "space-evenly" : "flex-start"} direction="row" >
                {filteredPillars.map(pillarBuild => {
                    return (
                        <Grid item key={pillarBuild.pillar_name + character.id + pillarBuild.pillar_number}>
                            <Typography color="info.main" variant="caption" sx={{ fontSize: '.65rem', style: "italic" }} component="div" >
                                {pillarBuild.pillar_name}
                            </Typography>
                        </Grid>
                    )
                })}
            </Grid>
            <Typography variant="h6" component="div">
                Unspent XP: {character.xp_unspent}
            </Typography>
        </Grid>
    )
}

function card2(character) {
    return (
        <Grid container alignItems={"center"} alignContent={"center"} direction="column" justifyContent="center" sx={{ margin: '0', mt: '-20px', padding: '0' }}>
            <Grid item>
                <Typography variant="h6" textAlign="center" component="div" sx={{ fontWeight: 'bold', color: '#A1A1A1', marginTop: '25px' }}>
                    LEVEL
                </Typography>
            </Grid>
            <Grid item>
                <Typography color="success.main" sx={{ fontWeight: '900', fontSize: '3.5rem' }} variant="h3" textAlign="center" component="div">
                    {character.level}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6" textAlign="center" component="div" sx={{ fontSize: '1rem' }}>
                    XP: {character.xp_total}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default CharacterSelectSummary;


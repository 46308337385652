import { Box, Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { relevantModalObjContext } from "../../App";
import { emptyItemsEq, highLevelItemsInv, highLevelItemsSmall } from "../../mocks/MockItemInventory";
import { emptyItem } from "../../mocks/MockItems";
import { itemSelectModalContext } from "../CharacterScreen/CharacterScreen";
import ItemSlot from "./ItemSlot";

//This component shows all items you currently have equipped.
function Equipment() {

    const [itemsInventory, setItemsInventory] = useState([]);
    const [itemsEquipped, setItemsEquipped] = useState(highLevelItemsSmall);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { itemSelectModal, setItemSelectModal } = useContext(itemSelectModalContext);

    const generateItemDisplayArray = (equipped) => {
        const arr = [...emptyItemsEq.equipped_items];
        if (equipped.length > 0) {
            equipped.forEach((element, index) => {
                arr.splice(index, 1, element)
            });
        }
        return arr;
    }

    const generateGuardianDisplayArray = (equipped) => {
        const arr = [...emptyItemsEq.equipped_guardians];
        if (equipped.length > 0) {
            equipped.forEach((element, index) => {
                arr.splice(index, 1, element)
            });
        }
        return arr;
    }

    const setItemModal = (item) => {
        setRelevantModalObj(item);
        setItemSelectModal(true);
    }

    return (
        <Box sx={{ maxWidth: 'sm'}} >
            <Typography align={'left'}>
                Bound Items
            </Typography>
            <Divider bottomGutter/>
            <Grid container spacing='.25' direction='column' justify-content='center'>
                {
                    generateItemDisplayArray(itemsEquipped.equipped_items).map((item, index) => {
                        return <ItemSlot type='item' key={`key-${item.identifier}-${index}`} item={item} itemNumber={index + 1} onClick={() => setItemModal(item)} />
                    })}
            </Grid>
            <Divider bottomGutter/>
            <Typography align={'left'}>
                Guardians and Boons
            </Typography>
                        <Grid container spacing='.25' direction='column' justify-content='center'>
                {
                    generateGuardianDisplayArray(itemsEquipped.equipped_guardians).map((item, index) => {
                        return <ItemSlot type='guardian' key={`key-${item.identifier}-${index}`} item={item} itemNumber={index + 1} onClick={() => setItemModal(item)} />
                    })}
            </Grid>
        </Box>
    )
}

export default Equipment;
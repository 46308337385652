import { Close } from "@mui/icons-material";
import { Fade, Grid, IconButton, Modal, Paper } from "@mui/material";
import { useContext } from "react";
import { relevantModalObjContext } from "../../App";
import { itemSelectModalContext } from "../CharacterScreen/CharacterScreen";
import ItemSelectModalHeader from "./ItemSelectModalHeader";

function ItemSelectModal() {

    const { itemSelectModal, setItemSelectModal } = useContext(itemSelectModalContext);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);

    const closeModal = () => {
        setItemSelectModal(false)
        setRelevantModalObj('');
    }

    return (
        <Modal
            open={itemSelectModal}
            aria-labelledby="item-select-modal"
            aria-describedby="modal-selects-items"
            sx={{ overflow: "scroll", width: '100%' }}
            closeAfterTransition
        >
            <Fade in={itemSelectModal} timeout={200}>
                <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%' }}>
                    <Grid item sx={{ maxWidth: 'sm' }}>
                        <Paper sx={{ m: '10px', borderRadius: '10px', p: '25px' }}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <IconButton onClick={closeModal}>
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <ItemSelectModalHeader item={relevantModalObj} />
                        </Paper>
                    </Grid>
                </Grid>
            </Fade>
        </Modal>
    );
}

export default ItemSelectModal;
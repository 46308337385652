const automata = {
    concept_name: "Automata",
    concept_type: "Creature Type",
    concept_text: "Constructs made of inanimate matter, Automata follow specific programmed decision trees to perform tasks ranging from gardening to soldiery.$$ Automata can range from the simplest animated hunk of iron to a creation of the most complex artifice."
}

const beast = {
    concept_name: "Beast",
    concept_type: "Creature Type",
    concept_text: "Beasts are the natural creatures of Daear.$$ Many of these creatures have an origin in the tinkering of arcanists and alchemists, however they have found niches and formed stable populations, making them as much a part of the world as any other creature."
}

const celestial = {
    concept_name: "Celestial",
    concept_type: "Creature Type",
    concept_text: "The gods created for themselves many servants, collectively known as the Celestials.$$ Celestials often, but not always, have human or superhuman intelligence, and may have survived the death of their creator god. Some still serve their creators' every whim."
}

const colossi = {
    concept_name: "Colossi",
    concept_type: "Creature Type",
    concept_text: "Colossi are titanic creatures of any variety.$$ While they are biologically distinct, their sheer size makes them fall into a catagory all their own. These creatures rarely find ecological niches, and instead function more as walking 'natural' disasters."
}

const cryptid = {
    concept_name: "Cryptid",
    concept_type: "Creature Type",
    concept_text: "Thinking, unnatural creatures that have made secret niches for themselves.$$ Cryptids are often the subject of myths and stories told by the locals. While cryptids sometimes find cults or other local groups who support them, most are simply treated as monsters to be feared or hunted."
}

const drakinoid = {
    concept_name: "Drakinoid",
    concept_type: "Creature Type",
    concept_text: "Semi-reptillian animals that have been enhanced with magical powers.$$ Drakinoids come in a myriad of winged, legged, and even wyrm varieties, all distinct in their behaviors and intelligence. All of them have incredible durability, and are often enhanced with the ability to project magic from their very bodies."
}

const endless = {
    concept_name: "@@The Endless@@",
    concept_type: "Creature Type",
    concept_text: "Entities made of white light, poorly understood and murderous.$$ The Endless are unlike any other creatures in Daear, and debate still rages whether or not they constitute a creature type at all. Almost nothing is known about their nature, origins, intelligence, or goals."
}

const undead = {
    concept_name: "Undead",
    concept_type: "Creature Type",
    concept_text: "Unliving creatures who harbor nothing but hate in their hearts for all living things.$$ The Undead are the inevitable fate of all Revenants, as the fate that leads them to resurrect does not cease even after their minds and souls have degraded into psychopathy or bestial rage at all things living."
}

const vegetation = {
    concept_name: "Vegetation",
    concept_type: "Creature Type",
    concept_text: "Once natural flora, Vegetation are creatures who have been twisted into violent and dangerous mobile plants.$$ Vegetation do not include your typical trees, but instead those that have been mutated or otherwise developed more animal-like behaviors whilst still maintaining their biology."
}

const weird = {
    concept_name: "Weird",
    concept_type: "Creature Type",
    concept_text: "Creatures composed entirely of magical energy, that are almost always intelligent.$$ Ranging from the mischevious Pixies to the incinerating Phoenixes, Weirds almost always represent a concept made material through magical means."
}

export const creatureTypes = [automata, beast, celestial, colossi, cryptid, drakinoid, endless, undead, vegetation, weird]
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, List, ListItem, ListItemButton, Stack, Tooltip, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createContext, useContext, useState } from "react";
import { activeCharacterContext, charactersContext, pillarInventoryContext, relevantModalObjContext } from "../../App";
import { theme } from "../../styles/AppTheme";
import AbilityModal from '../Ability/AbilityModal/AbilityModal';
import CapstoneModal from '../Capstone/CapstoneModal/CapstoneModal';
import { buildModeContext, relevantPillarInventoryContext } from '../CharacterScreen/CharacterScreen';
import ExpertiseModal from '../Expertise/ExpertiseModal/ExpertiseModal';
import PillarBuildRemoveModal from './PillarBuildRemoveModal';
import { createRenderablePillar, generateEmptyAbility, renderAbilityTiers, renderCapstone, renderExpertise, respendFalsifier } from './PillarBuildUtils';
import CloseIcon from '@mui/icons-material/Close';
import { borderColor } from '@mui/system';
import { findConcept, formatTextBlob } from '../../processing/DataFormattingUtils';

export const abilityModalOpenContext = createContext(false);
export const expertiseModalOpenContext = createContext(false);
export const capstoneModalOpenContext = createContext(false);
export const pbRemoveModalContext = createContext(false);
export const originalAbilityContext = createContext({});


function PillarBuild() {
    const { characters, setCharacters } = useContext(charactersContext);
    const { buildMode, setBuildMode } = useContext(buildModeContext);
    const { pillarInventory, setPillarInventory } = useContext(pillarInventoryContext)
    const { renderablePillars, setRenderablePillars } = useState({});
    const [abilityModalOpen, setAbilityModalOpen] = useState(false);
    const [expertiseModalOpen, setExpertiseModalOpen] = useState(false);
    const [capstoneModalOpen, setCapstoneModalOpen] = useState(false);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext)
    const { relevantPillarInventory, setRelevantPillarInventory } = useContext(relevantPillarInventoryContext);
    const [originalAbility, setOriginalAbility] = useState({});
    const [pbRemoveModal, setPbRemoveModal] = useState(false);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);

    return (relevantPillarInventory.map((pillarBuild, index) => {
        const renderablePillar = createRenderablePillar(pillarBuild, index);
        const renderableTierArray = [renderablePillar.tier1, renderablePillar.tier2, renderablePillar.tier3, renderablePillar.tier4, renderablePillar.tier5]
        const renderableExpertise = renderablePillar.expertise;

        return (
            <ThemeProvider theme={theme}>
                <Box sx={{ margin: '10px 0', maxWidth: 'sm' }}>
                    <abilityModalOpenContext.Provider value={{ abilityModalOpen, setAbilityModalOpen }}>
                        <expertiseModalOpenContext.Provider value={{ expertiseModalOpen, setExpertiseModalOpen }}>
                            <capstoneModalOpenContext.Provider value={{ capstoneModalOpen, setCapstoneModalOpen }}>
                                <pbRemoveModalContext.Provider value={{ pbRemoveModal, setPbRemoveModal }}>
                                    <originalAbilityContext.Provider value={{ originalAbility, setOriginalAbility }}>
                                        {relevantModalObj.ability_name ? <AbilityModal /> : null}
                                        {relevantModalObj.expertise_name ? <ExpertiseModal /> : null}
                                        {relevantModalObj.capstone_ability ? <CapstoneModal /> : null}
                                        {relevantModalObj.pillar_name ? <PillarBuildRemoveModal /> : null}
                                        <Accordion elevation={0} sx={{maxwidth:"xs"}}>
                                            <AccordionSummary sx={pillarBuild.pillar_number < 3 ? { border: '2px solid', borderColor: "warning.dark", fontWeight: 'bold' } : { border: '2px solid #2A2A2A', fontWeight: 'bold' }}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Grid container direction="row" justifyContent="space-between">
                                                    {pillarBuild.pillar_number < 3 ?
                                                        <Grid item >
                                                            <Tooltip title={formatTextBlob(findConcept("Focus Pillar").concept_text)}>
                                                                <Typography sx={{ color: "warning.dark" }}>Focus</Typography>
                                                            </Tooltip>
                                                        </Grid> :
                                                        <Grid item>
                                                            <Tooltip title={formatTextBlob(findConcept("Support Pillar").concept_text)}>
                                                                <Typography>Support</Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    }
                                                    <Grid item>
                                                        <Typography sx={{ mr: '10px' }}> Pillar {pillarBuild.pillar_number}: {pillarBuild.pillar_name}</Typography>
                                                    </Grid>
                                                </Grid>

                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: '15px 5px 16px;', border: '2px solid #2A2A2A' }}>
                                                <Box>
                                                    <Grid container direction="row" justifyContent="space-between" sx={{ paddingBottom: '10px', fontWeight: 'bold', fontSize: '1rem', pl: '5%', pr: '3%' }}>
                                                        <Grid item>
                                                            <Typography variant="caption" sx={{ pr: "20px", fontWeight: 'bold', fontSize: '1rem' }}>Abilities</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="caption" sx={{ pr: "20px", fontWeight: 'bold', fontSize: '1rem', paddingRight: '0px' }}>Expertise</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Stack direction="row" spacing={.5}>
                                                        <Grid container spacing={.5} justify="center" >
                                                            {renderAbilityTiers(pillarBuild, activeCharacter.xp_unspent, buildMode, renderableTierArray, originalAbility)}
                                                        </Grid>
                                                        <Stack direction="column" spacing={.5}>
                                                            {renderExpertise(pillarBuild, activeCharacter.xp_unspent, buildMode, renderableExpertise)}
                                                        </Stack>
                                                    </Stack>
                                                    <Grid item>
                                                        {pillarBuild.pillar_number < 3 ? renderCapstone(pillarBuild, renderablePillar, buildMode) : null}
                                                    </Grid>
                                                    <Divider sx={{ m: '10px' }} />
                                                    <Grid item>
                                                        {(buildMode && respendFalsifier(pillarBuild))?
                                                            <List>
                                                                <ListItem button key={"new-character-list-" + index} sx={{ margin: "0 auto" }} disablePadding onClick={() => {
                                                                    setRelevantModalObj(pillarBuild)
                                                                    setPbRemoveModal(true)
                                                                }}>
                                                                    <ListItemButton key={"new-character-button-" + index} sx={{ margin: "0 auto", bgcolor: '#121212', border: '2px', borderStyle: 'solid', borderColor: "error.main", borderRadius: '10px', py: '10px', width: '300px' }} divider={true}>
                                                                        <Grid container justifyContent="center" alignContent="center">
                                                                            <Grid item>
                                                                                <Stack direction='row' spacing={1}>
                                                                                <Typography variant='button' color='error.main'>Remove Pillar</Typography>
                                                                                    <CloseIcon color="error" sx={{ minWidth: '25px', minHeight: '25px' }} />
                                                                                </Stack>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            </List>
                                                            :
                                                            null
                                                        }
                                                    </Grid>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </originalAbilityContext.Provider>
                                </pbRemoveModalContext.Provider>
                            </capstoneModalOpenContext.Provider>
                        </expertiseModalOpenContext.Provider>
                    </abilityModalOpenContext.Provider>
                </Box>
            </ThemeProvider >
        )
    }))
}

export default PillarBuild;
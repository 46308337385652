const artificer ={
  name: "Artificer",
  description: "Artificers are able to create the most advanced and powerful physical objects in Daear and are capable of Masterworking them. Masterwork must be executed when an item is created, but can increase Runic Capacity, increase damage potential, improve armor total, and apply other effects.",
  component: "Hexite",
  ability_name: "Rework Armor",
  ability_description:"The Artificer fully repairs the armor of one willing creature they can touch. This process is not interrupted by damage, and may be done while moving."
}
const enchanter ={
  name: "Enchanter",
  description: "Enchanters are able to fill items with magical runes which allows them to channel the magic of the Pillars, or empty the items  again. Enchanters can cause items to do additional damage to specific monster types, allow users to use more abilities, grant additional defenses, and impart powerful curses.",
  component: "Crystalized Mana",
  ability_name: "Restore Mana",
  ability_description:"The Enchanter restores the use of one ability, of a tier no higher than their enchanter level, to one willing creature they can touch. If the restored ability is level two or below, one crystal is used. If it is level three or four, two are used. If it is level 5, three are used. This process is interrupted by damage."
}
const oracle ={
  name: "Oracle",
  description: "Oracles are invokers of divine power and foresight, granting their charges powerful blessings and boons. A blessing is a temporary benefit that greatly increases a character’s statistics, but only for a single event. A boon is similar, but instead disappears only when the user chooses to unbind from it. These bonuses can extend to resistances, additional health, bonus damage, and many more effects.",
  component: "Sacred Candle",
  ability_name: "Restore Health",
  ability_description:"The Oracle fully restores the health of one willing target they can touch. This process must be stationary and is interrupted by damage.."
}
const taskmaster ={
  name: "Taskmaster",
  description: "Taskmasters are the quintessential beast-tamers, summoners, or warlords. They are capable of attracting mercenaries to fight for them, enthralling monsters to serve them, or summoning powerful creatures to fight for them. While mercenaries are simply hired soldiers, enthralled or summoned creatures are bound to the soul of their master, granting minor benefits whilst defending their homes.",
  component: "Petrified Dragon Tooth",
  ability_name: "Possession",
  ability_description:"The Taskmaster takes possession of one creature bound to them. They must leave behind a body-sized physical representation of their unconscious body, and wear the costume for said creature instead of their own. They utilize that creature’s statistics instead of their own, but can magically project their voice from it. If the creature dies, it is utterly destroyed, and the possessor goes back to their body. If the possessor’s body is killed while they are away, they must resurrect as normal at a waystone. This process consumes a number of Petrified Dragon’s Teeth equal to the creature’s level divided by two, rounded up. This process must be stationary and is interrupted by damage."
}
const mystic ={
  name: "Mystic",
  description: "The Mystic arts are wide and ranging, dealing often in the matters of time, space, and the soul. Mystics are the only crafters who can fortify one’s soul against death, soul-bind items to their wielder, teleport using the Waystones, scry upon far away places with psychometry, and many other unusual feats.",
  component: "Fossilized Raven Feather",
  ability_name: "Wayfind",
  ability_description:"The Mystic teleports themselves or a willing creature to a Waystone that the Mystic is familiar with. The character leaves play and walks to the location of the appropriate waystone. This process must be stationary."
}

export const ProfessionArray = [artificer, enchanter, oracle, taskmaster, mystic];
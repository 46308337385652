export function createRenderableSummaryTrait(trait, pillarBuilds) {
  let dailyCounter = 0;
  let encounterCounter = 0;
  let capstoneCounter = 0;
  pillarBuilds.forEach((pillarBuild) => {
    dailyCounter = dailyCounter +
      tallyCounter(pillarBuild.daily_abilities, trait) +
      tallyCounter([pillarBuild.daily_expertise], trait);
  })

  pillarBuilds.forEach((pillarBuild) => {
    encounterCounter = encounterCounter +
      tallyCounter(pillarBuild.encounter_abilities, trait) +
      tallyCounter([pillarBuild.encounter_expertise], trait);
  })

  pillarBuilds.forEach((pillarBuild) => {
    if (pillarBuild.capstone !== null) {
      capstoneCounter = capstoneCounter +
        tallyCounter([pillarBuild.capstone.capstone_ability], trait);
    }
  })

  return ((dailyCounter * trait.trait_multiplier) + (encounterCounter * trait.trait_encounter_multiplier) + capstoneCounter * trait.trait_multiplier);
}

function tallyCounter(inputAbilities, trait) {
  let count = 0;
  if (inputAbilities.length > 0 && inputAbilities[0] != null) {
    inputAbilities.forEach((looper) => {
      trait.trait_ability.forEach((traitAbility) => {
        if (looper.ability != null && looper.ability.ability_name === traitAbility) { count = count + looper.ability_count }
        if (looper.expertise != null && looper.expertise.expertise_name === traitAbility) { count = count + looper.expertise_count }
      })
    })
  }
  return count;
}

export function determineSummaryHealth(pillarBuilds, professions) {
  let totalHp = 0;
  if (professions && professions.length > 0) {
    professions.forEach((profession) => { totalHp = totalHp + profession.profession_level })
  }
  if (pillarBuilds != null && pillarBuilds.length > 0) {
    pillarBuilds.forEach((pillarBuild) => {
      totalHp = totalHp + pillarBuild.pillar_level
      if (pillarBuild.daily_expertise != null && pillarBuild.daily_expertise.expertise_count > 0) {
        const relevantExpertise = pillarBuild.daily_expertise;
        if (relevantExpertise.expertise.expertise_name === "Toughness") { totalHp = totalHp + (2 * relevantExpertise.expertise_count) }
        if (relevantExpertise.expertise.expertise_name === "Constitution") { totalHp = totalHp + (4 * relevantExpertise.expertise_count) }
      }
      if (pillarBuild.encounter_expertise != null && pillarBuild.encounter_expertise.expertise_count > 0) {
        const relevantExpertise = pillarBuild.encounter_expertise;
        if (relevantExpertise.expertise.expertise_name === "Toughness") { totalHp = totalHp + (2 * relevantExpertise.expertise_count) }
        if (relevantExpertise.expertise.expertise_name === "Constitution") { totalHp = totalHp + (4 * relevantExpertise.expertise_count) }
      }
      if (pillarBuild.daily_abilities != null) {
        pillarBuild.daily_abilities.forEach((pbAbility) => {
          if (pbAbility.ability.ability_name != null && pbAbility.ability.ability_name === "Fortitude") { totalHp = totalHp + 5 }
        })
      }
      if (pillarBuild.capstone !== null && pillarBuild.capstone.capstone_name === "Pharmacist Champion") {
        totalHp = totalHp + 10
      }
    })
  }
  if (totalHp > -1) {
    return totalHp;
  }
  else {
    return 0;
  }
}

export function determineSummaryArmorCap(pillarBuilds) {
  let armorCap = 10;
  if (pillarBuilds != null && pillarBuilds.length > 0) {
    pillarBuilds.forEach((pillarBuild) => {
      if (pillarBuild.daily_expertise != null && pillarBuild.daily_expertise.expertise_count > 0) {
        const relevantExpertise = pillarBuild.daily_expertise;
        if (relevantExpertise.expertise.expertise_name === "Toughness") { armorCap = armorCap + (5 * relevantExpertise.expertise_count) }
        if (relevantExpertise.expertise.expertise_name === "Dirty Deeds") { armorCap = armorCap + (2 * relevantExpertise.expertise_count) }
      }
      if (pillarBuild.encounter_expertise != null && pillarBuild.encounter_expertise.expertise_count > 0) {
        const relevantExpertise = pillarBuild.encounter_expertise;
        if (relevantExpertise.expertise.expertise_name === "Toughness") { armorCap = armorCap + (5 * relevantExpertise.expertise_count) }
        if (relevantExpertise.expertise.expertise_name === "Dirty Deeds") { armorCap = armorCap + (2 * relevantExpertise.expertise_count) }
      }
      if (pillarBuild.capstone !== null && pillarBuild.capstone.capstone_name === "Soldier Champion") { armorCap = armorCap + 10 }
    })
  }
  return armorCap;
}

export function determineSummaryDamage(pillarBuilds) {
  let damage = 1;
  if (pillarBuilds != null && pillarBuilds.length > 0) {
    pillarBuilds.forEach((pillarBuild) => {
      pillarBuild.daily_abilities.forEach((pbAbility) => {
        if (pbAbility.ability.ability_name === "Weapons Proficiency") { damage = damage + 1 }
      })
      pillarBuild.encounter_abilities.forEach((pbAbility) => {
        if (pbAbility.ability.ability_name === "Weapons Proficiency") { damage = damage + 1 }
      })
    })
  }
  return damage;
}
import axios from "axios"
require('dotenv').config()

const baseUrl = process.env.REACT_APP_FIVE_PILLARS_SERVER_ROOT;
let headers = {};
if (localStorage.token) {
    headers.Authorization = `Bearer ${localStorage.token}`;
}

const instance = axios.create({
    timeout: 5000,
});
instance.defaults.baseURL = baseUrl;
instance.defaults.headers.post['Content-Type'] = 'application/json';

//Login functionality
export function testUserLogin() {
    const loginRequest = {
        username: "fivepillarslarp@gmail.com",
        password: "password"
    }
    return (instance.post("/api/login/", JSON.stringify(loginRequest)));
}

export function googleUserLogin() {
    return (instance.get("/api/login/"));
}

export function googleUserCreate() {
    return (instance.get("/api/user/create"));
}

export function googleUserLogout() {
    return (instance.get("/api/logout"));
}

//Character Ops
export function findCharacterByName(character) {
    const characterName = character.character_name;
    return (instance.post("/api/character/findByCharacterName", JSON.stringify({ character_name: characterName })));
}

export function createCharacter(character) {
    return (instance.post("/api/character/", JSON.stringify(character)));
}

export function updateCharacter(character) {
    return (instance.put("/api/character/", JSON.stringify(character)));
}

export function deleteCharacter(id) {
    return (instance.delete("/api/character/" + id));
}

export function submitServiceAssignment(serviceSub) {
    return (instance.post("/api/character/service/", JSON.stringify(serviceSub)));
}

export function submitAudit(id) {
    return (instance.get("/api/character/audit/" + id))
}

//PB ops
export function postPillarBuild(pillarBuilds) {
    return (instance.post("/api/build/creator/", JSON.stringify(pillarBuilds)));
}

export function updatePillarBuild(pillarBuild) {
    return (instance.put("/api/build/", JSON.stringify(pillarBuild)));
}

export function deletePillarBuild(id) {
    return (instance.delete("/api/build/" + id));
}

export function updateProfessions(professions) {
    return (instance.put("/api/profession/", JSON.stringify(professions), {
        headers: {
            'Content-Type': 'apllication/Json'
        }
    }));
}

//Permissions functionality
export function isUserSuper() {
    return (instance.get("/api/admin/isSuper/"));
}

export function isUserAdmin() {
    return (instance.get("/api/admin/isAdmin/"));
}

//Admin functionality

export function getBankByCharacterId(charId){
    console.log(charId);
    console.log("/api/bank/character/" + charId);
    return (instance.get("/api/bank/character/" + charId))
}

export function updateBankById(id, body){
    return (instance.post("/api/bank/" + id, JSON.stringify(body)))
    
}

export function adminGet(url) {
    return (instance.get(url));
}

export function adminPost(url, body) {
    return (instance.post(url, body));
}

export function adminPut(url, body) {
    return (instance.put(url, body));
}

export function adminDelete(url, body) {
    return (instance.delete(url, body));
}
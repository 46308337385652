
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { conceptArray } from '../../data/glossary/Concepts';
import { currentConceptListContext, searchErrorContext } from './Glossary';

function GlossaryFilter() {

    const { currentConceptList, setCurrentConceptList } = useContext(currentConceptListContext);
    const { searchError, setSearchError } = useContext(searchErrorContext);
    const [value, setValue] = useState("");
    const [filter, setFilter] = useState("");

    const handleSearch = (searchValue) => {
        if (searchValue !== undefined || searchValue !== "" || searchValue !== null) {
            setSearchError("");
            setFilter("");
            const filteredArray = conceptArray.filter((concept) => concept.concept_name.toLowerCase().includes(searchValue.toLowerCase()) || concept.concept_text.toLowerCase().includes(searchValue.toLowerCase()));
            if (filteredArray.length === 0) {
                setSearchError("No Results");
                setCurrentConceptList([...conceptArray]);
            }
            else {
                setCurrentConceptList(filteredArray);
            }
        }
        else {
            setCurrentConceptList([...conceptArray]);
            setSearchError("Invalid Search");
        }
    }

    const handleFilter = (filterValue) => {
        setValue("");
        const filteredArray = conceptArray.filter((concept) => concept.concept_type.toLowerCase().includes(filterValue.toLowerCase()));
        if (filterValue === "All") {
            setCurrentConceptList(conceptArray);

        }
        else {
            setCurrentConceptList(filteredArray);
        }
    }

    return (
        <Box>
            <Grid container spacing={1} direciton="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={6}>
                    <FormControl sx={{ m: 1 }} variant="outlined" sx={{ float: 'left', marginLeft: '20px' }}>
                        <InputLabel htmlFor="glossary-search-bar">Search</InputLabel>
                        <OutlinedInput sx={{ bgcolor: 'rgba(255,255,255,.15)', float: 'left', }}
                            id="glossary-search-bar"
                            type={"text"}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            onKeyDown={(event) => event.keyCode === 13 ? handleSearch(value) : null}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="searchItem"
                                        onClick={() => handleSearch(value)}
                                        onMouseDown={() => handleSearch(value)}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="search-button"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl sx={{ minWidth: '100px', bgcolor: 'rgba(255,255,255,.15)', float: 'right', marginRight: '20px' }}>
                        <InputLabel>Filters</InputLabel>
                        <Select
                            labelId="Heritage"
                            id="heritage-selector"
                            value={filter}
                            label="Filter"
                            onChange={(event) => {
                                setFilter(event.target.value)
                                handleFilter(event.target.value)
                            }}
                        >
                            <MenuItem value={"All"}>Clear Filters</MenuItem>
                            <MenuItem value={"System"}>System</MenuItem>
                            <MenuItem value={"Condition"}>Condition</MenuItem>
                            <MenuItem value={"Damage Type"}>Damage Type</MenuItem>
                            <MenuItem value={"Creature Type"}>Creature Type</MenuItem>
                            <MenuItem value={"Statistics"}>Statistics</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box >
    )
}

export default GlossaryFilter;
import { ThemeProvider } from "@emotion/react";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createContext, useState } from "react";
import { conceptArray } from "../../data/glossary/Concepts";
import { theme } from "../../styles/AppTheme";
import BottomNavigator from "../BottomNavigator";
import TopBar from "../TopBar";
import TopMenuDrawer from "../TopMenuDrawer";
import GlossaryEntry from "./GlossaryEntry";
import GlossaryFilter from "./GlossaryFilter";

export const searchErrorContext = createContext(" ");
export const currentConceptListContext = createContext([...conceptArray]);

function Glossary() {

    const [expanded, setExpanded] = useState(false);
    const [currentConceptList, setCurrentConceptList] = useState([...conceptArray]);
    const [searchError, setSearchError] = useState(" ");

    const handleChange = (accordionId) => {
        expanded === false ? setExpanded(accordionId) : setExpanded(false);
    }

    const handleFilter = (filterValue) => {

        if (filterValue !== null || filterValue !== "" || filterValue !== undefined) {
            const filteredArray = currentConceptList.filter((item) => item.concept_type === filterValue);
            setCurrentConceptList(filteredArray);
        }
        else {
            setCurrentConceptList([...conceptArray]);
        }

    }

    return (
        <Box>
            <ThemeProvider theme={theme} >
                <currentConceptListContext.Provider value={{ currentConceptList, setCurrentConceptList }}>
                    <searchErrorContext.Provider value={{ searchError, setSearchError }}>
                        <Container component="main" lg={12} sx={{ mb: "50px", padding: '0 !important', marginTop:'50px' }}>
                            <Grid container spacing={2} direction="column">
                                <Grid item sx={{float:'right'}}>
                                    <GlossaryFilter/>
                                </Grid>
                                <Grid item>
                                    <Typography color="error" variant="caption">
                                        {searchError}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{maxWidth:'sm'}}>
                                {currentConceptList.map((concept, index) => {
                                    return (
                                        <Grid item sx={{ px: "20px", width: '100%' }}>
                                            <GlossaryEntry id={"Glossary-Accordion-" + concept.concept_name} key={"Glossary-Accordion-" + concept.concept_name + "-" + index} concept={concept} expanded={expanded} handleChange={handleChange} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <BottomNavigator />
                        </Container>
                    </searchErrorContext.Provider>
                </currentConceptListContext.Provider>
            </ThemeProvider>
        </Box>
    )

}

export default Glossary;
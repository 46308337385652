const human = {
    heritage_name: "Human",
    heritage_lore: "During the three thousand year Pyrrhic Age, humanity paid the blood price again and again for their past sins, to the point where some despaired that Mankind’s time may have been spent. Unable to reach the same mythic heights as the Gaelûn—the children of Eidolon (the new gods)—the greatest minds and mages of humankind banded together to prevent certain destruction.$$" + 
        "The Marbaghd, an anti-human genocide of unprecedented scale, was halted by the creation of the Gwaredûr, true-blooded humans whose might matched humanity’s heroes from tales of old. With the aid of the risen and nephilim, these soldier-saviors proved an equal match for the Gaelûn. Across myriad battlefields of the centuries, the Gwaredûr practiced the art of war until the tide was stemmed.$$" +
        "With the annihilation of mankind averted, the paragons of humanity began their long-term goal: to protect humankind from future similar efforts. Battles had been fought with various tactics, causing a conflict of opinions on mankind’s protection of their heritage. Established civilizations argued about how defense should be undertaken.$$" + 
        "The Tariȃn formed their own kingdoms, established themselves as nobles, and passed their gifts to their descendants for unified strength. The Bandûr instead integrated into every culture they could, attempting to proliferate their gifts amongst all, knowing that only on occasion would these gifts emerge amongst their lineage. The Gaelûn threat was subdued, being unable to attack single villages or whole nations without immediate reprisal, so the defense of humankind was ensured.$$" + 
        "The Pyrrhic Age and the bloody chaos that ensued therein stretched defenses thin, with Tariȃn kingdoms becoming fewer, falling to humankind’s commonplace infighting. Of these kingdoms, the greatest is doubtless Nerthôl, to whom many Tariȃn kingdoms owe their allegiance. Nerthôl’s guardian star, the Waystar, constantly watches, shining from its place in the heavens over the city as a beacon to humanity. Nerthôl has grown more open to outsiders, welcoming back its Bandûr kin as they are discovered, hoping to swell its ever-pressed armies with more Gwaredûr.$$" + 
        "All such beings rose to the challenge when @@The Endless@@ attacked in Battle of the Bacheran, what humans call the Bragodalrach, the ultimate battle of the Pyrrhic Age, and have since joined the Bacheran Pact en masse. The kings of Nerthôl staunchly support the Pact, and recognize that, while they must be forced to work with their hated enemy, the defense of humankind is paramount",
    heritage_culture: 'Gwaredûr hail from primarily two lineages, with the Bandûr being by far the most diverse and expressive. The abilities of a Bandûr usually do not appear until they reach maturity, but rarely even in infancy, leading many to believe their gifts bestowed by local deities.$$' + 
        'Many go on to become local heroes of renown, driven by a desire to protect their communities, and widen their net of their concern until they are inevitably drawn to fighting The Endless. These individuals make up the majority of the humans in the Bacheran Pact. Unfortunately, the Bandûr are also renowned bandit kings and local tyrants, whose desire to protect has materialized in absolute control of threats and dangerous behaviors or generalized rebellion with no goal.$$' +
        'The Tariȃn are a much more organized people with a single cultural origin, though heavily permutated due to the passage of many millennia since their founding. These cultures have a stark aristocracy in which the Tariȃn warriors find themselves duty bound to perpetuate their kingdoms and noble houses. These houses are by necessity supported by kingdoms, fiefs, and vassalages filled with the more common peoples of Daear. There have been many configurations of this arrangement, but it is an unfortunate truth that the Tariȃn are as likely as their more diverse cousins to adopt warlord-hood and infighting.$$' +
        'The Kingdom of Nerthôl is the mightiest united force of the Gwaredûr and is ruled by a council of seven Stewards, each from one of the noble houses of Tariȃn who founded the kingship. From these stewards, a High King is elected at the death or abdication of the previous, which has occasionally resulted in Nerthôl being ruled by a non-Gwaredûr.$$' + 
        'The people of Nerthôl are stalwart defenders of humankind, but are cruel and brutal when faced with the Gaelûn, such that the word is now an insult of great offense amongst their people. This hostility does not extend to the nephilim or revenants in general, but is sometimes applied to these ancient allies of humankind as well.$$' + 
        'The Nerthôl build great walled cities and are the most productive road-builders of all free peoples yet living. Their armies are almost always led by Gwaredûr, but have entire legions of mortal men serving in their best capacity to defend their homes from nigh-constant attacks by the horrid remnants of the Pyrrhic Age, which most often target the Seven Kingdoms.',
    heritage_appearance: 'Humans typically have skin and hair colors in wide ranges, of almost all warm or earthy colors, their eyes falling largely into the same spectrum. This spectrum is widened to an even larger degree, with the Human tendency to apply pigment to their bodies on a semi-permanent basis, and thus they are nearly impossible to categorize. Just as widely as their coloration varies the attire of humanfolk, who are found both in nearly every environment.$$' +
        'The people of Nerthôl wear heraldry in deep Navy Blue and Silver. The symbol of the Seven Kingdoms is the Waystar, itself sometimes crowned by seven stars representing each kingdom. They wear these symbols with pride, as they are one of the few human societies that has lasted long enough to have a universally recognized heraldry.',
    heritage_unliving: '',
    heritage_costuming: 'Humans have no mandatory costuming.',
    heritage_resistance: 'A human character may call Resist once per day to any single Condition applied to you, so long as that condition has a duration. This represents human tenacity and the refusal to simply die quietly.',
}

const elf = {
    heritage_name: "Elf",
    heritage_lore: "During the waning of the empires of Humankind, the Elves were the most fair and gentle of the New Gods children. Coming from the sea and forests, these children built beautiful cities, made great works of art, and worked tirelessly to the cause of peace. They brokered treaties between belligerents and offered succor to all who came by the command of their chief goddess, Lauriel of Light. With her husband Fingorn the Craftsman at her right hand, She ruled justly and was the only New God who’s entire pantheon was respected by the Old Gods of Humankind. Despite this, the wars between the Old and New gods raged about the elven lands, and dire plans were laid against them. In a Great Betrayal that signalled the end of the World that Was, Lauriel was assassinated by an unhallowed pact between several New and Old gods. The Grief of Fingorn was echoed by his people, and the mourning of the Slain Queen lasted a century.$$" +
        "The elves that emerged from this were a honed blade, the fury of Fingorn writ large as their armies marched into the battles that consumed Daear. The Warsong of the Elvenkind was sung, and is the date that most histories use to mark the beginning of the War of Ending. Over the course of the War, the Elves conquered much and built great cities as they expanded their empire, but in doing so made many foes. Again and again the elves would rally, but late in the war their Kingdoms became little more than advanced, isolated city-states with loosely controlled fiefs surrounding them. Engaged in the final battle of the War, Ciruth-Thruin in the Elventongue, against both the Dwarven Hosts and assembled armies of Mankind, the elves were preparing to retreat when @@The Endless@@ opened the first Rift onto Daear. The Elvenking’s first born, Feanwe violated the express wishes of his father and led the assembled forces to destroy @@The Endless@@, in what would become the founding moment of the Bacheran Pact.$$" +
        "Since the founding, the Elves have remained ever wary of the Pact, only tolerating it out of respect for their Prince, now a High Marshal of the Pact. His long service as a formidable general in his father’s armies leads them to a dichotomy of both being suspicious of the other members of the pact while trusting in his assessment that @@The Endless@@ could not be stopped without it. Some elves even wish that his brother, Fanolin, should be made Heir Apparent in his “dereliction of duty.”",
    heritage_culture: 'During the early days of the elves, they made many great and beautiful things, planted great forests, tended great gardens, and shared and prospered with the rest of Daear. For the last three millenia, the elves have turned this dedication to the creation of beautiful and deadly weapons, impenetrable fortresses, and great cities in the name of Fingorn. The elves value the craftsman over almost every other profession except warrior, whom they have come to revere as artists of vengeance for the slaying of Lauriel. Elves were created with her blessing, granting them eternal life and vigor, giving them long years to both perfect their artistry and lay their plans. Elves are often patient, dedicated, indefatigable, and sleepless in their pursuits to the point where other races consider them obsessive. Elves consider this the highest form of compliment if it relates to their craftwork or artistry.$$' +
        'The elves follow the Elvenking, the line of hereditary rulers originally appointed by Lauriel, as the steward of her people. During the War of Ending several Elvenkings were slain by assassins, userpurs, or on the field of battle, and the current Elvenking, Feranil has been considered one of the most capable of his line, having ruled for over 1500 years. His policies have largely been defensive, only moving on the  attack to retake lost Elven territories. He resides in the capitol of Tirmarin, a city made of marble and gold, after which all elven cities have been modeled.$$' +
        'Elves in the outlands find themselves making fine buildings of wood and stone, worked into the landscape to preserve and enhance the natural beauty of their surroundings. These elves are more in keeping with the more ancient traditions, and even though the murder of Lauriel weighs heavily on their hearts. They are less ingrained in the newer traditions of bladecraft and warmaking, and have a greater focus on the hunt and tending of greenery. Despite their differences, these elves above all value loyalty to their kinfolk, and such is the reason that the lands of the elves remain as whole as they do, despite the depredations of @@The Endless@@.',
    heritage_unliving: '',
    heritage_appearance: 'The elves most similar to humans can be spotted easily by their pointed ears, but with no more distinctions from the rest of humankind. The closer to divinity the elves come their hair becomes progressively more red, gold, black, white, or even navy blue, and their eyes start to reflect similar colors. The highest of the elves find themselves with almost entirely grayscale skin, an almost unbroken solid color.$$' +
    'Elves dress finely but practically, and will not be seen attempting to wear a formal robe to the battlefield, just as they would not be seen wearing full armor in a workshop, or working garb at a banquet. Jewelry is common among the elves, but lavish gaudiness is considered distasteful as restraint is the social equivalent of patience.',
    heritage_costuming: "An elf who meets only the minimum requirements may use their resistances twice per day. An elf who meets all the moderate requirements as well as the minimum may use their resistances once per encounter, and an additional two times per day. An elf who meets all of the requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% Pointed Ear prosthetics$$" +
        "%%Moderate-%% Colored Hair or Colored Eyes (Black, Gold, Navy, Red, White)$$" +
        "%%Full-%% Exposed skin must be grayscale (on a scale from white - grey - black), and all lower requirements",
    heritage_resistance: "An elf character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist Sleep effects, representing the elven tireless pursuit of vengeance for The Great Betrayal."
}

const littlefolk = {
    heritage_name: 'Littlefolk',
    heritage_lore: 'The Littlefolk came to be by the organized collaboration of three gods, Fenzzik, Kurzzon, and Nothog. The three brothers had worked together to create a single government under their rule, and they each imparted traits onto their followers to mutually support one another. The children of Fenzzik hungered for the peace of fresh tilled earth, those of Kurzzon sought the rush of innovation, and those of Nothog sought the joy of creation. While they are Children of the New Gods, the Littlefolk live human-scale lives, due to their elevated metabolism. These traits led the Littlefolk to create many rolling and productive farms surrounding sturdy and well maintained architecture, crowned by spindly cranes of the newest design.$$' +
        'These industrial traits were not matched by an acumen for warfare however, and when the War of Ending began in earnest, these areas of idyllic productivity were fought over bitterly. The Littlefolk found themselves the vassals and absorbed by various powers, spread wide apart from their former brethren. The Halflings of Fenzzik still live as they have done for ages in some parts of the world, and in others they have gained a reputation for their bravery. The Gnomes of Kurzzon have settled largely with the Dwarves and Lizardfolk, both of whom are capable of sourcing the exotic components they need for their experiments. The Goblins of Nothog have come to dwell often with the Orcish clans, trading their prodigious repair and production abilities for protection against the ever more dangerous world.$$' +
        'While the Littlefolk were ill-equipped to fight in the Last Battle of the War of Ending, they still found themselves drawn in, and were subjected to the absolute terror of . The bravest Halflings saw @@The Endless@@ for the threat they represented, the Gnomes as a riddle to be unlocked, and the greatest threat to the living in Goblin reckoning. Each support the Bacheran Pact as some of its greatest members, somewhat concerning the lords to which the Littlefolk serve as vassals. They are more united now than they ever have been, trying to make sure their form of creation still has a place of Daear for generations to come.',
    heritage_culture: 'Halflings are the most ubiquitous of their kinfolk, quietly living out their lives in relatively happy obscurity in the farms and cities of Daear. Their natural affinity for nature and their adaptability has led them to become the backbone of many nation’s agrarian complexes, and it has been found that this breadbasket produces most when it is left alone. This has left many of their number naive to the ways of the wider world, an opinion that many hold of the Halflings. Many of the Littlefolk overlook this as jaded cynicism of the Bigfolk, but some use it as a cloak to hide their true intentions. The Littlefolk fiercely defend their way of life, earning themselves a reputation for bravery when pressed.$$' +
        'Gnomes are highly independent, focusing on researching alone or in tandem with a small number of partners. Of all the Littlefolk, they maintain a culture of genius worship, where those who excel are rightfully or wrongfully seen as pillars of innovation in their own right. This has led to many finding themselves either elevated amongst their kind, or feeling as though they have been denied recognition of their just contributions. These Gnomes often pursue high-risk high-reward schemes, as they are seen as the most effective way to both innovate and gain recognition for one’s contributions. Ultimately this brings them into even closer kinship with the Dwarves as their resentments are compatible with the Grudge of Dwarven fame.$$' +
        'Goblins are known for their pragmatic and efficient use of available resources to construct whatever tools or shelter they feel they need to survive. This in particular has made them most likely to form true mercenary corps of work teams, who almost always complete their given tasks on-time and under-budget. While they are highly respected artisans, they are unfortunately considered prized slaves by cultures that take them, in particular the Orcs. Such treatment has resulted in many goblins picking up a vicious streak and a hunger for material comforts, though this is in no way ubiquitous.',
    heritage_unliving: '',
    heritage_appearance: 'All Littlefolk, despite being of small to average height for humans, have very neotenous features, leading many to consider them “small looking,” the namesake of their people. All littlefolk have large ears, though in some cases they are pointed, and in others round. The closer they are to divinity, they begin to develop buck or rodentine teeth, and their feet grow to a large size enhancing the illusion of their “smallness.” In their idealized state, they have skin ranging heavily in the mottled yellow spectrum, with some underground varieties having muted gray tones.$$' +
        'The Littlefolk dress in accordance to their function, and often in the style of their host cultures. Regardless of host culture however, the Littlefolk often adorn themselves with small decorations and objects, such as beads, coins, or bits of bone. These serve as meaningful reminders of that Littlefolk’s personal values and home.',
    heritage_costuming: "A littlefolk who meets only the minimum requirements may use their resistances twice per day. A littlefolk who meets all the moderate requirements as well as the minimum may use their resistances once per encounter, and an additional two times per day. A littlefolk who meets all of the requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% Large prosthetic ears, round or pointed.$$" +
        "%%Moderate-%% Buck/rodent teeth or enlarged boot and foot prosthesis$$" +
        "%%Full-%% Pastel yellow to dark gray coloration across all exposed skin, and all lower requirements",
    heritage_resistance: 'A littlefolk character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Slow effects, representing the Littlefolk constant drive towards progress.',
}

const orc = {
    heritage_name: 'Orc',
    heritage_lore: 'In the World that Was, the children of the New Gods arrived within rapid succession, seeming to the followers of the Old Ways to be a united front, but nothing could be further from the truth. The first husband of Lauriel of Light, the warrior Melican, did not agree with her passive approach to conquest, and began a long divergence until his people lived separated from their mother. Melican demanded that his people grow stronger, affix iron to their souls until they had nothing left but strength and devotion. His worshipers changed at his command, and over time, even before the earliest days of the War of Ending, what was once Melican had become Urguk and what were once Elves had become Orcs.$$' +
        'Their bodies were of shining metal, copper, bronze, and iron, and their teeth had grown to the point where they were themselves weapons. Demanding primacy over all Gods, it is Urguk who struck the first blow against not only the Old Gods of humankind, but also who would land the killing blow upon Dun-Thalmuz. Reveling in his victory, for a thousand years Urguk ruled in Gundmar, formerly Grim-Thargor a great city of Dwarfmake, as his will was not for building fortifications but for slaying. It was this great betrayal that led the Dwarves and Elves in the bloody conflict that would eventually culminate in the death of Urguk in a vicious battle that claimed the lives of several other gods. Their hopes were dashed when the Orcs were not broken by this defeat, but instead took it as the great model of their culture. The great Warlords of his armies determined that Urguk had died victorious as he had died at the hands of Worthy foes. Even with the will of Urguk subverted, and the Horde fractured into Clans, the highest Orcish virtue is to die in Worthy battle against your foes.$$' +
        'These Clans have become endemic in almost all the harsh climates of Daear, which do not bother the orcs nearly so much as they do humans. Without remorse they raid and pillage, occupying the strongholds of their conquests, with allegiances so shifting mapping them has become impossible. While there were orcs who fought in the Gorgotha, the Orcish name for the Final Battle, their allegiance to the Bacheran Pact  has ever been one of glory-seeking, the Worthy opportunity to face @@The Endless@@ in combat superseding the concerns of monetary gain or valor.',
    heritage_culture: 'Orcish culture has diverged wildly from that of the Elves in their many years of separation from their ancient kin. Though orcs are still possessed of unending life, they never expect it to last long, and continue to grow in stature over the course of their lives. These Greatorcs lead their tribes not due to size or prowess, but because they all bear countless scars and wounds of battle, and have come to the moment of glorious death more than any other orc. The Clans, the largest unit of Orc society, measure in the many thousands, and have a single Warlord who rules over them with a metallic fist. These Warlords often take tribute from those they find too lacking to pose a credible threat, and constantly pit themselves against those they see as worthy foes.$$' +
        'A Worthy Orc is never without scars, as many injuries that would kill lesser beings simply inconvenience an orc, and to be seen without such markings or corrosion of their metallic skin is considered the pinnacle of unclean cowardice, befitting only an Outcast. The Orcs revile these cowards far more than they revile their own enemies, as their enemies have the good sense to fight and die while offering the Orcs the chance to do the same. Being Worthy is considered far more important than being rich, honorable, or any other form of status valued by many of the other children, and it is the ultimate goal of almost all orthodox orcs. Nearly every Clan has a different and conflicting definition of what behavior is Worthy, and thus Orcs fight amongst themselves almost as often as they do any others.',
    heritage_unliving: '',
    heritage_appearance: 'The Orcs in their least refined form simply have long tusks, and almost always bear the scars of battle proudly on their bodies. Often as they continue to seek to be Worthy, their injuries grow more and more, until they have metallic sutures and plates bolted to their bodies to hold them together. As they grow closer and closer to Urguk’s vision, their skin begins to molt into metallic surfaces, which become more chopped and corroded as they engage in battle. Orcs wear these damaged and well-used visages with the utmost of Tarnished pride.$$' +
        'Orcs dress in practical and battle-worthy garb at all times, as they may be called to fight at any moment. Their armor is generally scavenged from their slain foes, however they do take pride in their armor and treat it with respect. Orcs find almost no value in metals or jewels, instead focusing on trophies of their battles, or on decorations that serve an immediate practical purpose.',
    heritage_costuming: "An orc who meets only the minimum requirements may use their resistances twice per day. An orc who meets all the moderate requirements as well as the minimum may use their resistances once per encounter, and an additional two times per day. An orc who meets all of the requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% Prosthetic tooth tusks.$$" +
        "%%Moderate-%% Metallic Wound Repair Prosthesis (Iron staples, metal plates, corroded copper Kintsugi) or prosthetic scarring$$" +
        "%%Full-%% Corroded metallic coloration (Rusty Red, Bronze Green, Coppery Teal) across all exposed skin, and all lower requirements",
    heritage_resistance: 'An orc character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Fear effects, representing the Orcish love of battle and glorious death.',
}

const beastfolk = {
    heritage_name: 'Beastfolk',
    heritage_lore: 'Beastfolk are the product of Bray the Keeper, the lord of beasts amongst the Eidolon, the new gods. They were created as punishment for the abuses the god believed humankind had visited upon the other lesser beasts of the World that Was. He twisted and warped many until they came to resemble beasts themselves, and in their minds he planted The Call of the Wild. Driven into borderline madness, these beings left their settled ways behind and migrated into the wilds and woods where their descendants live to this day. The first beastfolk declared themselves the protectors of the wildlands and its bestial inhabitants, taking hostile action against those who attempted to settle in the wilds.$$' +
        'Over time, the Beastfolk populated the remote regions of the world, and their population slowly grew as the numbers of their landed ancestors shrank. Entire herds of beastmen would roam the wilds, sometimes exchanging their services for coins, and others simply burned to ashes anything unfortunate enough to fall in their path. Bitter wars were fought between the largest herds and the roaming Urguk clans. This bred their deeply ingrained hatred of one another, as well as the attrition death of both the Orcish and Beastfolk pantheons. It was by these wars, and the horrors they spawned, that the numerous beast herds were scattered and broken down into the smaller clans of the modern day. Occasionally a massive herd would arise, and the beat of their war drums would bring fear to all who dared enter the woods.$$' + 
        'Beastfolk, thoroughly disorganized by the death of their gods, acted as mercenaries during the three thousand years of strife in the Pyrrhic Age. It is said that a group of beastmen were even fighting on the side of the Revenants when Bray the Keeper was slain. Beastfolk auxiliaries fought on both sides of the Last Battle, known commonly as the Battle of the Bacheran, but known to many Beastfolk as The Silence, for the arrival of @@The Endless@@ drowned out even The Call of the Wild. The Beastfolk felt this as an inherent and incredible terror, driving many to flee and others into a murderous frenzy, just as lesser animals react with the immediacy of flight-or-fight to @@The Endless@@. The surviving Beastfolk fled to spread the word to others of their kind, and when The Bacheran Pact was formed, many Beastfolk came to see the threat with their own eyes. Many have died in this way, and many more have simply abandoned the cause and become roving herds of murdering bandits to pray upon the other mercenaries who heed The Bacheran.',
    heritage_culture: 'The Call causes Beastfolk to constantly seek the companionship of their like animals and to feel restless and spiteful when indoors. Though The Call of the Wild is weaker in some beastfolk, it is rare that they remain in the same place for more than a few days at a time. Not all Beastfolk manifest extreme bestial features, and sometimes are able to pass for short periods amongst strangers or the inattentive, but this is not done often as The Call makes it difficult to pretend to enjoy enclosure for long. Other beastfolk tend to look down on those with lesser degrees of bestial transformation, assuming this indicates that they are “tame” or too cowardly to accept their mission.$$' +
        'The Beastfolk have no central language or culture shared between them, and no homelands to call their own. Instead they form herds or packs with beasts like unto themselves, living amongst the animals as their guardians and protectors. In some exceptional cases, the beastfolk have even taken to herding and ranching their animal kin, and it is thanks to these industrious members that beastfolk are found in every province in equal share. Unfortunately, this extreme propagation has led to just as many destructive beastfolk pillaging as it has to nomadic herders interacting with the landed peoples. Many of these interactions are tense as beastfolk understand the value of personal possession and property, but their nomadic lifestyle makes many feel they can and will evade punishment.',
    heritage_unliving: '',
    heritage_appearance: 'Beastfolk appearance varies as wildly as do the warm-blooded animals of Daear; however, there are certain unifying features shared by all. The least beastly of their kind simply display small bestial features, such as feathers around scaly hands, or furred and feathered tails. Often they dress in simple hides and furs sourced from the slain of their own herds, or armor looted from those that would attack them.$$' +
        'As they grow closer to divinity, they become progressively more and more bestial, snouts, talons, teeth, horns, claws, and fur all presenting in time to better hide them amongst their bestial kin. The most ornate and decorated of the beastfolk adorn themselves with looted beads, bones, and small metallic objects, considering each a trophy of a conflict well won.',
    heritage_costuming: "A beastfolk who meets only the minimum requirements may use their resistances twice per day. A beastfolk who meets the minimum requirements and the moderate requirements may use their resistances once per encounter, and an additional two times per day. A beastfolk who meets all requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% Minor bestial features (tail, ears, feathers or fur at wrists or neck).$$" +
        "%%Moderate-%% Bestial coloration and texturing on face or beastial anatomy, such as Beaks, Horns, or prosthetic snouts.$$" +
        "%%Full-%% All exposed skin textured as feather or fur, and all lower requirements",
    heritage_resistance: 'A beastfolk character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Silence effects, representing the unstoppable nature of the Call of the Wild.',
}
const elemental = {
    heritage_name: 'Elemental',
    heritage_lore: 'Elementals are a mortal race originating from the Primal Will which first created gods to shepherd each element from the heavens to Daear. The Will’s god-servants were immortal vessels of pure fire, water, wind, gray geological earth, and green biological earth, housing attitudes and intelligence equal to each element in due measure. The Primal Will desired a claim on Daear, which the Elementals came to know as the hybrid heaven, and sent its godly envoys to propagate its will. However, the gods brought disharmony with them, unused to sharing dominion with each other over a place of mixed composition. The red heaven’s god Glowen dried the air to cause fire and compressed the earth to foment inner fires, but the gray earth goddess Agatia gripped the mantle, preventing the chaos that emergent volcanoes would cause to the preexisting denizens of Daear. Lethe of the wind whipped up frustrations between the water and wood gods, Octavor and SkyReacher, claiming each was demented in the other’s eyes, desiring that water would drown biological life or that biology would propagate and soak up all the water, leaving only one victor for her to defeat. Thus, the gods fought each other instead of working in balance.$$' +
        'The godly envoys took humans dwelling in the hybrid heaven and, finding them enfeebled before the chaos of elemental nature, changed their forms to better survive their favorite materials; immortal playthings of fire, wind, water, wood, and stone to use against each other. History or mythology evidences that the Primal Will was displeased with these actions as a mysterious affliction struck down the gods in one monumental blow, corrupting their beings until their vital essence was drained. The disease affected their mutated offspring and caused a rampant fear: the Elementals became mortal and able to kill each other, or be killed by other denizens of the hybrid heaven. They changed their tactics and sought allies among the other peoples that most resembled themselves. They discovered the art of biologic propagation, an ability that the Primal Will had apparently left intact, and halted their internal conflict in time to prevent their mutual extinction. Their most unified aim became to reclaim their lost immortality. Many search for a cure across the hybrid heaven and others seek it in their source material, using portals to travel back to their heaven of origin.$$' +
        'Champions emerged among the mortally diminished Elementals in the wake of the gods’ deaths. They travel, rarely, to the Nexus, a plane in which to hold an Elemental Conclave. They arrive under the flag of truce to discuss heritage-wide matters such as advances on researching a cure to mortality, maintaining a semblance of harmony on the hybrid heaven on which they must reside, and administering justice to those who have transgressed. The Conclave determined that no element may be permitted dominion over another to prevent jeopardizing the existence of the native inhabitants of Daear, especially the favorite heritages that certain Elementals had grown fond of. These more modern motivations included finding better ways to coexist while allowing each element to promote itself and protect their necessary habitats. For fire requires a hot landscape such as a desert or volcano to dwell near, and water elementals require seas and rivers to revive themselves.$$' +
        'The Elementals were late to understand the dangers of @@The Endless@@ and saw them first as a mere aberration of geology. But when an army of gray earth elementals supported by some of their fire brethren clashed against the Lizardfolk and Orcs, it caused the second rift to open. All Elementals felt a tension in their Will, a wrongness that twisted into their cores when @@The Endless@@ stepped through, cried out with their beings, and acted in unified intent for the first time in thousands of years. Many were too far away to react in time, but those that reached the last battle, the Battle of the Bacheran, fought as though they’d never raised arms against a fire and water, wind or gray earth. They fought to protect the allies they’d made on Daear and forevermore held @@The Endless@@ as a desecration of what is natural, a non-element that must be purged as a blight that must not take hold on this or any heaven.',
    heritage_culture: 'The heavens the gods were descended from, and from which their mortal offspring bear direct resemblance, are immeasurably vast lands separated like pocket worlds unto themselves. The Red Heaven, a place of ash and flame, surging volcanoes, and firestorms, is a suitable dwelling for only life forms that can withstand it. The Blue Heaven, a place of deep ocean and underwater lairs, is rife with pescarian creatures that could only inhabit a landless zone. The Gray Heaven has a dense core surrounded by layers of aged cold layers, whereupon the rock itself seems to move. The Violet Heaven, full of tempests, howling wind, and raging updrafts, is populated only with beings that live aloft. The Green Heaven is a haven to plant life of every variety, where every twisting vine and shaking tree may contain sentience, and lives, improbably, without most other species.$$' +
        'Traveling between the heavens requires hours of focus, a cast ritual, and then one’s mind and essence project from the hybrid heaven to the desired elemental heaven. However, the body is weighted by its mortal flesh and remains vacant and defenseless for the duration back in Daear. The gods could once perform this feat with a mere thought, but the mortal heritage of Elementals have much less freedom.$$' +
        'When the Elementals required the help of others to survive, Agatia found an ally in the dwarven god Dun-Thalmuz. Despite the industrialization of the dwarves, she found them curious beings not made of the earthen essence the same way as she, but still made stalwart in its likeness. She sought to protect their caverns, metallic veins of ore, and all the riches they created from it, but SkyReacher disagreed that the lands should be transformed in such ways. Even after Agatia’s death, the friendship and curiosity between both peoples flourished until the attack of the Lizardfolk who, bringing Orcs to savage the city of Grim-Kazdul, resulted in the death of the dwarven god. Thus, the raiders earned the enmity of the dwarves and elementals of rock and metal alike. Over the years, gray earth elementals have grown to agree with the green earthers that the Hate Forged dwarves are better suited as allies as they abandoned industrialization in favor of spite, while the more common Sun Chaser dwarves promote industrialization. The green earth elementals of plant, wood, and flower allied instead with the beast folk who harbored a like-minded protectiveness of the wilds.$$' + 
        'The water elementals found an auspicious commonality with the Lizardfolk, being a sea-dwelling people, though each were sometimes contentious over the right to hold any oceanic territory. The wind elementals aided the Nephilim, the only heritage deemed worthy because of their limited ability to fly, though wind elementals also trade occasionally with Lizardfolk who transit on the flying type dragon turtles. The fire elementals found no such easy kinship on Daear and considered themselves superior by design because no others could exist amidst the flame. Some abandoned the surface to find the Hate Forged dwarves and the heat of the world’s interior, and others relegated themselves to the wilds as Primal Purists and disregarded cohabitation entirely, perhaps becoming zealots determined to rediscover the Primal Will itself. Others accepted disappointment and satisfied themselves with analogous traits. They might admire the passion of an Orcish warrior or an Elven artisan, or might take the more obvious route and befriend an evoker who prefers to cast from fire.',
    heritage_unliving: '',
    heritage_appearance: 'Elementals require little adornment to seem appealing or garish to the other heritages, as they are made of the derived materials of their heavens. They are a curious blend of humanoid and primal force, mortal yet overtly mutated from their human roots. An Elemental’s face bears gemstones, crystals, stone, greenery, or wood either as replacement features, encrusted facial characteristics, or protrusions. As they become nearer to their gods, their hair changes characteristics to approximate their derived material as well, and their skin grows faceted like cut or natural gems, patterned like stone, or grained like wood or plant fiber.$$' +
        'The garb of Elementals is as varied as their temperaments. They may choose garments that are redolent of their element, or eschew that likeness in favor of the task they wish to perform, choosing armor with the care of a warrior or lightweight clothes that allow them to move easily. They may take a different inspiration entirely, modeling garments after what their favored ally wears, or selecting garb that alludes to their - often robust - personality traits.',
    heritage_costuming: "An elemental who meets only the minimum requirements may use their resistances twice per day. An elemental who meets all the moderate requirements as well as the minimum may use their resistances once per encounter, and an additional two times per day. An elemental who meets all of the requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% Gemstone, crystalline, wooden, plant, or stone portrusions on face.$$" +
        "%%Moderate-%% Appropriate material and color hair (Leaves, cloud-like, firey, etc) or gem/wooden (gemstone, crystalline, wooden, plant, or stone) patterning and coloration on face$$" +
        "%%Full-%% Gemstone, crystalline, wooden, plant, or stone features on all exposed skin, and all lower requirements",
    heritage_resistance: 'An elemental character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Blight effects, representing the natural resistance of the elements to divine meddling.',
}

const revenant = {
    heritage_name: 'Revenant',
    heritage_lore: 'The dawn of the Pyrrhic Age raged across Daear, resulting in the Marbaghd, the great killing. The children of the new gods, called children of Eidolon, took it upon themselves to engage in wholesale slaughter of any who lived in their lands and worshiped the Domara, the old gods.$$' +
        'Forces of ardents went from city to village and murdered any they suspected of heresy. The bodies were piled in shallow or mass graves, and funerary rites were never performed over these forsaken souls.$$' +
        'It is said that Kellach, the God of Justice, heard the cries of the slain begging for redress and decided to make them his champions. From their graves, eyes shining eerie yellow in the dark, these slain dressed their wounds and marched to face the ardents in battle. The slaughter was avenged across many provinces in a single evening, but justice could not prevail while humanity lay at the mercy of the Eidolon.$$' +
        'These hallowed undead, the Risen, continued their quest and hid among the human natives of every province, uniting the disaffected in the name of Justice. In this time, the dead were heroes, the righters of wrongs, the bringers of law, renowned for having the strongest wills among all the Domara’s servants. But in the fullness of time, and the waning of years, more and more humans fell, and more rose from the grave to continue the battle.$$' + 
        'The number of Risen began to slowly decrease as the years gradually eroded their sanity. Sight of their grand goal was stripped away my this maddening. Each time they rose, less of them returned from death, until eventually only their ephemeral spirits remained.$$' +
        'Many were driven to madness by this death-awareness and claimed the world was to be taken for only the dead and became murderous tyrants, while others became reclusive hermits desperate to find a solution. These were the first thinking undead, and it was they who discovered that fewer of the undead were rising as Revenants. The greater number of Risen were simply shambling corpses who had no flame of justice in their hearts, only the hunger for the blood and brains of the living.$$' +
        'Revenants have served with prominence in every army of man, and in the ultimate battle of the Pyrrhic Age, commonly called the Battle of the Bacheran, but known as The Great Dying in Revenant lore, the armies of the Domara were lead by Jerrimiah “Ironspine” McCullough, one of the last true believing Risen. He watched as @@The Endless@@ ripped through the assembled forces and knew that these things, whatever they were, would perpetrate a slaughter that would make the Marbaghd seem inconsequential by comparison.$$' +
        'Rallying his men, Ironspire led a charge in tandem with the Elvenprince, his long-hated foe of many battles, driving @@The Endless@@ into ruin. He made an impassioned speech to the survivors and the Newdeads of The Great Dying, and was the first to propose the creation of The Bacheran Pact.$$' +
        'Since that day, the Revenants have flocked to his cause, hoping to destroy @@The Endless@@, prevent further slaughter, and maybe earn some peace for their restless souls.',
    heritage_culture: 'Revenants hail from every human culture and originated from, or have survived, across many human time periods, which causes much variation between each revenant, though they have some superficial similarities. The Newdead are the most recently deceased, counted as anyone who has died and returned since The Great Dying. They often continue to use the names they used in life and still think of themselves as part of the cultures of the living.$$' + 
        'Their intact features and bodies keep them attached to current events, as does checking in on their families and their descendants. Over time, these connections wane and fade just as their aspect of mortality does.$$' +
        'The Longdead are those that have lived for at least a century, often several. They have ceased to resemble mortals in more than a superficial way, and the cultures from which they hail have drifted so far as to be almost as distant from them as those of the children of Eidolon. They are hinged to humanity and sanity only by their force of will and their burning desire to bring justice to all.$$' +
        'They often begin forgetting their original names for lack of use, and instead adopt monikers that describe their appearances. Abandoning the cultural adornments of their lives, they adopt plain, functional, and well-worn garb meant for practicality in battle rather than aesthetic. Despite their worn appearances, the Longdead are incredible combatants.$$' +
        'They retain both the swiftness of a living mind and the centuries of experience from being dead, which has turned them into brutally efficient killers.',
    heritage_unliving: 'All revenants are Unliving.',
    heritage_appearance: 'Younger Revenants typically try to treat their mortal injuries as would the living, bandaging their hurts, and trying to make them appear as normal. Often they will have many stitched injuries, which do heal, though much slower than when they were living. They almost always have at least one obvious mortal wound, as they eventually revive each time they receive one. Caring much more for their appearance, some newly dead continue to do things such as style their hair or do makeup.$$' +
        'The older the Revenant gets, the more their life of injury and wear becomes apparent, with their flesh slowly being carved away, and their bones requiring more and more repairs to continue functioning. Eventually some will even intentionally remove their flesh, as it has become more hideous than a simple bare skull. Others go to great lengths to keep the appearance of normalcy, grafting the flesh of the dead on to replace their own, resulting in mismatched skin and limbs.',
    heritage_costuming: "A revenant who meets only the minimum requirements may use their resistances twice per day. A revenant who has yellow eyes and one of the moderate feature requirements as well as the minimum may use their resistances once per encounter, and an additional two times per day. A revenant who has yellow eyes and meets all the requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% Dark eye makeup and an obvious mortal wound.$$" +
        "%%Moderate-%% Yellow Colored Eyes and the character's choice of Skeletal or Zombie features on face/body$$" +
        "%%Full-%% Skeletal or Zombie features and coloration across all exposed skin, and all lower requirements",
    heritage_resistance: 'A revenant character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Waste effects, representing the inability of the dead to suffer further degredation.',
}

const lizardfolk = {
    heritage_name: 'Lizardfolk',
    heritage_lore: 'Before time was counted in years and while peace still reigned, Sedhecit Rex desired of his human children a more reptilian nature, to better serve as his representatives in the world. He emerged as one of the Eidolon, the new gods, and sought to push his fledgling Lizardfolk closer to his own semblance, and thus closer to accessing the power of the pillars.$$' +
        'Sedhecit Rex was not without foes, and as his people roamed the sea, he watched the stalwart dwarves always expanding underground, their resilience a perceived threat to his longevity. He sought to test his children’s mettle, and the dwarves could prove an effective measure. He set his offspring loose on the dwarves at an opportune moment during the Pyrrhic Age, intending to watch their success without intervention. However, the Lizardfolk found themselves outmatched against dwarven-made defenses, undermined by the depths and far removed from the familiarity of the sea. Sedhecit Rex dispatched Pikhas Rax to salvage his damaged pride after his children’s failure by assassinating Dun-Thalmuz, god of the dwarves, who had risen to repel the invaders. The Eidolon clashed, Dun-Thalmuz locking stone-encased gauntlets around the demi-god serpent to choke out her breath with his stalwart hold, but Pikhas Rax wrapped the dwarven god within her pythonic embrace, injecting him with venom so deadly it corroded the stone of his body, ensuring an end to them both. Disappointed at the blunders of his Lizardfolk offspring, Sedhecit Rex caused the dragon-turtle cities to turn their backs on the failed species until they could prove themselves by wiping out the dwarves of Grim-Kazdul, the most ancient of dwarven fortresses. The Lizardfolk treatied with the bloody-minded Orcs, and for several hundred years, using their superior wits and seaworthy abilities, sailed huge forces of Orcs into Grim-Kazdul. The Lizardfolk helped the bloodyminded Godspawn wreak savage ruin until the dwarves were destroyed, leaving only remnants of their population to scatter. The Lizardfolk returned to the dragon-turtle cities with their tails between their legs only to find that during the centuries they’d been gone, Sedhecit Rex had spawned a new generation of children that were ever closer to the Father’s draconic seeming.$$' +
            'Near the end of the Pyrrhic Age, Sedhecit Rex sent both his draconic and less fortunate offspring against those who followed the Domara, the old gods. His elder children had learned from the missteps of generations past; cautious tactics translated to greater efficiency and success. When they chose to fight, they observed their enemies from afar, used dragon-turtles to position their forces, and employed the war dinosaurs to give their cavalry a devastating offense. The Lizardfolk watched the ultimate battle of the age from a distance, the Battle of the Bacheran, choosing at the last minute not to engage because of an anomalous catastrophe: the first rift opened to emit @@The Endless@@. Only then, the Draconids of the species, bearing the weight of their father’s favor, were the first to turn to fight this new unknown, convinced that @@The Endless@@ were a test conceived by their father to test their valor. Two years later, the Lizardfolk and the Orcs fought against the Elementals, resulting in another rift. They, along with all other heritages, began taking @@The Endless@@ as a serious threat',
    heritage_culture: 'The Lizardfolk have always lived a nomadic life on the backs of gargantuan turtles spanning the size of cities - and indeed they carry sprawling settlements with individually nurtured ecologies on their shells. These dragon-turtles are bred to carry various tribes that dwell in different areas, desert, sea, sky, plains, islands, and so on. Dragon-turtle cities tool around making gradual progress across the world’s vast oceans, and occasionally rise to swim through the sky, casting elephantine shadows over the lands below. No one is sure how, or on what, the dragon-turtles feed themselves, but it’s assumed their metabolism is slow due to size. The Lizardfolk live adrift like their elders, for dragons require the ocean as their domain. There is only one landmass they lay any claim to, a chain of islands which holds their most sacred land. Anmapana, the largest of the islands in the chain, was first formed of rock and reef like any other. Yet when the gargantuan turtles came to their end of life over the millennia, they chose the islands’ foundation as their final destination. Now, Anmapana’s top crust is made of layers of humongous shells cemented together by the ocean, barnacles, weather, and time.$$' +
        'The Lizardfolk also utilize the creatures from which Sedhecit Rex took inspiration in their own creation, taming, breeding, and cultivating dinosaurian species. These reliable mounts provide mobility when the Lizardfolk gain land to trade their fish, shells, and other sea-gotten wares with land folk, whether they ride dinos bred for desert, sea, or anything else.$$' +
        'The father dragon turned his love away from the Lizardfolk at the turning point of Grim-Kazdul during the Pyrrhic Age, the greatest test of their merit and their greatest shame. This has given the Lizardfolk a permanent inferiority complex, constantly seeking their father dragon’s approval through valorous actions. The Lizardfolk believe Sedhecit Rex has not completely abandoned them, though they recognize the elevation of their more draconic kin. In their belief, he loves them so much that he makes life intentionally difficult to provide opportunities to prove their worth in order to earn redemption. Unlike the Orcs, the opportunity to succeed is not enough, as they value valor and boldness but aren’t suicidal in their pursuit. Though their actions may seem ambitious or audacious to some, Lizardfolk are not impetuous. Their methods are meticulous, for redemption can be assured by brazen bids and reasoned risks, and an attempt is only as good as the result',
    heritage_unliving: '',
    heritage_appearance: 'The Lizardfolk are primarily ocean-faring people by habit and ancestry, living as their father dragon intended. Their numbers are vastly made up of folk with closest resemblance to island swimming type lizards, but Sedhecit Rex’s first born, or first made, children created other varieties. All of them are reptilian mammals, warm-blooded, and do not lay eggs. Lizardfolk resembling any type of serpent or amphibian lizards, such as the gecko, can be found as well. The most rare variety are the draconid, those that take on characteristics of the water dragons of ancient past, and are revered by all Lizardfolk for their close resemblance to Sedhecit Rex. As they are considered blessed, they are well cared for by their kin and better educated, and thus tend disproportionately to become adventurers compared to other varieties. Lastly, there are chimeric kin, holdouts from bloodlines that were so altered by the father dragon as to fit no recognizable reptilian, yet blend many of their characteristics into something dinosaurian.$$' +
        'Lizardfolk live for hundreds of years and are capable of regenerating any removed limb, though it takes years to do so, unless said limb is cauterized by fire. Like with most other heritages, they find it easier and faster in the short term to heal themselves with magic. They do not, however, regenerate damage to their faces or organs thereof, and tend to wear metallic masks to protect themselves from damage because they’re underwater so much of the time.',
    heritage_costuming: "A lizardfolk who meets only the minimum requirements may use their resistances twice per day. A lizardfolk who meets the moderate requirements may use their resistances once per encounter, and an additional two times per day. A lizardfolk who meets all requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% A reptilian, amphibian, or draconic tail.$$" +
        "%%Moderate-%% Reptilian facial prosthesis or reptilian texturing on face$$" +
        "%%Full-%% Reptilian texturing on all exposed skin, and all lower requirements",
    heritage_resistance: 'A lizardfolk character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Push effects, representing the Lizardfolk affinity for moving with waves and riding the flow of force.',
}

const hateDwarf = {
    heritage_name: 'Dwarf: Hate Forged',
    heritage_lore: 'Dwarves were once the most powerful Children of the Eidolon, their empire spanning great mountainous and subterranean expanses of Daear. In service to Dun-Thalmuz, God of Fortifications, the dwarves turned the earth and quarry stone and erected cavernous fortress-temples in his name.$$' +
        'Ever ones to preserve what came before, the dwarves went to great efforts to create and maintain their own traditions while cataloging those practiced by the humans in the world of antiquity. It is due mostly to this archival nature that anything is known about the nigh vanished history of humanity. This pursuit was aided by their long lives, measured in several centuries, which allowed the dwarves to better weather the effects of chaos throughout the Pyrrhic Age.$$' +
        'The dwarves responded to the start of the bloody age by providing shelter to the other Eidolon godspawn, but eventually found themselves mired in a web of political intrigue more complex than any of their labyrinthine underways. The lizardfolk sailed in orcs to attack Grim-Kazdul. With the alliance of green and gray earth elementals, the dwarves held the assailants at bay until the lizardfolk goddess Pikhas Rax arrived. She and Dun-Thalmuz mutually killed each other, marking the turning point of the city’s destruction. Lost Grim-Kazdul would in time become the Celdonath Frontier. The loss of holy purpose in the wake of their god’s demise began a schism of thought among dwarvenkind, sewing the seeds of deviant ideals which took time to mature. Their spite inevitably led the dwarves into a myriad of wars, especially against the orcs who covet the highly defensible dwarf warrens, and the lizardfolk who helped put them to ruin.$$' +
        'Despite the robustness of dwarven defense, a slow chipping of their holdings occurred over the millennia. Dead and forgotten dwarven warrens sit as stone tombs of their defenders, and the Krum-Kahzak, the underhighways, are littered with their broken remains.$$' +
        'The last major undercity is that of Grim-Dalgoz, the granite fortress city. From its mighty walls, the Dwarvenqueen Karla Dalgoz led her armies into the Battle of the Bacheran, known to dwarvenfolk as Zakoz-Duum.$$' +
        'It was here before the fury of @@The Endless@@ that the Dwarvenqueen encountered the full extent of Daear’s folly as her lines were decimated by the radiant terrors. The first army attacked by The Endless, the dwarves were ruined by their terrible power. Determined to sell their lives dearly in the spirit of Dun-Dalgoth, the Will of Spite, the Dwarvenqueen’s army was astonished when forces of both elves and men crashed headlong into The Endless, giving her soldiers time to recoup.$$' +
        'Rather than retreat, Karla Dalgoz declared a grudge upon @@The Endless@@ before all, staking her honor on their destruction. Her warriors rallied and stormed into the fray.$$' +
        'Since that day, all dwarves have been sworn foes of The Endless, and the Dwarvenqueen herself is one of the High Lords of The Bacheran Pact. They stand as its firmest backers and will stop at nothing to avenge themselves upon the invaders and bring what peace may be found back to Daear.',
    heritage_culture: 'Dwarves are a race of hardy folk who respect self-reliance and durability over almost all other virtues. This idea of lasting sustenance has led them to preserve not only their own histories, but the histories of other cultures within their halls, and the museums of dwarvenkind are without peer.$$' + 
        'Sun Chaser dwarvenfolk stolidly defend that which has come before, and think the act of preservation to be the noblest goal an individual dwarf can undertake. This extends to their martial attitudes, preferring to fight defensive conflicts in places of their choosing. This has led to a long respected tradition of strategy, but can lead to staggering failures when oversight fails to uncover exploitable weaknesses.$$' +
        'The Sun Chasers emerged from the Pyrrhic Age with renewed interest in protecting the past and reclaiming old grounds and artifacts with the aid of others—even the aid of outsiders. By far the larger part of the dwarven population, Sun Chaser dwarves coexist remarkably well, especially with folk for whom they bear the least spite, such as the gray earth elementals.$$' +
        'Some consider their splintered Hate Forged cousins as crazed relics lost to spite, and others revere them as true progeny of Dun-Dalgoth. Opinions are as varied as grudges.$$' +
        'The Hate Forged dwarvenfolk deviated in thought from the Sun Chasers after the death of their god and the loss of their homes. Dun-Dalgoth is the way of the Hate Forged, an all-consuming grudge taken to extremes. They eschewed the path of their more hopeful cousins and delved deeper through the lost underways, ever closer to Daear’s molten heart, protecting the ruins from all outsiders. Only an unfathomable hatred could forge such stalwart people into a “Lava Beard,” and empower dwarvenfolk to continue existing as if on enmity alone. They consider themselves the truest incarnation of dwarvenkind, and the Sun Chasers as young cousins in whom the grudge has gone soft.$$' +
        'Dwarves prefer geometric patterns and mathematical designs, and carefully weave such into their beards and chops, which is viewed as their most important accessory. As they grow closer to their slain gods’ intent, both dwarven varieties become progressively more stony, eventually coming to resemble walking statues. The Sun Chasers’ beards become molded into their stony exterior and supplemented by mosses. The Hate Forged become more magmatic, even having volcanic vents emerge from their backs at times. Some dwarves work precious metals into their very flesh to exemplify their accomplishments. This also allows the dwarves to display their talent for ironwork, as the dwarves take great pride in skillfully made metal and stone artifacts, the most durable of produce.$$' +
        'Drinking together in ale halls in celebration of Dun-Karnen is a common feasting tradition for the dwarves, as is the swearing of indomitable grudges for slights, in which a dwarf proclaims to all in sight that they will stake their honor against their adherence. Such are not to be taken lightly, as a failure to uphold one’s grudge will damage a dwarf’s reputation beyond repair, often leading to self-imposed exile and financial ruin.',
    heritage_unliving: 'All Hate Forged Dwarves are Unliving.',
    heritage_appearance: 'All dwarves, male and female, sport long facial hair, with that of females generally stopping before the chin, and the males sporting massive bushy facial hair. This is intricately woven, beaded, braided, or otherwise tamed to keep it from interfering in daily life. Dwarves are no shorter than humans, though many have a stooped posture due to their cramped living conditions in dwarven cities.$$' + 
        'Strong of limb and body to a fault, the dwarves begin to become carven and craggy as they approach divinity, with their features beginning to reflect the stone in which they live. Their features progress as they get more and more akin to their dead gods, resulting in the slow loss of their ability to project emotion as their entire skin surface becomes hard and stony.$$' +
        'The Hate Forgers begin to resemble the deep caverns near Daear’s core, places of heat, pressure, magma, and steam. The strength of their enmity changes them beyond what their dead gods originally envisioned.',
    heritage_costuming: "A dwarf who meets only the minimum requirements may use their resistances twice per day. A dwarf who meets the moderate requirements may use their resistances once per encounter, and an additional two times per day. A dwarf who meets all requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% False beard or sideburns in excess of 4 inches length, in unnatural red or black colors.$$" +
        "%%Moderate-%% Magma rock features on the face, or prosthesis for volcanic vents on back or magmatic jagged features$$" +
        "%%Full-%% Extend stone and magma/lava texture and coloration across all exposed skin, and all lower requirements.",
    heritage_resistance: 'A Dwarf character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Wither effects, representing the infallible Dwarven constitution.',
}

const sunDwarf = {
    heritage_name: 'Dwarf: Sun Chaser',
    heritage_lore: 'Dwarves were once the most powerful Children of the Eidolon, their empire spanning great mountainous and subterranean expanses of Daear. In service to Dun-Thalmuz, God of Fortifications, the dwarves turned the earth and quarry stone and erected cavernous fortress-temples in his name.$$' +
        'Ever ones to preserve what came before, the dwarves went to great efforts to create and maintain their own traditions while cataloging those practiced by the humans in the world of antiquity. It is due mostly to this archival nature that anything is known about the nigh vanished history of humanity. This pursuit was aided by their long lives, measured in several centuries, which allowed the dwarves to better weather the effects of chaos throughout the Pyrrhic Age.$$' +
        'The dwarves responded to the start of the bloody age by providing shelter to the other Eidolon godspawn, but eventually found themselves mired in a web of political intrigue more complex than any of their labyrinthine underways. The lizardfolk sailed in orcs to attack Grim-Kazdul. With the alliance of green and gray earth elementals, the dwarves held the assailants at bay until the lizardfolk goddess Pikhas Rax arrived. She and Dun-Thalmuz mutually killed each other, marking the turning point of the city’s destruction. Lost Grim-Kazdul would in time become the Celdonath Frontier. The loss of holy purpose in the wake of their god’s demise began a schism of thought among dwarvenkind, sewing the seeds of deviant ideals which took time to mature. Their spite inevitably led the dwarves into a myriad of wars, especially against the orcs who covet the highly defensible dwarf warrens, and the lizardfolk who helped put them to ruin.$$' +
        'Despite the robustness of dwarven defense, a slow chipping of their holdings occurred over the millennia. Dead and forgotten dwarven warrens sit as stone tombs of their defenders, and the Krum-Kahzak, the underhighways, are littered with their broken remains.$$' +
        'The last major undercity is that of Grim-Dalgoz, the granite fortress city. From its mighty walls, the Dwarvenqueen Karla Dalgoz led her armies into the Battle of the Bacheran, known to dwarvenfolk as Zakoz-Duum.$$' +
        'It was here before the fury of @@The Endless@@ that the Dwarvenqueen encountered the full extent of Daear’s folly as her lines were decimated by the radiant terrors. The first army attacked by The Endless, the dwarves were ruined by their terrible power. Determined to sell their lives dearly in the spirit of Dun-Dalgoth, the Will of Spite, the Dwarvenqueen’s army was astonished when forces of both elves and men crashed headlong into The Endless, giving her soldiers time to recoup.$$' +
        'Rather than retreat, Karla Dalgoz declared a grudge upon @@The Endless@@ before all, staking her honor on their destruction. Her warriors rallied and stormed into the fray.$$' +
        'Since that day, all dwarves have been sworn foes of The Endless, and the Dwarvenqueen herself is one of the High Lords of The Bacheran Pact. They stand as its firmest backers and will stop at nothing to avenge themselves upon the invaders and bring what peace may be found back to Daear.',
    heritage_culture: 'Dwarves are a race of hardy folk who respect self-reliance and durability over almost all other virtues. This idea of lasting sustenance has led them to preserve not only their own histories, but the histories of other cultures within their halls, and the museums of dwarvenkind are without peer.$$' + 
        'Sun Chaser dwarvenfolk stolidly defend that which has come before, and think the act of preservation to be the noblest goal an individual dwarf can undertake. This extends to their martial attitudes, preferring to fight defensive conflicts in places of their choosing. This has led to a long respected tradition of strategy, but can lead to staggering failures when oversight fails to uncover exploitable weaknesses.$$' +
        'The Sun Chasers emerged from the Pyrrhic Age with renewed interest in protecting the past and reclaiming old grounds and artifacts with the aid of others—even the aid of outsiders. By far the larger part of the dwarven population, Sun Chaser dwarves coexist remarkably well, especially with folk for whom they bear the least spite, such as the gray earth elementals.$$' +
        'Some consider their splintered Hate Forged cousins as crazed relics lost to spite, and others revere them as true progeny of Dun-Dalgoth. Opinions are as varied as grudges.$$' +
        'The Hate Forged dwarvenfolk deviated in thought from the Sun Chasers after the death of their god and the loss of their homes. Dun-Dalgoth is the way of the Hate Forged, an all-consuming grudge taken to extremes. They eschewed the path of their more hopeful cousins and delved deeper through the lost underways, ever closer to Daear’s molten heart, protecting the ruins from all outsiders. Only an unfathomable hatred could forge such stalwart people into a “Lava Beard,” and empower dwarvenfolk to continue existing as if on enmity alone. They consider themselves the truest incarnation of dwarvenkind, and the Sun Chasers as young cousins in whom the grudge has gone soft.$$' +
        'Dwarves prefer geometric patterns and mathematical designs, and carefully weave such into their beards and chops, which is viewed as their most important accessory. As they grow closer to their slain gods’ intent, both dwarven varieties become progressively more stony, eventually coming to resemble walking statues. The Sun Chasers’ beards become molded into their stony exterior and supplemented by mosses. The Hate Forged become more magmatic, even having volcanic vents emerge from their backs at times. Some dwarves work precious metals into their very flesh to exemplify their accomplishments. This also allows the dwarves to display their talent for ironwork, as the dwarves take great pride in skillfully made metal and stone artifacts, the most durable of produce.$$' +
        'Drinking together in ale halls in celebration of Dun-Karnen is a common feasting tradition for the dwarves, as is the swearing of indomitable grudges for slights, in which a dwarf proclaims to all in sight that they will stake their honor against their adherence. Such are not to be taken lightly, as a failure to uphold one’s grudge will damage a dwarf’s reputation beyond repair, often leading to self-imposed exile and financial ruin.',
    heritage_unliving: '',
    heritage_appearance: 'All dwarves, male and female, sport long facial hair, with that of females generally stopping before the chin, and the males sporting massive bushy facial hair. This is intricately woven, beaded, braided, or otherwise tamed to keep it from interfering in daily life. Dwarves are no shorter than humans, though many have a stooped posture due to their cramped living conditions in dwarven cities.$$' + 
        'Strong of limb and body to a fault, the dwarves begin to become carven and craggy as they approach divinity, with their features beginning to reflect the stone in which they live. Their features progress as they get more and more akin to their dead gods, resulting in the slow loss of their ability to project emotion as their entire skin surface becomes hard and stony.$$' +
        'The Hate Forgers begin to resemble the deep caverns near Daear’s core, places of heat, pressure, magma, and steam. The strength of their enmity changes them beyond what their dead gods originally envisioned.',
    heritage_costuming: "A dwarf who meets only the minimum requirements may use their resistances twice per day. A dwarf who meets the moderate requirements may use their resistances once per encounter, and an additional two times per day. A dwarf who meets all requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% False beard or sideburns in excess of 4 inches length in any natural beard colors.$$" +
        "%%Moderate-%% Stony facial texture and prosthsis, or a hunchback prosthesis$$" +
        "%%Full-%% Stony textured coloration across all exposed skin, and all lower requirements",
    heritage_resistance: 'A Dwarf character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Wither effects, representing the infallible Dwarven constitution.',
}

const celNephilim = {
    heritage_name: 'Nephilim: Angelic',
    heritage_lore: 'In the world of antiquity, the Domara had many Angels and Demons that served as their primary messengers and agents. As was the nature of humanity in those days, they lusted after these servants, and in the fullness of time, the race of the nephilim came into the world.$$' + 
        'These half-human half-divine creatures lived between both worlds and manifested powers beyond those of normal mortals. The nephilim gradually integrated into human societies and served as agents of their angelic and demonic parents’ masters until the coming of the Eidolon. Their role took a massive turn with the emergence of these new gods.$$' +
        'The nephilim were the first targets of the zealots who tried to sweep Daear clean of Domaran worship, a holocaust known as the Marbagdh. Nephilim were culled and had their wings cut from their bodies and paraded about as trophies. Many survivors of this great purge found themselves in hiding, disguising their wings under cloaks, halos and horns under hoods, in an attempt to blend in with humans.$$.' + 
        'Unlike their angelic and demonic parents, however, the nephilim only lived for a few centuries. The fear of the slaughter waned as did confidence in the rising forces of Revenants. Invigorated, the nephilim came to support their Domaran brethren and joined the fighting of the Pyrrhic Age in full force. It was found in this time that nephilim begot nephilim, such that their numbers swelled to become a major portion of the armies of humankind.$$' +
        'Countless nephilim gave their lives to free their people from bondage, viewing the Eidolon as the worst form of tyrant, even as the Domara perished during the course of the war. As the gods failed and died, many parents of the nephilim fell to madness. Then came the Battle of the Bacheran, the final battle of the age, called The Tragedy by the nephilim, which brought forth @@The Endless@@ onto Daear.$$' +
        'The nephilim component of the armies of humankind did not bend when others began to quail in the face of @@The Endless@@, and when the risen general Ironspine ordered their charge, they hurled themselves into battle. Screaming their defiance, they resembled the Angels and Devils of their origin, and though many fell before the onslaught of @@The Endless@@, the dawn came with wings spread victorious.$$' +
        'Since that day, many nephilim have devoted their lives to driving this new hateful adversary from the world, as there can be no freedom in the face of @@The Endless@@.',
    heritage_culture: 'The nephilim nature is one of order in contrast with chaos. They are poor fits for organizations that are not flexible and adaptive. This leads the nephilim to have a respect for authority but a raging hatred of tyrannical practices. For some, this is a clearly defined boundary, and for others it depends on the whim of their variant moods.$$' +
        'The nephilim are as wildly varied as the humans and celestial servants from which they were spawned. Their desire for freedom extends to their very core, an analogy made manifest by their wings, capable of carrying them aloft for only a few hours on the solstices. They are truly free from earthly concerns during those short hours. Their traditions on these two days, called The Ishtana, are sacred and not to be interrupted or abused. Many small villages make altars with offerings to the passing nephilim to provide them the greatest freedom in thanks for their deeds.$$' +
        'The nephilim are imbued with many of the urges of their ancestors, be they diabolic or angelic. These unnatural behaviors range from harmless ritual to murderous cannibalism, and often cause the nephilim to attempt religious ceremonies and meditations to calm their desires. These traits are inescapable, and while they may be delayed, their inevitable emergence makes it a challenge to stay in any community for too long. Some nephilim have taken to isolation to spare others the brunt of their impulses, and some have simply embraced them—to the chagrin of their kin.',
    heritage_unliving: '',
    heritage_appearance: 'Nephilim come in two distinct varieties, the first of which are the descendants of divine archangels. They still bear the halos of their ancestors though they lack the level of divine radiance and gifts of those greater celestial entities. They often have white hair and wings, though other colors have been seen as there are angels of nearly every virtue. They start to manifest eerie blue eyes that reflect the open skies of their desired ascension, and even their skin begins to reflect these colors as they grow closer to their ancestry.$$' +
        'The second form of nephilim are the diabolic descendants, whose parentage is linked to the demons of the Domara. Their very nature is infernal, and as such, healing magic affects them inversely. They are a punitive and vicious group whose horns signify their dominance over lesser beings. As they grow more powerful, they begin to gain more inhuman facial features and wings that carry them over their domain. Scales, talons, and fangs become more common until finally the very bodies of these creatures become a seamless furrow of monochrome.',
    heritage_costuming: "A nephilim who meets only the minimum requirements may use their resistances twice per day. A nephilim who meets one of the moderate requirements as well as the minimum may use their resistances once per encounter, and an additional two times per day. A nephilim who meets all of the requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% A golden circle or halo atop the hair or head.$$" +
        "%%Moderate-%% Angelic facial features (including blue eyes or sharp teeth) or Wings$$" +
        "%%Full-%% Exposed skin must demonstrably white, black, or blue, and all lower requirements",
    heritage_resistance: 'A nephilim character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Immobilize effects, representing the Nephilim drive for freedom and refusal to be enslaved.',
}

const infernalNephilim = {
    heritage_name: 'Nephilim: Diabolic',
    heritage_lore: 'In the world of antiquity, the Domara had many Angels and Demons that served as their primary messengers and agents. As was the nature of humanity in those days, they lusted after these servants, and in the fullness of time, the race of the nephilim came into the world.$$' + 
        'These half-human half-divine creatures lived between both worlds and manifested powers beyond those of normal mortals. The nephilim gradually integrated into human societies and served as agents of their angelic and demonic parents’ masters until the coming of the Eidolon. Their role took a massive turn with the emergence of these new gods.$$' +
        'The nephilim were the first targets of the zealots who tried to sweep Daear clean of Domaran worship, a holocaust known as the Marbagdh. Nephilim were culled and had their wings cut from their bodies and paraded about as trophies. Many survivors of this great purge found themselves in hiding, disguising their wings under cloaks, halos and horns under hoods, in an attempt to blend in with humans.$$.' + 
        'Unlike their angelic and demonic parents, however, the nephilim only lived for a few centuries. The fear of the slaughter waned as did confidence in the rising forces of Revenants. Invigorated, the nephilim came to support their Domaran brethren and joined the fighting of the Pyrrhic Age in full force. It was found in this time that nephilim begot nephilim, such that their numbers swelled to become a major portion of the armies of humankind.$$' +
        'Countless nephilim gave their lives to free their people from bondage, viewing the Eidolon as the worst form of tyrant, even as the Domara perished during the course of the war. As the gods failed and died, many parents of the nephilim fell to madness. Then came the Battle of the Bacheran, the final battle of the age, called The Tragedy by the nephilim, which brought forth @@The Endless@@ onto Daear.$$' +
        'The nephilim component of the armies of humankind did not bend when others began to quail in the face of @@The Endless@@, and when the risen general Ironspine ordered their charge, they hurled themselves into battle. Screaming their defiance, they resembled the Angels and Devils of their origin, and though many fell before the onslaught of @@The Endless@@, the dawn came with wings spread victorious.$$' +
        'Since that day, many nephilim have devoted their lives to driving this new hateful adversary from the world, as there can be no freedom in the face of @@The Endless@@.',
    heritage_culture: 'The nephilim nature is one of order in contrast with chaos. They are poor fits for organizations that are not flexible and adaptive. This leads the nephilim to have a respect for authority but a raging hatred of tyrannical practices. For some, this is a clearly defined boundary, and for others it depends on the whim of their variant moods.$$' +
        'The nephilim are as wildly varied as the humans and celestial servants from which they were spawned. Their desire for freedom extends to their very core, an analogy made manifest by their wings, capable of carrying them aloft for only a few hours on the solstices. They are truly free from earthly concerns during those short hours. Their traditions on these two days, called The Ishtana, are sacred and not to be interrupted or abused. Many small villages make altars with offerings to the passing nephilim to provide them the greatest freedom in thanks for their deeds.$$' +
        'The nephilim are imbued with many of the urges of their ancestors, be they diabolic or angelic. These unnatural behaviors range from harmless ritual to murderous cannibalism, and often cause the nephilim to attempt religious ceremonies and meditations to calm their desires. These traits are inescapable, and while they may be delayed, their inevitable emergence makes it a challenge to stay in any community for too long. Some nephilim have taken to isolation to spare others the brunt of their impulses, and some have simply embraced them—to the chagrin of their kin.',
    heritage_unliving: 'All Diabolic Nephelim are Unliving.',
    heritage_appearance: 'Nephilim come in two distinct varieties, the first of which are the descendants of divine archangels. They still bear the halos of their ancestors though they lack the level of divine radiance and gifts of those greater celestial entities. They often have white hair and wings, though other colors have been seen as there are angels of nearly every virtue. They start to manifest eerie blue eyes that reflect the open skies of their desired ascension, and even their skin begins to reflect these colors as they grow closer to their ancestry.$$' +
        'The second form of nephilim are the diabolic descendants, whose parentage is linked to the demons of the Domara. Their very nature is infernal, and as such, healing magic affects them inversely. They are a punitive and vicious group whose horns signify their dominance over lesser beings. As they grow more powerful, they begin to gain more inhuman facial features and wings that carry them over their domain. Scales, talons, and fangs become more common until finally the very bodies of these creatures become a seamless furrow of monochrome.',
    heritage_costuming: "A nephilim who meets only the minimum requirements may use their resistances twice per day. A nephilim who meets one of the moderate requirements as well as the minimum may use their resistances once per encounter, and an additional two times per day. A nephilim who meets all of the requirements may use their resistances up to three times an encounter.$$" +
        "%%Minimum-%% Horns adorning the head or face$$" +
        "%%Moderate-%% Diabolic facial features (including blue eyes or sharp teeth) or Wings.$$" +
        "%%Full-%% Exposed skin must be demonstrably white, black, or red, and all lower requirements",
    heritage_resistance: 'A nephilim character gains a progressive number of resistances that scale to the intensity of their heritage costuming. These allow the character to call Resist to Immobilize effects, representing the Nephilim drive for freedom and refusal to be enslaved.',
}

export const heritageList = [human, elf, sunDwarf, littlefolk, orc, celNephilim, beastfolk, elemental, lizardfolk, revenant, infernalNephilim, hateDwarf]
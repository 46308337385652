import { berserkerCapstoneAbility } from "./abilities/BerserkerAbilities";
import { chemistCapstoneAbility } from "./abilities/ChemistAbilities";
import { clericCapstoneAbility } from "./abilities/ClericAbilities";
import { mageCapstoneAbility } from "./abilities/MageAbilities";
import { poisonerCapstoneAbility } from "./abilities/PoisonerAbilities";
import { rogueCapstoneAbility } from "./abilities/RogueAbilities";
import { sageCapstoneAbility } from "./abilities/SageAbilities";
import { soldierCapstoneAbility } from "./abilities/SoldierAbilities";
import { pharmacistCapstoneAbility } from "./abilities/PharmacistAbilities";
import { evokerCapstoneAbility } from "./abilities/EvokerAbilities";
import { aegisCapstoneAbility } from "./abilities/AegisAbilities";
import { necromancerCapstoneAbility } from "./abilities/NecromancerAbilities";
import { priestCapstoneAbility } from "./abilities/PriestAbilities";
import { bardCapstoneAbility } from "./abilities/BardAbilities";
import { tacticianCapstoneAbility } from "./abilities/TacticianAbilities";

export const sageCapstone = {
    capstone_name: "Sage Champion",
    capstone_text: "Your intensive study and acquisition of knowledge has led to you being able to conjure up esoteric information almost at will, as if you were tapping into great fonts of limitless understanding.$$" +
        "Once per Day, when you use an active Ability that affects at least one creature, you may double the numerical effect of that Ability.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day. When repurchasing Diagnose, it instead removes the Three Second Count, allowing you to simply say %%Diagnose%% to the Target creature.",
    capstone_ability: sageCapstoneAbility,
}

export const soldierCapstone = {
    capstone_name: "Soldier Champion",
    capstone_text: "The art of war has irrevocably molded you; your discipline and mettle are near impeccable.$$ " +
        "Your Armor Cap increases by 10.$$" +
        "Twice per Day, you may subject yourself to call %%10 Heal%% or %%10 Harm%% on a %%silent%% ten Count. You may do this when Dying, but not while Dead$$ " +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again.",
    capstone_ability: soldierCapstoneAbility,
}

export const mageCapstone = {
    capstone_name: "Mage Champion",
    capstone_text: "Your control of the arcane approaches perfection as you can draw more of its energy into you more frequently.$$" +
        "If you have taken Magic Missiles, the maximum sum of Arcane damage you may throw in an individual packet doubles (from five to ten, or from ten to twenty.)$$" +
        "At any time, you may choose to become immobilized for a thirty Second %%Quickening%% Count, allowing you to regain the use of one first tier ability, or up to 20 points of Magic Missile pool. A tier two ability or 40 points of Magic Missile pool is restored if you have this capstone twice.$$" +
        "Once per Day on completion of your %%Quickening%% Count, you may Reset all of your Mage Abilities, allowing you to use them again.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day. The damage pool provided by Magic Missiles does not Reset each Encounter, however, those purchases instead grant you double the amount of damage pool.",
    capstone_ability: mageCapstoneAbility,
}

export const chemistCapstone = {
    capstone_name: "Chemist Champion",
    capstone_text: "You have discovered the deepest secrets of the Alchemical sciences and understand the underlying magic behind them. You can create and improve your elixirs, Tonics, and toxins are surpassed by none.$$" +
        "Your mastery of alchemy allows you to mix a Hyde Serum. Once per Day, you can ingest this Tonic on a five second %%pouring%% Count, granting you greater strength and durability. You gain ten Health, ten Armor (that ignores your Armor cap), and deal an additional five damage with one-handed weapons for one Encounter. When the effect ends, you are subjected to %%Waste 300%%. At any time before the start of the next Encounter, you may end the effect on a five Second %%Returning%% Count.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.",
    capstone_ability: chemistCapstoneAbility,
}

export const clericCapstone = {
    capstone_name: "Cleric Champion",
    capstone_text: "Your dedication to your god has manifested in greater and increased powers. As you have served them loyally, they have seen fit to bestow more responsibility upon you, calling you to greater and more frequent miracles.$$" +
        "If you have taken Lay on Hands, twice per Day you may deliver a %%Spell {damage} Heal%% or %%Spell {damage} Harm%% strike that utilizes your pool from Lay on Hands, and has no limit as to how much or little damage you choose to use from your pool.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day. The damage pool provided by Lay on Hands does not Reset each Encounter, however, those purchases instead grant you double the amount of damage pool.",
    capstone_ability: clericCapstoneAbility,
}

//Specialization Capstone

export const rogueCapstone = {
    capstone_name: "Rogue Champion",
    capstone_text: "A complete knowledge of the arteries and veins of the humanoid body allows you to always strike true with sufficient concentration.$$" +
        "Once per Day, you may call %%Skill 150 Pierce%% on a successful Strike with a weapon.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$",
    capstone_ability: rogueCapstoneAbility,
}

export const berserkerCapstone = {
    capstone_name: "Berserker Champion",
    capstone_text: "Your unending rage and impossible durability have made you a whirlwind of death, seemingly immortal before your foes.$$" +
        "Once per Day, when damage would reduce you to the Dying status, you may call Resist and drop to 1 hp instead.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$", capstone_ability: berserkerCapstoneAbility,
}

export const poisonerCapstone = {
    capstone_name: "Poisoner Champion",
    capstone_text: "Knowing every chemical combination that causes harm to humanoids, you find yourself able to mix particularly virulent concoctions.$$" +
        "Once per Day, you may double the duration of one poison you use.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$", capstone_ability: poisonerCapstoneAbility,
}

export const pharmacistCapstone = {
    capstone_name: "Pharmacist Champion",
    capstone_text: "Extreme self-testing has left your constitution dramatically enhanced by comparison to that of the average mortal. You are able to both suffer more punishment, as well as push your own stamina beyond normal boundaries with small chemical assistance.$$" +
        "You gain ten additional Health.$$" +
        "On completion of a thirty second “Mixing” Count, you may grant yourself five temporary Health at any time.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$", capstone_ability: pharmacistCapstoneAbility,
}

export const evokerCapstone = {
    capstone_name: "Evoker Champion",
    capstone_text: "You are capable of channeling the elements so effectively through your body that the elements consider you to be of their kind. This allows you to withstand the mighty forces necessary for directing the most potent material magics, while being able to prevent the same from ruining you.$$" +
        "Twice Per day, when you suffer Acid, Cold, Fire, or Shock damage, you may call Resist and take no damage from the triggering effect.$$" +
        "If you have taken Elemental Blast, the maximum sum of elemental damage you may throw in an individual packet doubles (from five to ten, or from ten to twenty.)$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day. The damage pool provided by Elemental Blast does not Reset each Encounter; however, those purchases instead grant you double the amount of damage pool.$$",
    capstone_ability: evokerCapstoneAbility,
}

export const aegisCapstone = {
    capstone_name: "Aegis Champion",
    capstone_text: "You are a stalwart defender of your charges, and your power over defensive magic has grown such that your legendary bravery and prowess are made manifest in your very presence.$$" +
        "You gain an additional ten Armor Points. This increase ignores your armor cap, and is automatically repaired at the start of each encounter.$$" +
        "Once per day, upon completion of a three second “Armiger” count, you gain fifty Temporary Armor.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$",
    capstone_ability: aegisCapstoneAbility,
}

export const necromancerCapstone = {
    capstone_name: "Necromancer Champion",
    capstone_text: "You are one of the favored servants of death and your control over the soul is so complete that you are able to prevent your own from passing on... for now.$$" +
        "Once per Day, when your Dead count reaches its maximum and you would otherwise resurrect, you instead are subjected to the Life or Death effect.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$", capstone_ability: necromancerCapstoneAbility,
}

export const priestCapstone = {
    capstone_name: "Priest Champion",
    capstone_text: "You have helped many troubled souls mend their minds and bodies in the face of the countless threats that face Daear. You succor all hurts and remind those about you that the gods care for them with your presence alone.$$" +
        "If you have taken the Lay on Hands Ability, you gain the ability to touch an ally, and after completing a three Second “Lay on Hands” Count, you may call %%Spell Counter%% to any single Condition affecting that creature. Each use of this ability consumes ten points from your Lay on Hands pool and may not be used if there are less than ten points of Healing or Harming in that pool.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$", capstone_ability: priestCapstoneAbility,
}

export const bardCapstone = {
    capstone_name: "Bardic Champion",
    capstone_text: "You have come to understand the secrets of the power words to the point where you can command the powers of life and death. This is the pinnacle of Bardic abilities and your deeds are known far and wide, sung by bards other than yourself.$$" +
        "Once per day, you may throw a %%Shout Death%% or %%Shout Life%% packet.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day. When repurchasing Succor, it instead reduces the %%“Succoring”%% Count to fifteen Seconds.$$",
    capstone_ability: bardCapstoneAbility,
}

export const tacticianCapstone = {
    capstone_name: "Tactician Champion",
    capstone_text: "You have come to understand the secrets of the battlefield and are able to not only perform a masterful attack against a critical foe, but can train your men to do so as well. The secrets of the battlefield are an open book to you, knowledge to share with your warriors.$$" +
        "Once per day, you may Touch one creature, and upon the completion of a five Second Count of “Training,” you may grant that creature the power to call %%“Shout 50 Pierce”%% before the end of the next Encounter.$$" +
        "Additionally, you may repurchase your Tier Expertise and Ability Tree choices at double cost up to tier 3. Of those Abilities, you may repurchase any Ability with a maximum per tier that many times again. Any limited use Abilities purchased in this way do not add to your total number of uses, but instead convert those uses to per-Encounter instead of per-Day.$$",
    capstone_ability: tacticianCapstoneAbility,
}
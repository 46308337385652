import { useContext } from "react";
import { offlineModeContext } from "../../App";

const RESTError = 'REST Not Yet Implemented'

export function initialState(type, data) {
    if (data) {
        return data
    }
    else {
        switch (type) {
            case 'characters':
                return []
                break;
            case 'pillarInventory':
                return []
                break;
        }
    }
}

export function saveToSource(offlineMode, location, data) {
    if (offlineMode) {
        localStorage.setItem(location, JSON.stringify(data));
    }
}

export function readFromSource(offlineMode, location) {
    if (offlineMode) {
        const retrieve = localStorage.getItem(location)
        return JSON.parse(retrieve);
    }
}

//Tier 1

export const elementalBlast1 = {
    ability_name: "Elemental Blast",
    ability_text: "The elements are yours to direct and they sear your foes away with ease. You gain a pool of twenty elemental damage which may be thrown in sums as great as ten, and may be thrown as %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% You must incant the Verbal and follow with %%Spell {damage} {element}%%. When purchased subsequent times, you add twenty to your pool of elemental damage. This pool refills at the start of each day.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Elemental"],
    ability_cost: 1,
    ability_uses: 0,
    ability_verbal: "Elemental Blast! Spell 10 {element}",
    ability_tier_limit: null,
}

export const imbueWeapon = {
    ability_name: "Imbue Weapon",
    ability_text: "You must Touch a weapon, and upon the completion of a three Second Count of “Imbuing,” you must choose one of %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% Until the end of the next Encounter, the weapon's user may choose to call that damage type for their next three swings at any time.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "3 Count", "Elemental"],
    ability_cost: 1,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 2

export const elementalBlast2 = {
    ability_name: "Elemental Blast",
    ability_text: "The elements are yours to direct and they sear your foes away with ease. You gain a pool of twenty elemental damage which may be thrown in sums as great as ten, and may be thrown as %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% You must incant the Verbal and follow with %%Spell {damage} {element}%%. When purchased subsequent times, you add twenty to your pool of elemental damage. This pool refills at the start of each day.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Elemental"],
    ability_cost: 2,
    ability_uses: 0,
    ability_verbal: "Elemental Blast! Spell 10 {element}",
    ability_tier_limit: null,
}

export const thunderstrike = {
    ability_name: "Thunderstrike",
    ability_text: "The elements fortify your strikes. You may deliver a %%Spell {damage} {element}%% attack that utilizes your pool from the Elemental Blast Ability. The damage is equal to your normal melee damage plus the maximum damage you deal from your Elemental Blast ability, and it's type may be selected from %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%%",
    pillar_name: "Evoker",
    prerequesite: "Elemental Blast",
    ability_tags: ["Spell", "Strike", "Elemental"],
    ability_cost: 2,
    ability_uses: 2,
    ability_tier_limit: null,
}

//Tier 3

export const elementalBlast3 = {
    ability_name: "Elemental Blast",
    ability_text: "The elements are yours to direct and they sear your foes away with ease. You gain a pool of twenty elemental damage which may be thrown in sums as great as ten, and may be thrown as %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% You must incant the Verbal and follow with %%Spell {damage} {element}%%. When purchased subsequent times, you add twenty to your pool of elemental damage. This pool refills at the start of each day.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Elemental"],
    ability_cost: 3,
    ability_uses: 0,
    ability_verbal: "Elemental Blast! Spell 10 {element}",
    ability_tier_limit: null,
}

export const searingTorrent = {
    ability_name: "Searing Torrent",
    ability_text: "You bring forth multiple jets of elemental power, scattering them amongst your foes. You must incant the Verbal and select a damage type from %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% You may then throw five %%Spell 20 {element}%% packets of the type selected. You must throw all packets within five seconds of completing your verbal.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Elemental"],
    ability_cost: 3,
    ability_uses: 1,
    ability_verbal: "Searing Torrent! Spell 20 {element}...",
    ability_tier_limit: null,
}

//Tier 4

export const elementalBlast4 = {
    ability_name: "Greater Elemental Blast",
    ability_text: "The elements are yours to direct and they sear your foes away with ease. You gain a pool of sixty elemental damage which may be thrown in sums as great as ten, and may be thrown as %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% You must incant the Verbal and follow with %%Spell {damage} {element}%%. When purchased subsequent times, you add twenty to your pool of elemental damage. This pool refills at the start of each day.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Elemental"],
    ability_cost: 4,
    ability_uses: 0,
    ability_verbal: "Elemental Blast! Spell 10 {element}",
    ability_tier_limit: null,
}

export const rendMetal = {
    ability_name: "Rend Metal",
    ability_text: "You call to the elements to suffuse your enemy’s armor, utterly destroying it. You must incant the Verbal and may then throw a %%Spell Sunder%% packet.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Sunder"],
    ability_cost: 4,
    ability_uses: 1,
    ability_verbal: "Rend Metal! Spell Sunder...",
    ability_tier_limit: null,
}

//Tier 5

export const elementalBlast5 = {
    ability_name: "Greater Elemental Blast",
    ability_text: "The elements are yours to direct and they sear your foes away with ease. You gain a pool of sixty elemental damage which may be thrown in sums as great as ten, and may be thrown as %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% You must incant the Verbal and follow with %%Spell {damage} {element}%%. When purchased subsequent times, you add twenty to your pool of elemental damage. This pool refills at the start of each day.",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Verbal", "Packet", "Elemental"],
    ability_cost: 5,
    ability_uses: 0,
    ability_verbal: "Elemental Blast! Spell 10 {element}...",
    ability_tier_limit: null,
}

export const desolation = {
    ability_name: "Desolation",
    ability_text: "In a single gesture, you call forth the wrath of the elements. You must incant the Verbal and select a damage type from %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%% You may then throw a %%Spell 100 {element}%% packet of the type selected.",
    pillar_name: "Evoker",
    prerequesite: "Elemental Blast",
    ability_tags: ["Spell", "Verbal", "Packet", "Elemental"],
    ability_cost: 5,
    ability_uses: 1,
    ability_verbal: "Desolation! Spell 100 {element}...",
    ability_tier_limit: null,
}

//Tierless

export const evokerExpertiseAbility = {
    ability_name: "Power Surge",
    ability_text: 'You can gather elemental energy to you at will, hurling it at your foes. At any time, you may choose to become Immobilized for up to five Seconds while completing a five Second \"Surging\" count. Upon successful completion of the count, you may throw a %%Spell {damage} Arcane packet.%% This packet deals damage equal to the number of times you have purchased this expertise.$$' +
    'You may choose instead to throw a packet that deals double this damage, but the damage type must be one of %%Acid%%, %%Cold%%, %%Fire%% or %%Shock.%%',
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Stance", "5 Count", "Packet", "Elemental"],
    ability_cost: null,
    ability_uses: 0,
    ability_tier_limit: 1,
}

export const evokerCapstoneAbility = {
    ability_name: "Evoker Champion",
    ability_text: "Twice per Encounter, when you suffer Acid, Cold, Fire, or Shock damage, you may call Resist and take no damage from the triggering effect. If you have taken Elemental Blast, the maxiumum sum of elemental damage you may throw in an individual packet doubles (from ten to twenty, or from twenty to forty.)",
    pillar_name: "Evoker",
    prerequesite: null,
    ability_tags: ["Spell", "Resist"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: 1,
}
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { ProfessionArray } from "../../data/Professions";
import { professionIconMapper } from "../../data/Icons";

function ProfessionAccordion({ profession, buildMode, unspentXp, buyProf }) {
  const professionName = profession.profession.name;

  const mapProfessionData = (input) => {
    if (input != null) {
      const name = input.profession.name;
      const returnable = ProfessionArray.filter(prof => prof.name.toLowerCase() === name.toLowerCase());
      return (returnable);
    }
    return null;
  }

  let profTimer;
  let profCostText;

  // Prof Data Matcher
  switch (profession.profession.name) {
    case "Artificer":
      profTimer = (6 - profession.profession_level) * 60;
      profCostText = "1 Hexite";
      break;
    case "Enchanter":
      profTimer = 1;
      profCostText = "1/2/3 Crystalized Mana";
      break;
    case "Oracle":
      profTimer = 6 - profession.profession_level;
      profCostText = "1 Sacred Candle";
      break;
    case "Taskmaster":
      profTimer = 1;
      profCostText = "1/2/3 Dragon's Tooth";
      break;
    case "Mystic":
      profTimer = (6 - profession.profession_level) * 60;
      profCostText = "1 Petrified Feather";
      break;
    default:
      profTimer = 5;
  }

  const profLevelUp = profession.profession_level + 1

  let levelUpCost;
  switch (profession.profession_number) {
    case 1:
      levelUpCost = 5 * profLevelUp;
      break;
    case 2:
      levelUpCost = 10 * profLevelUp;
      break;
    case 3:
      levelUpCost = 15 * profLevelUp;
      break;
    case 4:
      levelUpCost = 25 * profLevelUp;
      break;
    case 5:
      levelUpCost = 40 * profLevelUp;
      break;
    default:
      levelUpCost = 0;
  }

  let professionData = (mapProfessionData(profession))[0];

  const canBuy = levelUpCost < unspentXp;

  return (
    <Accordion sx={{ padding: "0.1rem" }}>
      <AccordionSummary sx={{ margin: "0px", maxWidth: 'xs', border: '2px solid', borderColor: "primary.dark" }}>
        <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems={"center"}>
          <Grid item xs={3}>
            <img object-fit="cover" height="50" alt="profession-icon" src={professionIconMapper(professionName.toLowerCase())}></img>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{fontWeight:"bold"}} variant="h6">
            {professionName.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1}>
              <Typography>
                Level:
              </Typography>
              <Typography color="primary.main">
                {profession.profession_level}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ border: '2px solid #2A2A2A' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ maxWidth: 'xs' }}>
            <Grid container item direction="column">
              <Typography>
                {professionData.description}
              </Typography>
              <Divider />
            </Grid>
            <Grid container direction="column" justifyContent="center" >
              <Grid container justifyContent={"center"}>
                <Grid item xs={4}>
                  <Typography color="secondary.main">
                    {professionData.ability_name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {profCostText}
                </Grid>
                <Grid item xs={4}>
                  <Stack direction="row" spacing={1}>
                    <Typography color="primary.main">
                      {profTimer}
                    </Typography>
                    <Typography>
                      Second Count
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item justifyContent="center" alignItems={"center"} direction="row">
                <Typography>
                  {professionData.ability_description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" />
          <Grid item xs={buildMode ? 3 : 0}>
          </Grid>
          {!buildMode ? null :
            <Grid container sx={{ minHeight: '100%', mt:'1rem' }} direction="column" justifyContent="center" alignitems="center">
              <Grid item>
                {profession.profession_level < 5 ?
                  <Button onClick={() => buyProf(profession, levelUpCost)} sx={{width:"50%", maxwidth:"xs"}} variant={canBuy ? "contained" : "outlined"} color={"success"} disabled={!canBuy}>
                    <Grid container direction="column" justifyContent="center" alignItems={"center"}>
                      <Grid item>
                        Purchase Level {profLevelUp}
                      </Grid>
                      <Grid item>
                        <Stack spacing={1} direction="row">
                          <Typography color={!canBuy ? "error" : null}>
                            {levelUpCost}
                          </Typography>
                          <Typography>
                            XP
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Button> :
                  null}
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProfessionAccordion;
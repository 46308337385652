const health = {
    concept_name: "Health",
    concept_type: "Statistics",
    concept_text: "Health measures the overall well-being of your character.$$ Your character's health is equal to their level as well as any other bonuses from abilities.$$ This number is either increased or reduced by damage and may not be reduced below 0. While a character has Health, they are considered to be of Living status; once at 0 health, a character falls to the Dying status. The number of your health in-game is never stated unless you are subjected to the Diagnose Ability, at which point you report your current and total HP like so: %%Ten of Fifty Health.%%"
}

const heritage = {
    concept_name: "Heritage",
    concept_type: "Statistics",
    concept_text: "All characters are native to the world of Daear, and as such, may hail from one of its many cultures and human-derived heritages. These are detailed in the individual heritage documents, containing brief histories, required costuming, benefits, and lists of names and places to help inspire character creation.$$"
}

const pillar = {
    concept_name: "Pillar",
    concept_type: "Statistics",
    concept_text: "A Pillar represents a larger theme or school of knowledge in the game world. These Pillars are: Martial, Alchemy, Arcana, Divinity, and Mastery. Within each pillar is a generalist pillar and two specialist pillars that can be selected if they are chosen as one of your character's Focuses."
}

const ability = {
    concept_name: "Ability",
    concept_type: "Statistics",
    concept_text: "An Ability is a single powerful action taken that calls upon the power of one of the pillars. These must be purchased within an ability tree, and must be purchased for XP."
}

const abilityTree = {
    concept_name: "Ability Tree",
    concept_type: "Statistics",
    concept_text: "An Ability Tree is the collection of abilities your character has purchased, as well as those which they have available for purchase. You may initially only buy abilities at the first tier of a Pillar’s Ability Tree. Once you have two abilities in the first tier, you may buy the first Ability in the next tier. You must always have one more Ability in all tiers below the current tier until you have four abilities in a tier. You may not purchase more than four abilities at any given tier; once you have purchased four abilities within each tier of your Pillar, the Pillar is completed. "
}

const tierExpertise = {
    concept_name: "Tier Expertise",
    concept_type: "Statistics",
    concept_text: "Often referred to as Expertise, Tier Expertise is the demonstration of your character's mastery of a given level of a Pillar. Each time you finish a tier within a Pillar, you may purchase the tier’s corresponding Tier Ability. Tier Expertise may only be purchased at the completion of a tier, but they do not provide any progression to the next tier. They symbolize your increasing mastery of a Pillar."
}

const tier = {
    concept_name: "Tier",
    concept_type: "Statistics",
    concept_text: "Pillars are constructed of five Tiers, which represent the level of complexity of specific abilities within that Pillar."
}

const capstoneConcept = {
    concept_name: "Capstone",
    concept_type: "Statistics",
    concept_text: "You may only purchase the Capstone Ability of the first two Pillars you complete (Your Focuses) and are unable to do so for any subsequent Pillars in which you purchase abilities. Capstone abilities have unique benefits that symbolize your total mastery of a Pillar and allow you to repurchase your existing abilities such that they may be used each encounter. Capstones also allow you to repurchase your Tier Expertise, which have their repurchases detailed in each Capstone. However, once you have a Pillar Capstone, you may no longer respend your abilities in that Pillar."
}

const focusConcept = {
    concept_name: "Focus Pillar",
    concept_type: "Statistics",
    concept_text: "Your Focus Pillar, or Focuses, are the first two Pillars you purchase abilities in.$$ Your second pillar costs 1.5 times as much as your first pillar.$$ These two pillars may be specialized, you may purchase the capstones for these pillars, and you may repurchase abilities within these pillars to convert them to encounter powers.$$ These are the only pillars you may opt to respend (but only before you buy their Capstone.)"
}

const supportConcept = {
    concept_name: "Support Pillar",
    concept_type: "Statistics",
    concept_text: "Your Support Pillars are your third, fourth, and fifth pillars. They are more expensive than your Focuses, and you cannot specialize, purchase capstones, acquire encounter powers, or respend them."
}

const reach = {
    concept_name: "Reach",
    concept_type: "System",
    concept_text: "The area within 180° in front of you that you can touch with your weapon of choice witout it leaving your hands."
}

const resist = {
    concept_name: "Resist",
    concept_type: "System",
    concept_text: "Target is unaffected by the most recent Ability that hit them. If necessary for clarification, or if multiple abilities are being resisted sequentially, each type of resist can be stated."
}

const skill = {
    concept_name: "Skill",
    concept_type: "System",
    concept_text: "Skill is a primary prefix for combat communication.$$ Skill indicates that all system statements following it are the result of that character using a skill, and that the opponent may use abilities that target skills to interact with the given effect."
}

const stance = {
    concept_name: "Stance",
    concept_type: "System",
    concept_text: "An ability that lasts for a set duration imparting a specific effect from its description."
}

const strike = {
    concept_name: "Strike",
    concept_type: "System",
    concept_text: "An attack that connects with another character. One that hits nothing is considered an \"unsuccessful strike\" and wastes any abilities that don't explicitly allow them to continue after the initial strike."
}

const damageConcept = {
    concept_name: "Damage",
    concept_type: "System",
    concept_text: "To deal damage to an opposing player, you will always state a number and then a damage type. Examples include “Ten Arcane,” “Four Pierce” and “Fifty Fire.” Damage types never have names that are the duplicates of effects.$$" +
        + "Most damage may be blocked by shields or other weapons. However, there are several damage types that can affect you if any part of your body or any possessions on your body are touched by a projectile."
}

const spell = {
    concept_name: "Spell",
    concept_type: "System",
    concept_text: "Spell is a primary prefix for combat communication.$$ Spell indicates that all system statements following it are the result of that character using a Spell, and that the opponent may use abilities that target Spells to interact with the given effect."
}

const shout = {
    concept_name: "Shout",
    concept_type: "System",
    concept_text: "Shout is a primary prefix for combat communication.$$ Shout indicates that all system statements following it are the result of that character using a Shout, and that the opponent may use abilities that target Shouts to interact with the given effect."
}

const level = {
    concept_name: "Level",
    concept_type: "System",
    concept_text: "Your character’s level is determined by adding the total number of tiers they have abilities unlocked in. This total includes all pillars they have unlocked, and increases by one when you repurchase abilities in pillars for which you have purchased the capstone. For example, a character with Five tiers of a pillar and encounter powers in their first and second tiers would be considered level seven."
}

const armor = {
    concept_name: "Armor",
    concept_type: "System",
    concept_text: "Your character may wear armor to stave off death up to a maximum of 10 points. Points of armor are subtracted in the same way that Health points are; however, armor is subtracted first. Characters who wear period-appropriate costuming are awarded two base points of armor. Individual armor points are assigned in accordance with the armor system."
}

const tempHealth = {
    concept_name: "Temporary Health",
    concept_type: "System",
    concept_text: "Your character can receive Temporary Health from abilities. These points cannot be healed like their conventional counterparts. Temporary Health is lost first when damage is recieved that would target Health. Multiple counts or sources of Temporary Health do not stack with each other, resolving to the highest value available. For example, if a character has 5 Temporary Health already and then receives 10 Temporary Health, they only have 10 Temporary Health."
}

const tempArmor = {
    concept_name: "Temporary Armor",
    concept_type: "System",
    concept_text: "Your character can receive Temporary Armor from abilities. These points cannot be repaired or reset like their conventional counterparts. Temporary Armor is lost first when damage is recieved that would target Armor. Multiple counts or sources of Temporary Armor do not stack with each other, resolving to the highest value available. For example, if a character has 5 Temporary Armor already and then receives 10 Temporary Armor, they only have 10 Temporary Armor."
}

const dying = {
    concept_name: "Dying",
    concept_type: "System",
    concept_text: "A character at 0 health begins to die over the course of 60 seconds.$$ You must silently count your %%Dying%% count to yourself. Only when subjected to the Diagnose Ability may you inform anyone of your count. If your count reaches 60, you immediately drop to the Dead status. Any appropriate healing/harming will immediately end this status and return you to full activity. While Dying or Dead, you must take a knee, bend over horizontally at the hips, or otherwise make it clear that you are pretending to be on the ground. Do not lie on the ground itself, as you are likely to become a hazard or injured."
}

const dead = {
    concept_name: "Dead",
    concept_type: "System",
    concept_text: "A character at the Dead status begins to disintegrate beyond the point of recognition. You must begin counting your %%Dead%% count over the course of 300 seconds. At the end of the count, you must exit play to resurrect. To do so, you must leave behind all in-play items and go to the plot cabin where your resurrection will be logged. You may then resurrect at the local spawn point."
}

const unliving = {
    concept_name: "Unliving",
    concept_type: "System",
    concept_text: "A character who is unliving is not dead, but is instead “not living” in the traditional way one uses that word. These characters are still capable of thinking and acting as though they were living; they can fall from the Unliving to Dying status as would the Living. However, Unliving characters have their Health increased by Harm damage, and take damage from Heal damage. They may never be subjected to the Undeath condition."
}

const killingBlow = {
    concept_name: "Killing Blow",
    concept_type:"System",
    concept_text: "A character can be killed outright at the completion of a three second count of %%Killing Blow%%, being immediately reduced to Dead status. Any character may simply say %%Resist%% to prevent this effect, but only if they are able to move either their arms or legs."
}

export const statistics = [armor, health, tempHealth, tempArmor, dying, dead, unliving, killingBlow, heritage, pillar, ability, abilityTree, tierExpertise, tier, capstoneConcept, focusConcept, supportConcept, damageConcept, reach, resist, skill, shout, spell, stance, strike, level];
import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { theme } from "../styles/AppTheme";

function copyright() {
    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ bgcolor: '#121212' }}>
                <Grid item xs={12}>
                    <Typography variant="caption" color="white" align="center" >
                        {'Copyright © Five Pillars LARP '}
                        {new Date().getFullYear()}
                    </Typography>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
export default copyright;
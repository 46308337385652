//Tier 1

export const cleave = {
    ability_name: "Cleave",
    ability_text: "Your followthrough is flawless, allowing you to sweep through your enemies with ease. You may call %%Skill {Damage} Pierce%% on one weapon Attack instead of your normal damage type.",
    pillar_name: "Berserker",
    prerequisite: null,
    ability_tags: ["skill", "strike", "pierce"],
    ability_cost: 1,
    ability_uses: 2,
    ability_tier_limit: null,
}

export const powerStrike = {
    ability_name: "Power Strike",
    ability_text: "Superhuman strength comes naturally to a Berserker. On your next Swing, you increase your one-handed damage by five and this is doubled when used with a two-handed weapon.",
    pillar_name: "Berserker",
    prerequisite: null,
    ability_tags: ["skill", "strike"],
    ability_cost: 1,
    ability_uses: 2,
    ability_tier_limit: null,
}

//Tier 2

export const battery = {
    ability_name: "Battery",
    ability_text: "A two-handed shove and kick sends your opponent sprawling. You may call %%Skill Push 3%% on a successful strike with a weapon.",
    pillar_name: "Berserker",
    prerequisite: null,
    ability_tags: ["skill", "strike", "push"],
    ability_cost: 2,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const prof2 = {
    ability_name: "Weapons Proficiency",
    ability_text: "You are the consummate warrior, your prowess reflected in your handling of all weapons. You gain a bonus of one to the damage you deal with one-handed weapons. This damage is cumulative with all further purchases of this Ability, and this is doubled when used with two-handed weapons.",
    pillar_name: "Berserker",
    prerequisite: null,
    ability_tags: ["passive", ],
    ability_cost: 2,
    ability_uses: null,
    ability_tier_limit: 1,
}

//Tier 3

export const bloodrush = {
    ability_name: "Blood Rush",
    ability_text: "Seeing your enemy’s blood fills you with vitality. You may call %%Skill {Damage} Drain%% on one weapon Attack instead of your normal damage type.",
    pillar_name: "Berserker",
    prerequisite: null,
    ability_tags: ["skill", "strike", "drain"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const mightyBlows = {
    ability_name: "Mighty Blows",
    ability_text: "Carving apart your foes is just another day for the Berserker. For the next five Swings, you increase your one-handed damage by five and this is doubled when used with two-handed weapons. Additionally, you call %%Pierce%% instead of your normal damage type.",
    pillar_name: "Berserker",
    prerequisite: "Cleave",
    ability_tags: ["skill", "self", "pierce"],
    ability_cost: 3,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 4

export const demolisher = {
    ability_name: "Demolisher",
    ability_text: "Armor is for weaker beings; you will teach them what it means to be strong. You may call %%Skill Sunder%% on a successful strike with a weapon.",
    pillar_name: "Berserker",
    prerequisite: "Battery",
    ability_tags: ["skill", "strike", "sunder"],
    ability_cost: 4,
    ability_uses: 2,
    ability_tier_limit: null,
}

export const seethe = {
    ability_name: "Seethe",
    ability_text: "Fury powers you, motivates you, and lets you simply ignore wounds that normally kill the weak. Upon completion of a sixty Second Count, you may set your Health to half its maximum value.",
    pillar_name: "Berserker",
    prerequisite: null,
    ability_tags: ["skill", "self", "60 count"],
    ability_cost: 4,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tier 5

export const bloodbath = {
    ability_name: "Bloodbath",
    ability_text: "Taking the role of the Death Dealer, you smite hither and thither. You may choose to be Immobilized for up to ten Seconds. While Immobilized, you increase your one-handed damage by five and this is doubled when used with two-handed weapons. If any strike delivered by this Ability is Countered, the Ability immediately ends.",
    pillar_name: "Berserker",
    prerequisite: "Mighty Blows",
    ability_tags: ["skill", "stance"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

export const mortalStrike = {
    ability_name: "Mortal Strike",
    ability_text: "Your enemies are simply meat waiting for your butcher’s hand. You may call %%Skill 100 Pierce%% on a successful strike with a weapon.",
    pillar_name: "Berserker",
    prerequisite: "Slay",
    ability_tags: ["skill", "strike", "pierce"],
    ability_cost: 5,
    ability_uses: 1,
    ability_tier_limit: null,
}

//Tierless

export const berserkerCapstoneAbility = {
    ability_name: "Berserker Champion",
    ability_text: "Once per Encounter, when damage would reduce you to the Dying status, you may call %%Resist%% and drop to 1 health instead.",
    pillar_name: "Berserker",
    prerequesite: null,
    ability_tags: ["skill", "resist"],
    ability_cost: 6,
    ability_uses: 1,
    ability_tier_limit: null,
    usage: "encounter"
}

export const berserkerExpertiseAbility = {
    ability_name: "Constitution",
    ability_text: "You have the strength of a raging bull and the stamina of a hunting wolf. You gain +4 to your Health total.",
    pillar_name: "Berserker",
    prerequisite: null,
    ability_tags: ["passive"],
    ability_cost: null,
    ability_uses: null,
    ability_tier_limit: 1,
}
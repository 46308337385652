import { Button, Divider, Fade, Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createContext, useContext, useState } from "react";
import { activeCharacterContext, charactersContext, relevantModalObjContext } from "../../../App";
import { buildModeContext, modifiedAbilsContext, relevantPillarInventoryContext } from "../../CharacterScreen/CharacterScreen";
import { capstoneModalOpenContext } from "../../PillarBuild/PillarBuild";
import CapstoneConfirmationModal from "./CapstoneConfirmationModal";
import CapstoneModalHeader from "./CapstoneModalHeader";
import CloseIcon from '@mui/icons-material/Close';
import { formatTextBlob } from "../../../processing/DataFormattingUtils";

export const capstoneConfirmationModalOpenContext = createContext(false);

function CapstoneModal() {

    const { capstoneModalOpen, setCapstoneModalOpen } = useContext(capstoneModalOpenContext);
    const { relevantModalObj, setRelevantModalObj } = useContext(relevantModalObjContext);
    const { buildMode, setBuildMode } = useContext(buildModeContext);
    const { relevantPillarInventory, setRelevantPillarInventory } = useContext(relevantPillarInventoryContext);
    const { activeCharacter, setActiveCharacter } = useContext(activeCharacterContext);
    const { characters, setCharacters } = useContext(charactersContext);
    const [capstoneConfirmationModalOpen, setCapstoneConfirmationModalOpen] = useState(false);
    const { modifiedAbils, setModifiedAbils } = useContext(modifiedAbilsContext);

    const currentPillarNumber = relevantModalObj.pillar_number;
    const currentPillarbuild = relevantPillarInventory[currentPillarNumber];

    const capstoneCost = currentPillarNumber === 0 ? 6 : 12;

    let modifiedPillarBuild = { ...currentPillarbuild };
    let modifiedPillarInventory = [ ...relevantPillarInventory ];
    let modifiedActiveCharacter = { ...activeCharacter };
    let modifiedCharactersArray = [...characters];

    const characterIndex = modifiedCharactersArray.findIndex((character) => character.character_name === activeCharacter.character_name);

    const handleClose = (event) => {
        setRelevantModalObj("");
        setCapstoneModalOpen(false);
    }

    const openConfirm = (event) => {
        setCapstoneConfirmationModalOpen(true);
    }

    let capstoneExists = false;
    let purchaseValid = false;

    const handleBuy = (pillarNumber) => {
        modifiedPillarBuild.capstone = relevantModalObj;
        modifiedPillarInventory[currentPillarNumber] = modifiedPillarBuild;
        modifiedActiveCharacter.xp_unspent = activeCharacter.xp_unspent - capstoneCost;
        modifiedCharactersArray[characterIndex] = modifiedActiveCharacter;
        setModifiedAbils(true);
        setRelevantPillarInventory(modifiedPillarInventory);
        setActiveCharacter(modifiedActiveCharacter);
        setCharacters(modifiedCharactersArray);
        setCapstoneConfirmationModalOpen(false);
    }

    const exitModal = (event) => {
        setCapstoneConfirmationModalOpen(false);
    }

    const capstoneFind = relevantPillarInventory[relevantModalObj.pillar_number]
    if (capstoneFind && capstoneFind.capstone) {
        capstoneExists = true;
        purchaseValid = false;
    }
    else if (capstoneFind && !capstoneFind.capstone) {
        purchaseValid = (capstoneFind.daily_abilities.length === 20);
    }

    return (
        <Modal
            open={capstoneModalOpen}
            onClose={() => { setRelevantModalObj("") }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: "scroll" }}
            closeAfterTransition
        >
            <Fade in={capstoneModalOpen} timeout={200}>
                <Box>
                    <capstoneConfirmationModalOpenContext.Provider value={{ capstoneConfirmationModalOpen, setCapstoneConfirmationModalOpen }}>
                        <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%' }}>
                            <Grid item sx={{ maxWidth: 'sm' }}>
                                <CapstoneConfirmationModal exit={exitModal} onConfirm={handleBuy} />
                                <Paper sx={{ margin: '40px auto 0', padding: '40px', borderRadius: '10px', maxWidth: 'sm' }}>
                                    <CapstoneModalHeader capstone={relevantModalObj} />
                                    {relevantModalObj.capstone_name === " " ? null : <Divider />}
                                    <Grid container maxWidth="m" id="modal-modal-description" sx={{ mt: 2 }}>
                                        {relevantModalObj.capstone_text ? formatTextBlob(relevantModalObj.capstone_text) : null}
                                    </Grid>
                                    {!capstoneExists && buildMode ?
                                        <Box>
                                            <Divider sx={{ my: "10px" }} />
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="button" color="error" sx={{ py: "10px" }}>
                                                        Pillar Capstone purchase is permanent, disabling the respending of your abilities
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ my: "10px" }} />
                                        </Box>
                                        :
                                        null
                                    }
                                    <Grid container justifyContent="space-between" sx={{ my: "10px" }}>
                                        <Grid item>
                                            <Button startIcon={<CloseIcon />} variant="contained" color="success" onClick={handleClose}> Close </Button>
                                        </Grid>
                                        {!capstoneExists && purchaseValid && buildMode ?
                                            <Grid item>
                                                <Button variant="contained" color="success" onClick={openConfirm}> Buy </Button>
                                            </Grid>
                                            :
                                            null
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </capstoneConfirmationModalOpenContext.Provider>
                </Box>
            </Fade>
        </Modal>
    )
}

export default CapstoneModal;
